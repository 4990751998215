import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../../global-states/useAuthState';
import moment from 'moment/moment';
import { orderStatusDateFormatter } from '../../../utils/DateFormatter';
import { FirebaseCateringRequest } from './types';
import { ReactComponent as CheckIcon } from '../../../assets/green-check.svg';
import { ReactComponent as RejectIcon } from '../../../assets/crossIcon.svg';

interface CateringRequestDetailedHistoryProps {
  request: FirebaseCateringRequest;
}

export const CateringRequestDetailedHistory: React.FC<
  CateringRequestDetailedHistoryProps
> = ({ request }) => {
  const { t } = useTranslation('catering');
  const auth: any = useAuthState.getState();

  return (
    <div className={'flex justify-center w-full'}>
      <div
        className={'bg-white rounded-xl lg:w-2/3 md:w-full sm:w-full mt-8 p-4'}
      >
        <div className={'flex justify-between'}>
          <section className='w-full'>
            <div className='flex justify-between'>
              <div className='flex font-medium text-lg pl-2 md:pl-4'>
                {t('status_history.label')}
              </div>
              <div className='flex items-center'>
                <div className='bg-gray-100 py-1 px-2 sm:px-2 md:px-3 lg:px-4 ml-1 rounded-md text-center text-md sm:text-md md:text-md lg:text-lg font-medium'>
                  {moment(request?.requestStatusTimestamp.received).format(
                    'D MMMM YYYY'
                  )}
                </div>
              </div>
            </div>
            <div className='container p-2 lg:p-4 md:p-3 sm:p-2 mx-auto mt-2 flex flex-wrap'>
              <div className='flex flex-wrap w-full'>
                <div className='w-4/5'>
                  <div className='flex relative pb-12'>
                    <div className='h-full w-8 sm:w-8 md:w-10 lg:w-10 absolute inset-0 flex items-center justify-center'>
                      <div className='h-full w-1 bg-gray-200 pointer-events-none' />
                    </div>
                    <div className='flex-shrink-0 w-8 h-8 rounded-full bg-light_green inline-flex items-center justify-center text-white relative z-10 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10'>
                      <CheckIcon />
                    </div>
                    <div className='w-full pl-4'>
                      <h2 className='text-md text-black mb-1'>
                        1 - {t('received.label')}
                      </h2>
                      <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                        {t('received.subtext')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                  {orderStatusDateFormatter(
                    request?.requestStatusTimestamp.received,
                    auth.companyLanguage,
                    auth.clockFormat12,
                    true
                  )}
                </div>
                <div className='w-4/5'>
                  <div className='flex relative pb-2'>
                    <div
                      className={`flex-shrink-0 w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10 rounded-full ${
                        request?.requestStatus !== 'received'
                          ? request?.requestStatus === 'confirmed'
                            ? 'bg-light_green'
                            : 'bg-red-100'
                          : 'bg-gray-200'
                      } inline-flex items-center justify-center text-white relative z-10`}
                    >
                      {request?.requestStatus !== 'received' &&
                        (request?.requestStatus === 'confirmed' ? (
                          <CheckIcon />
                        ) : (
                          <RejectIcon />
                        ))}
                    </div>
                    <div className='flex-grow pl-4'>
                      <h2
                        className={`text-md ${
                          request?.requestStatus !== 'received'
                            ? 'text-black'
                            : 'text-gray-400'
                        } mb-1`}
                      >
                        2 -{' '}
                        {request?.requestStatus !== 'rejected'
                          ? t('confirmed.label')
                          : t('rejected.label')}{' '}
                      </h2>
                      <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                        {request?.requestStatus !== 'rejected'
                          ? t('confirmed.subtext')
                          : t('rejected.subtext')}
                      </p>
                    </div>
                  </div>
                </div>
                {request?.requestStatus !== 'received' && (
                  <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                    {request?.requestStatus === 'confirmed'
                      ? moment(
                          request?.requestStatusTimestamp.confirmed
                        ).format('D MMMM YYYY') +
                        '\n' +
                        orderStatusDateFormatter(
                          request?.requestStatusTimestamp.confirmed,
                          auth.companyLanguage,
                          auth.clockFormat12,
                          true
                        )
                      : moment(request?.requestStatusTimestamp.rejected).format(
                          'D MMMM YYYY'
                        ) +
                        '\n' +
                        orderStatusDateFormatter(
                          request?.requestStatusTimestamp.rejected,
                          auth.companyLanguage,
                          auth.clockFormat12,
                          true
                        )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
