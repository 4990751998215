import React, { useState } from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { createCompanyFeedback } from 'firebase-logic/feedback';
import useAuthState from 'global-states/useAuthState';
import { FeedbackObject, FeedbackTag } from '../types';

export default function FeedbackModal({ onClose, open }: any) {
  const auth: any = useAuthState.getState();

  const cancelButtonRef: any = useRef();
  const [feedbackForm, setFeedbackForm] = useState<FeedbackObject>({
    tag: undefined,
    message: '',
  });

  const handleFeedbackTypeClick = (tag: FeedbackTag) => {
    if (tag !== feedbackForm.tag) {
      setFeedbackForm((prevFeedback) => ({
        ...prevFeedback,
        tag: tag,
      }));
    } else {
      setFeedbackForm((prevFeedback) => ({
        ...prevFeedback,
        tag: undefined,
      }));
    }
  };
  const handleFeedbackMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedbackForm((prevFeedback) => ({
      ...prevFeedback,
      message: event.target.value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createCompanyFeedback(
        auth,
        feedbackForm,
        t('toastSuccess.label'),
        t('toastError.label')
      );
      onClose();
      setFeedbackForm({ tag: undefined, message: '' });
    } catch (error) {
      console.log('error in saving feedback', error);
    }
  };

  const handleClose = () => {
    onClose();
    setFeedbackForm({ tag: undefined, message: '' });
  };

  const { t } = useTranslation(['feedback']);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={!!open}
        onClose={handleClose}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form
              onSubmit={(e) => handleSubmit(e)}
              className='p-6 lg:absolute inline-block lg:top-4 lg:right-40 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:mx-0 mx-4  sm:align-middle sm:max-w-lg'
            >
              <div className='max-w-md mx-auto'>
                <h1 className=' text-lg'>
                  <b>Feedback</b>
                </h1>
                <p className=' text-gray-400 mt-2'>
                  {t('feedbackInstruction.label')}
                </p>
                <div className='my-4 flex space-x-2 justify-around'>
                  <button
                    type='button'
                    className={`feedback_button ${
                      feedbackForm.tag === FeedbackTag.GoodJob
                        ? 'border-droov text-droov'
                        : ''
                    }`}
                    id='good-job-button'
                    onClick={() => handleFeedbackTypeClick(FeedbackTag.GoodJob)}
                  >
                    {t('goodJob.label')} &#x1F60A;
                  </button>
                  <button
                    type='button'
                    className={`feedback_button ${
                      feedbackForm.tag === FeedbackTag.Criticise
                        ? 'border-droov text-droov'
                        : ''
                    }`}
                    id='criticise-button'
                    onClick={() =>
                      handleFeedbackTypeClick(FeedbackTag.Criticise)
                    }
                  >
                    {t('criticise.label')} &#x1F615;
                  </button>
                  <button
                    type='button'
                    className={`feedback_button ${
                      feedbackForm.tag === FeedbackTag.Idea
                        ? 'border-droov text-droov'
                        : ''
                    }`}
                    id='idea-button'
                    onClick={() => handleFeedbackTypeClick(FeedbackTag.Idea)}
                  >
                    {t('idea.label')} &#x1F4A1;
                  </button>
                  <button
                    type='button'
                    className={`feedback_button  ${
                      feedbackForm.tag === FeedbackTag.Bug
                        ? 'border-droov text-droov'
                        : ''
                    }`}
                    id='bug-button'
                    onClick={() => handleFeedbackTypeClick(FeedbackTag.Bug)}
                  >
                    {t('bug.label')} &#x1F41E;
                  </button>
                </div>
                <textarea
                  value={feedbackForm.message}
                  onChange={handleFeedbackMessageChange}
                  placeholder={t('feedbackPlaceholder.label')}
                  className='my-4 placeholder-italic resize-none focus:ring-droov focus:border-droov block w-full sm:text-sm border-gray-300 rounded-md'
                  name='feedback-text'
                  rows={4}
                  id={'feedback-text'}
                  required={true}
                />
              </div>
              <div />
              <div className='mt-6 flex items-center justify-end'>
                <div className='flex justify-end items-center'>
                  <button
                    type='button'
                    className='cancel_button mr-2'
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    {t('cancel.button')}
                  </button>
                  <button
                    className='relative_blue_button h-9 w-24'
                    type='submit'
                    disabled={feedbackForm.message === ''}
                  >
                    {t('send.button')}
                  </button>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
