import React, { ForwardedRef, forwardRef, memo, ReactNode, useMemo } from 'react';
import {
  CustomerObject,
  CustomerStatistics,
  customerStatisticsKeys,
} from '@common/types';
import { useTranslation } from 'react-i18next';

export type CustomerKeys = keyof CustomerObject | keyof CustomerStatistics;
export type RowStyle = Record<
  CustomerKeys,
  {
    color?: string;
    fontWeight?: string;
    textAlign?: string;
  }
>;
export type RowUtils = Record<
  CustomerKeys,
  { format: (s: unknown) => ReactNode | string }
>;
type RowKeys = (keyof RowStyle)[];

function isCustomerObjectKey(keyInput: unknown): keyInput is CustomerObject {
  return !customerStatisticsKeys.find((key) => key === keyInput);
}

type RowProp = {
  row: CustomerObject;
  keys?: RowKeys;
  rowStyle?: Partial<RowStyle>;
  utils?: Partial<RowUtils>;
  onClick: (row: CustomerObject) => void
};

const CustomersTableRow = memo(
  forwardRef(
    (
      { row, keys, rowStyle, utils, onClick }: RowProp,
      ref: ForwardedRef<HTMLTableRowElement>
    ) => {
      const rowKeys = keys ?? (Object.keys(row) as RowKeys);
      const { t } = useTranslation(['customer']);
      const getKey = (row: CustomerObject, key: CustomerKeys): unknown => {
        if (isCustomerObjectKey(key)) {
          return row[key as keyof CustomerObject];
        } else {
          return row.stats?.[key as keyof CustomerStatistics];
        }
      };
      const rowColumns = useMemo(
        () =>
          rowKeys.map((key, idx) => {
            const color = rowStyle?.[key]?.color ?? 'text-gray-500';
            const fontWeight = rowStyle?.[key]?.fontWeight ?? '';
            const textAlign = rowStyle?.[key]?.textAlign ?? '';
            return (
              <td
                className='px-4 py-3 w-4/12 align-middle overflow-auto'
                key={idx}
              >
                <p
                  className={`text-sm text-left items-center py-2 ${fontWeight} ${color} ${textAlign}`}
                >
                  {utils?.[key]?.format(getKey(row, key)) ??
                    getKey(row, key) ??
                    t('noData.label')}
                </p>
              </td>
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }),
        [rowKeys, rowStyle, row, utils]
      );

      return (
        <tr
          ref={ref}
          className="flex w-full bg-white hover:bg-gray-100 cursor-pointer"
          onClick={() => onClick(row)}
          id={`row-${row.uid}`}
        >
          {rowColumns}
        </tr>
      );
    }
  )
);

export default CustomersTableRow;
