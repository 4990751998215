import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomBreadCrumb from 'ui-components/CustomBreadCrumb';
import { SubscriptionAPI } from 'api/subscription';
import toast from 'react-hot-toast';
import CustomLoadingScreen from 'ui-components/CustomLoadingScreen';
import { BillingInformation } from 'ui-components/billing-components/BillingInformation';
import { REACT_APP_OLD_IDS } from 'config';
import { Customer, Subscription } from '../types';
import { SubscriptionInfo } from 'ui-components/billing-components/SubscriptionInfo';
import useAuthState from 'global-states/useAuthState';
import { useTranslation } from 'react-i18next';

export const SubscriptionSettings = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const [customer, setCustomer] = useState<Customer>();
  const auth: any = useAuthState.getState();

  const { t } = useTranslation(['onboarding']);

  const getSubscription = async () => {
    const request = await SubscriptionAPI.getSubscription();
    if (request?.ok) {
      const subscription = await request.json();
      console.log(subscription);
      setSubscription({
        current_period_end: new Date(
          subscription.subscription.current_period_end * 1000
        ),
        current_period_start: new Date(
          subscription.subscription.current_period_start * 1000
        ),
        cancel_at_period_end: subscription.subscription.cancel_at_period_end,
        canceled_at: new Date(subscription.subscription.canceled_at * 1000),
        status: subscription.subscription.status,
        metadata: subscription.subscription.metadata,
      });
    } else {
      console.error('Request:', request);
      toast.error(
        'Something went wrong while fetching your subscription status.'
      );
    }
  };

  const handleSaveUpdatedBillingInformation = async (
    updatedBillingInformation: Customer
  ) => {
    toast.loading('Saving billing information');
    const request = await SubscriptionAPI.updateCustomer(
      updatedBillingInformation
    );
    if (request?.ok) {
      toast.dismiss();
      setCustomer(updatedBillingInformation);
      toast.success('Billing information is updated successfully.');
    } else {
      toast.dismiss();
      console.error(request);
      toast.error(
        'Something went wrong while updating your billing information. Please try again later or contact support.'
      );
    }
  };

  const getCustomer = async () => {
    const request = await SubscriptionAPI.getCustomer();
    if (request?.ok) {
      const customer = await request.json();
      setCustomer({
        id: customer.id,
        name: customer.name,
        email: customer.email,
        address: {
          line1: customer.address.line1,
          line2: customer.address?.line2 ?? '',
          postal_code: customer.address.postal_code,
          city: customer.address.city,
          country: customer.address?.country ?? '',
        },
        phone: customer.phone,
        vat:
          customer.tax_ids.total_count > 0
            ? customer.tax_ids.data[0].value
            : '',
        customer_name: customer.invoice_settings?.custom_fields
          ? customer.invoice_settings?.custom_fields[0].value
          : '',
      });
      console.log(customer);
    } else {
      toast.error(
        'Something went wrong while fetching your customer information.'
      );
    }
  };

  const handleGetBillingPortal = async (
    setDisabled: Dispatch<SetStateAction<boolean>>
  ) => {
    toast.loading('Loading billing view');
    try {
      const request = await SubscriptionAPI.requestBillingPortal();
      if (request?.ok) {
        const session = await request.json();
        // Redirect to the Stripe portal session URL
        toast.dismiss();
        window.location.href = session.url;
      }
    } catch (error) {
      toast.dismiss();
      console.error('Error redirecting to the billing portal:', error);
      toast.error('Error redirecting to the billing portal');
      setDisabled(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getCustomer();
    await getSubscription();
    setIsLoading(false);
  };

  useEffect(() => {
    if (REACT_APP_OLD_IDS(auth.user)) {
      return;
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=''>
      <CustomBreadCrumb
        onClickAction={() => history.goBack()}
        displayString={'Settings'}
      />
      <h1 className='font-medium text-2xl pt-1'>
        {t('billing_and_plan.label')}
      </h1>

      {isLoading ? (
        <div className='flex flex-col mx-auto my-auto gap-9'>
          <CustomLoadingScreen />
          <p className='mt-5 text-lg'>{t('billing_loading.label')}</p>
        </div>
      ) : (
        <>
          <SubscriptionInfo
            subscription={subscription!}
            onHandleGetBillingPortal={handleGetBillingPortal}
          />
          <BillingInformation
            billingInformation={customer!}
            onSave={handleSaveUpdatedBillingInformation}
          />
        </>
      )}
    </div>
  );
};
