import CustomInputField from './CustomInputField';
import useAuthState from '../global-states/useAuthState';

export default function InputFieldWithType({
  onInputFieldValueChanged,
  value,
  disabled,
  unit,
  classnameadditional,
}: any) {
  const auth: any = useAuthState.getState();
  return (
    <div className='relative rounded-md shadow-sm'>
      <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
        <span className='text-gray-500 sm:text-sm'>
          {/*Using the js library to get the only symbol based on company currency and company language*/}
          {unit
            ? unit
            : new Intl.NumberFormat(auth.companyLanguage, {
                style: 'currency',
                currency: auth.companyCurrency,
              })
                .format(0)
                .replace(/[\d,.]/g, '')}
        </span>
      </div>
      <CustomInputField
        required={true}
        value={value}
        onChange={(e: any) => onInputFieldValueChanged(e.target.value)}
        type='text'
        inputMode='numeric'
        pattern='[0-9]+([.][0-9]+)?'
        disabled={disabled === undefined ? false : disabled}
        name='price'
        id='price'
        classnameadditional={
          disabled
            ? `bg-gray-100 text-right text-gray-600 placeholder-italic mr-3 mt-1 ${classnameadditional}`
            : `placeholder-italic text-right mr-3 mt-1 ${classnameadditional}`
        }
        placeholder='0.00'
      />
    </div>
  );
}
