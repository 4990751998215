import create from 'zustand';

export const onboardingInitialData = {
  currentStep: 0,
  allStepsCompleted: false,
  storeInfoStep: {
    completed: false,
    storeName: '',
    storeUrl: '',
    storePhoneNumber: '',
  },
  storeLocationStep: {
    completed: false,
    streetAndHouse: '',
    postcode: '',
    city: '',
    country: '',
    lattitude: 48.137274,
    longitude: 11.578517,
    language: '',
    utcOffsetMinutes: 0,
    currency: '',
  },
  storeAuthStep: {
    completed: false,
    email: '',
    password: '',
    repeatedPassword: '',
  },
};

interface OnboardingState {
  onboarding: typeof onboardingInitialData;
  setOnboardingState: (onboarding: typeof onboardingInitialData) => void;
}

export const useOnboardingState = create<OnboardingState>((set) => ({
  onboarding: onboardingInitialData,
  setOnboardingState: (onboarding) =>
    set({
      onboarding,
    }),
}));
