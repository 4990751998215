import React, { Ref, ReactElement, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useRefOnScreen from '../hooks/useRefOnScreen';
import { OrderObject } from '../types';

export default function OrdersTable({
  orders,
  onLoadMore,
  moreOrdersLoading,
  noOrdersLeft,
  orderRow,
  lastOrderRow,
}: {
  orders: OrderObject[];
  onLoadMore: () => void;
  moreOrdersLoading: boolean;
  noOrdersLeft: boolean;
  orderRow: (order: OrderObject, idx: number) => ReactElement;
  lastOrderRow: (
    order: OrderObject,
    ref: Ref<HTMLTableRowElement>,
    idx: number
  ) => ReactElement;
}) {
  const screenRef = useRefOnScreen<HTMLTableRowElement>(onLoadMore);
  const { t } = useTranslation(['orders']);
  const tableRef = useRef<HTMLTableSectionElement | null>(null);

  const memoizedRows = useMemo(() => {
    return orders.map((order: OrderObject, idx: number) => {
      if (idx === orders.length - 1) {
        return lastOrderRow(order, screenRef, idx);
      } else {
        return orderRow(order, idx);
      }
    })
  }, [orders, orderRow, lastOrderRow]);

  return (
    <>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 w-full'>
          <div className='py-2 align-middle inline-block min-w-full lg:px-0'>
            <div>
              <table className='text-left w-full divide-y divide-gray-200'>
                <tbody
                  className='bg-grey-light flex flex-col items-center w-full divide-y divide-gray-200'
                  ref={tableRef}
                >
                {memoizedRows}
                {/*Load More*/}
                {noOrdersLeft && !moreOrdersLoading ? (
                  <tr
                    className='flex bg-white justify-center text-sm font-bold text-blue-900 uppercase tracking-wider rounded-b-xl cursor-default focus:ring-0 w-full'>
                    <td colSpan={10} className='p-3 focus:ring-0'>
                      {t('allordersloaded.label')}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <div className="loader ease-linear rounded-full border-4 h-6 w-6 my-4" />
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/*)}*/}
    </>
  );
}
