import db from './../firebase';
import toast from 'react-hot-toast';

export const createLabels = async (
  labels: any,
  user: any,
  setCatalogue: any,
  catalogue: any,
  productId?: any
) => {
  //IN USAGE
  //if creating label
  try {
    const loading = toast.loading('Loading...');

    //if adding for a specific product
    setCatalogue({
      ...catalogue,
      products: {
        ...catalogue.products,
        [productId]: {
          ...catalogue.products[productId],
          labels: labels,
        },
      },
    });
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({
        labels: labels,
      });

    toast.success('Label created', { id: loading });
  } catch (error) {
    toast.dismiss();
    toast.error('Labels not created');
    return;
  }
};
