import React, { useRef, VFC } from 'react';
import ReactDOM from 'react-dom';
import LogoPaypal from 'assets/logo-paypal.png';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';

type PayPalDialogProps = {
  dialogIsOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  hasCompletedOnboarding: boolean;
  onCreateAccountLink: () => void;
};

export const PayPalDialog: VFC<PayPalDialogProps> = (props) => {
  const { t } = useTranslation(['settings']);

  const {
    dialogIsOpen,
    onClose,
    isLoading,
    hasCompletedOnboarding,
    onCreateAccountLink,
  } = props;

  const cancelButtonRef: any = useRef();
  return ReactDOM.createPortal(
    <Transition.Root show={dialogIsOpen} as={'div'}>
      <Dialog
        as='div'
        static
        className=' fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={dialogIsOpen}
        onClose={onClose}
      >
        <div className=''>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className=' fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='absolute w-full md:w-auto top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-6 shadow-lg rounded-lg flex flex-col gap-2.5'
              style={{ transform: 'translate(-50%, -50%)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className='text-lg font-bold leading-4 mb-3'>
                {t('connectpaypal.modal.label')}
              </h1>
              <div className='flex flex-col-reverse md:flex-row items-center border-2 border-droov_text_description_light rounded-md px-6 py-3.5'>
                <p className='text-sm droov_text_description_light w-80'>
                  {t('connectpaypal.modal.description')}
                </p>
                <img
                  src={LogoPaypal}
                  alt='PayPal'
                  className='my-3 md:ml-10 h-8'
                />
              </div>
              <h2 className='font-medium leading-4 text-sm'>
                {t('connectpaypal.modal.hint')}
              </h2>
              <p
                className={`text-sm font-medium leading-4 ${
                  hasCompletedOnboarding
                    ? 'text-green-400'
                    : 'droov_text_description_light'
                }`}
              >
                {!hasCompletedOnboarding ? (
                  <>&#8226; {t('onboarding.incompleted')}</>
                ) : (
                  <>&#10003; {t('onboarding.completed')}</>
                )}
              </p>
              <div className='flex justify-end items-center gap-4 mt-4'>
                <button
                  className='cancel_button'
                  ref={cancelButtonRef}
                  onClick={() => onClose()}
                >
                  {t('cancel')}
                </button>
                {!hasCompletedOnboarding && (
                  <button
                    className='relative_add_button h-10'
                    onClick={() => {
                      if (!hasCompletedOnboarding) {
                        onCreateAccountLink();
                      }
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className='flex gap-2 items-center'>
                        <div className='inline-block loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4'></div>
                        <span>{t('processing')}</span>
                      </div>
                    ) : (
                      t('start.onboarding')
                    )}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body
  );
};
