import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAuthState from '../../../global-states/useAuthState';
import { useTranslation } from 'react-i18next';
import { FirebaseCateringRequest } from './types';
import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../../CustomHeaderComponent';
import {
  fetchRequestDetails,
  updateRequestStatus,
} from '../../../firebase-logic/cateringRequests';
import CustomBreadCrumb from '../../CustomBreadCrumb';
import moment from 'moment';
import { ReactComponent as PhoneIcon } from '../../../assets/phone.svg';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../../config';
import HomeIcon from '../../../assets/homeIcon.svg';
import CustomLoadingScreen from '../../CustomLoadingScreen';
import toast from 'react-hot-toast';
import { modalConfirm, ModalMode } from '../../ConfirmationModal';
import { CateringRequestDetailedHistory } from './CateringRequestDetailedHistory';
import AddressCard from '../../AddressCard';

interface Params {
  requestId: string;
}

const googleMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  mapId: 'd5eece9d53c1b22a',
  fullscreenControl: false,
  zoomControl: false,
  gestureHandling: `none`,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const CateringRequestDetailScreen: React.FC = () => {
  const history = useHistory();
  const { requestId } = useParams<Params>();
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['catering']);

  const [request, setRequest] = useState<FirebaseCateringRequest | undefined>(
    undefined
  );
  const [center, setCenter] = useState<any>(null);
  const [rejectEnabled, setRejectEnabled] = useState(true);
  const [confirmEnabled, setConfirmEnabled] = useState(true);

  useEffect(() => {
    if (!request) {
      return;
    }
    setConfirmEnabled(request.requestStatus === 'received');
    setRejectEnabled(request.requestStatus === 'received');
  }, [request, request?.requestStatus]);

  const headerComponent: CustomHeaderComponentProps = {
    heading: t('cateringRequestDetails.label'),
    showButton: false,
  };

  const handleBackNavigation = () => {
    history.push(`/orders#catering`);
  };

  useEffect(() => {
    if (requestId) {
      fetchRequestDetails(auth.user, requestId, (snap: any) => {
        const data = snap.docs.map((doc: any) => doc.data());
        if (data && data.length > 0) {
          setRequest(data[0]);
        }
      });
    }
  }, [requestId, auth.user]);

  useEffect(() => {
    if (request?.eventAddress.geoLocation) {
      setCenter(request?.eventAddress?.geoLocation);
    }
  }, [request?.eventAddress.geoLocation]);

  const handleConfirmClick = useCallback(async () => {
    if (!request) return;
    if (!confirmEnabled) {
      toast.error(t('handle_disabled.label'));
      return;
    }
    const confirmRequest = async () => {
      setRejectEnabled(false);
      setConfirmEnabled(false);
      const newRequestStatusTimestamp = { ...request.requestStatusTimestamp };
      newRequestStatusTimestamp.confirmed = new Date().getTime();
      const newRequestStatus = 'confirmed';
      await toast
        .promise(
          updateRequestStatus(
            auth.user,
            request.documentID,
            newRequestStatus,
            newRequestStatusTimestamp
          ),
          {
            loading: t('confirming.label'),
            success: t('confirm_success.label'),
            error: t('confirm_failed.label'),
          }
        )
        .catch((e) => {
          console.log(e);
          setRejectEnabled(true);
          setConfirmEnabled(true);
        });
    };
    modalConfirm(
      {
        message: t('confirm_modal_msg.label'),
        title: t('confirm_modal.title'),
      },
      confirmRequest,
      ModalMode.CONFIRM
    );
  }, [auth.user, confirmEnabled, request, t]);

  const handleRejectClick = useCallback(async () => {
    if (!request) return;
    if (!rejectEnabled) {
      toast.error(t('handle_disabled.label'));
      return;
    }
    const rejectRequest = async () => {
      setRejectEnabled(false);
      setConfirmEnabled(false);
      const newRequestStatusTimestamp = { ...request.requestStatusTimestamp };
      newRequestStatusTimestamp.rejected = new Date().getTime();
      const newRequestStatus = 'rejected';
      await toast
        .promise(
          updateRequestStatus(
            auth.user,
            request.documentID,
            newRequestStatus,
            newRequestStatusTimestamp
          ),
          {
            loading: t('rejecting.label'),
            success: t('reject_success.label'),
            error: t('reject_failed.label'),
          }
        )
        .catch((e) => {
          console.log(e);
          setRejectEnabled(true);
          setConfirmEnabled(true);
        });
    };
    modalConfirm(
      {
        message: t('reject_modal_msg.label'),
        title: t('reject_modal.title'),
      },
      rejectRequest
    );
  }, [auth.user, rejectEnabled, request, t]);

  return (
    <Fragment>
      <div>
        <div className='sticky top-8 bg-droov_background'>
          <CustomHeaderComponent
            heading={headerComponent.heading}
            breadCrumbComponent={
              <CustomBreadCrumb
                onClickAction={handleBackNavigation}
                displayString={t('cateringRequests.label')}
              />
            }
            showButton={headerComponent.showButton}
          />
          <div className={'flex justify-center w-full'}>
            <div
              className={
                'flex flex-col gap-0 items-center lg:w-2/3 md:w-full w-full'
              }
            >
              <div className='bg-white rounded-t-xl w-full p-4'>
                <div className='flex justify-between'>
                  <div className='flex space-x-4'>
                    <div className='bg-gray-100 p-3 rounded-md text-center font-bold'>
                      {request?.requestID.toUpperCase().toString()}
                    </div>
                  </div>

                  <div className='w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/4'>
                    {request?.requestStatus === 'received' && (
                      <div className='rounded-md p-2 h-7 bg-light_blue opacity-80 flex justify-center items-center'>
                        <span className='text-blue-900'>
                          {' '}
                          {t('pending.label')}
                        </span>
                      </div>
                    )}
                    {request?.requestStatus === 'confirmed' && (
                      <div className='rounded-md p-2 h-7 bg-light_green flex opacity-80 justify-center items-center'>
                        <span className='text-green-900'>
                          {' '}
                          {t('confirmed_status.label')}
                        </span>
                      </div>
                    )}
                    {request?.requestStatus === 'rejected' && (
                      <div className='rounded-md p-2 h-7 bg-red-100 flex opacity-80 justify-center items-center'>
                        <span className='text-red-900'>
                          {' '}
                          {t('rejected_status.label')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={'flex justify-between'}>
                  <div className={'flex flex-col'} style={{ maxWidth: '65%' }}>
                    <div className='px-2 pt-2 rounded-xl items-start font-bold'>
                      {request?.customer.name}
                    </div>
                    <div>
                      <div className='flex items-center mt-2'>
                        <div className='w-6 h-6 p-1 -mt-0.5 ml-2 text-blue-700 flex items-center'>
                          ✉️
                        </div>
                        <div className='ml-2 text-gray-500'>
                          {request?.customer.email}
                        </div>
                      </div>
                    </div>
                    {request?.customer.phone && (
                      <div>
                        <div className='flex items-center mt-2'>
                          <div className='w-6 h-6 p-1 ml-2 flex items-center text-blue-700'>
                            <PhoneIcon />
                          </div>
                          <div className='ml-2 text-gray-500'>
                            {request!.customer.phone}
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className='flex items-center mt-2'>
                        <div className='w-6 h-6 p-1 -mt-0.5 ml-2.5 flex items-center'>
                          💁
                        </div>
                        <div className='ml-1.5 text-gray-500'>
                          {request?.peopleNumber} {t('people.label')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      'flex flex-col gap-2 w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/4'
                    }
                  >
                    <div className='flex w-full'>
                      <div className='w-full'>
                        <div className='bg-yellow-200 px-2 py-1 rounded-md text-center font-bold w-full'>
                          <p>
                            {moment(request?.eventTimeStamp).format(
                              'DD.MM.YYYY'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {request?.notes && (
                  <div
                    className='w-max mt-2 bg-yellow-100 px-3 py-2 rounded-md text-left text-sm relative'
                    style={{ maxWidth: '75%', minWidth: '30%' }}
                  >
                    <div
                      className='border border-yellow-100'
                      style={{
                        position: 'absolute',
                        top: -7,
                        left: 15,
                        width: '0',
                        height: '0',
                        borderLeft: '7px solid transparent',
                        borderRight: '7px solid transparent',
                        borderBottom: '7px solid #fef3c7',
                      }}
                    />
                    {request?.notes}
                  </div>
                )}
                { request?.eventAddress.streetAndHouseNo && center &&
                  <AddressCard
                    address={{
                      streetAndHouseNumber: request.eventAddress.streetAndHouseNo,
                      postCode: +request.eventAddress.postcode,
                      lat: center.lat,
                      lng: center.lng,
                      city: request.eventAddress.city,
                    }}
                    label={t('event_address.label')}
                  />
                }
              </div>
              <div className='bg-gray-50 rounded-b-xl w-full px-4 py-3 sm:px-4 sm:flex sm:flex-row sm:justify-end flex flex-col items-center'>
                <button
                  type='button'
                  className={`catering_reject_button w-1/2 sm:w-auto ${
                    rejectEnabled
                      ? 'catering_reject_button_on_hover'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                  onClick={handleRejectClick}
                >
                  {t('reject.button')}
                </button>
                <button
                  type='button'
                  className={`catering_confirm_button w-1/2 sm:w-auto sm:mt-0 sm:ml-3 mt-2 ${
                    confirmEnabled
                      ? 'catering_confirm_button_on_hover'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                  onClick={handleConfirmClick}
                >
                  {t('confirm.button')}
                </button>
              </div>
            </div>
          </div>
          <CateringRequestDetailedHistory request={request!} />
        </div>
      </div>
    </Fragment>
  );
};
