import useAuthState from '../global-states/useAuthState';

export default function PrintingOrderTable({ urlLogo, printingOrder }: any) {
  const auth: any = useAuthState.getState();

  return (
    <table style={{ borderSpacing: '5px' }}>
      <tr>
        <img
          id='logo-img'
          src={urlLogo}
          style={{
            display: 'block',
            // marginLeft: 'auto',
            // marginRight: 'auto',
            width: '50%',
          }}
        />
      </tr>
      <tr style={{ outline: 'thin solid' }} />
      <tr>
        {printingOrder?.orderType === 'pickup' && (
          <td>Pickup #{printingOrder.orderID}</td>
        )}
        {printingOrder?.orderType === 'delivery' && (
          <td>Delivery #{printingOrder.orderID}</td>
        )}
      </tr>
      <tr>
        <td>{printingOrder.customer.name}</td>
      </tr>
      <tr>
        <td>{printingOrder.customer.phone}</td>
      </tr>

      {printingOrder?.comments && (
        <>
          <tr>
            <td>
              <u>
                <b>Comments:</b>
              </u>
            </td>
          </tr>
          <tr>
            <td>{printingOrder.comments}</td>
          </tr>
        </>
      )}

      {printingOrder?.deliveryAddress?.streetAndHouseNumber && (
        <>
          <tr>
            <td>
              <u>
                <b>Address:</b>
              </u>
            </td>
          </tr>
          <tr>
            <td>{printingOrder.deliveryAddress.streetAndHouseNumber}</td>
          </tr>
          <tr>
            <td>
              {printingOrder.deliveryAddress.postCode}{' '}
              {printingOrder.deliveryAddress.city}
            </td>
          </tr>
        </>
      )}

      <tr style={{ outline: 'thin solid' }} />

      {Object.entries(printingOrder.orderItems).map(
        (orderItem: any, index: number, orderItems) => (
          <tr>
            <td width='50%'>
              <table style={{ borderSpacing: '5px' }}>
                <tr>
                  <td>x{orderItem[1].itemQuantity}</td>
                  <td>{orderItem[1].itemName}</td>
                </tr>
                <tr>
                  <td />
                  <td>
                    <b>Size:</b> {orderItem[1].itemSize}
                  </td>
                </tr>
                {orderItem[1].itemSpecs &&
                  orderItem[1].itemSpecs.map((itemSpec: any) => (
                    <tr>
                      <td />
                      <td>
                        <b>{itemSpec.groupTitle}: </b>
                        {itemSpec.items.reduce(
                          (text: string, item: any, index: number) => {
                            return (
                              text +
                              `${item.qty ? 'x' + item.qty : 'x1'}${'\u00A0'}${
                                item.addon
                              }${
                                index !== itemSpec.items.length - 1 ? ', ' : ''
                              }`
                            );
                          },
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
              </table>
            </td>
          </tr>
        )
      )}
      <tr style={{ outline: 'thin solid' }} />

      <tr>
        <td>Total Price:</td>
        <td>
          {new Intl.NumberFormat(auth.companyLanguage, {
            style: 'currency',
            currency: auth.companyCurrency,
            minimumFractionDigits: 2,
          }).format(printingOrder.amount)}{' '}
          (Tip:{' '}
          {new Intl.NumberFormat(auth.companyLanguage, {
            style: 'currency',
            currency: auth.companyCurrency,
            minimumFractionDigits: 2,
          }).format(printingOrder.tip)}
          )
        </td>
      </tr>
    </table>
  );
}
