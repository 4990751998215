import React from 'react';
import { Toaster } from 'react-hot-toast';

export interface CustomBreadCrumbProps {
  displayString: string;
  onClickAction: () => void;
}

export default function CustomBreadCrumb({
  displayString,
  onClickAction,
}: CustomBreadCrumbProps) {
  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <br />
      <div className='flex text-gray-500'>
        <div className='hover:text-droov h-auto' onClick={onClickAction}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6 mr-2 mb-1 inline cursor-pointer'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M15 19l-7-7 7-7'
            />
          </svg>
          <p className='cursor-pointer inline'>{displayString}</p>
        </div>
      </div>
      <br />
    </>
  );
}
