import { Draggable, Droppable } from 'react-beautiful-dnd';
import { modalConfirm } from '../../ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
export default function DraggableAddons({
  // addonsArray,
  deleteAddons,
  configurator,
  addonToEdit,
  setAddonToEdit,
  snapshot,
  product,
}: any) {
  const { t } = useTranslation(['catalogue']);
  const addonToEditRef = useRef(addonToEdit);
  const handleDelete = async () => {
    if (product === undefined) {
      //at configurator
      deleteAddons(
        Object.keys(configurator?.addons[addonToEditRef.current])[0],
        undefined,
        addonToEditRef.current
      );
    } else {
      //at product
      deleteAddons(
        Object.keys(product?.addons[addonToEditRef.current])[0],
        product.id,
        addonToEditRef.current
      );
    }
    setAddonToEdit(-1);
  };
  return (
    <Droppable
      droppableId={'addonsOrder-' + product?.id}
      type='addons'
      direction='horizontal'
    >
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={
            snapshot.isDraggingOver
              ? 'flex flex-wrap opacity-30'
              : 'flex flex-wrap'
          }
        >
          {product?.addons !== undefined &&
            product?.addons?.map((addonObject: any, index: any) => (
              <div key={addonObject.title}>
                {
                  <Draggable
                    key={addonObject + index}
                    draggableId={addonObject.title + ' - chip' + product.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        key={index}
                        className='relative_clear_button bg-green_chip relative hover:bg-green-600 button_close h-8 w-auto pl-4 pr-2 mt-2 text-white mr-2'
                      >
                        <span className='mr-2'> {addonObject.title}</span>

                        <div
                          onClick={() => {
                            addonToEditRef.current = index;
                            modalConfirm(
                              {
                                message: t(
                                  product === undefined
                                    ? 'deleteAllAddons.label'
                                    : 'deleteAddons.label'
                                ),
                                title: t('delete.label'),
                              },
                              handleDelete
                            );
                          }}
                          className='cursor-pointer ml-2 absolute -top-0.5 -right-0.5 bg-red-400 hidden h-4 w-4 rounded-full z-10 text-white focus:outline-none'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='duration-150 ease-in-out h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={2}
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </Draggable>
                }
              </div>
            ))}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
