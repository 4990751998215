import { ReactComponent as ArrowNotExpanded } from '../assets/arrowNotExpanded.svg';
export const ExpandArrow = ({
  expanded,
  styles,
}: {
  expanded: boolean;
  styles?: string;
}) => {
  return (
    <ArrowNotExpanded
      className={`transition-all fill-current text-gray-400 hover:text-black group-hover:text-gray-800 duration duration-300 cursor-pointer transform ${styles}  ${
        expanded ? 'rotate-180' : 'rotate-0'
      }`}
    />
  );
};
export default ExpandArrow;
