import React from 'react';
import { ReactComponent as CarIcon } from '../assets/car.svg';
import { ReactComponent as BikeIcon } from '../assets/bike.svg';
import { ReactComponent as WalkingIcon } from '../assets/walking.svg';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

const vehicles = [
  {
    id: 1,
    name: 'Bike',
    avatar: <BikeIcon />,
  },
  {
    id: 2,
    name: 'Car',
    avatar: <CarIcon />,
  },
  {
    id: 3,
    name: 'Walking',
    //source https://www.svgrepo.com/svg/352679/walking
    avatar: <WalkingIcon />,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CourierVehicleSelector({
  onValueChanged,
  vehicle,
}: any) {
  const { t } = useTranslation(['couriers']);

  return (
    <Listbox
      value={vehicles.find((e) => e?.name === vehicle)}
      onChange={(e) => onValueChanged(e?.name)}
    >
      {({ open }) => (
        <>
          <div className='mt-1 relative'>
            <Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-2 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-droov focus:border-droov sm:text-sm'>
              <span className='flex items-center'>
                <div className='flex-shrink-0 h-7 w-7 rounded-full'>
                  {vehicles.find((e) => e?.name === vehicle)?.avatar}
                </div>
                <span className='ml-5 block truncate'>{vehicle}</span>
              </span>
              <span className='ml-5 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <SelectorIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
              >
                {vehicles.map((vehicle) => (
                  <Listbox.Option
                    key={vehicle.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-droov' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={vehicle}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <div className='h-6 w-6 mr-3 mb-2 rounded-full'>
                            {vehicle.avatar}
                          </div>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {t(vehicle.name.toLowerCase() + '.label')}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'bg_droov',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
