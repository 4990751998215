import { Switch } from '@headlessui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  addonsForm: any;
  setAddonsForm: any;
  allowMultiple: boolean;
  setAllowMultiple: any;
};
export const AllowMultipleSwitch: React.FC<Props> = ({
  addonsForm,
  setAddonsForm,
  allowMultiple,
  setAllowMultiple,
}) => {
  useEffect(() => {
    setAllowMultiple(addonsForm.allowMultiple);
  }, [addonsForm.allowMultiple]);
  const { t } = useTranslation(['catalogue']);
  return (
    <div
      className='flex items-center mt-4'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <span className=' italic text-gray-400 mr-4 hidden sm:inline-block'>
        {t('allowmultiple.label')}
      </span>
      <Switch
        checked={allowMultiple}
        onChange={() => {
          setAllowMultiple(!allowMultiple);
          setAddonsForm({
            ...addonsForm,
            allowMultiple: !addonsForm.allowMultiple,
          });
        }}
        className={`${
          allowMultiple ? 'bg-droov' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
      >
        <span
          className={`${
            allowMultiple ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
        />
      </Switch>
    </div>
  );
};
