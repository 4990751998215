import React from 'react';
import { DeliveryDataObject } from '../../types';
import EditIcon from '../EditIcon';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../global-states/useAuthState';

type Props = {
  deliveryAreaDetails: DeliveryDataObject;
  onEditClicked: Function;
};
function DeliveryAreaCard(props: Props) {
  const auth: any = useAuthState.getState();
  const { deliveryAreaDetails, onEditClicked } = props;
  const { t } = useTranslation(['delivery']);

  const handleEdit = () => {
    onEditClicked(deliveryAreaDetails);
  };
  return (
    <div className='mb-4 rounded-xl my-2 px-4 py-3 bg-white z-0'>
      <div className='flex flex-row justify-between mb-4'>
        <div className='flex flex-row'>
          <div
            style={{
              backgroundColor: deliveryAreaDetails.color,
              marginTop: '3px',
            }}
            className='w-7 h-7 mr-3 rounded-md'
          />
          <div className='font-bold mt-0.5 text-gray-500 text-lg'>
            {deliveryAreaDetails.name}
          </div>
        </div>
        <div
          className='w-2/6 ml-5 flex relative justify-end items-center'
          onClick={handleEdit}
        >
          <EditIcon />
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-col'>
          <div className='text-sm text-gray-400'>{t('deliveryfee.label')}</div>
          <div className='mt-0.5 text-droov text-xl font-medium'>
            {new Intl.NumberFormat(auth.companyLanguage, {
              style: 'currency',
              currency: auth.companyCurrency,
              minimumFractionDigits: 2,
            }).format(deliveryAreaDetails.deliveryFee)}
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='text-sm text-gray-400'>{t('minimumorder.label')}</div>
          <div className='mt-0.5 text-droov text-xl font-medium'>
            {new Intl.NumberFormat(auth.companyLanguage, {
              style: 'currency',
              currency: auth.companyCurrency,
              minimumFractionDigits: 2,
            }).format(deliveryAreaDetails.minOrder)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryAreaCard;
