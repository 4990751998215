import { DeliveryDataObject } from "../types";
import db from "./firebase";

export const addDeliveryAreas = async (
  user: any,
  deliveryAreas: DeliveryDataObject[]
) => {
  try {
    await db
      .collection("companies")
      .doc(user)
      .update({ deliveryAreas: deliveryAreas });
    return;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getDeliveryAreas = async (user: any) => {
  const val = await db.collection("companies").doc(user).get();
  const deliveryAreas: DeliveryDataObject[] = val.get("deliveryAreas");

  return deliveryAreas;
};


export const changeDeliveryStatus = async (
  user: any,
  deliveryStatus: boolean
) => {
  try {
    await db
      .collection("companies")
      .doc(user)
      .update({ delivery: deliveryStatus });
    return;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getDeliveryStatus = async (user: any) => {
  const val = await db.collection("companies").doc(user).get();
  const deliveryStatus: boolean = val.get("delivery");

  return deliveryStatus;
};