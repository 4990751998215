import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../global-states/useAuthState';
import InputFieldWithType from '../../ui-components/InputFieldWithType';
import React from 'react';
import NumberInput from 'ui-components/NumberInput';

export interface TimeslotLimitProps {
  active: boolean;
  setActive: (active: boolean) => void;
  avgItemPrice: number;
  setAvgItemPrice: (avgItemPrice: number) => void;
  maxItems: number;
  setMaxItems: (maxItems: number) => void;
}

export default function TimeslotLimit(props: TimeslotLimitProps) {
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['settings']);
  const [avgPriceVal, setAvgPriceVal] = React.useState<string>(
    props.avgItemPrice.toString()
  );

  return (
    <div className='w-full bg-white p-4 rounded-lg max-w-3xl mt-5 cursor-default'>
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>{t('timeslot_limit.label')}</h2>
        <Switch
          checked={props.active}
          onChange={() => props.setActive(!props.active)}
          className={`${
            props.active ? 'bg-droov' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none`}
        >
          <span
            className={`${
              props.active ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
          />
        </Switch>
      </div>
      <p className='droov_text_description_light'>
        {t('timeslot_limit_description.label')}
      </p>

      {props.active && (
        <div>
          <form className='pt-4 lg:flex lg:space-x-16 w-full'>
            <div>
              <div className='mb-4 font-medium'>
                {t('avg_item_price.label')}
              </div>
              <div className='relative'>
                <InputFieldWithType
                  value={avgPriceVal}
                  type='text'
                  id='avgItemPrice'
                  required={true}
                  onInputFieldValueChanged={(e: any) => {
                    setAvgPriceVal(e);
                    props.setAvgItemPrice(
                      Math.round((parseFloat(e) + Number.EPSILON) * 100) / 100
                    );
                  }}
                />
              </div>
            </div>

            <div>
              <div className='mb-4 font-medium'>
                {t('max_items_per_slot.label')}
              </div>
              <div className='relative'>
                <NumberInput
                  value={props.maxItems}
                  onNumberChanged={props.setMaxItems}
                />
              </div>
            </div>
          </form>
          <div className='w-full p-3 mt-5 font-light leading-5 text-sm rounded-sm bg-gray-100'>
            <p>
              {t('limit_explanation.label', {
                time: '10:00',
                itemPrice: props.avgItemPrice
                  ? props.avgItemPrice.toLocaleString(auth?.companyCurrency, {
                      style: 'currency',
                      currency: auth?.companyCurrency,
                    })
                  : 0,
                maxItems: props.maxItems,
              })}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
