import CustomInputField from '../../CustomInputField';

import ChangeImage from '../../../assets/changeImageSmall.png';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import NoImage from '../../../assets/noImage.png';
import noImg from '../../../assets/NoImg.png';
import ShowOtherAddonImages from './ShowOtherAddonImages';
import InputFieldWithType from '../../InputFieldWithType';
import { useState } from 'react';
const ProductInputCard = ({
  i,
  x,
  handleInputChange,
  showImages,
  addonsForm,
  toggleOpenImageGalery,
  handleRemoveClick,
  openImageGalery,
  setAddonsForm,
  setFilteredImages,
  addonsImages,
  imagesForDelete,
  setImagesForDelete,
  uploadedImages,
  setUploadedImages,
  showNettoPrice,
  isInConfigurator,
}: any) => {
  const [priceVal, setPriceVal]: any = useState(
    showNettoPrice && !isInConfigurator ? x.nettoPrice : x.price
  );
  return (
    <div key={i} className='my-1 item_box_shadow  rounded-lg sm:shadow-none'>
      <div className='flex flex-wrap'>
        <span className='ml-1 mr-2 flex self-center'>{i + 1}</span>
        <div className=' w-64 sm:w-60 h-11 mx-2'>
          <CustomInputField
            classnameadditional='placeholder-italic'
            name='addon'
            type='text'
            maxLength={45}
            placeholder='e.g Avocado'
            step='0.01'
            value={addonsForm.items[i].addon}
            id='addon'
            onChange={(e: any) => {
              // showImages(e.target.value, i);
              handleInputChange('addon', e.target.value, i);
            }}
            required={true}
          />
        </div>

        <div className='my-3 sm:my-0 ml-7 sm:ml-2 w-32 sm:w-20 h-11 mr-5'>
          <div className='relative'>
            <InputFieldWithType
              value={priceVal}
              id='price'
              name='price'
              step='0.01'
              required={true}
              onInputFieldValueChanged={(e: any) => {
                setPriceVal(e);
                handleInputChange(
                  showNettoPrice && !isInConfigurator ? 'nettoPrice' : 'price',
                  e ? parseFloat(e) : '',
                  i
                );
              }}
            />
          </div>
        </div>

        {addonsForm.withImages ? (
          <div className='my-3 sm:my-0 w-11 h-11 mx-4 flex items-center justify-center'>
            {typeof addonsForm.items[i].imgURL !== 'undefined' &&
            addonsForm.items[i].imgURL !== '' ? (
              <div
                className='fixed w-11 h-11 flex items-center justify-center object-contain '
                key={addonsForm.items[i].imgURL}
                onClick={() => {
                  toggleOpenImageGalery(i);
                  // showOtherImageOptions(addonsForm.items[i])
                }}
              >
                <img
                  src={addonsForm.items[i].imgURL}
                  className='cursor-pointer w-11 h-11 object-contain rounded-lg'
                  alt=''
                />
                <img
                  src={ChangeImage}
                  alt='shadow'
                  className=' cursor-pointer w-11 h-11 opacity-0 hover:opacity-100  duration-500 absolute inset-0 z-10 flex justify-center items-center object-contain rounded-lg'
                />
              </div>
            ) : (
              <div
                className='fixed w-11 h-11 flex items-center justify-center object-contain'
                onClick={() => {
                  toggleOpenImageGalery(i);
                  // showOtherImageOptions(addonsForm.items[i])
                }}
              >
                <img
                  src={NoImage}
                  alt='shadow'
                  className='cursor-pointer w-11 h-11 rounded-lg'
                />
                <img
                  src={ChangeImage}
                  alt='shadow'
                  className=' cursor-pointer w-11 h-11 opacity-0 hover:opacity-100 duration-500  absolute inset-0 z-10 flex justify-center items-center object-contain rounded-lg'
                />
              </div>
            )}
          </div>
        ) : (
          <div className='my-3 sm:my-0 w-11 h-11 mx-4 flex items-center justify-center'>
            <img
              src={noImg}
              className='w-7 h-7 object-contain rounded-lg'
              alt=''
            />
          </div>
        )}

        <div
          className='flex justify-center items-center mx-2 sm:mx-6'
          onClick={() => {
            handleRemoveClick(i);
          }}
        >
          <TrashIcon className='w-6 cursor-pointer text-gray-400 transition ease-in-out hover:text-gray-800' />
        </div>
      </div>

      <ShowOtherAddonImages
        openImageGalery={openImageGalery}
        index={i}
        addonsForm={addonsForm}
        setAddonsForm={setAddonsForm}
        toggleOpenImageGalery={toggleOpenImageGalery}
        setFilteredImages={setFilteredImages}
        addonsImages={addonsImages}
        imagesForDelete={imagesForDelete}
        setImagesForDelete={setImagesForDelete}
        uploadedImages={uploadedImages}
        setUploadedImages={setUploadedImages}
      />
    </div>
  );
};

export default ProductInputCard;
