export type ChartState = {
  entities: string[];
  barThickness: number;
  viewType: ViewType;
  lastEntities: string[];
  chartEntities: string[];
};

export type RevenueData = {
  revenue: number;
  date: string;
};

export enum ViewType {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LASTSEVENDAYS = 'LASTSEVENDAYS',
  LASTTHIRTYDAYS = 'LASTTHIRTYDAYS',
  LASTTHREEMONTH = 'LASTTHREEMONTH',
  LASTSIXMONTH = 'LASTSIXMONTH',
}
