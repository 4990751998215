import { Switch } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputFieldWithType from '../../ui-components/InputFieldWithType';

export interface BasketLimitProps {
  active: boolean;
  basketLimit: number;
  setLimit: (active: boolean, basketLimit: number) => void;
}

export default function BasketLimitSetting(props: BasketLimitProps) {
  const { t } = useTranslation(['settings']);
  const [basketLimitState, setBasketLimitState] = React.useState<string>(
    props.basketLimit.toString()
  );

  return (
    <div className='w-full bg-white p-4 rounded-lg max-w-3xl mt-5 cursor-pointer'>
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>{t('basket_limit.label')}</h2>
        <Switch
          checked={props.active}
          onChange={() => props.setLimit(!props.active, props.basketLimit)}
          className={`${
            props.active ? 'bg-droov' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none`}
        >
          <span
            className={`${
              props.active ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
          />
        </Switch>
      </div>
      <p className='droov_text_description_light'>
        {t('basket_limit_description.label')}
      </p>

      {props.active && (
        <div>
          <form className='pt-4 lg:flex lg:space-x-16 w-full'>
            <div>
              <div className='mb-4 font-medium'>{t('basket_limit.label')}</div>
              <div className='relative'>
                <InputFieldWithType
                  value={basketLimitState}
                  type='text'
                  id='avgItemPrice'
                  required={true}
                  onInputFieldValueChanged={(e: any) => {
                    setBasketLimitState(e);
                    props.setLimit(
                      props.active,
                      Math.round((parseFloat(e) + Number.EPSILON) * 100) / 100
                    );
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
