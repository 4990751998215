import { useTranslation } from 'react-i18next';
import SectionHeader from 'ui-components/layout/SectionHeader';
import { modalConfirm, ModalMode, updateConfirmModal } from 'ui-components/ConfirmationModal';
import { v4 } from 'uuid';
import moment from 'moment';
import SettingTag from './SettingTag';
import { DaysDateRange } from '../../typings/holiday';
import useHolidaysState from '../../states/holidayState';
import AddHolidayDialogContent, { useModalHolidaysData } from './AddHolidayDialogContent';
import { useEffect } from 'react';

const HolidaysSettings = () => {
  const { t } = useTranslation(['settings']);
  const resetModelData = useModalHolidaysData(state => state.resetData);
  const getData = useModalHolidaysData(state => state.get);
  const startDay = useModalHolidaysData(state => state.startDay);
  const { holidays, addHoliday, removeHoliday } = useHolidaysState();

  const onConfirm = () => {
    const holiday: DaysDateRange = {
      start:  getData().startDay ??  new Date(),
      end: moment(getData().startDay).add(getData().numberOfDays, 'days').toDate(),
      id: v4(),
    };
    addHoliday(holiday);
    resetModelData();
  }

  const onDeleteHoliday = (id: string) => {
    removeHoliday(id);
  }

  const onAddHoliday = () => {
    modalConfirm(
      {
        title: t('holiday_add.text'),
        content: <AddHolidayDialogContent addedHolidays={holidays} />,
        okText: t('add.label'),
        okDisabled: startDay === null,
      },
      () => {
        onConfirm();
      },
      ModalMode.UNSTYLED,
      () => {
        resetModelData();
      },
    );
  };

  useEffect(() => {
    updateConfirmModal({ okDisabled: startDay === null });
  }, [startDay]);

  const settingsTagText = (holiday: DaysDateRange) => {
    const start = moment(holiday.start);
    const end = moment(holiday.end);
    const multipleDays = +start.isSame(end, 'days')
    return t('closed_on', {
      count: multipleDays,
      from: start.format('D.MM.YYYY'),
      to: end.format('D.MM.YYYY')
    })
  }

  return (
    <div className="flex-1 bg-white outline-none rounded-md">
      <div className="p-5">
        <SectionHeader
          title={t('holiday.label')}
          description={t('holiday_description.label')}
        />
        <div className={'flex flex-wrap gap-4 mt-4'}>
          {holidays.map(holiday => (
            <SettingTag
              key={holiday.id}
              id={holiday.id}
              text={settingsTagText(holiday)}
              onDelete={onDeleteHoliday}
              deletable={true}
            />
          ))}
          <button
            type="button"
            className="relative_add_button_no_pad px-3 py-1"
            onClick={onAddHoliday}
          >
            + {t('holiday_add.text')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HolidaysSettings;