import React, { useEffect, useState } from 'react';
import CustomInputField from 'ui-components/CustomInputField';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { SingleStep } from 'typings/OnboardingModuleType';
import { NextButton } from './NextButton';
import { useOnboardingState } from '../../global-states/useOnboardingState';
import { Transition } from '@headlessui/react';
import { searchPrefixes } from 'firebase-logic/onboarding';
import StoreUrlInputField from './StoreUrlInputField';
import { useTranslation } from 'react-i18next';

interface StoreInfoDataObject {
  storeName: string;
  storeUrl: string;
  storePhoneNumber: string;
}

type Props = {
  handleNextClick?: any;
  currentStep?: SingleStep;
};

const onboardingStateSelector = (state: any) => [
  state.onboarding,
  state.setOnboardingState,
];

// eslint-disable-next-line no-useless-escape
const specialChars = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
const storeUrlMinLength = 3;
const storeUrlMaxLength = 35;

export const StoreInfoStep: React.FC<Props> = ({
  handleNextClick,
  currentStep,
}) => {
  const { t } = useTranslation(['onboarding']);

  const [onboarding, setOnboardingState] = useOnboardingState(
    onboardingStateSelector
  );

  const [storeInfoData, setStoreInfoData] = useState<StoreInfoDataObject>({
    storeName: '',
    storeUrl: '',
    storePhoneNumber: '',
  });
  const [hasValidPhone, setHasValidPhone] = useState(false);
  const [hasValidUrl, setHasValidUrl] = useState(true);
  const [storeUrlText, setStoreUrlText] = useState(
    `${t('storeurl.text')} https://my-restaurant.droov.store`
  );
  const [urlStringValidationMsgs, setUrlStringValidationMsgs] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (onboarding?.storeInfoStep?.completed) {
      setStoreInfoData({
        ...onboarding.storeInfoStep,
      });
      setStoreUrlText(
        `${t('storeurl.text')} https://${
          onboarding.storeInfoStep.storeUrl
        }.droov.store`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding.storeInfoStep]);

  const saveCurrentStepData = () => {
    let temp = JSON.parse(JSON.stringify(onboarding));
    temp.storeInfoStep = {
      completed: true,
      storeName: storeInfoData.storeName,
      storeUrl: storeInfoData.storeUrl,
      storePhoneNumber: storeInfoData.storePhoneNumber,
    };
    setOnboardingState(temp);
    handleNextClick(temp);
  };

  const handlePrefixSearch = async (searchText: any) => {
    const prefixResults = await searchPrefixes(searchText);
    if (prefixResults.length > 0) {
      setHasValidUrl(false);
    } else {
      setHasValidUrl(true);
    }
  };

  useEffect(() => {
    if (hasValidUrl) {
      if (storeInfoData.storeUrl.length > 0) {
        setStoreUrlText(
          `${t('storeurl.text')} https://${storeInfoData.storeUrl}.droov.store`
        );
      }
    } else {
      setStoreUrlText(
        `https://${storeInfoData.storeUrl}.droov.store ${t(
          'urlisbooked.label'
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidUrl]);

  const checkUrlValidity = () => {
    if (storeInfoData.storeUrl && storeInfoData.storeUrl.length > 0) {
      handlePrefixSearch(storeInfoData.storeUrl.toLocaleLowerCase().trim());
    }
    if (storeInfoData.storeUrl.length > 0) {
      setStoreUrlText(
        `${t('storeurl.text')} https://${storeInfoData.storeUrl}.droov.store`
      );
    }
    checkUrlValidationRules();
  };

  const checkUrlValidationRules = () => {
    const tempMsgs: string[] = [];
    if (specialChars.test(storeInfoData.storeUrl)) {
      // eslint-disable-next-line no-useless-escape
      tempMsgs.push(t('special_chars_not_allowed.label'));
    }
    if (storeInfoData.storeUrl.startsWith('-')) {
      tempMsgs.push(t('dash_start_not_allowed.label'));
    }
    if (storeInfoData.storeUrl.includes(' ')) {
      tempMsgs.push(t('spaces_not_allowed.label'));
    }
    if (storeInfoData.storeUrl.endsWith('-')) {
      tempMsgs.push(t('dash_end_not_allowed.label'));
    }
    if (storeInfoData.storeUrl.includes('.')) {
      tempMsgs.push(t('dots_not_allowed.label'));
    }
    if (storeInfoData.storeUrl.length < storeUrlMinLength) {
      tempMsgs.push(t('min_length.label', { minLength: storeUrlMinLength }));
    }
    if (storeInfoData.storeUrl.length > storeUrlMaxLength) {
      tempMsgs.push(t('max_length.label', { maxLength: storeUrlMaxLength }));
    }

    if (tempMsgs.length > 0) {
      setHasValidUrl(false);
      setUrlStringValidationMsgs(tempMsgs);
    } else {
      setHasValidUrl(true);
      setUrlStringValidationMsgs([]);
    }
  };

  return (
    <Transition
      appear={true}
      show={true}
      as='div'
      enter='transform transition ease-in-out duration-1000'
      enterFrom='-translate-x-4 opacity-0'
      enterTo='-translate-x-0'
    >
      <div className='mx-10 md:w-3/5 md:mx-auto flex flex-col items-center'>
        <div className='z-10 w-full mb-6'>
          <label className='droov_label_text'>{t('storename.label')}</label>
          <CustomInputField
            value={storeInfoData.storeName}
            onChange={(event) =>
              setStoreInfoData({
                ...storeInfoData,
                storeName: event.target.value,
              })
            }
            type='text'
            placeholder={t('example_store.label')}
            id='storeName'
            required={true}
          />
        </div>

        <div className='z-10 w-full mb-5 flex flex-col'>
          <label className='droov_label_text'>{t('storeurl.label')}</label>
          <StoreUrlInputField
            value={storeInfoData.storeUrl}
            id='storeUrl'
            name='storeUrl'
            required={true}
            onInputFieldValueChanged={(event: any) =>
              setStoreInfoData({
                ...storeInfoData,
                storeUrl: event.target.value.toLocaleLowerCase(),
              })
            }
            onBlur={checkUrlValidity}
          />
          <label
            className={`text-xs fade_in_text mt-2`}
            style={{
              color:
                hasValidUrl && !(urlStringValidationMsgs.length > 0)
                  ? '#08910d'
                  : 'red',
            }}
          >
            {urlStringValidationMsgs.length > 0
              ? `${urlStringValidationMsgs[0]}`
              : `${storeUrlText}`}
          </label>
        </div>

        <div className='z-20 w-full mb-10'>
          <label className='droov_label_text'>
            {t('telephonenumber.label')}
          </label>
          <IntlTelInput
            onPhoneNumberChange={(
              isValid,
              value,
              selectedCountryData,
              fullNumber,
              extension
            ) => {
              setStoreInfoData({
                ...storeInfoData,
                storePhoneNumber: fullNumber,
              });
              setHasValidPhone(isValid);
            }}
            onSelectFlag={(
              currentNumber,
              selectedCountryData,
              fullNumber,
              isValid
            ) => {
              setStoreInfoData({
                ...storeInfoData,
                storePhoneNumber: fullNumber,
              });
              setHasValidPhone(isValid);
            }}
            defaultValue={storeInfoData.storePhoneNumber}
            preferredCountries={['de', 'us', 'gb']}
            containerClassName=' z-20 mt-1 intl-tel-input w-full lg:w-100 m-auto justify-center'
            inputClassName='focus:ring-droov placeholder-gray-400 focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
          />
        </div>

        <div className='z-10 w-full flex flex-col items-center'>
          <NextButton
            disabled={
              !(
                storeInfoData.storeName.length > 0 &&
                storeInfoData.storeUrl.length > 0 &&
                storeInfoData.storePhoneNumber.length > 0 &&
                hasValidPhone &&
                hasValidUrl &&
                urlStringValidationMsgs.length === 0
              )
            }
            buttonText={
              currentStep?.stepNumber === 2
                ? t('start.button')
                : t('next.button')
            }
            goNextHandler={saveCurrentStepData}
          />
        </div>
      </div>
    </Transition>
  );
};
