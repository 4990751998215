import { ReactComponent as EditSVG } from '../assets/editIcon.svg';
import { useTranslation } from 'react-i18next';

export default function EditIcon() {
  const { t } = useTranslation(['common']);
  return (
    <div className='group flex cursor-pointer items-center mt-0.5 text-gray-400 hover:text-gray-800 duration duration-300'>
      <EditSVG className='flex cursor-pointer fill-current text-gray-400 hover:text-gray-800 duration duration-300' />
    </div>
  );
}
