export default function SummaryTable({ id, columns, data }: any) {
  return (
    <div className="overflow-auto">
      <table>
        <tbody>
          {data.map((rowData: any, index: any) => (
            <tr key={index}>
              {columns.map(({ path }: any) => (
                <td key={path} className="text-base sm:text-sm p-1.5">
                  {path === "parameter" ? (
                    <h2 className="text-sm font-medium text-gray-500 pr-4">
                      {rowData[path]}
                    </h2>
                  ) : (
                    <h2>{rowData[path]}</h2>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
