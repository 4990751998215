export const REACT_APP_TESTING_MODE = (companyId: string) => {
  if (
    companyId === 'PehDvzzI4FMqb7qE7aoEzrT15l23' ||
    companyId === 'MLNfcPJwPwhOcsycxedsJMyFrDn2' ||
    companyId === 'L66YxNO1wtNN5zaepEcX0Thhd0j1' ||
    companyId === 'woG5qvjA35UkVVgJIJu0zf7Wj7d2'
  ) {
    return true;
  } else {
    return false;
  }
};

export const REACT_APP_OLD_IDS = (companyId: string) => {
  if (
    companyId === 'dGpez4nH6ubjA1KnIqqs2pKcCl13' ||
    companyId === 'meatOF0NltSfAhEm4BK7FuHrBYm1' ||
    companyId === 'GoJz1kMGOvROv7TSx14qTok8a3L2'
  ) {
    return true;
  } else {
    return false;
  }
};
// = process.env.REACT_APP_TESTING_MODE != null
//   ? process.env.REACT_APP_TESTING_MODE
//   : true;

export const REACT_APP_PAYPAL_API_KEY = (companyId: string) => {
  if (REACT_APP_TESTING_MODE(companyId)) {
    return process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX || '';
  } else {
    return process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE || '';
  }
};

export const REACT_APP_GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export const REACT_APP_GOOGLE_MAPS_TIME_ZONE_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_TIME_ZONE_API_KEY || '';
