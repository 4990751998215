import db from './../firebase';
import firebase from 'firebase';
import toast from 'react-hot-toast';
import calculateVatFromPrice from 'utils/calculateVatFromPrice';

export const createSizes = async (
  sizes: any,
  defaultSize: any,
  general: any,
  user: any,
  setCatalogue: any,
  catalogue: any,
  productId?: any,
  sizeId?: any,
  fetchCatalogue?: any,
  sizesToEdit?: any,
  showNetto?: any,
  productVat?: any,
  createAndAdd = true,
  refID?: any
) => {
  console.log(sizes);
  //IN USAGE
  if (sizeId === undefined) {
    try {
      const loading = toast.loading('Loading...');
      let refId = refID;
      // here we are at the configurator
      if (productId === undefined || createAndAdd) {
        const x = await db
          .collection('companies')
          .doc(user)
          .collection('catalogue')
          .doc('catalogue')
          .collection('configuratorSizes')
          .add({ items: sizes, defaultSize: defaultSize, general: general });

        refId = x.id;
        setCatalogue({
          ...catalogue,
          configurator: {
            ...catalogue.configurator,
            sizes: [
              ...catalogue.configurator.sizes,
              {
                [x.id]: {
                  items: sizes,
                  defaultSize: defaultSize,
                  general: general,
                },
              },
            ],
          },
        });
      }

      if (productId !== undefined) {
        //if we are adding size for a specific product

        let sizesWithVat = sizes.map((size: any) => {
          const calcResult = calculateVatFromPrice(
            showNetto ? size.nettoPrice : size.price,
            showNetto,
            productVat
          );
          return { ...size, ...calcResult };
        });
        if (createAndAdd) {
          setCatalogue({
            ...catalogue,
            configurator: {
              ...catalogue.configurator,
              sizes: [
                ...catalogue.configurator.sizes,
                {
                  [refId]: {
                    items: sizes,
                    defaultSize: defaultSize,
                    general: general,
                  },
                },
              ],
            },
            products: {
              ...catalogue.products,
              [productId]: {
                ...catalogue.products[productId],
                sizes: {
                  items: sizesWithVat,
                  defaultSize: defaultSize,
                  refId: refId,
                },
              },
            },
          });
        } else {
          setCatalogue({
            ...catalogue,
            products: {
              ...catalogue.products,
              [productId]: {
                ...catalogue.products[productId],
                sizes: {
                  items: sizesWithVat,
                  defaultSize: defaultSize,
                  refId: refId,
                },
              },
            },
          });
        }

        await db
          .collection('companies')
          .doc(user)
          .collection('catalogue')
          .doc('catalogue')
          .collection('products')
          .doc(productId)
          .update({
            sizes: {
              items: sizesWithVat,
              defaultSize: defaultSize,
              refId: refId,
            },
          });
      }
      toast.success('Sizes added', { id: loading });
    } catch (error) {
      toast.dismiss();
      toast.error('Sizes not added');
      return;
    }
  } else {
    updateSizes(
      user,
      sizeId,
      sizes,
      defaultSize,
      productId,
      setCatalogue,
      catalogue,
      sizesToEdit,
      showNetto
    );
  }
};

export const deleteSizess = async (
  sizeId: any,
  user: any,
  setCatalogue: any,
  catalogue: any,
  productId?: any,
  sizeToEdit?: any,
  fetchCatalogue?: any
) => {
  try {
    const loading = toast.loading('Loading...');
    if (productId === undefined) {
      //if at configurator

      const newSizes = catalogue.configurator.sizes;
      newSizes.splice(sizeToEdit, 1);
      setCatalogue({
        ...catalogue,
        configurator: {
          ...catalogue.configurator,
          sizes: newSizes,
        },
      });

      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('configuratorSizes')
        .doc(sizeId)
        .delete();

      const newCatalogue = catalogue;
      Object.entries(newCatalogue.products).forEach((product: any) => {
        if (
          product[1]?.sizes?.refId !== undefined &&
          product[1]?.sizes?.refId === sizeId
        ) {
          const removedAddon = catalogue.products[product[0]];
          delete removedAddon.sizes;
          db.collection('companies')
            .doc(user)
            .collection('catalogue')
            .doc('catalogue')
            .collection('products')
            .doc(product[0])
            .update({
              sizes: firebase.firestore.FieldValue.delete(),
            });
        }
      });
      setCatalogue({ ...newCatalogue });
    } else {
      //at a product

      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: {
            ...catalogue.products[productId],
            sizes: undefined,
          },
        },
      });

      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('products')
        .doc(productId)
        .update({
          sizes: firebase.firestore.FieldValue.delete(),
        });
    }
    toast.success('Sizes deleted', { id: loading });
  } catch (error) {
    toast.dismiss();
    toast.error('Sizes not deleted');
    return;
  }
};

const updateSizes = async (
  user: any,
  sizeId: any,
  sizes: any,
  defaultSize: any,
  productId: any,
  setCatalogue: any,
  catalogue: any,
  sizesToEdit: any,
  showNetto: any
) => {
  try {
    const loading = toast.loading('Loading...');
    if (productId === undefined) {
      //if at configurator
      const newCat = catalogue;
      newCat.configurator.sizes[sizesToEdit] = {
        [sizeId]: { items: sizes, defaultSize: defaultSize, general: true },
      };
      setCatalogue({ ...newCat });

      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('configuratorSizes')
        .doc(sizeId)
        .update({
          items: sizes,
          defaultSize: defaultSize,
        });

      const newCatalogue = catalogue;
      Object.entries(newCatalogue.products).forEach((product: any) => {
        if (
          product[1]?.sizes?.refId !== undefined &&
          product[1]?.sizes?.refId === sizeId
        ) {
          const sizesWithVat = sizes.map((size: any) => {
            const calcResult = calculateVatFromPrice(
              size.price,
              showNetto,
              newCatalogue.products[product[0]].vat
            );
            return { ...size, ...calcResult };
          });
          newCatalogue.products[product[0]].sizes.items = sizesWithVat;
          newCatalogue.products[product[0]].sizes.defaultSize = defaultSize;
          // newCatalogue.products[product[0]].sizes.refId = sizeId

          db.collection('companies')
            .doc(user)
            .collection('catalogue')
            .doc('catalogue')
            .collection('products')
            .doc(product[0])
            .update({
              sizes: {
                items: sizesWithVat,
                defaultSize: defaultSize,
                refId: sizeId,
              },
            });
        }
      });
      setCatalogue({ ...newCatalogue });
    } else {
      //if updating for a specific product

      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: {
            ...catalogue.products[productId],
            addons: {
              ...catalogue.products[productId].addons,
              [sizeId]: sizes,
            },
          },
        },
      });
      db.collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('products')
        .doc(productId)
        .update({
          sizes: { items: sizes, defaultSize: defaultSize },
        });
    }
    toast.success('Sizes updated', { id: loading });
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error('Sizes not updated');
    return;
  }
};
