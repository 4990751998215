import { PaymentType } from '../types';
import moment from 'moment';
import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { ReactComponent as PayPalIcon } from '../assets/paypal.svg';
import { ReactComponent as ApplePayIcon } from '../assets/applePay.svg';
import { ReactComponent as CashIcon } from '../assets/icon-cash.svg';
import { ReactComponent as CardIcon } from '../assets/icon-card.svg';

type InformationBoxProps = {
  headerText: string;
  subheader: {
    main: string;
    supplementary?: string
  };
  company?: {
    language: string;
    currency: string;
  },
  onClick?: () => void;
  onLoadMore?: () => void;
  date?: string | number | ReactNode;
  format?: string | boolean;
  order?: { amount?: number, paymentType?: PaymentType };
  children?: ReactNode;
  idx: number;
}

export const InformationBox = forwardRef(({
  headerText,
  onClick,
  subheader,
  order,
  company,
  date,
  children,
  format = 'ddd D MMMM',
  idx
}: InformationBoxProps,
  ref?: ForwardedRef<HTMLDivElement>
) => {
  const processDate = (date: string | number | ReactNode, format: string) => {
    if (typeof date === 'number' || typeof date === 'string') {
      return moment(date).format(format)
    } else {
      return date;
    }
  }
  const cursorType = onClick ? 'cursor-pointer' : 'cursor-default';
  return (
    <div ref={ref} id={`box-${idx}`} className='w-6/12 md:w-4/12 p-2'>
      <div
        className={`bg-white p-3 rounded-xl text-sm ${cursorType}`}
        onClick={onClick}
      >
        <div className='bg-gray-100 p-2 rounded-md text-center font-bold'>
          {headerText?.toUpperCase().toString()}
        </div>
        <div className='py-2 mt-2 text-center font-bold truncate'>
          {subheader.main}
          {subheader.supplementary && (
            <div className="py-1 text-center text-sm font-normal">
              {subheader.supplementary}
            </div>
          )}
        </div>
        {order?.amount && (
          <div className="px-2 py-1 flex justify-center align-middle text-center font-normal">
            <p className="text-lg text-left text-gray-500 flex items-center">
              {order?.amount && (
                <span className="mr-2">
                        {new Intl.NumberFormat(company?.language, {
                          style: 'currency',
                          currency: company?.currency,
                          currencyDisplay: 'symbol',
                          minimumFractionDigits: 2,
                        }).format(order.amount)}
                      </span>
              )}
              {order?.paymentType === PaymentType.Apple ? (
                <ApplePayIcon className="border border-black rounded-sm opacity-80" />
              ) : order?.paymentType === PaymentType.PayPal ? (
                <PayPalIcon className="opacity-80" />
              ) : order?.paymentType === PaymentType.Card ? (
                <CardIcon className="card-icon opacity-80" />
              ) : (
                <CashIcon className="cash-icon opacity-80" />
              )}
            </p>
          </div>
        )}
        <div className='p-2 mt-2 text-gray-500 text-center font-light truncate'>
          {date && typeof format === 'string' && processDate(date, format)}
          {!format && date}
        </div>
        <div>
          { children }
        </div>
      </div>
    </div>
  )
});