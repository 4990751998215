import db from './firebase';
import moment from 'moment';


const fetchRevenue = async (user: any) => {
  const currentDate = moment();
  const queries = [];
  const monthsCount = 12;
  for (let i = 0; i < monthsCount; i++) {
      const month = currentDate.clone().subtract(i, 'months').format('MMM YYYY');
      const queryPromise = db
          .collection('companies')
          .doc(user)
          .collection('statistics')
          .doc(month)
          .get();
      queries.push(queryPromise);
  }
  
  const results = await Promise.all(queries);

  return results
};
export default fetchRevenue;
