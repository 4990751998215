function NumberInput({ value, onNumberChanged }: { value: number, onNumberChanged: (v: number) => void }) {
  return (
    <div className="custom-number-input h-10 w-32">
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          type="button"
          data-action="decrement"
          onClick={() => value > 0 && onNumberChanged(value - 1)}
          className=" bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
        >
          <span className="m-auto text-2xl">−</span>
        </button>
        <input
          type="number"
          value={value}
          placeholder="0"
          onChange={(e) =>
            e.target.value !== "" &&
            onNumberChanged(Number.parseInt(e.target.value))
          }
          className="outline-none border-none text-center w-full focus:border-droov z-10 bg-gray-200 font-semibold text-md hover:text-black focus:text-black  items-center text-gray-700"
          name="custom-input-number"
        />
        <button
          type="button"
          data-action="increment"
          onClick={() => onNumberChanged(value + 1)}
          className="bg-gray-200 border-none text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
        >
          <span className="m-auto text-2xl ">+</span>
        </button>
      </div>
    </div>
  );
}

export default NumberInput;
