const space = '\u00A0'; // This keeps the words togerher in linebreaks.

export default function OrderItem(orderItem: any) {
  return (
    <div className='flex items-left'>
      <div>x{orderItem.itemQuantity}</div>
      <div className='ml-3'>
        <div>{orderItem.itemName}</div>

        <div className='mt-1 text-xs'>
          <div>
            <span className='font-bold mr-1'>Size:</span>
            {orderItem.itemSize}
          </div>

          {orderItem.itemSpecs &&
            orderItem.itemSpecs.map((itemSpec: any) => (
              <div key={'item-' + Math.random()}>
                <span className='font-bold mr-1'>{itemSpec.groupTitle}:</span>
                {itemSpec.items.reduce(
                  (text: string, item: any, index: number) => {
                    return (
                      text +
                      `${item.qty ? 'x' + item.qty : 'x1'}${space}${
                        item.addon
                      }${index !== itemSpec.items.length - 1 ? ', ' : ''}`
                    );
                  },
                  ''
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
