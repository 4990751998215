import { useTranslation } from 'react-i18next';
import BoxLoader from '../BoxLoader';
import TrendingUpIcon from '@heroicons/react/outline/TrendingUpIcon';
import TrendingDownIcon from '@heroicons/react/outline/TrendingDownIcon';

export type SummaryStatistics = {
  value: string | number;
  tendency: number;
  title: string;
};
export default function StatisticalInformation({
  statistics,
  loaded,
}: {
  statistics: SummaryStatistics;
  loaded: boolean;
}) {
  const { t } = useTranslation(['dashboard']);
  if (!loaded) {
    return <BoxLoader />;
  }

  const TrendItem = () => {
    return (
      <div className='flex items-center gap-1'>
        {statistics.tendency > 0 ? (
          <TrendingUpIcon className='text-green_light' width={'1.5rem'} />
        ) : (
          <TrendingDownIcon className='text-orange_light' width={'1.5rem'} />
        )}
        <h6
          className={`text-base whitespace-nowrap ${
            statistics.tendency > 0 ? 'text-green_light' : 'text-orange_light'
          }`}
        >
          {statistics.tendency.toFixed(0)} %
        </h6>
      </div>
    );
  };

  return (
    <div
      className='bg-white rounded-2xl h-48 p-4 flex flex-col flex-1 border border-gray-200 cursor-default'
      style={{ minWidth: '200px' }}
    >
      <div className='text-gray-900 font-medium text-lg ml-1.5 flex flex-row justify-between flex-wrap'>
        <h3>{t(`${statistics.title}`)}</h3>
        {statistics.tendency !== 0 && <TrendItem />}
      </div>
      <h1 className='text-4xl text-gray-900 lg:text-4xl font-semibold md:font-medium flex-grow flex items-center justify-center mb-4 flex-nowrap'>
        {statistics.value}
      </h1>
    </div>
  );
}
