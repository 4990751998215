import { Switch } from '@headlessui/react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { darkToastStyle } from '../../utils/toastStyle';

export interface CateringSettingProps {
  enabled: boolean;
  setActive: (active: boolean) => void;
  cateringUrl: string;
}

const CateringSetting: React.FC<CateringSettingProps> = (
  props: CateringSettingProps
) => {
  const { t } = useTranslation(['settings']);

  return (
    <div className='w-full bg-white p-4 rounded-lg max-w-3xl mt-5 cursor-default'>
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>{t('catering.label')}</h2>
        <Switch
          checked={props.enabled || false}
          onChange={() => props.setActive(!props.enabled || false)}
          className={`${
            props.enabled ? 'bg-droov' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none`}
        >
          <span
            className={`${
              props.enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
          />
        </Switch>
      </div>
      <p className='droov_text_description_light'>
        {t('catering_description.label')}
      </p>
      {props.enabled && (
        <div className='flex flex-row mt-4'>
          <div className='flex flex-row justify-between cursor-copy text-md font-md bg-blue-50 p-1 w-full md:w-2/3 rounded-lg'>
            <span
              id='cateringLink'
              className='ml-2 cursor-copy w-full my-auto text-gray-700 truncate'
            >
              {props.cateringUrl}
            </span>
            <div
              className='cursor-pointer px-3 py-2 bg-white rounded-md text-gray-900 ml-4 font-medium transition transform ease-in-out hover:scale-90 hover:text-gray-700'
              onClick={() => {
                navigator.clipboard.writeText(props.cateringUrl);
                toast.success(t('copied.label'), {
                  icon: '🎉',
                  style: darkToastStyle,
                });
              }}
            >
              {t('copy.label')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CateringSetting;
