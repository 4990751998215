import { NavLink } from 'react-router-dom';
import { Fragment, FunctionComponent } from 'react';
import { Route } from '../routes/routes';

import { ReactComponent as DroovSmallSvg } from '../assets/droov_black_1.svg';
import { useTranslation } from 'react-i18next';

export interface SideBarProps {
  NavLinks: Array<Route>;
  setHideSideBar: (arg0: boolean) => void; // This is used to hide the text inside ProfileComponent. This is passed as prop from Approuter
  hideSideBar: boolean;
}

const SideBar: FunctionComponent<SideBarProps> = ({
  NavLinks,
  setHideSideBar,
  hideSideBar,
  children,
}) => {
  const toggleSideBar = () => {
    setHideSideBar(!hideSideBar);
  };
  const { t } = useTranslation(['sidebar']);

  return (
    <div
      className={
        hideSideBar
          ? 'transition-width transition-slowest ease h-auto w-20 flex-col items-center justify-between bg-white md:flex'
          : 'h-auto w-56 transition-width transition-slowest ease flex-col items-center justify-between bg-white hidden md:flex'
      }
    >
      <div>
        {/* The code which switches between Droov main logo and droov small icon */}
        {!hideSideBar ? (
          <div className='ml-8 mt-8 droov_logo_black h-7' />
        ) : (
          <div className='ml-4 mt-8 h-7'>
            <DroovSmallSvg />
          </div>
        )}

        {/* Code that handles hide menu */}
        <div
          onClick={toggleSideBar}
          className={
            hideSideBar
              ? 'flex items-center mt-6 w-full text-gray-500 h-12 px-4 rounded-md transition ease-in-out hover:bg-gray-100 cursor-pointer'
              : 'flex items-center mt-6 w-44 text-gray-500 h-12 px-4 rounded-md transition ease-in-out hover:bg-gray-100 cursor-pointer'
          }
        >
          <div className='flex'>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className={`w-6 h-6 transition duration-200 ease-in-out ${
                  hideSideBar ? 'transform rotate-180' : ''
                } 
                `}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5'
                />
              </svg>
            </div>
            {!hideSideBar ? (
              <div className='pl-3.5 pt-0.5 text-sm'>
                {' '}
                {t('hide_menu.label')}{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr className='mt-5 h-px bg-gray-100 border-0' />

        {/* <div className="divide-y-2 divide-red-100 divide-solid"/> */}
        <div className='flex'>
          <span className='pb-3.5' />
        </div>
        {NavLinks.map((navlink, index) => (
          <Fragment key={index}>
            {navlink.disabled != null && navlink.disabled ? (
              <NavLink
                key={navlink.name}
                onClick={(e) => e.preventDefault()}
                to={navlink.path}
                className={` ${
                  hideSideBar
                    ? ' hover:text-droov pl-6 pt-3 pb-3 inline-block w-full text-gray-500 cursor-not-allowed'
                    : ' pl-8 pt-3 pb-3 inline-block w-full text-gray-400 cursor-not-allowed'
                } ${navlink.hideFromNavbar ? 'hidden' : ''}`}
              >
                <div className='flex opacity-50 cursor-not-allowed'>
                  <button className='cursor-not-allowed' disabled={true}>
                    {navlink.icon}
                  </button>
                  {!hideSideBar && (
                    <button
                      className='pl-12 pt-0.5 text-sm transition-all ease-linear cursor-not-allowed'
                      disabled={true}
                    >
                      {navlink.name}
                    </button>
                  )}
                </div>
              </NavLink>
            ) : (
              <>
                <NavLink
                  key={navlink.name}
                  activeClassName={
                    'border-l-4 bg-droov bg-opacity-10 border-droov text-droov outline-none'
                  }
                  to={navlink.path}
                  className={`flex items-center text-gray-500 w-full h-10 px-3 mt-2 rounded-md transition ease-in-out hover:bg-gray-300 hover:bg-opacity-20 ${
                    navlink.hideFromNavbar ? 'hidden' : ''
                  }`}
                >
                  <div className='pl-1 flex'>
                    <button
                      disabled={
                        navlink.disabled != null ? navlink.disabled : false
                      }
                    >
                      {navlink.icon}
                    </button>
                    {!hideSideBar && (
                      <button
                        className='pl-3.5 pt-0.5 text-sm transition-all ease-linear outline-none'
                        disabled={
                          navlink.disabled != null ? navlink.disabled : false
                        }
                      >
                        {t(navlink.name)}
                      </button>
                    )}
                  </div>
                </NavLink>
              </>
            )}
          </Fragment>
        ))}
      </div>

      <div className=''>{children}</div>
    </div>
  );
};

export default SideBar;
