import { useState } from 'react';

import CustomInputField from './CustomInputField';
import toast from 'react-hot-toast';
import useAuthState from 'global-states/useAuthState';
import firebase from 'firebase';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  CreateAccountButton,
  DroovLogo,
  LoginScreenVideo,
  PrivacyImprints,
} from './LoginScreenReusables';

export default function SignInComponent({
  onSignInClicked,
}: {
  onSignInClicked: (
    email: string,
    password: string,
    sendEmail?: boolean
  ) => Promise<boolean | undefined>;
}) {
  const { t } = useTranslation(['login']);

  const { user, loading }: { user: firebase.User; loading: Boolean } =
    useAuthState() as any;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);

  const signIn = async (e: any) => {
    e.preventDefault();
    setDisableLoginBtn(true);
    if (!email && !password) {
      toast.error('Please provide username and password');
      setDisableLoginBtn(false);
      return;
    }
    const toastId = toast.loading('Signing In');
    try {
      const result = await onSignInClicked(email, password);
      toast.dismiss(toastId);
      if (!result) {
        toast((t) => (
          <div className='flex flex-col items-center'>
            <p>Email is not verified yet!</p>
            <button className='bg-gray-100 p-2' onClick={() => sendEmail(t.id)}>
              Send Verification Email
            </button>
          </div>
        ));
      } else {
        toast.success('Signed in Successfully');
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      toast.dismiss(toastId);
      toast.error(errorMessage);
    }
    setTimeout(() => {
      setDisableLoginBtn(false);
    }, 3000);
  };

  const sendEmail = async (toastId: any) => {
    toast.dismiss(toastId);
    await onSignInClicked(email, password, true);
  };

  return (
    <>
      {/* <!-- Favicon configurations --> */}

      <div className='flex min-h-screen relative font-Ubuntu bg-white login_background'>
        {/* <!--login left part--> */}
        <div className='bg-white xl:w-1/3 md:w-full w-full relative'>
          <DroovLogo />
          <CreateAccountButton />

          {/* <!-- form to login --> */}
          <div className='justify-center flex flex-1 flex-wrap items-center h-screen'>
            <div className='relative w-full sm:px-16'>
              <p
                id='login_label'
                className='font-medium text-2xl text-droov-black text-center mb-6'
              >
                Welcome
              </p>

              <form
                className='flex flex-col space-y-4 mt-5 w-full px-8 relative'
                id='loginForm'
              >
                <div className=''>
                  <CustomInputField
                    required
                    id='email'
                    type='email'
                    placeholder={t('email.label')}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    value={email}
                  />
                </div>
                <div className=''>
                  <CustomInputField
                    required
                    id='password'
                    type='password'
                    placeholder={t('password.label')}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    value={password}
                  />
                </div>
                <div className={'flex justify-end mr-1'}>
                  <Link
                    id={'login_forget_label'}
                    to={'/reset'}
                    className={
                      'text-droov-forgot-text-color text-left text-sm hover:text-black transition ease-in-out mb-4'
                    }
                  >
                    Forgot your password?
                  </Link>
                </div>

                {/* <!-- <div id="alert_failure_login" class="w-full text-center p-3 xl:p-6 bg-white text-red-400 mb-4 animate-appear_motion_bottomToUp hidden">Could not login</div> --> */}
                <div
                  className={`rounded-full self-center overflow-hidden w-40 h-16`}
                >
                  <button
                    disabled={disableLoginBtn}
                    onClick={signIn}
                    id='login_btn'
                    className={`self-center btn_black font-bold text-sm uppercase w-40 h-16`}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <PrivacyImprints />
        </div>
        <LoginScreenVideo />
      </div>
    </>
  );
}
