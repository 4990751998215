import { DiscountType } from '../types';
import CustomInputField from './CustomInputField';
import useAuthState from '../global-states/useAuthState';

export default function InputFieldSelector({
  onValueChanged,
  value,
  onSelectedValueChanged,
  selectedValue,
  disabled,
}: any) {
  const auth: any = useAuthState.getState();
  return (
    <div>
      <div className='mt-1 relative rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          <span className='text-gray-500 sm:text-sm'>
            {selectedValue === DiscountType.Percent
              ? '%'
              : new Intl.NumberFormat(auth.companyLanguage, {
                  style: 'currency',
                  currency: auth.companyCurrency,
                })
                  .format(0)
                  .replace(/[\d,.]/g, '')}
          </span>
        </div>
        <CustomInputField
          min='1'
          max={selectedValue === DiscountType.Percent ? 100 : undefined}
          required={true}
          onChange={(e) => onValueChanged(Number.parseFloat(e.target.value))}
          type='text'
          maxLength={2}
          pattern='[0-9]*'
          inputMode='numeric'
          name='price'
          value={value}
          id='price'
          classnameadditional='pl-8 pr-32'
          placeholder='0'
        />
        {/*<div className='absolute inset-y-0 right-0 flex items-center'>
          <label htmlFor='currency' className='sr-only'>
            Currency
          </label>
          <select
            disabled={disabled}
            onChange={(e) =>
              onSelectedValueChanged(e.target.value as DiscountType)
            }
            id='currency'
            name='currency'
            className='focus:ring-droov focus:border-droov h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md'
          >
            <option selected={selectedValue === DiscountType.Percent}>
              {DiscountType.Percent}
            </option>
            <option selected={selectedValue === DiscountType.Currency}>
              {auth.companyCurrency}
            </option>
          </select>
        </div>*/}
      </div>
    </div>
  );
}
