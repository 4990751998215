
export interface CustomerAddressObject {
  city: string;
  instruction: string;
  postCode: number;
  streetAndHouseNumber: string;
}
export interface CustomerObject {
  phoneNumber: string;
  name: string;
  joinedAt: number;
  uid: string;
  addresses: CustomerAddressObject[];
  isAuth: boolean;
  stats?: CustomerStatistics;
}

export const customerStatisticsKeys = [
  'numberOfOrders',
  'avgBasketSize',
  'totalSpend',
  'lastOrder'
] as const

export type CustomerStatistics = Record<typeof customerStatisticsKeys[number], number>