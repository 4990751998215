import SignInComponent from '../ui-components/SingnInComponent';
import { signIn } from '../firebase-logic/firebaseAuthFunctions';
import { Toaster } from 'react-hot-toast';
const LoginScreen = () => {
  return (
    <div>
      <SignInComponent onSignInClicked={signIn}></SignInComponent>
      <Toaster position='top-center' reverseOrder={false} />
    </div>
  );
};

export default LoginScreen;
