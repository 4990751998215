import create from 'zustand';
import { CustomerObject } from '@common/types';
import { useAllCustomers } from '../firebase-logic/customers';
import { useEffect, useState } from 'react';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';

type CustomersState = {
  customers: CustomerObject[];
  setCustomers: (customers: CustomerObject[]) => void;
  startAfter: QueryDocumentSnapshot | undefined;
  setStartAfter: (q?: QueryDocumentSnapshot) => void;
  search: {
    value: string;
    key: 'splits.name' | 'splits.phone',
    active: boolean
  },
  setSearchValue: (value: string) => void,
  setSearchKey: (key: 'splits.name' | 'splits.phone') => void,
  setActive: (active: boolean) => void,
  reset: () => void
}

export const useCustomersState = create<CustomersState>((set, get) => ({
  customers: [],
  startAfter: undefined,
  search: {
    value: '',
    key: 'splits.name',
    active: false,
  },
  setCustomers: (customers: CustomerObject[]) => set({ customers }),
  setStartAfter: (startAfter) => set({ startAfter }),
  setSearchKey: (key) => set({ search: { ...get().search, value: '', key  } }),
  setSearchValue: (value) => set({ search: { ...get().search, value  } }),
  setActive: (active: boolean) => set({ search: { ...get().search, active } }),
  reset: () => set({
    customers: [],
    startAfter: undefined,
    search: {
      value: '',
      key: 'splits.name',
      active: false,
    }
  })
}));


export const usePaginatedCustomerState = ({ id, limit }: { id: string, limit?: number }) => {
  const state = useCustomersState();
  const params = {
    id,
    limit,
    forgetOld: true,
    start : {
      startAfter: state.startAfter,
      setStartAfter: state.setStartAfter
    },
    search: state.search,
    loadInit: !(state.customers.length > 0),
  }
  const [customers, customersLoading, loadMore, noMoreLoad] = useAllCustomers(params);
  const [prevValue, setPreviousValue] = useState(state.search.value);

  useEffect(() => {
    if (state.search.value !== prevValue) {
      state.setCustomers(customers);
      setPreviousValue(state.search.value);
      return;
    }
    const phoneNumbers = state.customers.map(customer => customer.phoneNumber);
    state.setCustomers( [
      ...state.customers,
      ...customers.filter(customer =>
        !phoneNumbers.includes(customer.phoneNumber) || phoneNumbers.length === 0)
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  return [state.customers,  customersLoading, loadMore, noMoreLoad] as const;
}