import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { Customer } from '../../types';
import CustomInputField from 'ui-components/CustomInputField';
import useAuthState from '../../global-states/useAuthState';
import { useTranslation } from 'react-i18next';

type EditBillingInformationDialogProps = {
  onClose: () => void;
  billingInformation: Customer;
  onSave: (updatedBillingInformation: Customer) => void;
  open: boolean;
};

export const EditBillingInformationDialog = (
  props: EditBillingInformationDialogProps,
) => {
  const [billingInformation, setBillingInformation] = useState<Customer>({
    id: '',
    name: '',
    email: '',
    address: {
      city: '',
      line1: '',
      line2: '',
      country: '',
      postal_code: '',
    },
    phone: '',
    vat: '',
    customer_name: '',
  });

  useEffect(() => {
    setBillingInformation(props.billingInformation);
  }, [props.billingInformation]);
  const submitHandler = (e: any) => {
    e.preventDefault();
    console.log(billingInformation);
    props.onSave(billingInformation);
    props.onClose();
  };

  const { t } = useTranslation(['onboarding']);
  const auth: any = useAuthState.getState();
  const countryCode = auth.localizationSetting?.countryCode;

  const [, setHasValidPhone] = useState(false);
  const cancelButtonRef: any = useRef();

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-30 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={props.open}
          onClose={props.onClose}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <form
                onSubmit={submitHandler}
                className="p-6 inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg"
              >
                <div className="flex flex-col gap-1">
                  <h1 className="text-lg font-bold leading-4 mb-3">
                    {t('billing_information.label')}
                  </h1>
                  <div className="mb-3">
                  <span className="droov_label_text">
                    {t('company_name.label')}
                  </span>
                    <CustomInputField
                      value={billingInformation.name ?? ''}
                      onChange={(e) =>
                        setBillingInformation({
                          ...billingInformation,
                          name: e.target.value,
                        })
                      }
                      type="text"
                      id="name"
                      placeholder="Burger Queen Inc."
                      required={true}
                    />
                  </div>

                  <div className="flex flex-col md:flex-row gap-3 mb-3 mx-0">
                    <div className="flex flex-col w-full">
                    <span className="droov_label_text">
                      {t('responsible_person.label')}
                    </span>
                      <CustomInputField
                        value={billingInformation.customer_name}
                        onChange={(e) =>
                          setBillingInformation({
                            ...billingInformation,
                            customer_name: e.target.value,
                          })
                        }
                        type="text"
                        id="customer_name"
                        placeholder="Andreas Maus"
                        required={true}
                      />
                    </div>

                    <div className="flex flex-col w-full">
                    <span className="droov_label_text">
                      {t('billing_email.label')}
                    </span>
                      <CustomInputField
                        value={billingInformation.email}
                        onChange={(e) =>
                          setBillingInformation({
                            ...billingInformation,
                            email: e.target.value,
                          })
                        }
                        type="email"
                        id="email"
                        placeholder="myshop-billing@mail.com"
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-1.5 mb-3">
                  <span className="droov_label_text">
                    {t('billing_address.label')}
                  </span>
                    <CustomInputField
                      value={billingInformation.address.line1}
                      onChange={(e) =>
                        setBillingInformation({
                          ...billingInformation,
                          address: {
                            ...billingInformation.address,
                            line1: e.target.value,
                          },
                        })
                      }
                      placeholder={t('line_1.label')}
                      type="text"
                      id="line1"
                      required={true}
                    />

                    <CustomInputField
                      value={billingInformation.address.line2}
                      onChange={(e) =>
                        setBillingInformation({
                          ...billingInformation,
                          address: {
                            ...billingInformation.address,
                            line2: e.target.value,
                          },
                        })
                      }
                      type="text"
                      id="line2"
                      placeholder={t('line_2.label')}
                      required={false}
                    />

                    <div className="flex flex-row gap-2.5">
                      <CustomInputField
                        value={billingInformation.address.city}
                        onChange={(e) =>
                          setBillingInformation({
                            ...billingInformation,
                            address: {
                              ...billingInformation.address,
                              city: e.target.value,
                            },
                          })
                        }
                        type="text"
                        id="city"
                        placeholder={t('city_placeholder.label')}
                        required={true}
                      />
                      <CustomInputField
                        value={billingInformation.address.postal_code}
                        onChange={(e) =>
                          setBillingInformation({
                            ...billingInformation,
                            address: {
                              ...billingInformation.address,
                              postal_code: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder={t('postal_code_placeholder.label')}
                        id="postal_code"
                        required={true}
                      />
                    </div>

                    <CustomInputField
                      value={billingInformation.address.country}
                      onChange={(e) =>
                        setBillingInformation({
                          ...billingInformation,
                          address: {
                            ...billingInformation.address,
                            country: e.target.value,
                          },
                        })
                      }
                      type="text"
                      id="country"
                      placeholder={t('country_placeholder.label')}
                      required={true}
                    />
                  </div>

                  <div className="flex flex-col md:flex-row gap-3">
                    <div className="flex flex-col w-full">
                    <span className="droov_label_text">
                      {t('phone_number.label')} {t('optional.label')}
                    </span>
                      <IntlTelInput
                        onPhoneNumberChange={(
                          isValid,
                          value,
                          selectedCountryData,
                          fullNumber,
                          extension,
                        ) => {
                          setBillingInformation({
                            ...billingInformation,
                            phone: fullNumber,
                          });
                          setHasValidPhone(isValid);
                        }}
                        onSelectFlag={(
                          currentNumber,
                          selectedCountryData,
                          fullNumber,
                          isValid,
                        ) => {
                          setBillingInformation({
                            ...billingInformation,
                            phone: fullNumber,
                          });
                          setHasValidPhone(isValid);
                        }}
                        defaultValue={billingInformation.phone}
                        preferredCountries={['de', 'us']}
                        defaultCountry={
                          countryCode ? countryCode.toLowerCase() : 'de'
                        }
                        containerClassName="z-20 mt-1 intl-tel-input w-full lg:w-100 m-auto justify-center"
                        inputClassName="focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="flex flex-col w-full">
                    <span className="droov_label_text">
                      {t('vat_number.label')} {t('optional.label')}
                    </span>
                      <CustomInputField
                        value={billingInformation.vat}
                        onChange={(e) =>
                          setBillingInformation({
                            ...billingInformation,
                            vat: e.target.value,
                          })
                        }
                        type="text"
                        id="vat"
                        placeholder={t('vat_number.label')}
                        required={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-8">
                  <button
                    type="button"
                    className="cancel_button mr-6"
                    onClick={() => props.onClose()}
                  >
                    {t('cancel.button')}
                  </button>

                  <button className="blue_button" type="submit">
                    {t('save.button')}
                  </button>
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  );
};
