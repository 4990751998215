import db from './../firebase';
import firebase from 'firebase';
import toast from 'react-hot-toast';
import { Category } from '../../types';

const firebaseCreateACategory = async (user: any, category: Category) => {
  try {
    const loading = toast.loading('Loading...');
    const document = await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('categories')
      .add({
        ...category,
        visible: true,
      });
    await db
      .collection('companies')
      .doc(user)
      .collection('metadata')
      .doc('categoriesOrder')
      .set(
        {
          categoriesOrder: firebase.firestore.FieldValue.arrayUnion(
            document.id
          ),
        },
        { merge: true }
      );
    toast.success('Category created', { id: loading });
    return document.id;
  } catch (e) {
    toast.dismiss();
    toast.error('Category not created');
    return '';
  }
};

const firebaseUpdateACategory = async (
  user: any,
  categoryId: string,
  updatedCategory: any
) => {
  try {
    console.log(updatedCategory);
    const loading = toast.loading('Loading...');
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('categories')
      .doc(categoryId)
      .update({ ...updatedCategory });
    toast.success('Category updated', { id: loading });
  } catch (e) {
    console.log(e);
    toast.error('Category not updated');
  }
};

const firebaseDeleteACategory = async (user: any, categoryId: string) => {
  try {
    db.collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('categories')
      .doc(categoryId)
      .delete();
    toast.dismiss();
    toast.success('Success');
  } catch (e) {
    console.log('Category not deleted', e);
  }
};

export {
  firebaseCreateACategory,
  firebaseUpdateACategory,
  firebaseDeleteACategory,
};
