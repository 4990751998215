import CheckCircleIcon from '@heroicons/react/solid/CheckCircleIcon';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const DetailsHeader = ({
  isAuth,
  joinedAt,
}: {
  isAuth: boolean;
  joinedAt: number;
}) => {
  const { t } = useTranslation(['customer']);

  return (
    <div className='flex flex-col lg:flex-row justify-between gap-y-4 mb-4'>
      <div className='bg-gray-100 pl-3 pr-4 py-1 max-h-10 rounded-md font-bold flex items-center gap-x-2 '>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`h-6 w-6 ${
            isAuth !== false ? 'text-droov' : 'opacity-10'
          } `}
          viewBox='0 0 20 20'
          fill='currentColor'
          stroke='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
            clipRule='evenodd'
          />
        </svg>
        <span>
          {isAuth !== false
            ? t('authenticated.label')
            : t('unauthenticated.label')}
        </span>
      </div>

      <div
        className='flex justify-start lg:justify-center gap-x-2 md:flex-col
       bg-gray-100 px-4 py-3 rounded-md font-bold
       text-base md:text-xs lg:text-base
       '
      >
        <span className='text-left lg:text-center'>{t('joinedon.label')}</span>
        <span>{moment(joinedAt ?? new Date()).format('DD. MMM YYYY')}</span>
      </div>
    </div>
  );
};

export default DetailsHeader;
