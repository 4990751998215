
export interface IMapping {
  [propertyName: string]: any;
}
export const CatState: IMapping = {
  "categories": {

  },

  "products": {

  },

  "categoriesOrder": []

}

