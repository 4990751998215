import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { ReactComponent as XIcon } from '../../../assets/xIcon.svg';
import AddSizesModal from '../product-components/AddSizesModal';
import ConfigureAddonsList from './ConfigureAddonsList';
import ConfigureSizesList from './ConfigureSizesList';
import AddAddonsModal from '../product-components/AddAddonsModal';
import { useTranslation } from 'react-i18next';

const tabs = [
  { id: 'sizes', color: 'bg-droov' },
  { id: 'addons', color: 'bg-green_chip' },
];

export default function Configurator({
  openConfigurator,
  toggleSideBar,
  addToConfigurator,
  catalogue,
  updateConfigurator,
  addonsImages,
  addAddons,
  updateAddons,
  deleteAddons,
  setSizes,
  deleteSizes,
}: any) {
  const [addonToEdit, setAddonToEdit] = useState(-1);
  const [sizesToEdit, setSizesToEdit] = useState(-1);
  const [openAddSizesModal, setOpenAddSizesModal] = useState(false);
  const [openAddAddonsModal, setOpenAddAddonsModal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const { t } = useTranslation(['catalogue']);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };
    setTabPosition();
  }, [activeTabIndex]);

  return (
    // make it enter from right
    <Transition
      as={Fragment}
      show={openConfigurator}
      enter='transition-opacity duration-500'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-500'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='absolute max-h-screen top-8 bottom-0 right-0 ml-5 p-5 w-72 xl:w-96 bg-white overflow-auto'>
        <div className='flex justify-between mb-3'>
          <b className='text-lg'>{t('configurator.label')}</b>
          <XIcon
            onClick={toggleSideBar}
            className='flex w-6 items-center justify-center cursor-pointer fill-current text-gray-400 hover:text-gray-800 duration-300'
          />
        </div>

        <div className='relative flex h-12 rounded-3xl border border-gray-200 bg-gray-100 mb-4'>
          <span
            className='absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-3xl py-1.5 transition-all duration-300'
            style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
          >
            <span
              className={`h-full w-full rounded-3xl mx-1.5 transition-colors duration-300 ${tabs[activeTabIndex].color}`}
            />
          </span>
          {tabs.map((tab, index) => {
            const isActive = activeTabIndex === index;
            return (
              <button
                key={index}
                ref={(el) => (tabsRef.current[index] = el)}
                className={`${
                  isActive
                    ? 'text-white font-medium'
                    : 'text-gray-700 hover:text-gray-900 font-normal hover:font-gray-700'
                } z-10 flex-1 my-auto cursor-pointer select-none rounded-full px-4 text-center transition-colors duration-200`}
                onClick={() => setActiveTabIndex(index)}
              >
                {t(`${tab.id}.label`)}
              </button>
            );
          })}
        </div>

        {activeTabIndex === 0 && (
          <ConfigureSizesList
            state={catalogue?.configurator}
            toggleSideBar={toggleSideBar}
            setOpenAddSizesModal={setOpenAddSizesModal}
            setSizesToEdit={setSizesToEdit}
          />
        )}

        {activeTabIndex === 1 && (
          <ConfigureAddonsList
            state={catalogue?.configurator}
            setAddonToEdit={setAddonToEdit}
            setOpenAddAddonsModal={setOpenAddAddonsModal}
          />
        )}

        <AddSizesModal
          configuratorState={catalogue?.configurator}
          setSizes={setSizes}
          deleteSizes={deleteSizes}
          sizesToEdit={sizesToEdit}
          addToConfigurator={addToConfigurator}
          closeAddSizesModal={setOpenAddSizesModal}
          openAddSizesModal={openAddSizesModal}
          setSizesToEdit={setSizesToEdit}
          updateConfigurator={updateConfigurator}
        />

        <AddAddonsModal
          addToConfigurator={addToConfigurator}
          configuratorState={catalogue?.configurator}
          addAddons={addAddons}
          updateAddons={updateAddons}
          deleteSizes={deleteSizes}
          addonsImages={addonsImages}
          deleteAddons={deleteAddons}
          setOpenAddAddonsModal={setOpenAddAddonsModal}
          openAddAddonsModal={openAddAddonsModal}
          addonToEdit={addonToEdit}
          setAddonToEdit={setAddonToEdit}
          updateConfigurator={updateConfigurator}
        />
      </div>
    </Transition>
  );
}
