import { Tab } from '@headlessui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createSettingForDeliveryHours,
  WorkingHoursComplete,
  WorkingHoursProp,
  getCompanyWorkingHours,
} from '../firebase-logic/settings';
import useAuthState from '../global-states/useAuthState';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';

import toast from 'react-hot-toast';
import WorkingHoursCustomScreen from './WorkingHoursCustomScreen';
import { useTranslation } from 'react-i18next';
import useHolidaysState, { useFetchHolidays } from '../states/holidayState';
import { DaysDateIsoRange } from '../typings/holiday';

export default function WorkingHoursScreen() {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const { t } = useTranslation(['settings']);

  const auth: any = useAuthState.getState();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const tempWorkingHoursObject: WorkingHoursComplete = {
    delivery: {
      monday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      tuesday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      wednesday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      thursday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      friday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      saturday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      sunday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
    },
    pickup: {
      monday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      tuesday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      wednesday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      thursday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      friday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      saturday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
      sunday: {
        operating: true,
        operatingHours: [{ from: '11:00', to: '12:00' }],
      },
    },
  };

  const [workingHours, setWorkingHours] = useState<WorkingHoursComplete>(
    tempWorkingHoursObject
  );

  const holidays = useHolidaysState(state => state.holidays.map<DaysDateIsoRange>(holiday =>
    ({...holiday, end: holiday.end.toDateString(), start: holiday.start.toDateString() })));

  const handleChangeForPickup = (data: WorkingHoursProp) => {
    setWorkingHours({ ...workingHours, pickup: data });
  };

  const handleChangeForDelivery = (data: WorkingHoursProp) => {
    setWorkingHours({ ...workingHours, delivery: data });
  };

  const copyFromDelivery = (e: any) => {
    e.preventDefault();
    const toastId = toast.loading(t('copying.label'));
    // let workingHoursTemp = Object.create(
    //   Object.getPrototypeOf(workingHours),
    //   Object.getOwnPropertyDescriptors(workingHours)
    // );
    // const workingHoursDeliveryTemp = workingHoursTemp.delivery;
    // workingHoursTemp.pickup = workingHoursDeliveryTemp;
    // setCopyData(workingHoursTemp);
    toast.dismiss(toastId);
    toast.success(t('copySuccess.label'));
  };

  const copyFromPickup = (e: any) => {
    e.preventDefault();
    const toastId = toast.loading(t('copying.label'));
    // let workingHoursTemp = Object.create(
    //   Object.getPrototypeOf(workingHours),
    //   Object.getOwnPropertyDescriptors(workingHours)
    // );
    // const workingHoursDeliveryTemp = workingHoursTemp.delivery;
    // workingHoursTemp.pickup = workingHoursDeliveryTemp;
    // setCopyData(workingHoursTemp);
    toast.dismiss(toastId);
    toast.success(t('copySuccess.label'));
  };

  const getWorkingHoursDataAsync = async () => {
    setLoading(true);
    try {
      const companyWorkingHours = await getCompanyWorkingHours(auth.user);
      setLoading(false);
      if (companyWorkingHours != null) {
        // @ts-ignore
        setWorkingHours(companyWorkingHours);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getWorkingHoursDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFetchHolidays();

  const submitFunctionForWorkingHours = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) {
      toast.error(t('wrongTimeEntrance.label'));
      return;
    }
    const toastId = toast.loading('Loading...');
    try {
      await createSettingForDeliveryHours(
        auth.user,
        workingHours,
        holidays,
      );
      toast.dismiss(toastId);
      toast.success(t('updateSuccess.label'));
      history.push('/settings');
    } catch (error) {
      toast.dismiss(toastId);
      console.log('error in saving', error);
      toast.error(t('updateError.label'));
    }
  };

  const categoryLabels = {
    PICKUP: t('pickuporders.label'),
    DELIVERY: t('deliveryorders.label')
  }
  let categories: Record<string, ReactNode> = {
    [categoryLabels.PICKUP]: (
      <WorkingHoursCustomScreen
        operatingTimes={workingHours.pickup}
        submitFunction={submitFunctionForWorkingHours}
        setOperatingTimes={handleChangeForPickup}
        headingForTheTable={t('workingdaysandhours.label')}
        loading={loading}
        subHeadingForTheTable={t('workingdaysandhours.text')}
        copyFromOtherTabOnClick={copyFromDelivery}
        setIsValid={setIsValid}
      />
    ),
    [categoryLabels.DELIVERY]: (
      <WorkingHoursCustomScreen
        operatingTimes={workingHours.delivery}
        submitFunction={submitFunctionForWorkingHours}
        setOperatingTimes={handleChangeForDelivery}
        headingForTheTable={t('workingdaysandhours.label')}
        subHeadingForTheTable={t('workingdaysandhours.text')}
        loading={loading}
        copyFromOtherTabOnClick={copyFromPickup}
        setIsValid={setIsValid}
      />
    )
  };

  const history = useHistory();

  return (
    <div className='overscroll-none'>
      <CustomBreadCrumb
        onClickAction={() => history.goBack()}
        displayString={t('settings.label')}
      />

      <h1 className=' font-medium  text-2xl pt-1'>{t('workinghours.label')}</h1>
      <div className='overscroll-none'>
        <div className='w-full px-2 sm:px-0'>
          <Tab.Group>
            <Tab.List className='flex justify-start p-1 overscroll-none outline-none'>
              {Object.keys(categories).map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      ' p-2.5 text-sm outline-none overscroll-none leading-5 font-medium transition ease-in-out delay-75 border-b-2',
                      '',
                      selected
                        ? ' border-b-2 border-droov text-droov'
                        : 'text-gray-400 hover:bg-white/[0.12] hover:text-droov'
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className='outline-none'>
              {Object.values(categories).map((screenComponent: any, idx) => (
                <Tab.Panel
                  key={idx}
                  className={classNames(' rounded-xl p-3 outline-none')}
                >
                  {screenComponent}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
