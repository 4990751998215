import React from 'react';

export const CancelButton = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='duration-150 ease-in-out h-4 w-4 hover:h-5 hover:w-5'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M6 18L18 6M6 6l12 12'
      />
    </svg>
  );
};
