import {
  CreateAccountButton,
  DroovLogo,
  LoginScreenVideo,
  PrivacyImprints,
} from './LoginScreenReusables';
import CustomInputField from './CustomInputField';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { resetPassword } from '../firebase-logic/firebaseAuthFunctions';
import * as ReactRouter from 'react-router-dom';

export const ResetPasswordComponent: React.FC = () => {
  const { t } = useTranslation(['login']);

  const [email, setEmail] = useState('');
  const [disableResetBtn, setDisableResetBtn] = useState(false);
  const history = ReactRouter.useHistory();

  const resetUserPassword = useCallback(
    async (e: any) => {
      e.preventDefault();
      setDisableResetBtn(true);
      if (!email) {
        toast.error('Please provide an email address');
        setDisableResetBtn(false);
        return;
      }
      let toastId = toast.loading('Sending reset password email');
      try {
        await resetPassword(email);
        toast.dismiss(toastId);
        toast.success('Reset password email sent');
        setTimeout(() => {
          toastId = toast.loading('Redirecting to login page');
        }, 1000);
        setTimeout(() => {
          toast.dismiss(toastId);
          history.push('/login');
        }, 2000);
        return;
      } catch (error) {
        const errorMessage = (error as Error).message;
        toast.dismiss(toastId);
        toast.error(errorMessage);
      }
      setTimeout(() => {
        setDisableResetBtn(false);
      }, 1000);
    },
    [email, history]
  );
  return (
    <>
      {/* <!-- Favicon configurations --> */}

      <div className='flex min-h-screen relative font-Ubuntu bg-white login_background'>
        {/*!--login left part--*/}
        <div className='bg-white xl:w-1/3 md:w-full w-full relative'>
          <DroovLogo />
          <CreateAccountButton />

          {/*!--form to login --*/}
          <div className='justify-center flex flex-1 flex-wrap items-center h-screen'>
            <div className='relative w-full sm:px-16'>
              <p
                id='password_reset_label'
                className='font-medium text-2xl text-droov-black text-center mb-6'
              >
                Password Recovery
              </p>
              <p
                id='password_reset_instruction_label'
                className='font-thin text-md text-droov-black text-center mb-6 mx-2'
              >
                You will receive instructions for resetting your password
              </p>

              <form
                className='flex flex-col space-y-4 mt-5 w-full px-8 relative'
                id='reset_form'
              >
                <div className=''>
                  <CustomInputField
                    required
                    id='email'
                    classnameadditional={'mb-5'}
                    type='email'
                    placeholder={t('email.label')}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    value={email}
                  />
                </div>

                {/*<!-- <div id="alert_failure_login" class="w-full text-center p-3 xl:p-6 bg-white text-red-400 mb-4 animate-appear_motion_bottomToUp hidden">Could not login</div> -->*/}
                <div
                  className={`rounded-full self-center overflow-hidden w-40 h-16`}
                >
                  <button
                    disabled={disableResetBtn}
                    onClick={resetUserPassword}
                    id='reset_btn'
                    className={`self-center btn_black font-bold text-sm uppercase w-40 h-16`}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
          <PrivacyImprints />
        </div>
        <LoginScreenVideo />
      </div>
    </>
  );
};
