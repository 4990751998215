import React, { useState } from 'react';
import { Customer } from '../../types';
import { EditBillingInformationDialog } from 'ui-components/billing-components/EditBillingInformationDialog';
import { ReactComponent as WhiteEditIcon } from '../../assets/whiteEditIcon.svg';
import { useTranslation } from 'react-i18next';

type BillingInformationProps = {
  billingInformation: Customer;
  onSave: (updatedBillingInformation: Customer) => void;
};

export const BillingInformation = (props: BillingInformationProps) => {
  const { t } = useTranslation(['onboarding']);
  const [
    openEditBillingInformationDialog,
    setOpenEditBillingInformationDialog,
  ] = useState(false);

  return (
    <div className='bg-white rounded-lg p-4 mt-7 max-w-2xl'>
      <div className='flex flex-row justify-between'>
        <h1>{t('billing_information.label')}</h1>
        <button
          className='text-gray-500 text-sm'
          onClick={() => setOpenEditBillingInformationDialog(true)}
        >
          <div className='flex flex-row gap-1 hover:text-gray-400 mr-2'>
            {t('edit.label')}
            <WhiteEditIcon className='ml-1 flex items-center justify-center mt-0.5 h-5 text-gray fill-current' />
          </div>
        </button>
      </div>
      <p className='droov_text_description_light mt-1'>
        {t('company_billing_information.label')}
      </p>
      <div className='text-sm mt-6 font-medium leading-5 flex flex-col gap-4'>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('company.label')}</div>
          <div className='text-gray-500 font-normal'>
            {props.billingInformation?.name ?? ''}
          </div>
        </div>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('responsible_person.label')}</div>
          <div className='text-gray-500 font-normal'>
            {props.billingInformation?.customer_name ?? ''}
          </div>
        </div>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('billing_email.label')}</div>
          <div className='text-gray-500 font-normal'>
            {props.billingInformation?.email ?? ''}
          </div>
        </div>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('address.label')}</div>
          <div className='text-gray-500 font-normal'>
            {`${props.billingInformation?.address?.line1 ?? ''}, ${
              props.billingInformation?.address?.postal_code ?? ''
            } ${props.billingInformation?.address?.city ?? ''}, ${
              props.billingInformation?.address?.country ?? ''
            }`}
          </div>
        </div>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('phone_number.label')}</div>
          <div className='text-gray-500 font-normal'>
            {props.billingInformation?.phone}
          </div>
        </div>
        <div className='flex flex-col gap-2 sm:gap-0 sm:grid sm:grid-cols-2'>
          <div className='text-gray-900'>{t('vat_number.label')}</div>
          <div className='text-gray-500 font-normal'>
            {props.billingInformation?.vat}
          </div>
        </div>
      </div>
      <EditBillingInformationDialog
        onSave={props.onSave}
        onClose={() => setOpenEditBillingInformationDialog(false)}
        billingInformation={props.billingInformation}
        open={openEditBillingInformationDialog}
      />
    </div>
  );
};
