import React, { useEffect, useState } from 'react';
import { SingleStep } from 'typings/OnboardingModuleType';
import CustomInputField from 'ui-components/CustomInputField';
import { NextButton } from './NextButton';
import { useOnboardingState } from '../../global-states/useOnboardingState';
import { Transition } from '@headlessui/react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../config';
import countryToCurrency from 'country-to-currency';
import toast from 'react-hot-toast';
interface StoreLocationDataObject {
  streetAndHouse: string;
  postcode: string;
  city: string;
  country: string;
  countryCode: string;
  lattitude: number;
  longitude: number;
  language: string;
  utcOffsetMinutes: number;
  currency: string;
}

type Props = {
  handleNextClick?: any;
  currentStep?: SingleStep;
};

const onboardingStateSelector = (state: any) => [
  state.onboarding,
  state.setOnboardingState,
];

export const StoreLocationStep: React.FC<Props> = ({
  handleNextClick,
  currentStep,
}) => {
  const { t } = useTranslation(['onboarding']);

  const [onboarding, setOnboardingState] = useOnboardingState(
    onboardingStateSelector
  );

  const [storeLocationData, setStoreLocationData] =
    useState<StoreLocationDataObject>({
      streetAndHouse: '',
      postcode: '',
      city: '',
      country: '',
      countryCode: '',
      lattitude: 0,
      longitude: 0,
      language: window.navigator.language || 'en-US',
      utcOffsetMinutes: 0,
      currency: '',
    });
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [libraries] = useState<any>(['places']);
  useEffect(() => {
    if (onboarding?.storeLocationStep?.completed) {
      setStoreLocationData({
        ...onboarding.storeLocationStep,
      });
    }
  }, [onboarding.storeLocationStep]);

  const saveCurrentStepData = async () => {
    const valid = await validateAddress();
    if (valid) {
      setStoreLocationData((prevData) => {
        let temp = JSON.parse(JSON.stringify(onboarding));
        temp.storeLocationStep = {
          completed: true,
          streetAndHouse: prevData.streetAndHouse,
          postcode: prevData.postcode,
          city: prevData.city,
          country: prevData.country,
          countryCode: prevData.countryCode,
          lattitude: prevData.lattitude,
          longitude: prevData.longitude,
          language: prevData.language,
          utcOffsetMinutes: prevData.utcOffsetMinutes,
          currency: prevData.currency,
        };
        setOnboardingState(temp);
        handleNextClick(temp);
        return prevData; // Return the updated state
      });
    }
  };

  const validateAddress = async () => {
    let valid = true;
    try {
      if (google.maps) {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
          {
            address: `${storeLocationData.streetAndHouse} ${storeLocationData.postcode} ${storeLocationData.city}`,
          },
          (results: any, status: any) => {
            if (status === google.maps.GeocoderStatus.OK && results !== null) {
              const geocodedAddressComponents = results[0].address_components;
              // street
              const streetAndHouseComponent = geocodedAddressComponents.find(
                (component: { types: string | string[] }) =>
                  component.types.includes('route')
              );
              if (!streetAndHouseComponent) {
                toast.error(t('addressInvalid.label'));
                valid = false;
                return;
              }
              const geocodedStreetAndHouse = streetAndHouseComponent.long_name;
              // house number
              const streetNumberComponent = geocodedAddressComponents.find(
                (component: { types: string | string[] }) =>
                  component.types.includes('street_number')
              );
              if (!streetNumberComponent) {
                toast.error(t('houseNumberMissing.label'));
                valid = false;
                return;
              }
              const geocodedStreetNumber = streetNumberComponent.long_name;

              // postcode
              const geocodedPostcode = geocodedAddressComponents.find(
                (component: { types: string | string[] }) =>
                  component.types.includes('postal_code')
              ).long_name;

              // city
              let geocodedCity = geocodedAddressComponents.find(
                (component: { types: string | string[] }) =>
                  component.types.includes('locality')
              )?.long_name;
              if (!geocodedCity) {
                geocodedCity = geocodedAddressComponents.find(
                  (component: { types: string | string[] }) =>
                    component.types.includes('administrative_area_level_1')
                ).long_name;
              }
              // country and country code
              const countryComponent = geocodedAddressComponents.find(
                (component: { types: string | string[] }) =>
                  component.types.includes('country')
              );
              const geocodedCountry = countryComponent.long_name;
              const geocodedCountryCode = countryComponent.short_name;

              const lattitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();

              setStoreLocationData({
                ...storeLocationData,
                streetAndHouse:
                  geocodedStreetAndHouse + ' ' + geocodedStreetNumber,
                postcode: geocodedPostcode,
                city: geocodedCity,
                country: geocodedCountry,
                countryCode: geocodedCountryCode,
                lattitude: lattitude,
                longitude: longitude,
                // @ts-ignore
                currency: countryToCurrency[geocodedCountryCode],
              });
            } else {
              console.log('Geocoder failed with status', status);
              valid = false;
              return valid;
            }
          }
        );
      }
    } catch (error) {
      console.log('error in saving', error);
      valid = false;
      return valid;
    }
    return valid;
  };

  const onLoad = (data: any) => {
    setAutoComplete(data);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      let street = '';
      let streetNumber = '';
      let postCode = '';
      let city = '';
      let country = '';
      let countryCode = '';
      const placeData = autoComplete.getPlace();
      const addressComponents = placeData.address_components;
      for (const component of addressComponents) {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        } else if (component.types.includes('route')) {
          street = component.long_name;
        } else if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          if (!city) {
            city = component.long_name;
          }
        } else if (component.types.includes('country')) {
          country = component.long_name;
          countryCode = component.short_name;
        } else if (component.types.includes('postal_code')) {
          postCode = component.long_name;
        }
      }

      const lattitude = placeData.geometry.location.lat();
      const longitude = placeData.geometry.location.lng();
      const language = window.navigator.language || 'en-US';

      setStoreLocationData({
        streetAndHouse: `${street} ${streetNumber}`,
        postcode: postCode,
        city: city,
        country: country,
        countryCode: countryCode,
        lattitude: lattitude,
        longitude: longitude,
        language: language,
        utcOffsetMinutes: placeData.utc_offset_minutes,
        // @ts-ignore
        currency: countryToCurrency[countryCode],
      });
    } else {
      console.log('Autocomplete is not loaded yet');
    }
  };

  return (
    <>
      <LoadScript
        libraries={libraries}
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
      >
        <Transition
          appear={true}
          show={true}
          as='div'
          enter='transform transition ease-in-out duration-1000'
          enterFrom='-translate-x-4 opacity-0'
          enterTo='-translate-x-0'
        >
          <div className='mx-10 md:w-3/5 md:mx-auto flex flex-col items-center '>
            <div className='z-10 w-full mb-6'>
              <label className='droov_label_text'>
                {t('streetandhouse.label')}
              </label>

              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <CustomInputField
                  placeholder={t('address_placeholder.label')}
                  value={storeLocationData.streetAndHouse}
                  onChange={(event) =>
                    setStoreLocationData({
                      ...storeLocationData,
                      streetAndHouse: event.target.value,
                    })
                  }
                  type='text'
                  id='streetAndHouse'
                  required={true}
                />
              </Autocomplete>
            </div>

            <div className='z-10 w-full mb-6'>
              <div className='w-full flex'>
                <div className='w-1/3 mr-3'>
                  <label className='droov_label_text'>
                    {t('postcode.label')}
                  </label>
                  <CustomInputField
                    value={storeLocationData.postcode}
                    onChange={(event) =>
                      setStoreLocationData({
                        ...storeLocationData,
                        postcode: event.target.value,
                      })
                    }
                    type='text'
                    id='postcode'
                    required={true}
                  />
                </div>
                <div className='w-2/3'>
                  <label className='droov_label_text'>{t('city.label')}</label>
                  <CustomInputField
                    value={storeLocationData.city}
                    onChange={(event) =>
                      setStoreLocationData({
                        ...storeLocationData,
                        city: event.target.value,
                      })
                    }
                    type='text'
                    id='city'
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className='z-10 w-full mb-10'>
              <label className='droov_label_text'>{t('country.label')}</label>
              <CustomInputField
                value={storeLocationData.country}
                onChange={(event) =>
                  setStoreLocationData({
                    ...storeLocationData,
                    country: event.target.value,
                  })
                }
                type='text'
                id='country'
                required={true}
              />
            </div>

            <div className='z-10 w-full flex flex-col items-center'>
              <NextButton
                disabled={
                  !(
                    storeLocationData.streetAndHouse.length > 0 &&
                    storeLocationData.postcode.length > 0 &&
                    //storeLocationData.countryCode.length > 0 &&
                    //storeLocationData.lattitude !== 0 &&
                    //storeLocationData.longitude !== 0 &&
                    storeLocationData.city.length > 0 &&
                    storeLocationData.country.length > 0
                  )
                }
                buttonText={
                  currentStep?.stepNumber === 2
                    ? t('start.button')
                    : t('next.button')
                }
                goNextHandler={saveCurrentStepData}
              />
            </div>
          </div>
        </Transition>
      </LoadScript>
    </>
  );
};
