import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAQtrHpZlPOel0ZTlgBlSF65x25y-UmHoM',
  authDomain: 'droov-group-1.firebaseapp.com',
  projectId: 'droov-group-1',
  storageBucket: 'droov-group-1.appspot.com',
  messagingSenderId: '24057685515',
  appId: '1:24057685515:web:421d719ecb68d0d68d890e',
  measurementId: 'G-HKQCFMKVWF',
};

let firebaseApp;
// Initialize Firebase if not done jet
if (!firebaseApp) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

const storage = firebaseApp.storage();
const db = firebaseApp.firestore();
const FieldValue = firebase.firestore.FieldValue;
const auth = firebaseApp.auth();
const functions = firebaseApp.functions();
const functionsEuropeWest3 = firebaseApp.functions('europe-west3');

export { storage, db as default, auth, functions, functionsEuropeWest3, FieldValue };
