import db from './firebase';
import { useEffect, useState } from 'react';
import { FirebaseCateringRequest } from '../ui-components/order-components/Catering/types';
import firebase from 'firebase';

const CATERING_REQUEST_LIMIT: number = 10;

export const useAllRequests = (user: any) => {
  const [requests, setRequests] = useState<FirebaseCateringRequest[]>();
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [moreRequestsLoading, setMoreRequestsLoading] = useState(false);
  const [latestRequest, setLatestRequest]: any = useState();
  const [noRequestsLeft, setNoRequestsLeft] = useState(false);

  useEffect(() => {
    fetchRequestsAsync(user.companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let allRequests: FirebaseCateringRequest[] = [];

  const fetchRequestsAsync = async (user: any) => {
    setRequestsLoading(true);
    const docs = await db
      .collection('companies')
      .doc(user)
      .collection('cateringRequests')
      .orderBy('requestStatusTimestamp.received', 'desc')
      .limit(CATERING_REQUEST_LIMIT)
      .get();

    if (docs.size < CATERING_REQUEST_LIMIT) {
      setNoRequestsLeft(true);
    }

    if (docs.empty) {
      setRequestsLoading(false);
      return;
    }

    docs.forEach((data) => {
      const requestObj: FirebaseCateringRequest =
        getCateringRequestFromDataObject(data);
      allRequests.push(requestObj);
    });
    if (allRequests) {
      setRequests(allRequests);
    }
    setLatestRequest(docs.docs[docs.docs.length - 1]);
    setRequestsLoading(false);
  };

  const fetchMoreRequestsAsync = async (user: any) => {
    if (!latestRequest) {
      return;
    }
    if (requests && !noRequestsLeft) {
      setMoreRequestsLoading(true);
      const docs = await db
        .collection('companies')
        .doc(user)
        .collection('cateringRequests')
        .orderBy('requestStatusTimestamp.received', 'desc')
        .startAfter(latestRequest)
        .limit(CATERING_REQUEST_LIMIT)
        .get();
      if (docs.size < CATERING_REQUEST_LIMIT) {
        setNoRequestsLeft(true);
      }
      if (docs.empty) {
        setMoreRequestsLoading(false);
        return;
      }
      docs.forEach((data) => {
        allRequests.push(getCateringRequestFromDataObject(data));
      });
      if (requests) {
        setRequests([...requests, ...allRequests]);
      }
      setLatestRequest(docs.docs[docs.docs.length - 1]);
      setMoreRequestsLoading(false);
    }
  };

  return {
    requests,
    requestsLoading,
    fetchMoreRequestsAsync,
    moreRequestsLoading,
    noRequestsLeft,
  };
};

const getCateringRequestFromDataObject = (
  data: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): FirebaseCateringRequest => {
  return {
    documentID: data.data().documentID,
    requestID: data.data().requestID,
    customer: {
      name: data.data().customer.name,
      email: data.data().customer.email,
      phone: data.data().customer.phone,
    },
    notes: data.data().notes,
    eventAddress: {
      streetAndHouseNo: data.data().eventAddress.streetAndHouseNo,
      city: data.data().eventAddress.city,
      postcode: data.data().eventAddress.postcode,
      country: data.data().eventAddress.country,
      geoLocation: {
        lat: data.data().eventAddress.geoLocation.lat,
        lng: data.data().eventAddress.geoLocation.lng,
      },
    },
    peopleNumber: data.data().peopleNumber,
    eventTimeStamp: data.data().eventTimeStamp,
    moreThanOneDay: data.data().moreThanOneDay,
    requestStatus: data.data().requestStatus,
    requestStatusTimestamp: {
      received: data.data().requestStatusTimestamp.received,
      confirmed: data.data().requestStatusTimestamp.confirmed,
      rejected: data.data().requestStatusTimestamp.rejected,
    },
  };
};

export const fetchRequestDetails = (
  companyId: string,
  requestId: string,
  callback: (snap: any) => void
) => {
  db.collection('companies')
    .doc(companyId)
    .collection('cateringRequests')
    .where('requestID', '==', requestId)
    .onSnapshot(callback);
};

export const updateRequestStatus = async (
  companyId: string,
  docId: string,
  requestStatus: string,
  requestStatusTimestamp: {
    received: number;
    confirmed?: number;
    rejected?: number;
  }
) => {
  await db
    .collection('companies')
    .doc(companyId)
    .collection('cateringRequests')
    .doc(docId)
    .update({
      requestStatus: requestStatus,
      requestStatusTimestamp: requestStatusTimestamp,
    });
};
