import React, { useEffect, useState } from 'react';
import { ReactComponent as ImageUploadIcon } from '../assets/imageUploadIcon.svg';
import { CancelButton } from './CancelButton';

export default function UploadLogo({
  isFavicon,
  imgURL,
  imgFile,
  onImgChanged,
  onDeleted,
  imageDivClassName = null,
  className = null,
}: any) {
  const [imgUrl, setImgUrl]: any = useState(imgURL);
  const item = isFavicon ? 'favicon' : 'logo';

  const transformIntoImg = () => {
    if (!imgFile) {
      return;
    }

    let reader = new FileReader();
    reader.onload = (e) => setImgUrl(e.target?.result);

    reader.readAsDataURL(imgFile);
  };

  useEffect(() => {
    setImgUrl(imgURL);
  }, [imgURL, imgFile]);

  useEffect(() => {
    transformIntoImg();
    // eslint-disable-next-line
  }, [imgFile]);

  return (
    <div className={`flex justify-center items-center px-2 ${className}`}>
      {imgUrl ? (
        <div className='relative'>
          <button
            type='button'
            className='absolute -top-1 -right-1 bg-red-400 rounded-full z-10 text-white focus:outline-none'
            onClick={onDeleted}
          >
            <CancelButton />
          </button>

          <div
            className={`bg-white relative ${imageDivClassName} rounded-2xl p-3 flex justify-center items-center`}
          >
            <img
              className='object-cover max-h-32 w-auto rounded-lg px-3'
              src={imgUrl}
              alt=''
            />
            <label htmlFor={item}>
              <div className='px-2 top-0 left-0 w-full h-full flex justify-center items-center text-white font-Ubuntu font-bold opacity-0 hover:opacity-90 bg-gray-500 duration-500 rounded-lg transform cursor-pointer absolute'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z'
                  />
                </svg>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <label htmlFor={item}>
          <div className='my-2 w-32 h-auto flex flex-col items-center justify-center transform duration-300 customPopup cursor-pointer'>
            <ImageUploadIcon />
            <p className='mt-2 text-xs'>+ Upload {item}</p>
          </div>
        </label>
      )}
      <input
        type='file'
        id={item}
        name='profile_img'
        accept='image/png, image/jpeg'
        onChange={(e: any) => {
          onImgChanged(e.target.files?.[0]);
          e.target.value = null; //needed to be able to upload the same file again
        }}
        className='hidden'
      />
    </div>
  );
}
