import { useEffect } from 'react';


const useWindowScroll = (onScroll: (e?: Event) => void) => {
  useEffect(() => {
    window.addEventListener('scroll', onScroll, { capture: true });

    return () => {
      window.removeEventListener('scroll', onScroll, { capture: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useWindowScroll;