import React from "react";

export interface SettingsObject {
  generalInfo: string; //"Info to display about the company"
  cardType: string; // mention the type of the card. eg. if it is a location card or a company card
  cardIcon: any; //TODO find the appropriate type for an SVG Icon
  followUpLink: string; // the follow up link for a card
}

export interface SettingsProp {
  settingsObject: SettingsObject;
  onClicked: (arg0: string) => void;
}

function CustomSettingsCard({ settingsObject, onClicked }: SettingsProp) {
  return (
    <>
      <div
        onClick={() => onClicked(settingsObject.followUpLink)}
        className="cursor-pointer bg-white w-47 h-36 rounded-xl text-black   flex-col justify-center customPopup"
      >
        <div className="w-15 h-15 pt-5 pb-2">
          <div className="flex justify-center">{settingsObject.cardIcon}</div>
        </div>

        <h3 className=" text-sm  font-medium text-center ">
          {settingsObject.cardType}
        </h3>
        <p className=" mx-2 pt-3 text-xs  text-center text-gray-500 font-light">
          {settingsObject.generalInfo}
        </p>
      </div>
    </>
  );
}

export default CustomSettingsCard;
