import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import AddonCard from './AddonCard';

export default function AddonGroupCard({
  state,
  setAddonToEdit,
  setOpenAddAddonsModal,
}: any) {
  const { t } = useTranslation(['catalogue']);

  return (
    <>
      <Droppable droppableId='configureAddons' type='configurator'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {state?.addons?.map((addon: any, index: number) => {
              return (
                <div key={index}>
                  <AddonCard
                    cardElement={Object.values(addon)[0]}
                    index={index}
                    addonId={Object.keys(addon)[0]}
                    setAddonToEdit={setAddonToEdit}
                    setOpenAddAddonsModal={setOpenAddAddonsModal}
                  />
                </div>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div className='w-auto flex justify-center my-4'>
        <button
          type='button'
          className='relative_add_button w-56 h-10'
          onClick={() => {
            setOpenAddAddonsModal(true);
          }}
        >
          + {t('createaddons.button')}
        </button>
      </div>
    </>
  );
}
