import { ViewType } from 'typings/dashboardViewType';

export const returnRange = (viewType: ViewType) => {
  if (viewType === ViewType.LASTTHREEMONTH) {
    return 3;
  } else if (viewType === ViewType.LASTSIXMONTH) {
    return 6;
  } else {
    return 1;
  }
};
