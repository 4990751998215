const AllLoadedTableRow = ({ text = '' }: { text?: string }) => {
  return (
    <tr
      className='flex bg-white justify-center text-sm font-bold text-blue-900 uppercase tracking-wider rounded-b-xl cursor-default focus:ring-0 w-full'>
      <td colSpan={10} className='p-3 focus:ring-0'>
        {text}
      </td>
    </tr>
  )
}

export default AllLoadedTableRow;