import React, { useRef, VFC } from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

type StripeDialogProps = {
  onClose: () => void;
  dialogIsOpen: boolean;
  isLoading: boolean;
  hasAccount: boolean;
  hasCompletedOnboarding: boolean;
  onCreateAccount: () => void;
  onCreateAccountLink: () => void;
};

export const StripeDialog: VFC<StripeDialogProps> = (props) => {
  const { t } = useTranslation(['settings']);

  const {
    onClose,
    dialogIsOpen,
    isLoading,
    hasAccount,
    hasCompletedOnboarding,
    onCreateAccount,
    onCreateAccountLink,
  } = props;

  const cancelButtonRef: any = useRef();
  return ReactDOM.createPortal(
    <Transition.Root show={dialogIsOpen} as={'div'}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={dialogIsOpen}
        onClose={onClose}
      >
        <div className=''>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className=' fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='absolute w-full md:w-auto top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-6 shadow-lg rounded-lg flex flex-col gap-2.5'
              style={{ transform: 'translate(-50%, -50%)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className='text-lg font-bold leading-4'>
                {t('connectstripe.modal.label')}
              </h1>
              <div className='flex flex-col-reverse md:flex-row items-center border-2 border-droov_text_description_light rounded-md px-6 py-3.5'>
                <p className='text-sm droov_text_description_light w-80'>
                  {t('connectstripe.modal.description')}
                </p>
                <svg
                  width='76px'
                  height='32px'
                  viewBox='0 0 76px 32px'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mx-4 ml-0 md:ml-10 md:mb-auto '
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M76 16.534C76 11.0654 73.3822 6.75033 68.3789 6.75033C63.3544 6.75033 60.3144 11.0654 60.3144 16.4913C60.3144 22.9212 63.9033 26.1682 69.0544 26.1682C71.5667 26.1682 73.4667 25.5915 74.9022 24.7797V20.5073C73.4667 21.2336 71.82 21.6822 69.73 21.6822C67.6822 21.6822 65.8667 20.9559 65.6344 18.4352H75.9578C75.9578 18.1575 76 17.0467 76 16.534ZM65.5711 14.5047C65.5711 12.0908 67.0278 11.0868 68.3578 11.0868C69.6456 11.0868 71.0178 12.0908 71.0178 14.5047H65.5711ZM52.1656 6.75033C50.0967 6.75033 48.7667 7.73298 48.0278 8.41656L47.7533 7.09212H43.1089V32L48.3867 30.8678L48.4078 24.8224C49.1678 25.3778 50.2867 26.1682 52.1444 26.1682C55.9233 26.1682 59.3644 23.0921 59.3644 16.3204C59.3433 10.1255 55.86 6.75033 52.1656 6.75033ZM50.8989 21.4686C49.6533 21.4686 48.9144 21.02 48.4078 20.4646L48.3867 12.5394C48.9356 11.9199 49.6956 11.4927 50.8989 11.4927C52.82 11.4927 54.15 13.6716 54.15 16.47C54.15 19.3324 52.8411 21.4686 50.8989 21.4686ZM35.8467 5.48999L41.1456 4.33645V0L35.8467 1.13218V5.48999ZM35.8467 7.11349H41.1456V25.8051H35.8467V7.11349ZM30.1678 8.69426L29.83 7.11349H25.27V25.8051H30.5478V13.1375C31.7933 11.4927 33.9044 11.7917 34.5589 12.0267V7.11349C33.8833 6.85714 31.4133 6.38718 30.1678 8.69426ZM19.6122 2.47797L14.4611 3.58878L14.44 20.6996C14.44 23.8611 16.7833 26.1896 19.9078 26.1896C21.6389 26.1896 22.9056 25.8692 23.6022 25.4846V21.1482C22.9267 21.4259 19.5911 22.4085 19.5911 19.247V11.6636H23.6022V7.11349H19.5911L19.6122 2.47797ZM5.34111 12.5394C5.34111 11.7063 6.01667 11.3858 7.13556 11.3858C8.74 11.3858 10.7667 11.8772 12.3711 12.753V7.73298C10.6189 7.02804 8.88778 6.75033 7.13556 6.75033C2.85 6.75033 0 9.01469 0 12.7957C0 18.6916 8.02222 17.7517 8.02222 20.2937C8.02222 21.2764 7.17778 21.5968 5.99556 21.5968C4.24333 21.5968 2.00556 20.8705 0.232222 19.8879V24.972C2.19556 25.8264 4.18 26.1896 5.99556 26.1896C10.3867 26.1896 13.4056 23.9893 13.4056 20.1656C13.3844 13.7997 5.34111 14.9319 5.34111 12.5394Z'
                    fill='#C3C7CB'
                  />
                </svg>
              </div>
              <h2 className='font-medium leading-4 text-sm'>
                {t('connectstriple.modal.hint')}
              </h2>
              <p
                className={`text-sm font-medium leading-4 ${
                  hasAccount ? 'text-green-400' : 'droov_text_description_light'
                }`}
              >
                {!hasAccount ? (
                  <>&#8226; {t('account.not.created.yet')}</>
                ) : (
                  <>&#10003; {t('account.created')}</>
                )}
              </p>
              <p
                className={`text-sm font-medium leading-4 ${
                  hasCompletedOnboarding
                    ? 'text-green-400'
                    : 'droov_text_description_light'
                }`}
              >
                {!hasCompletedOnboarding ? (
                  <>&#8226; {t('onboarding.incompleted')}</>
                ) : (
                  <>&#10003; {t('onboarding.completed')}</>
                )}
              </p>
              <div className='flex justify-end items-center gap-4'>
                <button
                  className='cancel_button'
                  ref={cancelButtonRef}
                  onClick={() => onClose()}
                >
                  {t('cancel')}
                </button>
                {!hasCompletedOnboarding && (
                  <button
                    className='px-6 py-1 text-white border-2 border-droov font-medium rounded-full rounded-button text-sm bg-droov'
                    onClick={() => {
                      if (!hasAccount) {
                        onCreateAccount();
                      }
                      if (hasAccount && !hasCompletedOnboarding) {
                        onCreateAccountLink();
                      }
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className='flex gap-2 items-center'>
                        <div className='inline-block loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4'></div>
                        <span>{t('processing')}</span>
                      </div>
                    ) : hasAccount ? (
                      t('complete.onboarding')
                    ) : (
                      t('start.onboarding')
                    )}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body
  );
};
