import moment from 'moment';

export default function lastNEntities(
  start: any,
  end: moment.Moment,
  entityType: 'day' | 'month',
  ui: boolean = false,
): string[] {
  const entities = [];
  let currentDate = moment(start);
  const endDate = moment(end);
  while (currentDate > endDate) {
    if (ui) {
      entities.push(currentDate.format(entityType === 'day' ? 'DD MMM' : 'MMM'));
    } else {
      entities.push(
        currentDate.format(entityType === 'day' ? 'DD-MM-YYYY' : 'MMM YYYY')
      );
    }
    currentDate = currentDate.clone().subtract(1, entityType);
  }
  return entities.reverse();
}
