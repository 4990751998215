import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import LogoAmex from 'assets/logo-amex.png';
import LogoApplePay from 'assets/logo-applepay.png';
import LogoGPay from 'assets/logo-gpay.png';
import LogoMastercard from 'assets/logo-mastercard.png';
import LogoVisa from 'assets/logo-visa.png';
import useAuthState from '../global-states/useAuthState';
import db from '../firebase-logic/firebase';
import toast from 'react-hot-toast';
import { StripeDialog } from './StripeDialog';
import { StripeAPI } from 'api/stripe';
import { useTranslation } from 'react-i18next';

export const CardPaymentSetting: React.FC = () => {
  const [active, setActive] = useState(false);
  const [stripeAccountInformation, setStripeAccountInformation] = useState();
  const auth: any = useAuthState.getState();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['settings']);

  const getPaymentSettingsAsync = async () => {
    setIsLoading(true);
    try {
      const docFromPath = await db.collection('companies').doc(auth.user).get();
      const doc = docFromPath.data();
      if (doc != null) {
        setActive(doc.paymentSettings.cardPayment);

        if (doc.paymentSettings.stripeAccount) {
          const accountInformationRequest =
            await StripeAPI.getAccountInformation(
              auth.user,
              doc.paymentSettings.stripeAccount
            );
          console.log('What it returns: ', accountInformationRequest);
          if (accountInformationRequest?.ok) {
            const accountInformationResponse =
              await accountInformationRequest?.json();
            setStripeAccountInformation(accountInformationResponse.data);
            console.log(accountInformationResponse);

            /*const getDomainList = await StripeAPI.getDomainList();
            console.log('GET APPLE DOMAIN LIST: ');
            console.log(getDomainList);*/
          } else {
            toast.error(t('stripeconnect.error'));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  {
    /*// TODO: comment out. This is for testing purposes only //
  const addAppleDomain = async () => {
    setIsLoading(true);
    const addedDomain = await StripeAPI.addAppleDomain();
    if (addedDomain?.ok) {
      console.log('Domain added');
    } else setIsLoading(false);
  };
  */
  }

  useEffect(() => {
    getPaymentSettingsAsync();
  }, []);

  return (
    <div className='w-full bg-white p-4 rounded-md max-w-3xl mx-auto bg-red mt-8 cursor-pointer'>
      <StripeDialog
        dialogIsOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        isLoading={isLoading}
        hasAccount={!!stripeAccountInformation}
        hasCompletedOnboarding={
          stripeAccountInformation != null &&
          stripeAccountInformation!['details_submitted'] &&
          stripeAccountInformation!['charges_enabled'] &&
          stripeAccountInformation!['payouts_enabled']
        }
        onCreateAccount={async () => {
          setIsLoading(true);
          const connectedAccount = await StripeAPI.createConnectedAccount(
            auth.user
          );
          //auth.user

          if (connectedAccount?.ok) {
            connectedAccount.json().then((data) => {
              setStripeAccountInformation(data);
            });
            toast.success(t('stripeconnect.success'));
          } else {
            toast.error(t('stripeconnect.creation_error'));
          }
          /*const accountLink =
            await StripeAPI.createAccountLink(/!*auth.user*!/);
          StripeAPI.verifyDomain(/!*auth.user*!/);
          if (accountLink?.ok) {
            toast.success("You'll be redirected to the onboarding soon.");
            accountLink.json().then((data) => {
              window.location.href = data.url;
            });
          } else {
            toast.error(
              "Sorry, we're having trouble redirecting you to the onboarding. Please try again."
            );
          }*/
          setIsLoading(false);
        }}
        onCreateAccountLink={async () => {
          setIsLoading(true);
          const accountLink = await StripeAPI.createAccountLink(auth.user);
          StripeAPI.verifyDomain();
          if (accountLink?.ok) {
            toast.success(t('redirection_to_onboarding'));
            accountLink.json().then((data) => {
              window.location.href = data.url;
            });
          } else {
            toast.error(t('redirection_to_onboarding.error'));
          }
          setIsLoading(false);
        }}
      />
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>
          {t('acceptcardpayments.labels')}
        </h2>
        {isLoading ? (
          <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6'></div>
        ) : (
          <Switch
            checked={active}
            onChange={() => {
              if (
                stripeAccountInformation &&
                stripeAccountInformation['details_submitted'] &&
                stripeAccountInformation['charges_enabled'] &&
                stripeAccountInformation['payouts_enabled']
              ) {
                db.collection('companies')
                  .doc(auth.user)
                  .update({ 'paymentSettings.cardPayment': !active });
                setActive(!active);
                toast.success(t('stripeactivation.success'));
              } else {
                toast.error(t('stripeactivation.failure'));
              }
            }}
            className={`${
              active ? 'bg-droov' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11`}
          >
            <span
              className={`${
                active ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full`}
            />
          </Switch>
        )}
      </div>
      <p className='droov_text_description_light mr-11'>
        {t('acceptcardpayments.text')}
      </p>
      <div className='flex flex-col md:flex-row justify-start gap-8 items-center my-4'>
        <img src={LogoMastercard} alt='Mastercard' className='h-7' />
        <img src={LogoVisa} alt='Visa' className='h-7' />
        <img src={LogoAmex} alt='American Express' className='h-7' />
        <img src={LogoApplePay} alt='Apple Pay' className='h-10' />
        <img src={LogoGPay} alt='Google Pay' className='h-11' />
      </div>
      {!isLoading && (
        <div className='flex justify-between items-center'>
          <label
            className={`italic text-base ${
              stripeAccountInformation &&
              stripeAccountInformation['details_submitted'] &&
              stripeAccountInformation['charges_enabled'] &&
              stripeAccountInformation['payouts_enabled']
                ? 'text-green-400'
                : 'text-red-400'
            }`}
          >
            &#8226;{' '}
            {stripeAccountInformation &&
            stripeAccountInformation['details_submitted'] &&
            stripeAccountInformation['charges_enabled'] &&
            stripeAccountInformation['payouts_enabled']
              ? t('connected.label')
              : t('notconnected.label')}
          </label>
          <button
            onClick={() => setDialogIsOpen(true)}
            className='relative_add_button h-10'
          >
            {t('connectstripe.button')}
          </button>

          {/*
          <hr />
          // TODO: comment out. This is for testing purposes only //
          <button
            onClick={() => addAppleDomain()}
            className='relative_add_button h-10'
          >
            ADD DOMAIN
          </button>
          */}
        </div>
      )}
    </div>
  );
};
