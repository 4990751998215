import UbuntuFontImg from '../assets/standard-font-img.png';
import RalewayFontImg from '../assets/raleway-font-img.png';
import BioRhymeFontImg from '../assets/biorhyme-font-img.png';
import OswaldFontImg from '../assets/oswald-font-img.png';
import GlutenFontImg from '../assets/gluten-font-img.png';
import KalamFontImg from '../assets/kalam-font-img.png';
import CormorantUprightFontImg from '../assets/cormorant-font-img.png';
import GaeguFontImg from '../assets/gaegu-font-img.png';
import SatisfyFontImg from '../assets/satisfy-font-img.png';

export default function ChooseFont({ onFontSelected, selectedFont }: any) {
  return (
    <div className='w-full pt-4 pb-1 grid grid-cols-2 md:grid-cols-3 gap-4'>
      {fonts.map((font) => (
        <div
          onClick={() => {
            onFontSelected(font.name);
          }}
          key={font.name}
          className={`flex items-center justify-between transition duration-200 rounded-lg shadow col-span-1 pl-2 pr-2 py-1.5 cursor-pointer hover:shadow-md ${
            font.name === selectedFont
              ? 'border border-droov shadow-none'
              : 'border border-white'
          }`}
        >
          <div className='flex items-center'>
            <img className='h-12' src={font.img} alt='font image' />
          </div>

          {font.name === selectedFont ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 text-droov'
              viewBox='0 0 20 20'
              fill='currentColor'
              stroke='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                clipRule='evenodd'
              />
            </svg>
          ) : (
            <div> </div>
          )}
        </div>
      ))}
    </div>
  );
}

const fonts = [
  {
    name: 'Ubuntu',
    img: UbuntuFontImg,
  },
  {
    name: 'Raleway',
    img: RalewayFontImg,
  },
  {
    name: 'BioRhyme',
    img: BioRhymeFontImg,
  },
  {
    name: 'Oswald',
    img: OswaldFontImg,
  },
  {
    name: 'Gluten',
    img: GlutenFontImg,
  },
  {
    name: 'Kalam',
    img: KalamFontImg,
  },
  {
    name: 'Cormorant Upright',
    img: CormorantUprightFontImg,
  },
  {
    name: 'Gaegu',
    img: GaeguFontImg,
  },
  {
    name: 'Satisfy',
    img: SatisfyFontImg,
  },
];
