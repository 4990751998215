import { ReactComponent as ChangeImageSVG } from '../../../assets/changeImage.svg';
import { useTranslation } from 'react-i18next';

export default function EditIcon() {
  const { t } = useTranslation(['catalogue']);
  return (
    <div className='flex flex-col cursor-pointer text-white duration duration-300 justify-center items-center'>
      <ChangeImageSVG className='flex cursor-pointer fill-current text-white duration duration-300' />
      <p>{t('changeimage.label')}</p>
    </div>
  );
}
