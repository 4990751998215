import DetailsHeader from './DetailsHeader';
import PhoneIcon from '@heroicons/react/solid/PhoneIcon';

const InfoRectangle = ({
  statistic,
  value,
}: {
  statistic: string;
  value: string;
}) => {
  return (
    <div
      className={
        'w-full p-4 md:max-w-~1/3 border border-solid border-gray_light rounded-lg text-black font-medium'
      }
    >
      <p className={'text-center text-sm'}>{statistic}</p>
      <p className={'text-center text-2xl py-8'}>{value}</p>
    </div>
  );
};

type CustomerInfoCardProps = {
  isAuth: boolean;
  joinedAt: number;
  phoneNumber: string;
  statistics: {
    values: (number | undefined)[];
    labels: readonly string[];
    translations: readonly ((customer: number | undefined) => string)[];
  };
};

const CustomerInfoCard = ({
  isAuth,
  joinedAt,
  phoneNumber,
  statistics,
}: CustomerInfoCardProps) => {
  return (
    <div className='bg-white rounded-xl w-full p-4'>
      <DetailsHeader isAuth={isAuth} joinedAt={joinedAt} />
      <div className={'flex gap-x-4'}>
        <PhoneIcon className='w-5' />
        <span className={'text-sm'}>{phoneNumber}</span>
      </div>
      <div
        className={'flex justify-between mt-4 gap-4 flex-wrap md:flex-nowrap'}
      >
        {statistics.values.map((customer, idx) => (
          <InfoRectangle
            key={idx}
            statistic={statistics.labels[idx]}
            value={statistics.translations[idx](customer)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomerInfoCard;
