import React, { useEffect, useState } from 'react';
import { ReactComponent as ImageUploadIcon } from '../assets/imageUploadIcon.svg';
import { CancelButton } from './CancelButton';

export default function ImageUpload({
  imgURL,
  imgFile,
  onImgChanged,
  onDeleted,
  item,
  width,
  height,
  isImgDeleted,
  onAlreadySelected,
}: any) {
  const [imgUrl, setImgUrl]: any = useState(imgURL);

  const transformIntoImg = () => {
    if (!imgFile) {
      return;
    }

    let reader = new FileReader();
    reader.onload = (e) => setImgUrl(e.target?.result);

    reader.readAsDataURL(imgFile);
  };

  useEffect(() => {
    setImgUrl(imgURL);
  }, [imgURL, imgFile]);

  useEffect(() => {
    transformIntoImg();
    // eslint-disable-next-line
  }, [imgFile]);

  const labelItem = (
    <label htmlFor={item} onClick={onAlreadySelected}>
      <input
        type='file'
        id={item}
        name={`${item} image`}
        accept='image/png, image/jpeg'
        disabled={imgUrl && !isImgDeleted ? true : false}
        onChange={(e: any) => {
          onImgChanged(e.target.files?.[0]);
          e.target.value = null; //needed to be able to upload the same file again
        }}
        className='hidden'
      />
      {imgUrl && !isImgDeleted ? (
        <div className={`w-${Math.floor((width*3)/5)} h-${height} flex justify-center`}>
          <button
            type='button'
            className='absolute -top-2 -right-2 bg-red-400 rounded-full z-10 text-white focus:outline-none'
            onClick={(e: any) => onDeleted(e)}
          >
            <CancelButton />
          </button>
        </div>
      ) : (
        <div className={`w-${Math.floor((width*3)/5)} flex flex-col items-center justify-center transform duration-300 hover:scale-125 cursor-pointer`}>
          <ImageUploadIcon />
        </div>
      )}
    </label>
  );

  return (
    <div
        className={`flex items-center justify-center w-full relative h-full rounded-lg`}
        style={{
          backgroundImage: imgUrl && !isImgDeleted ? `url(${imgUrl})` : '',
          backgroundSize: 'cover',
        }}
      >
        {labelItem}
      </div>
  );
}
