import React, { ReactNode, Ref, useMemo, useRef } from 'react';
import useRefOnScreen from '../hooks/useRefOnScreen';
import AllLoadedTableRow from './AllLoaded';
import SkeletonLoaderLargeTable from './SkeletonLoaderLargeTable';

export default function AppTable<T>({
  row,
  refRow,
  head,
  onLoadMore,
  loading,
  data,
  allLoaded,
  allLoadedText,
}: {
  onLoadMore: () => void;
  row: (customer: T,  loading: boolean, idx: number) => void;
  refRow: (customer: T, ref: Ref<HTMLTableRowElement>, idx: number) => void;
  head: ReactNode;
  data: T[];
  allLoaded?: boolean;
  loading: boolean;
  allLoadedText?: string;
}) {
  const screenRef = useRefOnScreen<HTMLTableRowElement>(() => {
    onLoadMore();
  });
  const showSkeleton = loading && !allLoaded;

  const memoizedRows = useMemo(
    () =>
      data.map((dataItem, idx) => {
        if (idx === data.length - 1) {
          return refRow(dataItem, screenRef, idx);
        }
        return row(dataItem, showSkeleton, idx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [data, row]
  );
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const onceScroll = data.length > 0;

  if (loading && !onceScroll) {
    return <SkeletonLoaderLargeTable />;
  }

  return (
    <>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8 w-full'>
        <div className="py-2 align-middle inline-block min-w-full lg:px-0">
          <div>
            {head}
            <table className={'text-left w-full divide-y divide-gray-200'}>
              <tbody
                className={'bg-grey-light flex flex-col items-center w-full divide-y divide-gray-200 overflow-scroll'}
                ref={tableRef}
              >
              {memoizedRows}
              {showSkeleton && (
                <tr>
                  <td>
                    <div className="loader ease-linear rounded-full border-4 h-6 w-6 my-4" />
                  </td>
                </tr>
              )}
              {allLoaded && !showSkeleton && <AllLoadedTableRow text={allLoadedText} />}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
