import CustomInputField from '../CustomInputField';
import React from 'react';

export default function StoreUrlInputField({
  onInputFieldValueChanged,
  value,
  disabled,
  name,
  id,
  onBlur,
}: any) {
  return (
    <div className='flex rounded-md shadow-sm'>
      <div className='z-20 select-none pl-2.5 pr-2 mt-1 inline-flex shadow-sm items-center min-w-fit rounded-l-md border border-r-1 border-gray-300 bg-gray-100'>
        <span className='text-xs md:text-sm text-gray-400'>https://</span>
      </div>
      <CustomInputField
        required={true}
        value={value}
        onChange={(e: any) => onInputFieldValueChanged(e)}
        disabled={disabled === undefined ? false : disabled}
        type='text'
        name={name}
        id={id}
        classnameadditional='z-1 pl-4 pr-2 -ml-1.5 block w-full border-gray-200 shadow-sm'
        placeholder='my-restaurant'
        onBlur={onBlur}
      />
      <div className='z-10 select-none px-2 mt-1 -ml-1.5 inline-flex items-center shadow-sm min-w-fit rounded-r-md border border-l-1 border-gray-300 bg-gray-100'>
        <span className='text-xs md:text-sm text-gray-400'>.droov.store</span>
      </div>
    </div>
  );
}
