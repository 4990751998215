import { useHistory } from 'react-router-dom';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import TippingSetting from '../ui-components/settings/TippingSetting';
import Summary from '../ui-components/layout/Summary';
import { FormEvent, useEffect, useState } from 'react';
import {
  getStoreSettings,
  createOrUpdateStoreSettings,
  IcompanyStoreSettings,
} from '../firebase-logic/settings';
import { useTranslation } from 'react-i18next';
import useAuthState from '../global-states/useAuthState';
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';
import toast from 'react-hot-toast';
import TimeslotLimit from 'ui-components/settings/TimeslotLimit';
import CateringSetting from 'ui-components/settings/CateringSetting';
import BasketLimitSetting from 'ui-components/settings/BasketLimitSetting';

export default function StoreSettingsScreen() {
  const auth: any = useAuthState.getState();
  const history = useHistory();
  const { t } = useTranslation(['settings']);

  const cateringUrl = auth.urlStore + '/catering';

  const [loading, setLoading] = useState<boolean>(false);

  const [storeSettingsObject, setStoreSettingsObject] =
    useState<IcompanyStoreSettings>({
      groupOrder: false,
      groupOrderDiscount: 0,
      groupOrderMinValue: 0,
      defaultTipping: false,
      defaultTip: 0,
      basketLimit: {
        enabled: true,
        basketLimit: 0,
      },
      catering: { enabled: true },
      customerNotifications: false,
      timeslotLimit: {
        avgItemPrice: 0.0,
        enabled: false,
        maxItems: 0,
      },
    });

  const storeSettingsSummaryData = [
    {
      parameter: t('tipping.label'),
      data: storeSettingsObject?.defaultTipping
        ? t('on.label') + ' (' + storeSettingsObject.defaultTip + '%)'
        : t('off.label'),
    },
    {
      parameter: t('catering.label'),
      data: storeSettingsObject?.catering?.enabled
        ? t('on.label')
        : t('off.label'),
    },
    {
      parameter: t('basket_limit.label'),
      data: storeSettingsObject?.basketLimit?.enabled
        ? t('on.label')
        : t('off.label'),
    },
    {
      parameter: t('timeslot_limit.label'),
      data: storeSettingsObject?.timeslotLimit?.enabled
        ? t('on.label')
        : t('off.label'),
    },
  ];

  const onSavePressed = (e: FormEvent) => {
    e.preventDefault();

    toast
      .promise(createOrUpdateStoreSettings(auth.user, storeSettingsObject), {
        loading: t('loading.label'),
        success: t('success_store_settings.label'),
        error: t('error_saving_store_settings.label'),
      })
      .then(() => {
        history.push('/settings');
      });
  };

  useEffect(() => {
    setLoading(true);
    getStoreSettings(auth.user)
      .then((storeSettings) => {
        if (storeSettings) setStoreSettingsObject(storeSettings);
      })
      .catch((error) => {
        console.log(error);
        toast.error('An error occurred when loading store settings');
      })
      .finally(() => setLoading(false));
  }, [auth.user]);

  return (
    <>
      <div>
        <CustomBreadCrumb
          onClickAction={() => history.push('/settings')}
          displayString={t('settings.label')}
        />
        <h1 className='font-medium text-2xl pt-1'>
          {t('storesettings.label')}
        </h1>
        {loading ? (
          <CustomLoadingScreen />
        ) : (
          <form onSubmit={onSavePressed}>
            <div className='lg:flex w-full mb-6 pt-10'>
              <div className='flex-1 md:pr-4'>
                <TippingSetting
                  active={storeSettingsObject.defaultTipping || false}
                  setActive={(active) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      defaultTipping: active,
                    })
                  }
                  defaultTip={storeSettingsObject?.defaultTip}
                  setDefaultTip={(defaultTip) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      defaultTip: defaultTip,
                    })
                  }
                />

                <CateringSetting
                  enabled={storeSettingsObject?.catering?.enabled || false}
                  cateringUrl={cateringUrl}
                  setActive={(active) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      catering: { enabled: active },
                    })
                  }
                />
                <BasketLimitSetting
                  active={storeSettingsObject?.basketLimit?.enabled || false}
                  basketLimit={
                    storeSettingsObject?.basketLimit?.basketLimit || 0
                  }
                  setLimit={(active, limit) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      basketLimit: {
                        enabled: active,
                        basketLimit: limit,
                      },
                    })
                  }
                />
                <TimeslotLimit
                  avgItemPrice={
                    storeSettingsObject?.timeslotLimit?.avgItemPrice || 0
                  }
                  setAvgItemPrice={(avgItemPrice) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      timeslotLimit: {
                        ...storeSettingsObject.timeslotLimit,
                        avgItemPrice: avgItemPrice,
                      },
                    })
                  }
                  maxItems={storeSettingsObject?.timeslotLimit?.maxItems || 0}
                  setMaxItems={(maxItems) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      timeslotLimit: {
                        ...storeSettingsObject.timeslotLimit,
                        maxItems: maxItems,
                      },
                    })
                  }
                  active={storeSettingsObject?.timeslotLimit?.enabled || false}
                  setActive={(active) =>
                    setStoreSettingsObject({
                      ...storeSettingsObject,
                      timeslotLimit: {
                        ...storeSettingsObject.timeslotLimit,
                        enabled: active,
                      },
                    })
                  }
                />
              </div>
              <Summary summaryObject={storeSettingsSummaryData} />
            </div>
          </form>
        )}
      </div>
    </>
  );
}
