import { useEffect, useState, VFC } from 'react';
import { Switch } from '@headlessui/react';
import db from '../firebase-logic/firebase';
import CashIcon from 'assets/icon-cash.svg';
import toast from 'react-hot-toast';
import useAuthState from '../global-states/useAuthState';
import { useTranslation } from 'react-i18next';

export const CashPaymentSetting: VFC = () => {
  const [active, setActive] = useState(false);
  const auth: any = useAuthState.getState();

  const { t } = useTranslation(['settings']);

  const getPaymentSettingsAsync = async () => {
    try {
      const docFromPath = await db.collection('companies').doc(auth.user).get();
      const doc = docFromPath.data();

      if (doc != null) {
        setActive(doc.paymentSettings.cashPayment);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentSettingsAsync();
  }, []);

  return (
    <div className='w-full bg-white p-4 rounded-md max-w-3xl mt-8 mx-auto cursor-pointer'>
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>{t('acceptcashpayment.label')}</h2>
        <Switch
          checked={active}
          onChange={() => {
            db.collection('companies')
              .doc(auth.user)
              .update({ 'paymentSettings.cashPayment': !active });
            setActive(!active);
            toast.success(t('cashSuccess.label'));
          }}
          className={`${
            active ? 'bg-droov' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11`}
        >
          <span
            className={`${
              active ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full`}
          />
        </Switch>
      </div>
      <p className='droov_text_description_light'>
        {t('acceptcashpayment.text')}
      </p>
      <div className='flex justify-start items-center mt-4'>
        <img src={CashIcon} alt='Cash' className='h-7' />
      </div>
    </div>
  );
};
