import React from 'react';
import useAuthState from '../../global-states/useAuthState';
import { formatPrice } from '../../utils/helper';

interface BasketItem {
  title: string;
  imageURL: string;
  size: string;
  extras?: any;
  price: number;
  quantity: number;
}

interface BasketSummaryProps {
  basket: BasketItem[];
}

const BasketSummary: React.FC<BasketSummaryProps> = ({ basket }) => {
  return (
    <div className='mt-9'>
      <div className='w-full md:pr-5'>
        {basket.map((item: BasketItem, index: number) => (
          <BasketItemSummary item={item} />
        ))}
      </div>
      <br />
    </div>
  );
};

interface BasketItemSummaryProps {
  item: BasketItem;
}

const BasketItemSummary: React.FC<BasketItemSummaryProps> = ({ item }) => {
  const { title, price, imageURL, quantity, extras, size } = item;
  const auth: any = useAuthState.getState();

  function getSelectedAddOnDetails() {
    let groupedExtras = [];
    if (extras) {
      let tempTitle = '';
      for (let i = 0; i < extras.length; i++) {
        if (tempTitle === extras[i].subCatTitle) {
          let foundSubCat = groupedExtras.find(
            (item) => item.subCatTitle === extras[i].subCatTitle
          );
          if (extras[i].extraAddOns?.length > 0 && foundSubCat) {
            foundSubCat.extraAddOns.push(extras[i].extraAddOns);
          }
        } else {
          if (extras[i].extraAddOns?.length > 0) {
            tempTitle = extras[i].subCatTitle;
            groupedExtras.push({
              subCatTitle: tempTitle,
              extraAddOns: extras[i].extraAddOns,
            });
          }
        }
      }
    }
    return (
      <div>
        {size !== undefined && (
          <div className='text-gray-400'>
            <span> Size:</span> {size}
            {';'}
          </div>
        )}
        {groupedExtras.length > 0 && (
          <div className='mr-14'>
            {groupedExtras.map((extra) => {
              return (
                <span>
                  <span className='text-gray-400'>{`${extra.subCatTitle}:`}</span>
                  {extra.extraAddOns.map(
                    (addon: { qty: any; addon: any }, idx: number) => {
                      return `${addon.qty}x ${addon.addon}${
                        idx !== extra.extraAddOns.length - 1 ? ',' : ';'
                      } `;
                    }
                  )}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className='flex justify-between mx-auto items-center pb-4 relative'>
        <div className='flex space-x-3 transform transition-all duration-150 ease-out mr-12'>
          <img
            className={`object-cover rounded-lg h-16 w-auto`}
            src={imageURL}
            alt=''
          />
          <div>
            <div className='flex justify-start items-center mt-2'>
              <p className='tick text-sm font-medium leading-4 font-Ubuntu'>
                x
              </p>
              <p className='mr-2 tick text-sm font-medium leading-4 font-Ubuntu'>
                {quantity}
              </p>
              <p className='lg:ml-1 ml-1 text-gray-800 text-sm font-medium leading-4 font-Ubuntu'>
                {title}
              </p>
            </div>
            <div className='flex'>
              <p className='mb-4 mt-2 ml-7 text-gray-400 text-xs font-normal leading-4 font-Ubuntu'>
                {getSelectedAddOnDetails()}
              </p>
            </div>
          </div>
        </div>
        <div className='absolute top-2 right-0'>
          <p className='font-medium leading-4 font-Ubuntu whitespace-nowrap'>
            {formatPrice(price, auth.companyCurrency, auth.companyLanguage)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasketSummary;
