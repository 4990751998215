import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import StoreQRCode from 'ui-components/StoreQRCode';
import { getCompanyDoc, updateCompanyDoc } from '../firebase-logic/appearance';
import { deleteFileByURL, uploadProfileImg } from '../firebase-logic/couriers';
import {
  SettingsContactObject,
  createSettingForContactDetails,
  getCompanyDetails,
} from '../firebase-logic/settings';
import useAuthState from '../global-states/useAuthState';
import BusinessInfoComponent from '../ui-components/BusinessInfoComponent';
import ChooseColor from '../ui-components/ChooseColor';
import ChooseFont from '../ui-components/ChooseFont';
import CropDialog from '../ui-components/CropDialog';
import CustomHeaderComponent from '../ui-components/CustomHeaderComponent';
import Summary from '../ui-components/layout/Summary';
import UploadCover from '../ui-components/UploadCover';
import UploadLogo from '../ui-components/UploadLogo';
import CustomLoadingScreen from 'ui-components/CustomLoadingScreen';

export default function AppearanceScreen() {
  const [companyData, setCompanyData] = useState<any>(null);
  const [logoFile, setLogoFile] = useState<any>(null);
  const [coverFile, setCoverFile] = useState<any>(null);
  const [faviconFile, setFaviconFile] = useState<any>(null);
  const [color, setColor] = useState<any>(null);
  const [font, setFont] = useState<any>(null);
  const [showCropDialog, setShowCropDialog] = useState<boolean>(false);
  const [saveToggle, setSaveToggle] = useState<boolean>(false);
  const [settingsContactObject, setSettingsContactObject] =
    useState<SettingsContactObject>({
      storeName: '',
      storeDescription: '',
      contactEmail: '',
      telephone: '',
    });

  const { t } = useTranslation(['appearance']);

  //contains the urls of the images to be deleted (logo/ cover)
  const [markedForDeletion, setMarkedForDeletion] = useState<string[]>([]);
  const auth: any = useAuthState.getState();

  const designSummary: any = [
    { parameter: t('storename.label'), data: 'Write your store label' },
    {
      parameter: t('storedescription.label'),
      data: 'Write your store description',
    },
    { parameter: t('contactemail.label'), data: 'Enter your email' },
    {
      parameter: t('telephonenumber.label'),
      data: 'Enter your telephone number',
    },
    { parameter: t('summary_cover.label'), data: 'Upload a cover' },
    { parameter: t('summary_logo.label'), data: 'Upload a logo' },
    { parameter: t('summary_color.label'), data: 'Choose a color' },
    { parameter: t('summary_font.label'), data: 'Choose a font' },
    { parameter: t('summary_favicon.label'), data: 'Upload a favicon' },
  ];
  const [summaryObject, setSummaryObject] = useState<any>(designSummary);
  const [settingsLoading, setSettingsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const data: any = await getCompanyDoc(auth.user);
      setCompanyData(data);
      if (data.contactSetting.storeName)
        updateSummaryObject(
          t('storename.label'),
          data.contactSetting.storeName
        );
      if (data.contactSetting.storeDescription)
        updateSummaryObject(
          t('storedescription.label'),
          data.contactSetting.storeDescription
        );
      if (data.contactSetting.contactEmail)
        updateSummaryObject(
          t('contactemail.label'),
          data.contactSetting.contactEmail
        );
      if (data.contactSetting.telephone)
        updateSummaryObject(
          t('telephonenumber.label'),
          data.contactSetting.telephone
        );
      if (data.urlLogo)
        updateSummaryObject(t('summary_logo.label'), 'Uploaded');
      if (data.urlCoverImage)
        updateSummaryObject(t('summary_cover.label'), 'Uploaded');
      if (data.urlFavicon)
        updateSummaryObject(t('summary_favicon.label'), 'Uploaded');
      if (data.color) {
        updateSummaryObject(t('summary_color.label'), data.color);
        setColor(data.color);
      }
      if (data.font) {
        updateSummaryObject(t('summary_font.label'), data.font);
        setFont(data.font);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, saveToggle]);

  useEffect(() => {
    getContactSettingsAsync(auth.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContactSettingsAsync = async (user: any) => {
    setSettingsLoading(true);
    try {
      const contactSetting = await getCompanyDetails(user);

      setSettingsLoading(false);
      if (contactSetting != null) {
        setSettingsContactObject({
          storeName: contactSetting.storeName,
          contactEmail: contactSetting.contactEmail,
          telephone: contactSetting.telephone,
          storeDescription: contactSetting.storeDescription,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(t('errorRetrieving.label'));
      setSettingsLoading(false);
    }
  };

  const onSavePressed = async (e: any) => {
    e.preventDefault();
    const toastId = toast.loading(t('loading.label'));

    //delete from storage
    if (markedForDeletion) {
      markedForDeletion.forEach((url) => deleteFileByURL(url));
      updateCompanyDoc(auth.user, companyData);
      setMarkedForDeletion([]);
    }

    try {
      let updatedCompanyData = companyData;

      if (logoFile) {
        //save to storage
        const urlLogo = await uploadProfileImg(
          auth.user,
          logoFile,
          'appearance',
          'logo'
        );
        updatedCompanyData = { ...updatedCompanyData, urlLogo };
      }

      if (coverFile) {
        const urlCoverImage = await uploadProfileImg(
          auth.user,
          coverFile,
          'appearance',
          'cover'
        );
        updatedCompanyData = { ...updatedCompanyData, urlCoverImage };
      }

      if (faviconFile) {
        const urlFavicon = await uploadProfileImg(
          auth.user,
          faviconFile,
          'appearance',
          'favicon'
        );
        updatedCompanyData = { ...updatedCompanyData, urlFavicon };
      }

      if (color) {
        updatedCompanyData = { ...updatedCompanyData, color };
      }

      if (font) {
        updatedCompanyData = { ...updatedCompanyData, font };
      }

      await updateCompanyDoc(auth.user, updatedCompanyData);
      await createSettingForContactDetails(auth.user, settingsContactObject);

      setSaveToggle((prev: any) => !prev);
      //reset
      setLogoFile(null);
      setCoverFile(null);
      setFaviconFile(null);

      toast.success(t('updated.label'), { id: toastId });
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('updateError.label'));
      console.log(error);
    }
  };

  const updateSummaryObject = (param: string, data: string) => {
    setSummaryObject((prev: any) =>
      prev.map((item: any) => {
        return item.parameter === param
          ? { parameter: param, data: data }
          : item;
      })
    );
  };

  const updateContactObject = (
    updatedData: any,
    param: string,
    value: string
  ) => {
    setSettingsContactObject(updatedData);
    updateSummaryObject(param, value);
  };

  const markForDeletion = (item: string) => {
    switch (item) {
      case 'logo':
        setLogoFile(null);
        if (companyData.urlLogo)
          setMarkedForDeletion((prev: any) => [...prev, companyData.urlLogo]);

        setCompanyData((prev: any) => ({ ...prev, urlLogo: '' }));
        updateSummaryObject(t('summary_logo.label'), 'Upload a logo');
        break;

      case 'cover':
        setCoverFile(null);
        if (companyData.urlCoverImage)
          setMarkedForDeletion((prev: any) => [
            ...prev,
            companyData.urlCoverImage,
          ]);

        setCompanyData((prev: any) => ({ ...prev, urlCoverImage: '' }));
        updateSummaryObject(t('summary_cover.label'), 'Upload a cover');
        break;

      case 'favicon':
        setFaviconFile(null);
        if (companyData.urlFavicon)
          setMarkedForDeletion((prev: any) => [
            ...prev,
            companyData.urlFavicon,
          ]);

        setCompanyData((prev: any) => ({ ...prev, urlFavicon: '' }));
        updateSummaryObject(t('summary_favicon.label'), 'Upload a favicon');
        break;
    }
  };

  const handleImageChange = (imgFile: any, item: string) => {
    switch (item) {
      case 'logo':
        setLogoFile(imgFile);
        updateSummaryObject(t('summary_logo.label'), 'Save your changes');
        break;

      case 'favicon':
        setFaviconFile(imgFile);
        updateSummaryObject(t('summary_favicon.label'), 'Save your changes');
        break;
    }
  };

  const handleCoverChange = (imgFile: any) => {
    setCoverFile(imgFile);
    updateSummaryObject(t('summary_cover.label'), 'Save your changes');
    setShowCropDialog(true);
  };

  const handleCropSave = (imgFile: any) => {
    setShowCropDialog(false);
    setCoverFile(imgFile);
  };

  return (
    <div>
      <CustomHeaderComponent heading={t('designyourstore.label')} />

      {settingsLoading ? (
        <CustomLoadingScreen />
      ) : (
        <form onSubmit={onSavePressed}>
          <div className='lg:flex w-full mb-6'>
            <div className='flex-1 md:pr-4'>
              <div className='w-full bg-white rounded-md '>
                <BusinessInfoComponent
                  settingsContactObject={settingsContactObject}
                  setSettingsContactObject={updateContactObject}
                />
              </div>

              <div className='w-full bg-white p-4 rounded-md '>
                <h1>{t('setcoverimage.label')}</h1>
                <p className='droov_text_description_light'>
                  {t('setcoverimage.text')}
                </p>
                {companyData && (
                  <UploadCover
                    imgURL={companyData.urlCoverImage}
                    onDeleted={() => markForDeletion('cover')}
                    imgFile={coverFile}
                    onImgChanged={(imgFile: any) => handleCoverChange(imgFile)}
                    item='cover'
                  />
                )}
              </div>
              <div className='w-full bg-white p-4 py-5 mt-6 rounded-md flex flex-col md:flex-row gap-3'>
                <div className={'flex-1'}>
                  <h1>{t('setyourlogo.label')}</h1>
                  <p className='droov_text_description_light'>
                    {t('setyourlogo.text')}
                  </p>
                </div>
                <div className={'flex justify-center items-center flex-1'}>
                  {companyData && (
                    <UploadLogo
                      onDeleted={() => markForDeletion('logo')}
                      imgURL={companyData.urlLogo}
                      imgFile={logoFile}
                      onImgChanged={(imgFile: any) =>
                        handleImageChange(imgFile, 'logo')
                      }
                      imageDivClassName='shadow-md blur-md'
                    />
                  )}
                </div>
              </div>

              <div className='w-full bg-white p-4 mt-6 rounded-md '>
                <h1>{t('setcolorscheme.label')}</h1>
                <p className='droov_text_description_light'>
                  {t('setcolorscheme.text')}
                </p>
                <ChooseColor
                  selectedColor={color}
                  onColorSelected={(newColor: string) => {
                    setColor(newColor);
                    updateSummaryObject(
                      t('summary_color.label'),
                      'Save your changes'
                    );
                  }}
                />
              </div>

              <div className='w-full bg-white p-4 mt-6 rounded-md '>
                <h1>{t('setfontscheme.label')}</h1>
                <p className='droov_text_description_light'>
                  {t('setfontscheme.text')}
                </p>
                <ChooseFont
                  selectedFont={font}
                  onFontSelected={(newFont: string) => {
                    setFont(newFont);
                    updateSummaryObject(
                      t('summary_font.label'),
                      'Save your changes'
                    );
                  }}
                />
              </div>

              <div className='w-full bg-white p-4 mt-6 rounded-md '>
                <h1>{t('setfavicon.label')}</h1>
                <p className='droov_text_description_light'>
                  {t('setfavicon.text')}
                </p>
                {companyData && (
                  <UploadLogo
                    onDeleted={() => markForDeletion('favicon')}
                    imgURL={companyData.urlFavicon}
                    imgFile={faviconFile}
                    onImgChanged={(imgFile: any) =>
                      handleImageChange(imgFile, 'favicon')
                    }
                    isFavicon={true}
                    className={'mt-2'}
                  />
                )}
              </div>
              <div className='w-full bg-white p-4 rounded-md mt-4'>
                <h1 className='mb-2'>{t('qrCode.header.label')}</h1>
                <StoreQRCode storeName={companyData && companyData.urlStore} />
              </div>
            </div>

            <Summary
              summaryObject={summaryObject}
              disabled={
                !settingsContactObject.contactEmail ||
                !settingsContactObject.storeName ||
                !settingsContactObject.telephone
              }
            />
          </div>
        </form>
      )}
      {showCropDialog && (
        <CropDialog
          imgFile={coverFile}
          imageType='cover'
          cropInit={{ x: 0, y: 0 }}
          zoomInit={1}
          aspectInit={4 / 1}
          openCropImageDialog={showCropDialog}
          onCancel={() => setShowCropDialog(!showCropDialog)}
          onSaveCrop={handleCropSave}
        />
      )}
    </div>
  );
}
