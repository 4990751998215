import useAuthState from '../global-states/useAuthState';
import { getCurrencySymbol } from './convertToCurrency';


export const useCurrencySymbol = () => {
    const currency = useAuthState().companyCurrency;
    const language = useAuthState().companyLanguage;

    return (number?: string | number) => {
        const num = number && !Number.isNaN(+number) ? +number : 0;
        return `${(+num).toFixed(2)}${getCurrencySymbol(language, currency)}`
    };
}