import create from 'zustand';
import { Fragment, ReactNode, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export interface Message {
  title?: string;
  message?: string;
  content?: ReactNode;
  okText?: string;
  okDisabled: boolean;
  cancelText?: string;
}

export enum ModalMode {
  UNSTYLED = 'default',
  CONFIRM = 'confirm',
  ALERT = 'alert'
}

export interface ModalObject {
  message: Message;
  onConfirm: undefined | Fn;
  onClose: undefined | Fn;
  mode: ModalMode;
  close: Fn;
  confirm: Fn;
}

type Fn = () => void;

const useConfirmModalStore = create<ModalObject>((set, get) => ({
  message: {
    okDisabled: false,
  },
  mode: ModalMode.ALERT,
  onConfirm: undefined,
  onClose: undefined,
  close: () => {
    get().onClose?.();
    set({ onConfirm: undefined, message: { okDisabled: false }, mode: ModalMode.ALERT });
  },
  confirm: () => {
    get().onConfirm?.();
    set({ onConfirm: undefined, message: { okDisabled: false }, mode: ModalMode.ALERT });
  },
}));

export const modalConfirm = (message: Partial<Message>, onConfirm: Fn, mode: ModalMode = ModalMode.ALERT, onClose?: Fn) => {
  useConfirmModalStore.setState({
    onConfirm,
    message: {
      ...message,
      okDisabled: message.okDisabled ?? false
    },
    mode,
    onClose,
  });
};

export const updateConfirmModal = (message: Partial<Message>) => {
  useConfirmModalStore.setState({
    message: { ...useConfirmModalStore.getState().message, ...message },
  });
};

export const ConfirmModal = () => {
  const { onConfirm, message, mode, confirm, close } = useConfirmModalStore();
  const cancelButtonRef: any = useRef();

  const { t } = useTranslation(['common']);
  const buttonType = mode === ModalMode.ALERT ? 'red_button' : 'blue_button';
  const iconWrapperStyle = {
    [ModalMode.ALERT]: 'bg-red-100',
    [ModalMode.CONFIRM]: 'bg-green-100',
    [ModalMode.UNSTYLED]: 'hidden'
  };

  const icon = {
    [ModalMode.ALERT]: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-red-700"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
    ),
    [ModalMode.CONFIRM]: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6 text-green_chip"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m4.5 12.75 6 6 9-13.5"
        />
      </svg>
    ),
    [ModalMode.UNSTYLED]: null,
  };

  return (
    <Transition.Root show={!!onConfirm} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={!!onConfirm}
        onClose={close}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg'>
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg'>
                <div className='sm:flex sm:items-start'>
                  <div
                    className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${iconWrapperStyle[mode]}`}
                  >
                    { icon[mode] }
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {message.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {message.content ?? <p className="text-sm text-gray-500">{message.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-gray-50 rounded-lg px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex flex-col items-center">
                <button
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm ${buttonType}`}
                  disabled={message.okDisabled}
                  onClick={confirm}
                >
                  {message.okText ?? t('yes.button')}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm cancel_button"
                  onClick={close}
                  ref={cancelButtonRef}
                >
                  {message.cancelText ?? t('cancel.button')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
