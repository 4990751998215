import React from 'react';
import { SingleStep } from 'typings/OnboardingModuleType';
import { useTranslation } from 'react-i18next';

type Props = {
  handleBackClick: any;
  stepDetails: SingleStep;
};
export const StepHeader: React.FC<Props> = ({
  handleBackClick,
  stepDetails,
}) => {
  const { t } = useTranslation(['onboarding']);
  return (
    <div className='mx-5 flex justify-between items-center'>
      <button
        disabled={stepDetails.stepNumber === 0 || stepDetails.stepNumber === 3}
        className={`${
          stepDetails.stepNumber === 0 || stepDetails.stepNumber === 3
            ? ''
            : 'hover:text-droov transition ease-in-out'
        } h-auto`}
        style={{
          opacity: stepDetails.stepNumber === 0 ? '0.3' : '1',
        }}
        onClick={handleBackClick}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-6 w-6 mr-2 inline cursor-pointer'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M15 19l-7-7 7-7'
          />
        </svg>
        <p className='hidden md:inline-block cursor-pointer inline'>
          {t('back.label')}
        </p>
      </button>
      <div className='text-large ml-6 text-3xl font-medium text-center'>
        {stepDetails.title}
      </div>

      <div>
        <button
          className={`${
            stepDetails.stepNumber === 0 ? '' : 'hover:text-droov'
          } h-auto invisible`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6 mr-2 mb-1 inline cursor-pointer'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M15 19l-7-7 7-7'
            />
          </svg>
          <p className='cursor-pointer inline'>{t('back.label')}</p>
        </button>
      </div>
    </div>
  );
};
