import useScreenDetection from '../../../hooks/useScreenWidth';
import useAuthState from '../../../global-states/useAuthState';
import { useTranslation } from 'react-i18next';
import Cart3D from '../../../assets/cart_3d.png';
import React from 'react';
import { useAllRequests } from '../../../firebase-logic/cateringRequests';
import { CateringRequestsHistory } from './CateringRequestsHistory';
import { CateringRequestsBox } from './CateringRequestsBox';

export const CateringRequestsTab: React.FC = () => {
  const screenSize = useScreenDetection();
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['catering']);

  const {
    requests,
    requestsLoading,
    fetchMoreRequestsAsync,
    moreRequestsLoading,
    noRequestsLeft,
  } = useAllRequests({ companyId: auth.user });

  return (
    <>
      {requestsLoading ? (
        <div className='flex justify-center align-middle'>
          <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 mt-32 h-12 w-12'></div>
        </div>
      ) : (
        <>
          {requests === undefined || requests.length < 1 ? (
            <div className='flex justify-center items-center'>
              <div className='text-center mt-32'>
                <p className='text-2xl mx-8 text-gray-400'>
                  {t('no_request.label')}
                </p>
                <img className='mx-auto opacity-40' src={Cart3D} alt='Store' />
              </div>
            </div>
          ) : (
            <>
              {screenSize === 'lg' && (
                <CateringRequestsHistory
                  requests={requests}
                  fetchMore={fetchMoreRequestsAsync}
                  requestsLoading={requestsLoading}
                  moreRequestsLoading={moreRequestsLoading}
                  noRequestsLeft={noRequestsLeft}
                />
              )}
              {screenSize !== 'lg' && (
                <CateringRequestsBox
                  requests={requests}
                  fetchMore={fetchMoreRequestsAsync}
                  requestsLoading={requestsLoading}
                  moreRequestsLoading={moreRequestsLoading}
                  noRequestsLeft={noRequestsLeft}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
