import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import LoginScreen from './pages/LoginScreen';
import { useEffect, useLayoutEffect, useState } from 'react';
import { initFirebaseAuth } from './global-states/useAuthState';
import useAuthState from './global-states/useAuthState';
import firebase from 'firebase';
import SideBar from './ui-components/SideBar';
import { private_routes } from './routes/routes';
import ExtendedTopNav from './ui-components/ExtendedTopNav';
import CustomLoadingScreen from './ui-components/CustomLoadingScreen';
import { useTranslation } from 'react-i18next';
import { OnboardingScreen } from './pages/OnboardingScreen';
import { SubscriptionStatus } from './types';
import { SubscriptionFailedWarningBanner } from './ui-components/billing-components/SubscriptionFailedWarningBanner';
import MyStoreButtonComponent from './ui-components/MyStoreButton';
import ResetScreen from './pages/ResetScreen';

const AppRouter = () => {
  const {
    user,
    loading,
    companyLanguage,
    subscriptionStatus,
  }: {
    user: firebase.User;
    loading: Boolean;
    companyLanguage: string;
    subscriptionStatus: SubscriptionStatus;
  } = useAuthState() as any;
  const [hideSideBar, setHideSideBar] = useState(false); // used to hide the sidebar
  const [isSubscriptionBannerVisible, setIsSubscriptionBannerVisible] =
    useState(true);

  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    initFirebaseAuth();
  }, []);

  useEffect(() => {
    if (companyLanguage) {
      i18n.changeLanguage(companyLanguage);
    }
  }, [i18n, companyLanguage]);

  if (loading) {
    return <CustomLoadingScreen />;
  }

  if (!user) {
    return (
      <Router>
        <Switch>
          <Route path='/login'>
            <LoginScreen />
          </Route>
          <Route path='/onboarding'>
            <OnboardingScreen />
          </Route>
          <Route path='/reset'>
            <ResetScreen />
          </Route>
          <Redirect from='/' to='/login' />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      {isSubscriptionBannerVisible &&
        subscriptionStatus &&
        subscriptionStatus !== SubscriptionStatus.active &&
        subscriptionStatus !== SubscriptionStatus.canceled &&
        subscriptionStatus !== SubscriptionStatus.trialing && (
          <SubscriptionFailedWarningBanner
            status={subscriptionStatus}
            setIsVisible={setIsSubscriptionBannerVisible}
          />
        )}
      <div className='flex bg-gray-100'>
        <SideBar
          NavLinks={private_routes}
          setHideSideBar={setHideSideBar}
          hideSideBar={hideSideBar}
        >
          <MyStoreButtonComponent hideSideBar={hideSideBar} />
        </SideBar>

        <div className='w-full h-screen overflow-scroll' id='scroll-element'>
          <ExtendedTopNav
            setHideSideBar={setHideSideBar}
            hideSideBar={hideSideBar}
            NavLinks={private_routes}
          />
          <Switch>
            {private_routes.map((route, index) => (
              <Route exact key={index} path={route.path}>
                <div
                  style={{
                    height:
                      route.name === 'delivery.label'
                        ? 'calc(100% - 32px)'
                        : '',
                  }}
                  className={
                    route.name === 'delivery.label'
                      ? 'mx-auto box-border'
                      : 'max-w-6xl mx-auto px-3 pb-3 box-border'
                  }
                >
                  {route.component}
                </div>
              </Route>
            ))}
            <Redirect from='/' to='/dashboard' />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default AppRouter;
