import { ReactNode } from 'react';
import CreateDiscountScreen from '../pages/CreateDiscountScreen';
import DiscountsScreen from '../pages/DiscountsScreen';
import OrdersScreen from '../pages/OrdersScreen';
import CouriersScreen from '../pages/CouriersScreen';
import CreateCourierScreen from '../pages/CreateCourierScreen';
import CatalogueScreen from '../pages/CatalogueScreen';
import SettingsScreen from '../pages/SettingsScreen';
import CompanyLocationScreen from '../pages/CompanyLocationScreen';
import WorkingHoursScreen from '../pages/WorkingHoursScreen';
import DeliveryScreen from '../pages/DeliveryScreen';
import AppearanceScreen from '../pages/AppearanceScreen';
import PaymentSettingsScreen from '../pages/PaymentSettingsScreen';
import StoreSettingsScreen from '../pages/StoreSettingsScreen';
import { SubscriptionSettings } from '../pages/SubscriptionSettingsScreen';
import CustomerScreen from '../pages/CustomersScreen';
import OrderDetailScreen from '../pages/OrderDetailScreen';
import DashboardScreen from 'pages/DashboardScreen';
import { CateringRequestDetailScreen } from '../ui-components/order-components/Catering/CateringRequestDetailScreen';
import CustomersDetailsScreen from '../pages/CustomersDetailsScreen';

export interface Route {
  name: string;
  path: string;
  component: ReactNode;
  icon?: any;
  hideFromNavbar: boolean;
  disabled?: boolean;
}

export const private_routes: Array<Route> = [
  {
    name: 'dashboard.label',
    path: '/dashboard',
    hideFromNavbar: false,
    component: <DashboardScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='2'
        stroke='currentColor'
        className='h-6 w-6'
      >
        <path
          stroke-linecap='round'
          stroke-linejoin='round'
          d='M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605'
        />
      </svg>
    ),
  },
  {
    name: 'delivery.label',
    path: '/delivery',
    hideFromNavbar: false,
    component: <DeliveryScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7'
        />
      </svg>
    ),
    disabled: false,
  },
  {
    name: 'couriers.label',
    path: '/couriers',
    hideFromNavbar: false,
    component: <CouriersScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path d='M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z' />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0'
        />
      </svg>
    ),
  },
  {
    name: 'Create Couriers',
    hideFromNavbar: true,
    path: '/couriers/create_courier',
    component: <CreateCourierScreen />,
  },
  {
    name: 'orders.label',
    path: '/orders',
    hideFromNavbar: false,
    component: <OrdersScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'
        />
      </svg>
    ),
  },
  {
    name: 'Order details',
    hideFromNavbar: true,
    path: '/orders/:orderId',
    component: <OrderDetailScreen />,
  },
  {
    name: 'Catering request details',
    hideFromNavbar: true,
    path: '/catering/:requestId',
    component: <CateringRequestDetailScreen />,
  },
  {
    name: 'Create discounts',
    hideFromNavbar: true,
    path: '/discounts/create_discounts',
    component: <CreateDiscountScreen />,
  },
  {
    name: 'customers.label',
    hideFromNavbar: false,
    path: '/customers',
    component: <CustomerScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={2}
        stroke='currentColor'
        className='w-6 h-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'
        />
      </svg>
    ),
  },
  {
    name: 'CustomersDetails',
    hideFromNavbar: true,
    component: <CustomersDetailsScreen />,
    path: '/customers/:rowId',
  },
  {
    name: 'catalogue.label',
    path: '/catalogue',
    hideFromNavbar: false,
    component: <CatalogueScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
        />
      </svg>
    ),
  },
  {
    name: 'discounts.label',
    path: '/discounts',
    hideFromNavbar: false,
    component: <DiscountsScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z'
        />
      </svg>
    ),
  },
  {
    name: 'appearance.label',
    path: '/appearance',
    hideFromNavbar: false,
    component: <AppearanceScreen />,
    icon: (
      <svg
        className='w-6 h-6'
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
        ></path>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
        ></path>
      </svg>
    ),
    disabled: false,
  },
  {
    name: 'settings.label',
    path: '/settings',
    hideFromNavbar: false,
    component: <SettingsScreen />,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z'
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
        />
      </svg>
    ),
  },
  {
    name: 'Company Location',
    hideFromNavbar: true,
    path: '/settings/company_location',
    component: <CompanyLocationScreen />,
  },
  {
    name: 'Working hours',
    hideFromNavbar: true,
    path: '/settings/working_hours',
    component: <WorkingHoursScreen />,
  },
  {
    name: 'Payment',
    hideFromNavbar: true,
    path: '/settings/payment',
    component: <PaymentSettingsScreen />,
  },
  {
    name: 'Subscription',
    hideFromNavbar: true,
    path: '/settings/subscription',
    component: <SubscriptionSettings />,
  },
  {
    name: 'Store settings',
    hideFromNavbar: true,
    path: '/settings/storeSettings',
    component: <StoreSettingsScreen />,
  },
];

export const public_routes = [];
