import { useHistory } from 'react-router-dom';
import CustomBreadCrumb from 'ui-components/CustomBreadCrumb';
import { CardPaymentSetting } from 'ui-components/CardPaymentSetting';
import { PaypalPaymentSetting } from 'ui-components/PaypalPaymentSetting';
import { CashPaymentSetting } from 'ui-components/CashPaymentSetting';
import { useTranslation } from 'react-i18next';
import { REACT_APP_TESTING_MODE } from 'config';
import useAuthState from 'global-states/useAuthState';
import SandboxLogo from 'ui-components/SandboxLogo';

export default function PaymentSettingsScreen() {
  const history = useHistory();

  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['settings']);

  return (
    <>
      <div>
        <CustomBreadCrumb
          onClickAction={() => history.goBack()}
          displayString={t('settings.label')}
        />
      </div>
      <h1 className='font-medium text-2xl pt-1 mb-2'>
        {t('paymentmethods.label')}
      </h1>
      {/*{REACT_APP_TESTING_MODE(auth.user) && <SandboxLogo />}*/}
      <PaypalPaymentSetting />
      <CardPaymentSetting />
      <CashPaymentSetting />
    </>
  );
}
