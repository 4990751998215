import db from './firebase';
import { useCallback, useEffect, useState } from 'react';
import {
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types';
import firebase from 'firebase';
import { useDebounce } from 'use-debounce';

export type StartPagination = {
  startAfter: QueryDocumentSnapshot | undefined,
  setStartAfter: (q?: QueryDocumentSnapshot) => void,
}

type GenericFirebasePaginator<T> = {
  id: string;
  collection: string;
  limit?: number;
  fromDataToObject: (data: QueryDocumentSnapshot) => T | undefined;
  paginator?: (data: firebase.firestore.CollectionReference) => Query;
  loadInit?: boolean;
  forgetOld?: boolean;
  start?: StartPagination;
  activeSearch: boolean;
};

export const useGenericFirebasePaginator = <T extends object>({
  id,
  collection,
  limit = 15,
  fromDataToObject,
  paginator = (data) => data,
  activeSearch,
  start,
  forgetOld,
  loadInit = true
}: GenericFirebasePaginator<T>) => {
  const [startAfterFirst, setStartAfterFirst] = useState<QueryDocumentSnapshot | undefined>(start?.startAfter);
  const [docs, setDocs] = useState<QuerySnapshot | undefined>();
  const [data, setData] = useState<T[]>([]);
  const [state, setState] = useState<'initial' | 'loading' | 'loaded'>(
    'initial'
  );
  const [loadMore, setLoadMore] = useState(!start?.startAfter || loadInit);
  const [noMoreLoad, setNoMoreLoad] = useDebounce(false, 300);
  const loading = state === 'loading';

  const reset = () => {
    setDocs(undefined);
    setLoadMore(true);
    start?.setStartAfter(undefined);
  }

  useEffect(() => {
    if (loadMore) {
      fetchDataAsync().then(() => {
        setLoadMore(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    const lastDoc = docs?.docs.at(-1);
    if (lastDoc && !activeSearch) {
      start?.setStartAfter(lastDoc);
    }

    const nextData = docs?.docs.map(fromDataToObject);
    if (nextData) {
      const existingData = nextData.filter<T>((d: T | undefined): d is T => !!d);
      setData((prevState) => forgetOld ? existingData : [...prevState, ...existingData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[docs]);

  const queryObject = useCallback((): Query => {
    const collectionRef = db
      .collection('companies')
      .doc(id)
      .collection(collection);

    const query = (docs?.docs || startAfterFirst)
      ? paginator(collectionRef).startAfter(startAfterFirst ?? docs?.docs.at(-1))
      : paginator(collectionRef);
    if (startAfterFirst) {
      setStartAfterFirst(undefined);
    }
    return query.limit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs, id, limit]);

  const fetchDataAsync = async () => {
    setState('loading');
    const docs = await queryObject().get();
    if (docs.size < limit) {
      setNoMoreLoad(true);
    }

    if (docs.empty) {
      if (forgetOld) {
        setData([]);
      }
      setState('loaded');
      return;
    }
    setDocs(docs);
    setState('loaded');
  };

  return [data, loading, () => {
    setLoadMore(true);
  }, noMoreLoad, state, reset] as const;
};
