import { modalConfirm } from './ConfirmationModal';
import { signOut } from '../firebase-logic/firebaseAuthFunctions';
import { useTranslation } from 'react-i18next';
import useResetState from '../states/resetState';

export default function LogoutButton() {
  const { t } = useTranslation(['common']);
  const resetState = useResetState();
  return (
    <div className='flex w-full h-10 mt-4 md:mt-0'>
      <button
        onClick={() => {
          modalConfirm(
            {
              message: t('popup_logout.label'),
              title: t('popup_logout.title'),
            },
            () => signOut({ reset: resetState })
          );
        }}
        className='w-full flex justify-center items-center text-gray-500 hover:text-droov transition ease-in-out focus:outline-none'
      >
        {t('logout.button')}
      </button>
    </div>
  );
}
