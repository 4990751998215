import { RefObject, useEffect, useState } from 'react';


const useFocusState = (ref: RefObject<HTMLElement>, defaultState = false) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const onFocus = () => setState(true);
    const onBlur = () => setState(false);
    ref?.current?.addEventListener("focus", onFocus);
    ref?.current?.addEventListener("blur", onBlur);

    const reference = ref;
    return () => {
      reference.current?.removeEventListener("focus", onFocus);
      reference.current?.removeEventListener("blur", onBlur);
    };
  }, []);

  return state;
};

export default useFocusState;