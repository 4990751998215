import { Switch } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DeliveryInfoProps {
  isDeliveryEnabled: boolean;
  setIsDeliveryEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDeliveryAreaEmpty: boolean;
}
function DeliveryInfo({
  isDeliveryEnabled,
  setIsDeliveryEnabled,
  isDeliveryAreaEmpty,
}: DeliveryInfoProps) {
  const { t } = useTranslation(['delivery']);

  const deliveryEnabledAreaEmpty = isDeliveryEnabled && isDeliveryAreaEmpty;
  const deliveryDisabledAreaEmpty = !isDeliveryEnabled && isDeliveryAreaEmpty;
  const deliveryDisabledAreNotEmpty =
    !isDeliveryEnabled && !isDeliveryAreaEmpty;

  const deliveryEnabledAreaNotEmpty = isDeliveryEnabled && !isDeliveryAreaEmpty;

  return (
    <div
      className={`flex flex-col rounded-xl border border-solid p-4 transition ease-in-out duration-300 ${
        deliveryEnabledAreaNotEmpty
          ? 'border-green-300 bg-green-300 bg-opacity-30'
          : 'border-yellow-300 bg-yellow-100'
      }`}
    >
      <div className='flex flex-row justify-between font-bold'>
        <b className='text-lg'>{t('delivery.label')}</b>
        <div className='flex flex-row'>
          <div
            className={
              isDeliveryEnabled
                ? 'font-normal italic mr-3'
                : 'font-normal italic mr-3 text-gray-400'
            }
          >
            {isDeliveryEnabled ? t('on.switch') : t('off.switch')}
          </div>
          <Switch
            checked={isDeliveryEnabled}
            onChange={setIsDeliveryEnabled}
            className={`${
              isDeliveryEnabled ? 'bg-droov' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
          >
            <span
              className={`${
                isDeliveryEnabled ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
            />
          </Switch>
        </div>
      </div>
      <div className='mt-2.5 font-normal text-gray-500'>
        {deliveryEnabledAreaEmpty && (
          <p>{t('deliveryEnabledAreaEmpty.label')}</p>
        )}
        {deliveryDisabledAreaEmpty && (
          <p>{t('deliveryDisabledAreaEmpty.label')}</p>
        )}
        {deliveryDisabledAreNotEmpty && (
          <p>{t('deliveryDisabledAreaNotEmpty.label')}</p>
        )}
        {deliveryEnabledAreaNotEmpty && (
          <p>{t('deliveryEnabledAreaNotEmpty.label')}</p>
        )}
      </div>
    </div>
  );
}

export default DeliveryInfo;
