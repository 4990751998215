import React from 'react';

type Props = {
  totalSteps: number;
  currentStepNo: number;
};
export const StepsLoader: React.FC<Props> = ({ totalSteps, currentStepNo }) => {
  return (
    <div className='mx-6 h-2 bg-gray-200 my-6 rounded-xl mb-10'>
      <div
        className='h-2 bg-droov rounded-xl transition-all delay-150 duration-500 ease-in-out'
        style={{
          width: `${(currentStepNo / totalSteps) * 100}%`,
        }}
      />
    </div>
  );
};
