import { useState } from 'react'
import { Category } from '../../../types';
import { CategoryInputCard, CategoryInputCardDashed } from './CategoryInputCard';

//Component that displays either the add category button, or the form
const AddCategory = ({ createCategory }: any) => {

    const [openCreateCategory, setOpenCreateCategory] = useState(false);

    return (
        <> {openCreateCategory ? (
                <CategoryInputCard 
                    onSaveClicked={(newCat: Category) => { createCategory(newCat); }}
                    onCancelClicked={() => setOpenCreateCategory(false)}
                />

            ) : (
                <CategoryInputCardDashed
                    onCreateClicked={() => setOpenCreateCategory(true)}
                />

            )}
        </>
    )
}
export default AddCategory;