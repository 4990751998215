import * as firebase from 'firebase-logic/firebase';

export const CatalogueAPI = {
  updateAddonGroupVisibility: (
    addonId: string,
    isVisible: boolean
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      firebase.auth.currentUser
        ?.getIdToken(true)
        .then((token) => {
          if (!token) {
            reject(new Error('No authentication token available'));
            return;
          }
          return fetch(
            'https://europe-west3-droov-group-1.cloudfunctions.net/catalogueApp/update-addon-group-visibility',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ addonId, isVisible }),
            }
          );
        })
        .then((response) => response?.json())
        .then(resolve)
        .catch(reject);
    });
  },

  updateAddonItemVisibility: (
    addonId: string,
    itemIndex: number,
    isVisible: boolean
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      firebase.auth.currentUser
        ?.getIdToken(true)
        .then((token) => {
          if (!token) {
            reject(new Error('No authentication token available'));
            return;
          }
          return fetch(
            'https://europe-west3-droov-group-1.cloudfunctions.net/catalogueApp/update-addon-item-visibility',
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ addonId, itemIndex, isVisible }),
            }
          );
        })
        .then((response) => response?.json())
        .then(resolve)
        .catch(reject);
    });
  },
};
