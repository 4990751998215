import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

i18n
  .use(Backend)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: [
      'common',
      'delivery',
      'sidebar',
      'couriers',
      'catalogue',
      'appearance',
      'orders',
      'login',
      'discount',
      'settings',
      'onboarding',
      'customer',
    ],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
