import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';

import { useTranslation } from 'react-i18next';
import { CustomersTableView } from '../ui-components/customers/CustomersTableView';
import useScreenDetection from '../hooks/useScreenWidth';
import { CustomerBoxView } from '../ui-components/customers/CustomerBoxView';
import { CustomerObject } from '@common/types';
import useAuthState from '../global-states/useAuthState';
import { useHistory } from 'react-router-dom';
import {
  useCustomersState,
  usePaginatedCustomerState,
} from '../states/customersState';
import HeaderSearch, {
  HeaderSearchOption,
} from '../ui-components/HeaderSearch';
import { useScrollToLastPosition } from '../hooks/useScrollToLastPosition';

export default function CustomersScreen() {
  const { t } = useTranslation(['customer']);

  const searchKeyMap = {
    'splits.name': t('customerName.label'),
    'splits.phone': t('customerPhone.label'),
  };

  type SearchKey = keyof typeof searchKeyMap;

  const { search, setSearchKey, setSearchValue, setActive } =
    useCustomersState();
  const options = (
    Object.keys(searchKeyMap) as SearchKey[]
  ).map<HeaderSearchOption>((key, idx) => ({
    id: idx,
    value: key,
    name: searchKeyMap[key],
  }));

  const setSelected = (val: HeaderSearchOption) =>
    setSearchKey(val.value as SearchKey);

  const currentOption = {
    id: 1,
    name: searchKeyMap[search.key],
    value: search.key,
  };

  const screenSize = useScreenDetection();
  const isMobile = screenSize === 'sm';
  const history = useHistory();
  const { makeScroll } = useScrollToLastPosition({
    scrollId: 'scroll-element',
  });

  const openCustomersDetailsPage = (row: CustomerObject) => {
    const rowId = btoa(row.uid || row.phoneNumber);
    makeScroll();
    history.push(`/customers/${rowId}`, { customer: row });
  };
  const auth = useAuthState.getState();
  const [customers, customersLoading, loadMore, noMoreLoad] =
    usePaginatedCustomerState({
      id: auth.user,
    });

  const headerComponent: CustomHeaderComponentProps = {
    heading: t('customer.label'),
    buttonComponent: (
      <HeaderSearch
        loading={customersLoading}
        search={search.value}
        setSearch={setSearchValue}
        selected={currentOption}
        setSelected={setSelected}
        options={options}
        setActive={setActive}
      />
    ),
  };

  return (
    <>
      <div className='top-8 bg-droov_background'>
        <CustomHeaderComponent {...headerComponent} />
      </div>
      <div className='flex justify-center'>
        {!isMobile && (
          <CustomersTableView
            onOpenDetails={openCustomersDetailsPage}
            customers={customers}
            customersLoading={customersLoading}
            loadMore={loadMore}
            noMoreLoad={noMoreLoad}
          />
        )}
        {isMobile && (
          <CustomerBoxView
            onOpenDetails={openCustomersDetailsPage}
            customers={customers}
            customersLoading={customersLoading}
            loadMore={loadMore}
          />
        )}
      </div>
    </>
  );
}
