import { Ref, useCallback, useEffect, useState } from 'react';
import useOnScreen from './useOnScreen';
import { useDebounce } from 'use-debounce';


const useRefOnScreen = <T extends HTMLElement = HTMLElement>(onLoadMore: () => void): Ref<T> => {
  const [htmlRef, setHtmlRef] = useState<T | null>(null);
  const onRefChange = useCallback((node: T) => {
    setHtmlRef(node);
  }, []);

  const onScreen = useOnScreen({ reactRef: htmlRef });

  const [debouncedLoadMore] = useDebounce(() => {
    onLoadMore();
  }, 800);

  useEffect(() => {
    if (!onScreen) {
      return;
    }
    debouncedLoadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return onRefChange
}

export default useRefOnScreen;