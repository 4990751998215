import CustomersTableRow, {
  CustomerKeys,
  RowStyle,
} from '../CustomersTableRow';
import AppTable from '../AppTable';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CustomerObject } from '@common/types';
import { CustomersViewProps } from './types';
import { useCurrencySymbol } from '../../utils/getCurrencySymbol';
import { ValueOf } from '../../types';

const CustomerTableHead = ({
  columns,
}: {
  columns: { key: string; value: string; style?: ValueOf<RowStyle> }[];
}) => {
  return (
    <table className='text-left w-full divide-y divide-gray-200'>
      <thead className='bg-white border-t border-b border-gray-200 rounded-t-xl flex text-gray-900 w-full '>
        <tr className='flex w-full'>
          {columns.map((column) => {
            const color = column.style?.color ?? 'text-droov_dark';
            const fontWeight = column.style?.fontWeight ?? '';
            const textAlign = column.style?.textAlign ?? '';
            return (
              <th
                key={column.key}
                className={`px-4 py-3 w-4/12 text-left text-xs
             font-bold uppercase tracking-wider ${color} ${fontWeight} ${textAlign}`}
              >
                {column.value}
              </th>
            );
          })}
        </tr>
      </thead>
    </table>
  );
};

const rowStyle: Partial<RowStyle> = {
  name: {
    color: 'text-droov_dark',
    fontWeight: 'font-medium',
  },
  lastOrder: {
    textAlign: 'text-center',
  },
  totalSpend: {
    textAlign: 'text-center',
  },
  joinedAt: {
    textAlign: 'text-center',
  },
} as const;

export const CustomersTableView = ({
  onOpenDetails,
  customers,
  customersLoading,
  loadMore,
  noMoreLoad,
}: CustomersViewProps) => {
  const { t } = useTranslation(['customer']);
  const rowKeys: CustomerKeys[] = [
    'name',
    'phoneNumber',
    'lastOrder',
    'totalSpend',
    'joinedAt',
  ];
  const dateFormat = (date: unknown) => {
    if (!date) {
      return t('noData.label');
    }
    return moment(date as Date).format('DD. MMM YYYY');
  };
  const generateCurrencyOutput = useCurrencySymbol();

  const rowUtils: Partial<
    Record<
      CustomerKeys,
      {
        format: (num: unknown) => void;
      }
    >
  > = {
    phoneNumber: {
      format: (num: unknown) => {
        return num!.toString();
      },
    },
    totalSpend: {
      format: (num: unknown) => generateCurrencyOutput(num as number),
    },
    joinedAt: {
      format: dateFormat,
    },
    lastOrder: {
      format: dateFormat,
    },
  };
  const columns = rowKeys.map((key) => ({
    key,
    value: t(`${key.toString()}.label`),
    style: rowStyle[key],
  }));

  return (
    <AppTable
      data={customers}
      onLoadMore={() => {
        return !customersLoading && loadMore();
      }}
      head={<CustomerTableHead columns={columns} />}
      row={(customer: CustomerObject, loading, idx) => (
        <CustomersTableRow
          key={idx}
          row={customer}
          rowStyle={rowStyle}
          keys={rowKeys}
          utils={rowUtils}
          onClick={onOpenDetails}
        />
      )}
      loading={customersLoading}
      refRow={(customer: CustomerObject,  ref, idx) => (
        <CustomersTableRow
          key={idx}
          row={customer}
          rowStyle={rowStyle}
          keys={rowKeys}
          utils={rowUtils}
          onClick={onOpenDetails}
          ref={ref}
        />
      )}
      allLoadedText={t('allLoaded.label')}
      allLoaded={noMoreLoad}
    />
  );
};
