import { REACT_APP_TESTING_MODE } from 'config';
import * as firebase from 'firebase-logic/firebase';

export const PayPalAPI = {
  createPartnerReferral: (companyId: string) => {
    console.log('COMPANY ID, ', companyId);
    const url = REACT_APP_TESTING_MODE(companyId)
      ? 'https://europe-west3-droov-group-1.cloudfunctions.net/testPayPalOnboarding/partner-referrals'
      : 'https://europe-west3-droov-group-1.cloudfunctions.net/payPalOnboarding/partner-referrals';
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(url, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          companyId: companyId,
        }),
      });
    });
  },
  getAccountInformation: async (companyId: string) => {
    const functionName: string = REACT_APP_TESTING_MODE(companyId)
      ? 'testGetAccountInformationPayPal'
      : 'getAccountInformationPayPal';
    const getAccountInformationPayPal =
      firebase.functionsEuropeWest3.httpsCallable(functionName);
    const result = await getAccountInformationPayPal();
    // console.log("RESULT, ", result);
    if (result !== undefined) {
      return result.data;
    } else {
      return undefined;
    }
  },
};
