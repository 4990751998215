// import { Transition } from '@headlessui/react';
// import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import SizeCard from './SizeCard';

export default function SizeGroupCard({
  state,
  setOpenAddSizesModal,
  setSizesToEdit,
  toggleSideBar,
  changeSizesVisibility,
  visibleSizes,
}: any) {
  const { t } = useTranslation(['catalogue']);

  return (
    <>
      <Droppable droppableId='configureSizes' type='configurator'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {state?.sizes?.map((size: any, index: number) => {
              return (
                <div key={index}>
                  <SizeCard
                    cardElement={Object.values(size)[0]}
                    sizeId={Object.keys(size)[0]}
                    index={index}
                    toggleSideBar={toggleSideBar}
                    setOpenAddSizesModal={setOpenAddSizesModal}
                    setSizesToEdit={setSizesToEdit}
                  />
                </div>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className='w-auto flex justify-center my-4'>
        <button
          type='button'
          className='relative_add_button w-56 h-10'
          onClick={() => {
            setOpenAddSizesModal(true);
          }}
        >
          + {t('createsizes.button')}
        </button>
      </div>
    </>
  );
}
