import React, { useRef, VFC } from 'react';
import ReactDOM from 'react-dom';
import LogoPaypal from 'assets/logo-paypal.png';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';

type PayPalDisconnectDialogProps = {
  disconnectDialogIsOpen: boolean;
  onClose: () => void;
  onDisconnectAccount: () => void;
};

export const PayPalDisconnectDialog: VFC<PayPalDisconnectDialogProps> = (
  props: any
) => {
  const { t } = useTranslation(['settings']);

  const { disconnectDialogIsOpen, onClose, onDisconnectAccount } = props;

  const cancelButtonRef: any = useRef();
  return ReactDOM.createPortal(
    <Transition.Root show={disconnectDialogIsOpen} as={'div'}>
      <Dialog
        as='div'
        static
        className=' fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={disconnectDialogIsOpen}
        onClose={onClose}
      >
        <div className='min-h-screen'>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className=' fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={'div'}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='absolute w-full md:w-auto top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-1 pb-3 pr-3 shadow-lg rounded-lg flex flex-col gap-1.5'
              style={{ transform: 'translate(-50%, -50%)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 text-red-700'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                      />
                    </svg>
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <h1 className='text-lg font-bold leading-4 mb-2'>
                      {t('disconnectpaypal.modal.label')}
                    </h1>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500 leading-4 mb-2'>
                        {t('disconnectpaypal.modal.description')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-end items-center gap-4'>
                <button
                  className='cancel_button'
                  ref={cancelButtonRef}
                  onClick={() => onClose()}
                >
                  {t('cancel')}
                </button>
                <button
                  className='transition-colors bg-red_button text-red_text hover:bg-red-500 hover:text-white border border-transparent text-white cursor-pointer rounded-button  p-3 flex justify-center items-center  duration-200 text-sm h-10'
                  onClick={onDisconnectAccount}
                >
                  {t('disconnectpaypal.button.label')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body
  );
};
