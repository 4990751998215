import React, { CSSProperties } from 'react';


const BoxLoader = (style?: CSSProperties) => {
  return (
    <div
      style={style}
      className="animate-pulse cursor-default bg-gray-200 m-2 h-48 p-6 flex-1
      relative rounded-xl flex flex-col justify-between"
    />
  );
};

export default BoxLoader;