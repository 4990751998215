import db from './firebase';

export const getCompanyDoc = async (user: any) => {
  try {
    const companyDoc = await db.collection('companies').doc(user).get();

    return companyDoc.data();
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const updateCompanyDoc = async (user: any, companyObject: any) => {
  try {
    const companyDoc = db.collection('companies').doc(user);
    await companyDoc.update(companyObject);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const fetchUrlLogo = async (user: any) => {
  const document = await db.collection('companies').doc(user).get();

  // @ts-ignore
  const urlLogo = document.data().urlLogo;

  return urlLogo;
};
