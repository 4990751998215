import { useState } from 'react';
import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import NoImage from '../../../assets/noImage.png';
import EditIcon from '../../EditIcon';
import { Switch } from '@headlessui/react';
import ExpandArrow from '../../ExpandArrow';
import { useTranslation } from 'react-i18next';

const CategoryHeader = ({
  expanded,
  onHeaderClicked,
  setEditCategory,
  updateCategoryVisibility,
  category,
  provided,
}: any) => {
  const [visibility, setVisibility] = useState(category.visible);

  const { t } = useTranslation(['catalogue']);
  function handleOnEditHeaderClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setEditCategory(category.id);
  }

  function onChangeHandler() {
    setVisibility(!visibility);
    updateCategoryVisibility(!visibility, category.id);
  }

  return (
    <div
      onClick={() => onHeaderClicked()}
      className='relative border border-transparent cursor-pointer hover:border-droov bg-white rounded-box w-full max-w-4xl  
       p-4 overflow-hidden mb-2 flex justify-between'
    >
      {expanded && (
        <div className='absolute  bg-droov h-1/2 -left-2 rounded-md w-3' />
      )}

      <div className='flex justify-start items-center'>
        <div {...provided.dragHandleProps}>
          <DotsIcon className='mr-1' />
        </div>
        {/* <img src={category.imgURL || NoImage} className="h-10" alt="" /> */}
        {category.emoji ? (
          <div className='cursor-pointer rounded-full text-3xl flex px-2'>
            <div>{category.emoji}</div>
          </div>
        ) : (
          <img src={NoImage} className='h-10 cursor-pointer' alt='no-img' />
        )}
        <h1
          className={
            visibility
              ? 'ml-4 w-36 sm:w-full max-w-24 truncate '
              : ' ml-4 w-36 sm:w-full max-w-24 truncate text-gray-400'
          }
        >
          <b>{category.name}</b>
        </h1>
      </div>

      <div className='flex items-center justify-center'>
        <div
          className='mr-3 w-full flex flex-row justify-end'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {!visibility && (
            <span className='italic text-gray-400 mr-4 hidden sm:inline-block'>
              {t('hidden.label')}
            </span>
          )}
          <Switch
            checked={visibility}
            onChange={onChangeHandler}
            className={`${
              visibility ? 'bg-droov' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
          >
            <span
              className={`${
                visibility ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
            />
          </Switch>
        </div>

        <div className='flex relative items-center w-30 justify-start'>
          <div
            onClick={(e) => {
              handleOnEditHeaderClick(e);
            }}
          >
            <EditIcon />
          </div>
          <ExpandArrow styles={'ml-2 h-4 w-4'} expanded={expanded} />
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;
