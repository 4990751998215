import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import CustomSettingsCard, {
  SettingsObject,
} from '../ui-components/CustomSettingsCard';
import { ReactComponent as LocationIcon } from '../assets/carbon_location-filled.svg';
import { ReactComponent as WorkingTimeIcon } from '../assets/working_time.svg';
import { ReactComponent as PaymentIcon } from '../assets/payment-icon.svg';
import { ReactComponent as BillingIcon } from '../assets/billing-icon.svg';
import { ReactComponent as StoreSettingsIcon } from '../assets/storeSettingsIcon.svg';
import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';

function SettingsScreen() {
  const history = useHistory();
  const { t } = useTranslation(['settings']);

  const settings: SettingsObject[] = [
    {
      generalInfo: t('location_description.label'),
      cardType: t('location.label'),
      followUpLink: '/settings/company_location',
      cardIcon: <LocationIcon width={35} height={35} />,
    },
    {
      generalInfo: t('workinghours_description.label'),
      cardType: t('workinghours.label'),
      followUpLink: '/settings/working_hours',
      cardIcon: <WorkingTimeIcon width={35} height={35} />,
    },
    {
      generalInfo: t('storesettings_description.label'),
      cardType: t('storesettings.label'),
      followUpLink: '/settings/storeSettings',
      cardIcon: <StoreSettingsIcon width={35} height={35} />,
    },
    {
      generalInfo: t('paymentmethods_description.label'),
      cardType: t('paymentmethods.label'),
      followUpLink: '/settings/payment',
      cardIcon: <PaymentIcon width={35} height={35} />,
    },
    {
      generalInfo: t('billing_description.label'),
      cardType: t('billing.label'),
      followUpLink: '/settings/subscription',
      cardIcon: <BillingIcon width={35} height={35} />,
    },
  ];

  const headerComponent: CustomHeaderComponentProps = {
    heading: t('settings.label'),
  };

  const handleClick = (followUpLink: string) => {
    history.push(followUpLink);
  };

  return (
    <>
      <CustomHeaderComponent heading={headerComponent.heading} />
      <div className='flex justify-center pt-10'>
        <div className='grid xl:grid-cols-4 gap-10 w lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
          {settings.map((setting, index) => (
            <CustomSettingsCard
              key={index}
              onClicked={() => handleClick(setting.followUpLink)}
              settingsObject={setting}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default SettingsScreen;
