export function orderStatusDateFormatter(
  date,
  locale = 'de-DE',
  hour12 = false,
  onlyTime = false
) {
  let formattedTime = new Date(date).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: hour12,
  });

  if (onlyTime) return formattedTime;

  let formattedDate = new Date(date).toLocaleString(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });

  return `${formattedDate}, ${formattedTime}`;
}

export function calculateTimeDifference(timestamp1, timestamp2) {
  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const differenceInSeconds = differenceInMilliseconds / 1000;

  if (differenceInSeconds < 60) {
    return `(+ ${differenceInSeconds.toFixed(0)} sec) `;
  }

  const differenceInMinutes = differenceInSeconds / 60;
  return `(+ ${Math.round(differenceInMinutes)} min) `;
}
