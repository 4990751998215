import db from './firebase';
import { OrderObject } from '../types';
import firebase from 'firebase';
import { StartPagination, useGenericFirebasePaginator } from './generic-firebase-paginator';

export const useAllOrders = (user: { companyId: string }, phoneNumber?: string, start?: StartPagination, loadInit = true, orderLimit = 15) => {
  return useGenericFirebasePaginator({
    id: user.companyId,
    collection: 'orders',
    fromDataToObject: getOrderObjectFromDataObject,
    limit: orderLimit,
    start: start,
    paginator(collection) {
      if (phoneNumber) {
        return collection
          .orderBy('orderTimestamp.received', 'desc')
          .where('customer.phone', '==', phoneNumber)
      } else {
        return collection
          .orderBy('orderTimestamp.received', 'desc')
      }
    },
    loadInit,
    activeSearch: false,
  });
}

export const fetchOrdersFromDates = async (
  user: any,
  startDate: Date,
  endDate: Date
) => {
  //console.log("TIMESTAMPS CHECK 01")
  //console.log("Start date: " + startDate.getTime());
  //console.log("---")
  //console.log("End date: " + endDate.getTime());
  const docs = await db
    .collection('companies')
    .doc(user)
    .collection('orders')
    .where('orderTimestamp.received', '>=', startDate.getTime())
    .where('orderTimestamp.received', '<=', endDate.getTime())
    .get();
  let allOrdersReceived: OrderObject[] = [];
  docs.forEach((data) => {
    const orderObj: OrderObject = getOrderObjectFromDataObject(data);
    allOrdersReceived.push(orderObj);
  });

  return allOrdersReceived;
};

export const fetchOrders = (user: any, callback: any) => {
  return (
    db
      .collection('companies')
      .doc(user)
      .collection('orders')
      // .where('orderStatus', '!=', 'collected')
      .where('orderStatus', 'in', [
        'received',
        'confirmed',
        'ready',
        'collectedByCourier',
      ])
      .onSnapshot((snap) => callback(snap))
  );
};

export const fetchOrderDetails = (
  companyId: string,
  orderId: string,
  callback: any
) => {
  db.collection('companies')
    .doc(companyId)
    .collection('orders')
    .where('orderID', '==', orderId)
    .onSnapshot((snap) => callback(snap));
};

const getOrderObjectFromDataObject = (
  data: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): OrderObject => {
  return {
    amount: data.data().amount,
    nettAmount: data.data().nettAmount,
    comments: data.data().comments,
    customer: data.data().customer,
    deliveryFee: data.data().deliveryFee,
    deliveryType: data.data().deliveryType,
    documentID: data.data().documentID,
    orderId: data.data().orderID,
    orderItems: data.data().orderItems,
    orderStatus: data.data().orderStatus,
    orderTimestamp: data.data().orderTimestamp,
    orderType: data.data().orderType,
    tip: data.data().tip,
    vat: data.data().vat,
    vatBreakdown: data.data().vatBreakdown,
    paymentType: data.data().paymentType,
    preparationTime: data.data().preparationTime,
    discount: data.data().discount,
  };
};
