import React from 'react';
import { ReactComponent as CheckIcon } from '../../assets/green-check.svg';
import { orderStatusDateFormatter } from '../../utils/DateFormatter';
import { calculateTimeDifference } from '../../utils/DateFormatter';
import useAuthState from '../../global-states/useAuthState';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

interface OrderStatusProps {
  order: any;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ order }) => {
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['orders']);

  return (
    <>
      <section className='w-full'>
        <div className='flex justify-between'>
          <div className='flex font-medium text-lg pl-2 md:pl-4'>
            {t('status_history.label')}
          </div>
          <div className='flex items-center'>
            <div className='bg-gray-100 py-1 px-2 sm:px-2 md:px-3 lg:px-4 ml-1 rounded-md text-center text-md sm:text-md md:text-md lg:text-lg font-medium'>
              {moment(order?.orderTimestamp?.received).format('D MMMM YYYY')}
            </div>
          </div>
        </div>
        <div className='container p-2 lg:p-4 md:p-3 sm:p-2 mx-auto mt-4 flex flex-wrap'>
          <div className='flex flex-wrap w-full'>
            <div className='w-4/5'>
              <div className='flex relative pb-12'>
                <div className='h-full w-8 sm:w-8 md:w-10 lg:w-10 absolute inset-0 flex items-center justify-center'>
                  <div className='h-full w-1 bg-gray-200 pointer-events-none' />
                </div>
                <div className='flex-shrink-0 w-8 h-8 rounded-full bg-light_green inline-flex items-center justify-center text-white relative z-10 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10'>
                  <CheckIcon />
                </div>
                <div className='w-full pl-4'>
                  <h2 className='text-md text-black mb-1'>
                    1 - {t('received.label')}
                  </h2>
                  <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                    {t('received.subtext')}
                  </p>
                </div>
              </div>
            </div>
            <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
              {orderStatusDateFormatter(
                order?.orderTimestamp?.received,
                auth.companyLanguage,
                auth.clockFormat12,
                true
              )}
            </div>
            <div className='w-4/5'>
              <div className='flex relative pb-12'>
                <div className='h-full w-8 sm:w-8 md:w-10 lg:w-10 absolute inset-0 flex items-center justify-center'>
                  <div className='h-full w-1 bg-gray-200 pointer-events-none' />
                </div>
                <div
                  className={`flex-shrink-0 w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10 rounded-full ${
                    order?.orderTimestamp?.confirmed
                      ? 'bg-light_green'
                      : 'bg-gray-200'
                  } inline-flex items-center justify-center text-white relative z-10`}
                >
                  {order?.orderTimestamp?.confirmed && <CheckIcon />}
                </div>
                <div className='flex-grow pl-4'>
                  <h2
                    className={`text-md ${
                      order?.orderTimestamp?.confirmed
                        ? 'text-black'
                        : 'text-gray-400'
                    } mb-1`}
                  >
                    2 - {t('confirmed.label')}{' '}
                    {order?.orderTimestamp?.confirmed
                      ? t('preparation_time.text', {
                          time: order?.preparationTime,
                        })
                      : null}
                  </h2>
                  <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                    {t('confirmed.subtext')}
                  </p>
                </div>
              </div>
            </div>
            {order?.orderTimestamp?.confirmed && (
              <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                {calculateTimeDifference(
                  order?.orderTimestamp?.confirmed,
                  order?.orderTimestamp.received
                )}
                {orderStatusDateFormatter(
                  order?.orderTimestamp?.confirmed,
                  auth.companyLanguage,
                  auth.clockFormat12,
                  true
                )}
              </div>
            )}
            <div className='w-4/5'>
              <div className='flex relative pb-12'>
                <div className='h-full w-8 sm:w-8 md:w-10 lg:w-10 absolute inset-0 flex items-center justify-center'>
                  <div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
                </div>
                <div
                  className={`flex-shrink-0 w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10 rounded-full ${
                    order?.orderTimestamp?.ready
                      ? 'bg-light_green'
                      : 'bg-gray-200'
                  } inline-flex items-center justify-center text-white relative z-10`}
                >
                  {order?.orderTimestamp?.ready && <CheckIcon />}
                </div>
                <div className='flex-grow pl-4'>
                  <h2
                    className={`text-md ${
                      order?.orderTimestamp?.ready
                        ? 'text-black'
                        : 'text-gray-400'
                    } mb-1`}
                  >
                    3 - {t('ready.label')}
                  </h2>
                  <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                    {t('ready.subtext')}
                  </p>
                </div>
              </div>
            </div>
            {order?.orderTimestamp?.ready && (
              <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                {calculateTimeDifference(
                  order?.orderTimestamp?.ready,
                  order?.orderTimestamp.confirmed
                )}
                {orderStatusDateFormatter(
                  order?.orderTimestamp?.ready,
                  auth.companyLanguage,
                  auth.clockFormat12,
                  true
                )}
              </div>
            )}
            <div className='w-4/5'>
              <div
                className={`flex relative ${
                  order?.orderType === 'delivery' ? 'pb-12' : 'pb-4'
                }`}
              >
                {order?.orderType === 'delivery' ? (
                  <div className='h-full w-8 sm:w-8 md:w-10 lg:w-10 absolute inset-0 flex items-center justify-center'>
                    <div className='h-full w-1 bg-gray-200 pointer-events-none'></div>
                  </div>
                ) : null}
                <div
                  className={`flex-shrink-0 w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10 rounded-full ${
                    order?.orderTimestamp?.collectedByCourier ||
                    order?.orderTimestamp?.collected
                      ? 'bg-light_green'
                      : 'bg-gray-200'
                  } inline-flex items-center justify-center text-white relative z-10`}
                >
                  {(order?.orderTimestamp?.collectedByCourier ||
                    order?.orderTimestamp?.collected) && <CheckIcon />}
                </div>
                <div className='flex-grow pl-4'>
                  <h2
                    className={`text-md ${
                      order?.orderTimestamp?.collectedByCourier ||
                      order?.orderTimestamp?.collected
                        ? 'text-black'
                        : 'text-gray-400'
                    } mb-1`}
                  >
                    4 -{' '}
                    {order?.orderType === 'delivery'
                      ? t('collected_by_courier.label')
                      : t('collected_by_customer.label')}
                  </h2>
                  <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                    {order?.orderType === 'delivery'
                      ? t('collected_by_courier.subtext')
                      : t('collected_by_customer.subtext')}
                  </p>
                </div>
              </div>
            </div>
            {(order?.orderTimestamp?.collectedByCourier ||
              order?.orderTimestamp?.collected) && (
              <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                {calculateTimeDifference(
                  order?.orderType === 'delivery'
                    ? order?.orderTimestamp.collectedByCourier
                    : order?.orderTimestamp.collected,
                  order?.orderTimestamp.ready
                )}
                {orderStatusDateFormatter(
                  order?.orderType === 'delivery'
                    ? order?.orderTimestamp.collectedByCourier
                    : order?.orderTimestamp.collected,
                  auth.companyLanguage,
                  auth.clockFormat12,
                  true
                )}
              </div>
            )}
            {order?.orderType === 'delivery' ? (
              <>
                <div className='w-4/5'>
                  <div className='flex relative pb-4'>
                    <div
                      className={`flex-shrink-0 w-8 h-8 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-10 lg:h-10 rounded-full ${
                        order?.orderTimestamp?.delivered
                          ? 'bg-light_green'
                          : 'bg-gray-200'
                      } inline-flex items-center justify-center text-white relative z-10`}
                    >
                      {order?.orderTimestamp?.delivered && <CheckIcon />}
                    </div>
                    <div className='flex-grow pl-4'>
                      <h2
                        className={`text-md ${
                          order?.orderTimestamp?.delivered
                            ? 'text-black'
                            : 'text-gray-400'
                        } mb-1`}
                      >
                        5 - {t('delivered.label')}
                      </h2>
                      <p className='leading-relaxed text-gray-400 text-sm sm:text-base md:text-md lg:text-md'>
                        {t('delivered.subtext')}
                      </p>
                    </div>
                  </div>
                </div>
                {order?.orderTimestamp?.delivered && (
                  <div className='w-1/5 flex text-right text-gray-400 text-sm sm:text-base md:text-md lg:text-md justify-end'>
                    {calculateTimeDifference(
                      order?.orderTimestamp?.delivered,
                      order?.orderTimestamp?.collectedByCourier
                    )}
                    {orderStatusDateFormatter(
                      order?.orderTimestamp?.delivered,
                      auth.companyLanguage,
                      auth.clockFormat12,
                      true
                    )}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderStatus;
