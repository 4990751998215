import { Toaster } from 'react-hot-toast';
import { ResetPasswordComponent } from '../ui-components/ResetPasswordComponent';

const ResetScreen = () => {
  return (
    <div>
      <ResetPasswordComponent />
      <Toaster position='top-center' reverseOrder={false} />
    </div>
  );
};

export default ResetScreen;
