import React from 'react';
import SummaryTable from './SummaryTable';
import { useTranslation } from 'react-i18next';
import TableActionButtons from './TableActionButtons';

export default function Summary({ summaryObject, disabled = false }: any) {
  const columns = [
    { path: 'parameter', name: 'Parameter' },
    { path: 'data', name: 'Data' },
  ];

  const { t } = useTranslation(['common']);

  return (
    <div className='h-full lg:w-1/3 md:w-full bg-white p-5 lg:mt-0 mt-4 md:sticky top-14 rounded-lg z-0 md:max-w-lg'>
      <h1 className='pb-2 font-medium text-sm'>{t('summary.label')}</h1>

      <div className='mb-3'>
        <SummaryTable columns={columns} data={summaryObject} />
      </div>
      <div className='flex w-full  border-b my-4' />
      <div className='flex flex-row gap-4'>
        <TableActionButtons
          disabled={disabled}
        />
      </div>
    </div>
  );
}
