import React from 'react';
import { useHistory } from 'react-router-dom';
import CustomDiscountCard from '../ui-components/CustomDiscountCard';
import { useDiscounts } from '../firebase-logic/discounts';
import { DiscountObject } from '../types';
import useAuthState from '../global-states/useAuthState';
import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';
import { useTranslation } from 'react-i18next';
import Discount3D from '../assets/discount_3d.png';

function DiscountsScreen() {
  const auth: any = useAuthState.getState();
  const history = useHistory();

  const { t } = useTranslation(['discount']);

  const handleClick = (item: DiscountObject | undefined) => {
    history.push('/discounts/create_discounts', { data: item });
  };

  const [discounts, loading]: any = useDiscounts({ companyId: auth.user });

  const headerComponent: CustomHeaderComponentProps = {
    heading: t('discounts.label'),
    buttonOnClick: () => handleClick(undefined),
    buttonString: '+ ' + t('addnewdiscount.button'),
  };

  return (
    <div>
      <CustomHeaderComponent
        heading={headerComponent.heading}
        showButton={true}
        buttonOnClick={headerComponent.buttonOnClick}
        buttonString={headerComponent.buttonString}
      />
      {loading ? (
        <CustomLoadingScreen />
      ) : (
        <div className='flex justify-center'>
          <div className='grid xl:grid-cols-3 md:grid-cols-2 gap-10 '>
            {discounts &&
              discounts.map((discount: DiscountObject, index: number) => (
                <CustomDiscountCard
                  onClicked={() => handleClick(discount)}
                  key={index}
                  discountObject={discount}
                />
              ))}
          </div>
          {discounts.length < 1 && (
            <>
              <div className='flex justify-center items-center'>
                <div className='text-center mt-32'>
                  <p className='text-2xl mx-8 text-gray-400'>
                    {t('no_discounts.label')}
                  </p>

                  <img
                    className='mx-auto opacity-40'
                    src={Discount3D}
                    alt='Store'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default DiscountsScreen;
