import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';
import { useTranslation } from 'react-i18next';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as PickupIcon } from '../assets/pickup.svg';
import { ReactComponent as TruckIcon } from '../assets/truck.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import { ReactComponent as MapPinIcon } from '../assets/map-pin.svg';
import BasketSummary from '../ui-components/order-components/BasketSummary';
import AmountBox from '../ui-components/order-components/AmountBox';
import useAuthState from '../global-states/useAuthState';
import { fetchOrderDetails } from '../firebase-logic/orders';
import OrderStatus from '../ui-components/order-components/OrderStatus';
import DeliveryDetails from 'ui-components/order-components/DeliveryDetails';
import moment from 'moment/moment';
import { fetchUrlLogo } from '../firebase-logic/appearance';
import PrintingOrderTable from '../ui-components/PrintingOrderTable';

interface Params {
  orderId: string;
}

interface Customer {
  name: string;
  phone: string;
}

interface OrderItem {
  itemName: string;
  itemQuantity: number;
  itemImageURL: string;
  itemSpecs: string[];
  itemPrice: string;
  itemSize: string;
  itemId: string;
  itemVat: number;
}

interface OrderTimestamp {
  received: number;
  confirmed: number;
  ready: number;
  collected: number;
  collectedByCourier: number;
  delivered: number;
}

interface DeliveryType {
  name: string;
  timestamp: number;
}

interface Discount {
  type: string;
  value: string | number;
  amount: string | number;
  id: string;
}

interface GeoCode {
  lat: number;
  lng: number;
}

interface DeliveryAddress {
  city: string;
  customerName: string;
  deliveryInstructions: string | null;
  geoCode: GeoCode | null;
  postCode: string;
  streetAndHouseNumber: string;
}

interface OrderType {
  customer: Customer;
  amount: string;
  orderType: string;
  tip: string;
  totalVAT: string;
  documentID: string;
  comments: Array<string>;
  deliveryAddress: DeliveryAddress | null;
  orderItems: { [itemId: string]: OrderItem };
  orderTimestamp: OrderTimestamp;
  orderStatus: string;
  paymentType: string;
  discount: Discount;
  basketAmount: string;
  orderID: string;
  deliveryType: DeliveryType;
  deliveryFee: string | null;
  preparationTime: number;
}

const OrderDetailScreen = () => {
  const history = useHistory();
  const { orderId } = useParams<Params>();
  const [urlLogo, setUrlLogo] = useState<any>(null);
  const [printingOrder, setPrintingOrder] = useState<any>(null);

  const auth = useAuthState.getState();
  const { t } = useTranslation(['orders']);
  const [order, setOrder] = useState<OrderType | undefined>(undefined);
  const headerComponent: CustomHeaderComponentProps = {
    heading: t('orderDetails'),
    buttonOnClick: () => {
      if (order) {
        setPrintingOrder(order);

        fetchUrlLogo(auth.user)
          .then((url) => {
            setUrlLogo(url);

            const content = document.getElementById(
              `order-print-${order.orderID}`
            );
            const printFrame = document.getElementById('ifmcontentstoprint');

            if (printFrame && content) {
              // @ts-ignore
              const printWindow = printFrame.contentWindow;
              printWindow.document.open();
              printWindow.document.write(content.innerHTML);
              printWindow.document.close();

              const img = printWindow.document.getElementById('logo-img');

              img.addEventListener('load', () => {
                printWindow.focus();
                printWindow.print();
                setPrintingOrder(null);
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    buttonString: t('printOrder'),
  };

  const goBackLocation = (history.location.state as {goBackLocation?: string})?.goBackLocation;

  const handleBackNavigation = () => {
    if (goBackLocation) {
      history.push(goBackLocation, { orderId: orderId  });
      return;
    }
    history.goBack();
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(auth.user, orderId, (snap: any) => {
        const data: any = snap.docs.map((doc: any) => doc.data());
        if (data.length > 0) {
          setOrder(data[0]);
        }
      });
    }
  }, [orderId]);

  return (
    <>
      <div>
        <div className='sticky top-8 bg-droov_background'>
          <CustomHeaderComponent
            heading={headerComponent.heading}
            buttonOnClick={headerComponent.buttonOnClick}
            buttonString={headerComponent.buttonString}
            pulsatingBtn={headerComponent.pulsatingBtn}
            breadCrumbComponent={
              <CustomBreadCrumb
                onClickAction={handleBackNavigation}
                displayString={goBackLocation ? t('orders.label') : t('goBack.label')}
              />
            }
          />
          <div className={'flex justify-center w-full'}>
            <div className='bg-white rounded-xl w-full lg:w-2/3 p-4'>
              <div className='flex justify-between'>
                <div className='flex space-x-4'>
                  <div className='bg-gray-100 p-3 rounded-md text-center font-bold'>
                    {order?.orderID?.toUpperCase().toString()}
                  </div>
                </div>

                <div className='w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/4'>
                  {order?.orderType === 'pickup' && (
                    <div className='rounded-md p-2 h-7 bg-light_green flex opacity-80 justify-center items-center text-gray-700'>
                      <span className='pickup-icon text-green-900 m-0'>
                        <PickupIcon />
                      </span>
                      <span className='mr-3 text-green-900'>
                        {' '}
                        {t('pickup.label')}
                      </span>
                    </div>
                  )}
                  {order?.orderType === 'delivery' && (
                    <div className='rounded-md p-2 h-7 bg-light_blue opacity-80 flex justify-center items-center text-gray-700'>
                      <span className='delivery-icon m-1 sm:m-1 md:m-3 lg:m-3 text-blue-900'>
                        <TruckIcon />
                      </span>
                      <span className='mr-3 text-blue-900'>
                        {' '}
                        {t('delivery.label')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={'flex justify-between'}>
                <div className={'flex flex-col'} style={{ maxWidth: '65%' }}>
                  <div className='px-2 pt-2 rounded-xl items-start font-bold'>
                    {order?.customer.name}
                  </div>
                  <div>
                    <div className='flex items-center mt-2'>
                      <div className='w-6 h-6 p-1 ml-2 flex items-center text-blue-700'>
                        <PhoneIcon />
                      </div>
                      <div className='ml-2 text-gray-500'>
                        {order?.customer.phone}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex w-1/3 sm:w-1/3 md:w-1/4 lg:w-1/4'>
                  <div className='w-full'>
                    {order?.deliveryType?.name === 'now' && (
                      <div className='bg-gray-100 px-2 py-1 rounded-md text-center text-droov font-bold w-full'>
                        <p>{t('asap.label')}</p>
                      </div>
                    )}
                    {order?.deliveryType?.name === 'scheduled' && (
                      <div className='bg-yellow-200 px-2 py-1 rounded-md text-center font-bold w-full'>
                        <p>
                          {moment(order.deliveryType.timestamp).format(
                            'DD.MM.YYYY'
                          ) === moment(new Date()).format('DD.MM.YYYY') ? (
                            <p>{t('today.label')}</p>
                          ) : (
                            moment(order.deliveryType.timestamp).format(
                              'DD.MM.YYYY'
                            )
                          )}
                        </p>
                        <p className='text-lg'>
                          {auth.clockFormat12
                            ? moment(order.deliveryType.timestamp).format(
                                'h:mm A'
                              )
                            : moment(order.deliveryType.timestamp).format(
                                'HH:mm'
                              )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {order?.comments && (
                <div
                  className='w-max mt-2 bg-yellow-100 px-3 py-2 rounded-md text-left text-sm relative'
                  style={{ maxWidth: '75%', minWidth: '30%' }}
                >
                  <div
                    className='border border-yellow-100'
                    style={{
                      position: 'absolute',
                      top: -7,
                      left: 15,
                      width: '0',
                      height: '0',
                      borderLeft: '7px solid transparent',
                      borderRight: '7px solid transparent',
                      borderBottom: '7px solid #fef3c7',
                    }}
                  />
                  {order?.comments}
                </div>
              )}
              {order?.deliveryAddress?.streetAndHouseNumber && (
                <div
                  className='w-max flex mt-2 bg-blue-100 px-3 py-2 rounded-md text-left text-sm w-4/5'
                  style={{ maxWidth: '75%', minWidth: '30%' }}
                >
                  <div className='w-5 h-5 flex justify-between items-center text-blue-700'>
                    <MapPinIcon className='text-blue-900' />
                  </div>
                  <div className='flex ml-1 text-blue-900 justify-center items-center'>
                    {order?.deliveryAddress?.streetAndHouseNumber}
                  </div>
                </div>
              )}
              <BasketSummary
                basket={mapOrderItemsToBasketItem(order?.orderItems)}
              />
              <div className='mb-4'>
                <hr />
              </div>
              <AmountBox data={mapAmountDataToAmountBox(order)} />
            </div>
          </div>
          <div className={'flex justify-center w-full'}>
            <div
              className={
                'bg-white rounded-xl lg:w-2/3 md:w-full sm:w-full mt-8 p-4'
              }
            >
              <div className={'flex justify-between'}>
                <OrderStatus order={order} />
              </div>
            </div>
          </div>
          {order?.orderType === 'delivery' && (
            <div className={'flex justify-center w-full'}>
              <div className='bg-white rounded-xl w-full lg:w-2/3 md:w-full sm:w-full mt-8'>
                <div className='flex justify-between'>
                  <DeliveryDetails order={order} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div id={`order-print-${order?.orderID}`}>
        {printingOrder && (
          <>
            <iframe
              id='ifmcontentstoprint'
              style={{ height: '0px', width: '0px', position: 'absolute' }}
              title='print'
            />
            <PrintingOrderTable urlLogo={urlLogo} printingOrder={order} />
          </>
        )}
      </div>
    </>
  );
};

export default OrderDetailScreen;

/**
 * This function maps the oderItems in an order to a compatible type to be used in baskets summary component
 * @param orderItems
 */
function mapOrderItemsToBasketItem(orderItems: any) {
  let extractedData = [];
  for (const itemId in orderItems) {
    const item = orderItems[itemId];
    const {
      itemName,
      itemImageURL,
      itemSize,
      itemSpecs,
      itemPrice,
      itemQuantity,
    } = item;
    const newItemSpecs = itemSpecs.map((item: any) => {
      return {
        subCatTitle: item.groupTitle,
        extraAddOns: item.items,
      };
    });

    const data = {
      title: itemName,
      imageURL: itemImageURL,
      size: itemSize,
      extras: newItemSpecs, // Replace 'itemSpecs' with the actual field for extras if it's available
      price: itemPrice,
      quantity: itemQuantity,
    };

    extractedData.push(data);
  }
  return extractedData;
}

function mapAmountDataToAmountBox(order: any) {
  if (order) {
    return {
      tip: order.tip,
      deliveryFee: order.deliveryFee,
      vatAmount: order.totalVAT,
      discountType: order.discount?.type,
      discountPercentage: order.discount?.value,
      discountAmount: order.discount?.amount,
      total: order.amount,
      paymentType: order.paymentType,
    };
  }
}
