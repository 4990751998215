import create from 'zustand';
import { auth } from '../firebase-logic/firebase';
import {
  getSubscriptionStatus,
  getUserDetails,
} from '../firebase-logic/settings';
import { SubscriptionStatus } from '../types';


const useAuthState = create((set: any) => ({
  user: '',
  loading: true,
  customerName: null,
  companyName: null,
  companyLanguage: 'en-US',
  countryCode: 'US',
  urlStore: null,
  companyCurrency: 'EUR',
  timezone: '',
  clockFormat12: false,
  subscriptionStatus: null,
  pushToken: null,
  showNetto: false,
  setUser: (user: string) => set({ user }),
  setLoading: (loading: Boolean) => set({ loading }),
  setCustomerName: (customerName: string) => set({ customerName }),
  setCompanyName: (companyName: string) => set({ companyName }),
  setUrlStore: (urlStore: string) => set({ urlStore }),
  setCompanyLanguage: (companyLanguage: string) => set({ companyLanguage }),
  setCountryCode: (countryCode: string) => set({ countryCode }),
  setSubscriptionStatus: (subscriptionStatus: SubscriptionStatus) =>
    set({ subscriptionStatus }),
  setCompanyCurrency: (companyCurrency: string) => set({ companyCurrency }),
  setClockFormat12: (clockFormat12: boolean) => set({ clockFormat12 }),
  setPushToken: (pushToken: string) => set({ pushToken }),
  setShowNetto: (showNetto: boolean) => set({ showNetto }),
  setTimezone: (timezone: string) => set({ timezone }),
}));

export const initFirebaseAuth = async () => {
  const state: any = useAuthState.getState();
  auth.onAuthStateChanged(async (user) => {
    state.setUser(
      auth.currentUser?.emailVerified ? auth.currentUser?.uid : null
    );
    state.setLoading(false);
    const userDetails = await getUserDetails(auth.currentUser?.uid);
    state.setCustomerName(userDetails.customerName);
    state.setCompanyName(userDetails.companyName);
    state.setUrlStore(userDetails.urlStore);
    state.setCompanyLanguage(userDetails.companyLanguage);
    state.setCountryCode(userDetails.countryCode);
    state.setCompanyCurrency(userDetails.companyCurrency);
    state.setClockFormat12(userDetails.clockFormat12);
    state.setPushToken(userDetails.pushToken);
    state.setShowNetto(userDetails.showNetto);
    state.setTimezone(userDetails.timezone);
    const subscriptionStatus = await getSubscriptionStatus(
      auth.currentUser?.uid
    );
    state.setSubscriptionStatus(subscriptionStatus);
  });
};

export default useAuthState;
