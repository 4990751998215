export const enum OrderStates {
  NOW_RECEIVED,
  NOW_CONFIRMED,
  READY,
  SCHEDULED_RECEIVED,
  SCHEDULED_CONFIRMED,
  UNDEFINED,
  DELIVERED,
}

export const enum OrderTypes {
  PICKUP,
  DELIVERY,
}

export const isPreparing = (orderObj: any) => {
  if (orderObj?.deliveryType?.preparationStartTime) return true;
  else return false;
};

export const getOrderState = (orderObj: any) => {
  if (
    orderObj.deliveryType.name === 'now' &&
    orderObj.orderStatus === 'received'
  )
    return OrderStates.NOW_RECEIVED;
  else if (
    orderObj.deliveryType.name === 'now' &&
    orderObj.orderStatus === 'confirmed'
  )
    return OrderStates.NOW_CONFIRMED;
  else if (orderObj.orderStatus === 'ready') return OrderStates.READY;
  else if (
    orderObj.deliveryType.name === 'scheduled' &&
    orderObj.orderStatus === 'received'
  )
    return OrderStates.SCHEDULED_RECEIVED;
  else if (
    orderObj.deliveryType.name === 'scheduled' &&
    orderObj.orderStatus === 'confirmed'
  )
    return OrderStates.SCHEDULED_CONFIRMED;

  return OrderStates.UNDEFINED;
};

export const getOrderType = (orderObj: any) => {
  if (orderObj.orderType === 'pickup') return OrderTypes.PICKUP;
  else if (orderObj.orderType === 'delivery') return OrderTypes.DELIVERY;
};

export const canStartPreparingOrder = (orderObj: any) => {
  const currentTime = new Date().getTime();
  const estimatedDeliveryT =
    orderObj?.deliveryType?.estimatedDeliveryTime === undefined
      ? 0
      : orderObj?.deliveryType?.estimatedDeliveryTime * 60 * 1000;
  return (
    orderObj.deliveryType.timestamp - currentTime <=
    orderObj.preparationTime * 60 * 1000 + estimatedDeliveryT
  );
};
