import { useHistory, useLocation } from 'react-router-dom';
import CourierVehicleSelector from '../ui-components/CourierVehicleSelector';
import React, { useEffect, useState } from 'react';
import CustomCheckBox from '../ui-components/EcoFriendlyCheck';
import Summary from '../ui-components/layout/Summary';
import UploadPhoto from '../ui-components/UploadPhoto';
import { CourierObject, CourierVehicleType } from '../types';
import {
  createCourier,
  deleteCourier,
  deleteFileByURL,
  editCourier,
  uploadProfileImg,
} from '../firebase-logic/couriers';
import { modalConfirm } from '../ui-components/ConfirmationModal';
import useAuthState from '../global-states/useAuthState';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import CustomInputField from '../ui-components/CustomInputField';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import toast from 'react-hot-toast';
import CropDialog from '../ui-components/CropDialog';
import { useTranslation } from 'react-i18next';

function CreateCourierScreen() {
  const auth: any = useAuthState.getState();

  const history = useHistory();
  const location: any = useLocation();
  let initialCourierObject: CourierObject = location?.state?.data;
  const [showCropDialog, setShowCropDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [courierInformation, setCourierInformation] = useState<CourierObject>({
    photo: '',
    courierName: '',
    phoneNumber: '',
    vehicleType: CourierVehicleType.Bike,
    ecoFriendly: false,
    invited: true,
    status: 'Available',
  });

  const [markedForDeletion, setMarkedForDeletion]: any = useState('');
  const [imgFile, setImgFile] = useState<any>(null);
  const [hasValidPhone, setHasValidPhone] = useState(false);

  const { t } = useTranslation(['couriers']);

  useEffect(() => {
    if (initialCourierObject) {
      setCourierInformation(initialCourierObject);
    }
  }, [initialCourierObject]);

  const onSavePressed = async (e: any) => {
    e.preventDefault();
    if (!hasValidPhone) {
      toast.error(t('invalidPhone.label'), { position: 'bottom-center' });
      return;
    }
    const toastId = toast.loading(t('loading.label'), {
      position: 'bottom-center',
    });
    // upload File
    if (markedForDeletion) {
      // delete this file from storage
      await deleteFileByURL(markedForDeletion);
    }

    let extendCourierInformation: CourierObject = courierInformation;

    if (imgFile) {
      courierInformation.phoneNumber = courierInformation.phoneNumber
        .split(' ')
        .join('');
      let err = false;
      const imgURL: any = await uploadProfileImg(
        auth.user,
        imgFile,
        'couriers',
        courierInformation.phoneNumber
      )
        .then((imgUrl) => {
          return imgUrl;
        })
        .catch((e) => {
          err = true;
          toast.dismiss(toastId);
          console.log('the image could not be uploaded with ', e);
          toast.error(t('errorSavingImage.label'));
          return;
        });

      if (err) {
        toast.dismiss(toastId);
        console.log('the image could not be uploaded with ', e);
        toast.error(t('errorSavingImage.label'));
        return;
      }
      extendCourierInformation = { ...courierInformation, profileURL: imgURL };
    }

    // Validate if all necessary values are set

    const err = initialCourierObject
      ? await editCourier(auth.user, extendCourierInformation)
      : await createCourier(auth.user, extendCourierInformation);
    toast.dismiss(toastId);
    if (err) {
      toast.error(t('errorSaving.label'));
      return;
    }
    if (initialCourierObject) {
      toast.success(t('editSuccess.label'));
    } else {
      toast.success(t('createSuccess.label'));
    }
    history.push('/couriers');
  };

  const markFileForDeletion = () => {
    setImgFile(null);
    setMarkedForDeletion(courierInformation.profileURL);
    setCourierInformation({ ...courierInformation, profileURL: '' });
  };

  const onDeletePressed = async () => {
    const toastId = toast.loading(t('loading.label'));
    setLoading(true);

    const err = await deleteCourier(auth.user, courierInformation);
    toast.dismiss(toastId);
    // @ts-ignore
    if (err) {
      toast.error(t('errorDelete.label'));
      return;
    }
    toast.success(t('deleteSuccess.label'));
    setLoading(false);
    history.push('/couriers');
  };

  const courierSummaryData = [
    {
      parameter: t('summary_fullname.label'),
      data: courierInformation.courierName,
    },
    {
      parameter: t('summary_phoneno.label'),
      data: courierInformation.phoneNumber,
    },
    {
      parameter: t('summary_photo.label'),
      data: courierInformation.profileURL
        ? 'Uploaded'
        : t('summary_photo.text'),
    },
    {
      parameter: t('summary_vehicletype.label'),
      data:
        t(courierInformation.vehicleType.toLowerCase() + '.label') +
        (courierInformation.ecoFriendly === true
          ? t('eco_friendly.label')
          : ''),
    },
  ];

  const handlePhoneChange = (
    isValid: boolean,
    phoneNumber: string,
    infos: any,
    fullNumber: string
  ) => {
    /*    console.log('Full Number: ', fullNumber)*/
    // if the number is german, cut off the leading zero

    setCourierInformation({
      ...courierInformation,
      phoneNumber: fullNumber.replace(/[^\d\+]/g, ''),
    });
    setHasValidPhone(isValid);
  };

  const handleImageChange = (imgFile: any) => {
    setImgFile(imgFile);
    setShowCropDialog(true);
  };

  const handleCropSave = (imgFile: any) => {
    setImgFile(imgFile);
    setShowCropDialog(false);
  };

  return (
    <div>
      <CustomBreadCrumb
        onClickAction={() => history.goBack()}
        displayString={t('header.label')}
      />
      <div className='flex flex-row items-center'>
        {initialCourierObject ? (
          <h1 className='text-2xl'>{t('editCourier.label')}</h1>
        ) : (
          <h1 className='text-2xl'>{t('addanewcourier.label')}</h1>
        )}
        {courierInformation.invited && initialCourierObject && (
          <div className='ml-4 text-white rounded-lg bg-gray-300 text-sm flex flex-row-reverse items-center h-8 mr-2 px-2 gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              className='w-5 h-5'
            >
              <path d='M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z' />
              <path d='M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z' />
            </svg>
            {courierInformation.invited && t('invited.label')}
          </div>
        )}
      </div>
      <br />

      <form onSubmit={onSavePressed}>
        <div className='lg:flex w-full mb-6'>
          <div className='flex-1 md:pr-4'>
            {/*Personal details*/}
            <div className='w-full bg-white p-4 rounded-md'>
              <h1>{t('personaldetails.label')}</h1>
              <p className='droov_text_description_light'>
                {t('personaldetails.text')}
              </p>
              <br />
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 lg:col-span-3'>
                  <label className='droov_label_text'>
                    {t('courierName.label')}
                  </label>
                  <CustomInputField
                    value={courierInformation.courierName}
                    onChange={(event) =>
                      setCourierInformation({
                        ...courierInformation,
                        courierName: event.target.value,
                      })
                    }
                    type='text'
                    id='name'
                    required={true}
                  />
                </div>

                <div className='col-span-6 lg:col-span-3'>
                  <label className='droov_label_text'>
                    {t('telephonenumber.label')}
                  </label>
                  <IntlTelInput
                    onPhoneNumberChange={(
                      isValid,
                      phone,
                      infos,
                      fullNumber
                    ) => {
                      handlePhoneChange(isValid, phone, infos, fullNumber);
                    }}
                    onSelectFlag={(phone, infos, fullNumber, isValid) =>
                      handlePhoneChange(isValid, phone, infos, fullNumber)
                    }
                    disabled={initialCourierObject !== undefined}
                    // TODO
                    // fix this in the future to work with german phone numbers with leading 0's
                    // and different phone numbers without a leading zero (see: trunk numbers)
                    defaultValue={courierInformation.phoneNumber}
                    defaultCountry={
                      auth.countryCode ? auth.countryCode.toLowerCase() : 'us'
                    }
                    preferredCountries={['us', 'de', 'at']}
                    containerClassName='z-20 mt-1 intl-tel-input w-full lg:w-100 m-auto justify-center'
                    inputClassName={`focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      initialCourierObject ? 'opacity-40' : ''
                    }`}
                  />
                </div>
              </div>
              {/*Add photo*/}
              <div className='mt-6'>
                <label className='droov_label_text'>{t('photo.label')}</label>
                <UploadPhoto
                  onDeleted={markFileForDeletion}
                  imgURL={courierInformation.profileURL}
                  imgFile={imgFile}
                  onImgChanged={handleImageChange}
                />
              </div>
            </div>
            <br />
            {/*Courier Vehicle*/}
            <div className='w-full bg-white p-4 top-1 rounded'>
              <h1>{t('vehicle.label')}</h1>
              <p className='droov_text_description_light'>
                {t('vehicle.text')}
              </p>
              <br />
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 lg:col-span-3 z-0'>
                  <CourierVehicleSelector
                    vehicle={courierInformation.vehicleType}
                    onValueChanged={(vehicle: any) =>
                      setCourierInformation({
                        ...courierInformation,
                        vehicleType: vehicle,
                      })
                    }
                  />
                </div>
                <div className='col-span-6 lg:col-span-3'>
                  <CustomCheckBox
                    text={t('ecofriendly.label')}
                    value={courierInformation.ecoFriendly}
                    icon={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className={
                          !courierInformation.ecoFriendly
                            ? 'filter grayscale'
                            : ''
                        }
                      >
                        <path fill='none' d='M0 0H24V24H0z' />
                        <path
                          d='M21 3v2c0 9.627-5.373 14-12 14H7.098c.212-3.012 1.15-4.835 3.598-7.001 1.204-1.065 1.102-1.68.509-1.327-4.084 2.43-6.112 5.714-6.202 10.958L5 22H3c0-1.363.116-2.6.346-3.732C3.116 16.974 3 15.218 3 13 3 7.477 7.477 3 13 3c2 0 4 1 8 0z'
                          fill='rgba(60,207,135,1)'
                        />
                      </svg>
                    }
                    onValueChanged={(value: any) =>
                      setCourierInformation({
                        ...courierInformation,
                        ecoFriendly: value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Summary*/}
          <Summary summaryObject={courierSummaryData} disabled={loading} />
        </div>
      </form>

      {initialCourierObject && (
        <div>
          <div className='flex w-full  border-b my-4' />

          <div className='md:flex w-full sm:justify-left p-4'>
            {/*Delete courier*/}
            <div className='w-full md:w-1/2 lg:w-1/3'>
              <button
                disabled={loading}
                onClick={() =>
                  modalConfirm(
                    {
                      title: t('deleteCourier.label'),
                      message: t('deleteCourier.modal.message'),
                    },
                    onDeletePressed
                  )
                }
                className={`red_button ${
                  loading ? 'opacity-60 cursor-not-allowed' : ''
                }`}
              >
                {t('deleteCourier.label')}
              </button>
            </div>
          </div>
        </div>
      )}

      {showCropDialog && (
        <CropDialog
          imgFile={imgFile}
          imageType='profile'
          cropInit={{ x: 0, y: 0 }}
          zoomInit={1}
          aspectInit={1 / 1}
          openCropImageDialog={showCropDialog}
          onCancel={() => setShowCropDialog(false)}
          onSaveCrop={handleCropSave}
        />
      )}
    </div>
  );
}

export default CreateCourierScreen;
