import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  DrawingManager,
  Marker,
} from '@react-google-maps/api';
import { DeliveryDataObject, Location } from '../../types';
import AddDeliveryAreaModal from './AddDeliveryAreaModal';
import toast, { Toaster } from 'react-hot-toast';
import { addDeliveryAreas } from '../../firebase-logic/deliveryArea';
import useAuthState from '../../global-states/useAuthState';
import { getCompanyLocation } from '../../firebase-logic/settings';
import CustomLoadingScreen from '../CustomLoadingScreen';
import { useTranslation } from 'react-i18next';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../config';

let polygonRef: any[] = [];
let cancelDrawingShape = false;
type Props = {
  isEditing: boolean;
  setIsEditing: Function;
  deliveryAreas: DeliveryDataObject[];
  setDeliveryAreas: Function;
};
function DeliveryMap(props: Props) {
  const auth: any = useAuthState.getState();
  const { isEditing, setIsEditing, deliveryAreas, setDeliveryAreas } = props;
  const [drawingManagerOptions, setDrawingManagerOptions] = useState<any>({
    drawingControl: false,
  });
  const [libraries] = useState<any>(['drawing']);
  const [openAddDeliveryAreaModal, setOpenAddDeliveryAreaModal] =
    useState(false);
  const [selectedPath, setSelectedPath] = useState<Location[]>([]);
  const [drawnPolygon, setDrawnPolygon] = useState<any>(null);
  const [center, setCenter] = useState<any>(null);
  const [mapLoading, setMapLoading] = useState(true);
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  // const center = { lat: 48.131039, lng: 11.566912 };

  const googleMapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    mapId: 'd5eece9d53c1b22a',
  };

  useEffect(() => {
    getLocation(auth.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onEdit();

    return () => {
      polygonRef = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  let polygonOptions = {
    fillOpacity: 0.3,
    // strokeColor: "red",
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: true,
    geodesic: false,
    zIndex: 1,
  };

  const getLocation = async (user: any) => {
    setMapLoading(true);
    try {
      const companyLocation = await getCompanyLocation(user);
      setMapLoading(false);
      if (companyLocation.geoCode) {
        setCenter(companyLocation.geoCode);
      }
    } catch (error) {
      setMapLoading(false);
      console.log(error);
    }
  };
  const onPolygonComplete = (polygon: any) => {
    let polygonCoordsArray = [];
    setDrawnPolygon(polygon);
    let coords = polygon.getPath().getArray();

    for (let i = 0; i < coords.length; i++) {
      const singleCoords = {
        lat: coords[i].lat(),
        lng: coords[i].lng(),
      };
      polygonCoordsArray.push(singleCoords);
    }
    setSelectedPath(polygonCoordsArray);
    if (!cancelDrawingShape) {
      setOpenAddDeliveryAreaModal(true);
    } else if (cancelDrawingShape) {
      cancelDrawingShape = false;
      polygon.setMap(null);
      setDrawnPolygon(null);
    }
  };
  const onEdit = () => {
    if (isEditing) {
      if (google) {
        setDrawingManagerOptions({
          ...drawingManagerOptions,
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
        });
        toast(t('drawMap.label'), {
          icon: '✏️',
          position: 'top-center',
          className: 'mt-12',
        });
      }
    } else {
      setDrawingManagerOptions({
        ...drawingManagerOptions,
        drawingMode: null,
      });
    }
  };

  const handleAddDeliveryArea = async (
    deliveryAreaDetails: DeliveryDataObject
  ) => {
    let newDeliveryArea = { ...deliveryAreaDetails };
    newDeliveryArea.path = selectedPath;
    try {
      await addDeliveryAreas(auth.user, [...deliveryAreas, newDeliveryArea]);
      setDeliveryAreas([...deliveryAreas, newDeliveryArea]);
      drawnPolygon.setMap(null);
    } catch (error) {
      console.log('error in saving', error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedPath([]);
    getLocation(auth.user);
    if (!cancelDrawingShape) {
      drawnPolygon.setMap(null);
    }
  };

  const handlePolygonDrag = async (
    deliveryArea: DeliveryDataObject,
    index: number
  ) => {
    const selectedPolygonRef = polygonRef[index];

    let polygonCoordsArray = [];
    let coords = selectedPolygonRef.getPath().getArray();

    for (let i = 0; i < coords.length; i++) {
      const singleCoords = {
        lat: coords[i].lat(),
        lng: coords[i].lng(),
      };
      polygonCoordsArray.push(singleCoords);
    }

    let updatedDeliveryArea = { ...deliveryArea };
    updatedDeliveryArea.path = polygonCoordsArray;

    let tempAllDeliveryAreas = [...deliveryAreas];
    tempAllDeliveryAreas[
      tempAllDeliveryAreas.findIndex(
        (area) => area.id === updatedDeliveryArea.id
      )
    ] = updatedDeliveryArea;
    try {
      await addDeliveryAreas(auth.user, [...tempAllDeliveryAreas]);
      setDeliveryAreas([...tempAllDeliveryAreas]);
    } catch (error) {
      return error;
    }
  };

  const onLoadEachPolygon = (polygon: any) => {
    polygonRef.push(polygon);
  };

  const { t } = useTranslation(['common']);

  return (
    <div className='w-full h-full relative'>
      <LoadScript
        libraries={libraries}
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
      >
        {!mapLoading && center ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            options={googleMapOptions}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {deliveryAreas.length > 0 &&
              deliveryAreas.map((area, index) => (
                <Polygon
                  key={area.id}
                  paths={area.path}
                  editable={true}
                  draggable={false}
                  onMouseUp={() => {
                    toast.promise(handlePolygonDrag(area, index), {
                      loading: t('updating.label'),
                      success: t('updateSuccess.label'),
                      error: t('somethingWentWrong.label'),
                    });
                  }}
                  options={{
                    ...polygonOptions,
                    fillColor: area.color,
                    strokeColor: area.color,
                  }}
                  onLoad={onLoadEachPolygon}
                />
              ))}
            {/* {deliveryAreas.length > 0 &&
              deliveryAreas.map((area) => {
                let labelLat = 0;
                let labelLng = 0;
                for (let i = 0; i < area.path.length; i++) {
                  const element = area.path[i];
                  labelLat += element.lat;
                  labelLng += element.lng;
                }
                labelLat = labelLat / area.path.length;
                labelLng = labelLng / area.path.length;
                return (
                  <Marker
                    key={area.id}
                    position={{
                      lat: labelLat,
                      lng: labelLng,
                    }}
                    label={{
                      text: area.name,
                      fontSize: "32px",
                    }}
                    icon={{
                      path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                      scale: 0,
                    }}
                  />
                );
              })} */}
            <Marker
              position={{
                ...center,
              }}
              icon={
                'https://firebasestorage.googleapis.com/v0/b/droov-group-1.appspot.com/o/assets%2FstoreIcon.svg?alt=media&token=d5f50357-121b-4742-a136-79c36f256c20'
              }
              title={'Your store'}
              // animation={google ? google.maps.Animation.DROP : undefined}
            />
            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              options={drawingManagerOptions}
            />
          </GoogleMap>
        ) : (
          <CustomLoadingScreen />
        )}
      </LoadScript>
      {isEditing && (
        <div className='absolute top-820 left-370 sm:top-870 sm:left-450'>
          <button
            type='button'
            className='mt-6 relative_red_button w-32 h-12'
            onClick={() => {
              cancelDrawingShape = true;
              handleCancel();
            }}
          >
            <b className='text-lg font-normal'>{t('cancel.button')}</b>
          </button>
        </div>
      )}
      {openAddDeliveryAreaModal && (
        <AddDeliveryAreaModal
          openAddDeliveryAreaModal={openAddDeliveryAreaModal}
          closeAddDeliveryAreaModal={setOpenAddDeliveryAreaModal}
          handleAddDeliveryArea={handleAddDeliveryArea}
          handleCancel={handleCancel}
        />
      )}
      <Toaster position='bottom-center' reverseOrder={false} />
    </div>
  );
}

export default DeliveryMap;
