import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const TableActionButtons = ({ disabled = false, buttonClasses = 'w-full' }) => {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  return (
    <>
      <div className={`${buttonClasses} items-center`}>
        <button
          className='cancel_button w-full'
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {t('cancel.button')}
        </button>
      </div>
      <div className={`${buttonClasses} items-center`}>
        <button
          type='submit'
          disabled={disabled}
          className={`${
            disabled ? 'blue_disabled_btn cursor-not-allowed' : 'blue_button'
          } w-full`}
        >
          {t('save.button')}
        </button>
      </div>
    </>
  );
};

export default TableActionButtons;