import React from 'react';
import { Link } from 'react-router-dom';
import DroovLogoImg from '../../assets/droov_logo_black.png';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';

type Props = {
  defaultLanguage?: string;
};
export const OnboardingHeader: React.FC<Props> = ({ defaultLanguage }) => {
  const { t } = useTranslation(['onboarding']);

  return (
    <div className='w-full h-24 flex flex-row justify-between items-center px-6 md:px-10 pt-8 pb-12'>
      <img className='flex-none w-28' src={DroovLogoImg} alt='droov-logo' />

      <div className='flex justify-end items-center'>
        <LanguageSelector defaultLanguage={defaultLanguage} />
        <Link
          to='/login'
          className='hidden ml-5 md:block text-gray-700 hover:underline'
        >
          {t('alreadyhaveandaccount.label')}{' '}
          <span className='font-bold'>{t('login.label')}</span>
        </Link>

        <Link
          to='/login'
          className='block ml-5 md:hidden text-gray-700 hover:underline'
        >
          <span>{t('login.label')}</span>
        </Link>
      </div>
    </div>
  );
};
