import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../global-states/useAuthState';
import { formatPrice } from '../../utils/helper';
import { PaymentType } from '../../types';
import { ReactComponent as PayPalIcon } from '../../assets/paypal.svg';
import { ReactComponent as ApplePayIcon } from '../../assets/applePay.svg';
import { ReactComponent as CashIcon } from '../../assets/icon-cash.svg';
import { ReactComponent as CardIcon } from '../../assets/icon-card.svg';

type AmountBoxType = {
  tip: number;
  deliveryFee: number;
  vatAmount: number;
  discountType: string;
  discountPercentage: number;
  discountAmount: number;
  total: number;
  paymentType: PaymentType | string;
};

interface AmountBoxProps {
  data: AmountBoxType | undefined;
}

const AmountBox: FC<AmountBoxProps> = ({ data }) => {
  const { t } = useTranslation(['amount']);
  const auth: any = useAuthState.getState();

  if (data === undefined) {
    return null;
  }
  const {
    tip,
    deliveryFee,
    vatAmount,
    discountType,
    discountPercentage,
    discountAmount,
    total,
    paymentType,
  } = data;
  return (
    <div className='px-4'>
      {/*TIP*/}
      <p className='flex flex-row justify-end text-base'>
        {t('tip.label')}
        &nbsp;
        {formatPrice(tip, auth.companyCurrency, auth.companyLanguage)}
      </p>

      {/*DELIVERY FEE*/}
      {deliveryFee !== undefined && deliveryFee !== null && deliveryFee !== 0 && (
        <p className='flex flex-row justify-end text-base lg:pl-1'>
          {t('deliveryFee.label')}
          &nbsp;
          {formatPrice(deliveryFee, auth.companyCurrency, auth.companyLanguage)}
        </p>
      )}

      {/*TAX*/}
      <p className='flex flex-row justify-end text-base'>
        {t('vatAdded.label')}
        &nbsp;
        {formatPrice(vatAmount, auth.companyCurrency, auth.companyLanguage)}
      </p>

      {/*DISCOUNT*/}
      {discountAmount > 0 && (
        <>
          <p className='flex flex-row justify-end text-base'>
            {t('discount.label')}
            {discountType === 'Percent' && ` (${discountPercentage} %)`}
            &nbsp;
            {formatPrice(
              discountAmount,
              auth.companyCurrency,
              auth.companyLanguage
            )}
          </p>
        </>
      )}

      {/*TOTAL*/}
      <div className='flex'>
        <div className='flex flex-grow mt-4'>
          {paymentType === PaymentType.Apple ? (
            <ApplePayIcon className='border border-black rounded-sm opacity-80' />
          ) : paymentType === PaymentType.PayPal ? (
            <PayPalIcon className='opacity-80' />
          ) : paymentType === PaymentType.Card ? (
            <CardIcon className='card-icon opacity-80' />
          ) : (
            <CashIcon className='cash-icon opacity-80' />
          )}
          <span className='ml-3 text-gray-600'>{paymentType}</span>
        </div>
        <div className='flex flex-grow justify-end mt-4'>
          <p className='text-base font-bold'>
            {t('total.label')}
            &nbsp;
            {formatPrice(total, auth.companyCurrency, auth.companyLanguage)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AmountBox;
