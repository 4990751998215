import {useLocation, useParams} from "react-router-dom";
import useAuthState from "../global-states/useAuthState";
import {getCustomer} from "../firebase-logic/customers";
import { CustomerObject } from '@common/types';
import { useEffect, useState } from 'react';


const useCustomersDetails = () => {
  const location = useLocation<{ customer?: CustomerObject }>();
  const { rowId } = useParams<{ rowId?: string }>();
  const [customer, setCustomer] = useState(location.state?.customer);
  const user = useAuthState().user;
  const [loading, setLoading] = useState(true);

  const getCustomerFromDb = async () => {
    try {
      const id = atob(rowId ?? btoa(''));
      return await getCustomer({ userId: user, customerId: id  });
    } catch (e) {
      console.error(e);
      return;
    }
  }

  useEffect(() => {
    if (!customer) {
      getCustomerFromDb().then(customer => {
        setCustomer(customer);
        setLoading(false);
      })
    } else {
      setLoading(false)
    }
  }, []);

  return { customer, loading };
};

export default useCustomersDetails;