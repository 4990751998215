import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

interface StoreQRCodeProps {
  storeName: string;
}

const Loader = () => (
  <div className='inset-0 flex justify-center items-center  w-full h-full z-50 overflow-hidden opacity-75 '>
    <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mt-12 mb-4'></div>
  </div>
);

export default function StoreQRCode({ storeName }: StoreQRCodeProps) {
  const { t } = useTranslation(['appearance']);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsLoading(true);
    const canvas = document.getElementById(
      'qrCodeDownload'
    ) as HTMLCanvasElement;
    const imageURL = canvas.toDataURL('image/png');
    setQrCodeUrl(imageURL);

    const link = document.createElement('a');
    link.href = imageURL;
    link.setAttribute('download', 'qr-code.png');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsLoading(false);
  };

  const qrCodeProps = {
    value: storeName || '',
    size: 512,
    bgColor: '#FFFFFF',
    fgColor: '#262728',
    level: 'Q',
    includeMargin: false,
  };

  return (
    <div className='w-full p-4 rounded-md flex flex-col md:flex-row bg-gray-100'>
      <div className='w-full'>
        <p className='droov_text_description_light -mt-0.5 md:pr-4'>
          {t('qrCode.standardInformation.label')}
        </p>
        <p className='mt-4'>🔗 {storeName && storeName.substring(8)}</p>
      </div>
      {isLoading ? (
        <div className='w-42'>
          <Loader />
        </div>
      ) : (
        <div
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          className='relative self-center p-8 md:p-0 transition duration-500 ease-in-out transform hover:scale-95 cursor-pointer'
        >
          <div className='w-40 h-40'>
            <QRCode
              className='rounded-lg bg-gray-100 p-2.5 item_box_shadow w-full h-full'
              id='qrCode'
              value={storeName || ''}
              size={160} // Display size
              bgColor='#FFFFFF'
              fgColor='#262728'
              level='Q'
              includeMargin={false}
            />
          </div>
          <QRCode
            id='qrCodeDownload'
            style={{ display: 'none' }} // Hidden QR code for downloading
            {...qrCodeProps}
          />
          {isHovered && (
            <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 cursor-pointer rounded-lg'>
              <div className='flex flex-col justify-center align-center text-center'>
                <span>🔥</span>
                <span className='text-white text-lg font-bold'>
                  {t('qrCode.generate.label')}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
