import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import { Draggable } from 'react-beautiful-dnd';
import EditIcon from '../../EditIcon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export default function SizeCard({
  index,
  cardElement,
  setOpenAddSizesModal,
  setSizesToEdit,
  sizeId,
}: any) {
  const [activeTab, setActiveTab] = useState(cardElement.defaultSize);
  const { t } = useTranslation(['catalogue']);
  useEffect(() => {
    setActiveTab(cardElement.defaultSize);
  }, [cardElement.defaultSize]);
  return (
    <Draggable key={index} draggableId={sizeId} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className='border rounded-xl my-2 p-2 bg-white z-0'
          key={index}
        >
          <div className='flex w-full'>
            <div className='flex justify-start items-center mr-2'>
              <DotsIcon className='w-6 h-6' />
            </div>
            <b className='w-3/6 flex justify-start items-center text-lg mr-1'>
              {t('size.label')}
            </b>

            <div
              className='w-2/6 ml-5 flex relative justify-end items-center'
              onClick={() => {
                setOpenAddSizesModal && setOpenAddSizesModal(true);
                setSizesToEdit && setSizesToEdit(index);
              }}
            >
              <EditIcon />
            </div>
          </div>

          <div className='px-1.5'>
            <div className='my-2 h-9 flex bg-gray-100 rounded-full items-center'>
              {cardElement.items.map((sizeElements: any, index: number) => {
                return (
                  <button
                    key={index}
                    className={`draggable_button w-44 flex-grow ml-1 mr-1 font-Ubuntu text-sm leading-4 rounded-full h-7 text-center ${
                      activeTab === index ? ' bg-white' : 'bg-gray-100'
                    }`}
                  >
                    {sizeElements.size}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
