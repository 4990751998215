import { useEffect, useState } from 'react';
import { CatState } from '../utils/CategoryMock';
import onProductDrag from '../utils/onProductDrag';
import db from './firebase';
import { Category, Product } from '../types';
import toast from 'react-hot-toast';

import { createAddons, deleteAddonss } from './catalogue/addons';
import { createSizes, deleteSizess } from './catalogue/sizes';
import {
  changeOrderOfProducts,
  firebaseCreateAProduct,
  firebaseDeleteAProduct,
  updateAnItem,
  updateProductImage,
} from './catalogue/product';
import {
  firebaseCreateACategory,
  firebaseDeleteACategory,
  firebaseUpdateACategory,
} from './catalogue/category';
import {
  dragToOrderAddonChips,
  dragAddonFromConfiguratorToProduct,
  dragSizeFromConfiguratorToProduct,
} from './catalogue/dragAndDrop';
import { createLabels } from './catalogue/labels';
import calculateVatFromPrice from 'utils/calculateVatFromPrice';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next';

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || '',
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY || ''
);
const algoliaIndex = algoliaClient.initIndex('droov_addons');

const fetchCatalogue = async (user: string) => {
  const categoriesArray = await fetchCategories(user);
  const productsArray = await fetchProducts(user);
  const categoriesOrder = await fetchCategorieOrder(user);
  const configurator = await fetchConfigurator(user);

  let products: any = {};
  let categories: any = {};

  productsArray.forEach((product) => {
    if (!product.id) return;

    products[product.id] = product;
  });
  categoriesArray.forEach((category) => {
    if (!category.id) return;

    categories[category.id] = category;
  });

  if (!categoriesOrder) {
    return CatState;
  }

  return { categories, products, categoriesOrder, configurator };
};

const fetchCategories = async (user: string) => {
  let categories: Array<Category> = [];
  const documents = await db
    .collection('companies')
    .doc(user)
    .collection('catalogue')
    .doc('catalogue')
    .collection('categories')
    .get();

  documents.forEach((data) => {
    const { imgURL, name, products, visible, emoji } = data.data();
    let category: Category = {
      id: data.id,
      imgURL: imgURL,
      name: name,
      visible: visible,
      products: products || [],
      emoji: emoji || null,
    };
    categories.push(category);
  });
  return categories;
};

const fetchProducts = async (user: string) => {
  let products: Array<Product> = [];
  const documents = await db
    .collection('companies')
    .doc(user)
    .collection('catalogue')
    .doc('catalogue')
    .collection('products')
    .get();

  documents.forEach((data) => {
    const {
      imgURL,
      name,
      category,
      price,
      nettoPrice,
      description,
      sizes,
      addons,
      labels,
      defaultSize,
      vat,
      visible,
    } = data.data();
    let product: Product = {
      category,
      price,
      nettoPrice,
      id: data.id,
      imgURL,
      description,
      name,
      sizes,
      addons,
      labels,
      defaultSize,
      vat,
      visible,
    };
    products.push(product);
  });
  return products;
};

const fetchConfigurator = async (user: string) => {
  try {
    let configurator: any = [];
    let ad: any = []; //addons
    let sz: any = []; //sizes
    const addons = await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('configuratorAddons')
      .get();

    addons.forEach((addon) => {
      const {
        maximum,
        minimum,
        title,
        items,
        refId,
        visible,
        mandatory,
        withImages,
        allowMultiple,
      } = addon?.data();
      let x: any = {
        maximum,
        minimum,
        title,
        items,
        refId,
        visible,
        mandatory,
        withImages,
        allowMultiple,
      };
      ad.push({ [addon.id]: x });
    });

    const sizes = await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('configuratorSizes')
      .get();

    sizes.forEach((size) => {
      const { items, defaultSize, general, refId } = size?.data();
      let x: any = { items, defaultSize, general, refId };
      sz.push({ [size.id]: x });
    });

    configurator = {
      sizes: sz || [],
      addons: ad || [],
    };

    return configurator;
  } catch (e) {
    toast.dismiss();
    toast.error('Configurator not fetched');
  }
};

const fetchCategorieOrder = async (user: string) => {
  let categoryOrder: Array<string> = [];
  const document = await db
    .collection('companies')
    .doc(user)
    .collection('metadata')
    .doc('categoriesOrder')
    .get();

  categoryOrder = document?.data()?.categoriesOrder;
  return categoryOrder;
};

export const fetchCategorieImages = async () => {
  let categoryImages: any = [];
  const documents = await db.collection('emojiCategories').get();

  documents.forEach((data) => {
    const { imgURL, name } = data.data();
    let a: any = {
      imgURL,
      name,
    };
    categoryImages.push(a);
  });

  return categoryImages;
};

export const fetchAddonsImages = async () => {
  let addonsImages: any = [];
  const documents = await db.collection('addons').get();

  documents.forEach((data) => {
    const { imgURL, name } = data.data();
    let a: any = {
      imgURL,
      name,
    };
    addonsImages.push(a);
  });

  return addonsImages;
};

export const searchAddonImages = async (searchText: string) => {
  let addonsImages: any = [];

  const { hits } = await algoliaIndex.search(searchText, {
    attributesToRetrieve: ['name', 'imgURL'],
    hitsPerPage: 10,
  });

  hits.forEach((data: any) => {
    if (Array.isArray(data.imgURL)) {
      for (let i = 0; i < data.imgURL.length; i++) {
        const element = data.imgURL[i];
        let a: any = {
          imgURL: element,
          name: data.name,
        };
        addonsImages.push(a);
      }
    } else {
      let a: any = {
        imgURL: data.imgURL,
        name: data.name,
      };
      addonsImages.push(a);
    }
  });

  return addonsImages;
};

const changeOrderOfCategory = async (
  user: any,
  newOrderArray: Array<string>
) => {
  try {
    await db
      .collection('companies')
      .doc(user)
      .collection('metadata')
      .doc('categoriesOrder')
      .update({ categoriesOrder: newOrderArray });
  } catch (e) {
    toast.dismiss();
    toast.error('Could not update categories order');
  }
};

export const useCatalogue = (user: any, showNetto: boolean) => {
  const { t } = useTranslation(['catalogue']);
  const [catalogue, setCatalogue] = useState(CatState);
  const [loading, setLoading] = useState(false);
  const [visibleCategories, setVisibleCategories]: any = useState([]);
  useEffect(() => {
    const fetchAndSetCatalogue = async () => {
      setLoading(true);
      const catalogue = await fetchCatalogue(user);
      setCatalogue(catalogue);
      setLoading(false);
    };
    fetchAndSetCatalogue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLabels = async (labels: any, productId?: any) => {
    createLabels(labels, user, setCatalogue, catalogue, productId);
  };

  let temporaryCatalogue: any[] = [];

  const addAddons = async (
    addonGroup: any,
    productId?: any,
    productVat?: any,
    addonId?: any,
    addonToEdit?: any,
    bulk = { multiple: false, isLast: false },
    createAndAdd?: any
  ) => {
    if (productVat) {
      addonGroup.items = addonGroup.items.map((item: any) => {
        const calcResult = calculateVatFromPrice(
          showNetto ? item.nettoPrice : item.price,
          showNetto,
          productVat
        );
        return { ...item, ...calcResult };
      });
    }
    const addons = createAddons(
      addonGroup,
      user,
      setCatalogue,
      catalogue,
      addonId,
      productId,
      fetchCatalogue,
      addonToEdit,
      showNetto,
      !bulk.multiple,
      bulk,
      temporaryCatalogue,
      createAndAdd
    );
    if (bulk.multiple && bulk.isLast) {
      temporaryCatalogue = [];
    }

    return addons;
  };

  const setSizes = async (
    sizes: any,
    defaultSize: any,
    general: boolean,
    productId?: any,
    sizeId?: any,
    sizesToEdit?: any,
    productVat?: any,
    createAndAdd?: any,
    refID?: any
  ) => {
    createSizes(
      sizes,
      defaultSize,
      general,
      user,
      setCatalogue,
      catalogue,
      productId,
      sizeId,
      fetchCatalogue,
      sizesToEdit,
      showNetto,
      productVat,
      createAndAdd,
      refID
    );
  };

  const deleteAddons = async (
    addonId: any,
    productId?: any,
    addonToEdit?: any,
    showToast?: any
  ) => {
    deleteAddonss(
      addonId,
      user,
      setCatalogue,
      catalogue,
      productId,
      showToast,
      addonToEdit,
      fetchCatalogue
    );
  };

  const deleteSizes = async (
    sizesId: any,
    productId?: any,
    sizesToEdit?: any
  ) => {
    deleteSizess(
      sizesId,
      user,
      setCatalogue,
      catalogue,
      productId,
      sizesToEdit,
      fetchCatalogue
    );
  };

  const createProduct = async (categoryId: string, product: Product) => {
    const productId = await firebaseCreateAProduct(user, product, categoryId);
    if (!productId) {
      return;
    }

    if (catalogue.categories[categoryId].products === undefined) {
      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: { id: productId, ...product },
        },
        categories: {
          ...catalogue.categories,
          [categoryId]: {
            ...catalogue.categories[categoryId],
            products: [productId],
          },
        },
      });
    } else {
      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: { id: productId, ...product },
        },
        categories: {
          ...catalogue.categories,
          [categoryId]: {
            ...catalogue.categories[categoryId],
            products: [...catalogue.categories[categoryId].products, productId],
          },
        },
      });
    }
  };

  const updateAProduct = async (
    productId: string,
    product?: Product,
    imgURL?: string
  ) => {
    if (!productId) {
      return;
    }
    if (product) {
      await updateAnItem(user, productId, product);
      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: { id: productId, ...product },
        },
      });
    }
    if (imgURL) {
      setCatalogue({
        ...catalogue,
        products: {
          ...catalogue.products,
          [productId]: { ...catalogue.products[productId], imgURL: imgURL },
        },
      });
      await updateProductImage(user, productId, imgURL);
    }
  };

  const deleteProduct = async (productId: string, categoryId: string) => {
    if (!productId) {
      return;
    }
    //deletes product from products collection
    let newProductsObject = { ...catalogue.products };
    delete newProductsObject[productId];

    //deletes product from category
    let copyOfCategories = catalogue.categories[categoryId].products;
    var index = copyOfCategories.indexOf(productId);
    if (index !== -1) {
      copyOfCategories.splice(index, 1);
    }

    setCatalogue({
      ...catalogue,
      products: newProductsObject,
      categories: {
        ...catalogue.categories,
        [categoryId]: {
          ...catalogue.categories[categoryId],
          products: copyOfCategories,
        },
      },
    });
    await firebaseDeleteAProduct(
      user,
      catalogue.products[productId],
      categoryId
    );
    // setCatalogue(catalogue);
  };

  const createCategory = async (newCategory: Category) => {
    const catID = await firebaseCreateACategory(user, newCategory);
    catalogue.categoriesOrder.push(catID);
    setCatalogue({
      ...catalogue,
      categories: {
        ...catalogue.categories,
        [catID]: {
          ...newCategory,
          id: catID,
          visible: true,
          imgURL: newCategory.imgURL,
        },
      },
    });
  };

  const updateCategory = async (updatedCategory: any) => {
    await firebaseUpdateACategory(user, updatedCategory.id, {
      visible: updatedCategory.visible,
      name: updatedCategory?.name,
      imgURL: updatedCategory?.imgURL,
      emoji: updatedCategory?.emoji,
    });
    setCatalogue({
      ...catalogue,
      categories: {
        ...catalogue.categories,
        [updatedCategory.id]: { ...updatedCategory },
      },
    });
  };

  const deleteCategory = async (categoryId: string) => {
    if (!categoryId) {
      return;
    }
    const productsOfCategory = catalogue.categories[categoryId]?.products;
    productsOfCategory?.map((productId: string) => {
      return firebaseDeleteAProduct(
        user,
        catalogue.products[productId],
        categoryId
      );
    });

    var index = catalogue.categoriesOrder.indexOf(categoryId);
    if (index !== -1) {
      catalogue.categoriesOrder.splice(index, 1);
    }
    await firebaseDeleteACategory(user, categoryId);
    await changeOrderOfCategory(user, catalogue.categoriesOrder);
    setCatalogue(catalogue);
  };

  const updateCategoryVisibility = async (
    value: boolean,
    categoryId: string
  ) => {
    try {
      const loading = toast.loading(t('loading.label'));
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('categories')
        .doc(categoryId)
        .update({
          visible: value,
        });
      catalogue.categories[categoryId].products.forEach((productId: string) => {
        const currentCatalogue = catalogue.products[productId];
        currentCatalogue.visible = value;
        setCatalogue((catalogue) => ({
          ...catalogue,
          products: {
            ...catalogue.products,
            [catalogue.products[productId]]: currentCatalogue,
          },
        }));
        updateProductVisibility(value, productId, false);
      });

      toast.success(t('visibility_updated.label'), { id: loading });
    } catch (e) {
      toast.dismiss();
      toast.error('Visibility not updated');
    }
  };

  const updateProductVisibility = async (
    value: boolean,
    productId: string,
    showToast = true
  ) => {
    try {
      let loading;
      if (showToast) {
        loading = toast.loading(t('loading.label'));
      }
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('products')
        .doc(productId)
        .update({
          visible: value,
        });
      if (showToast) {
        toast.success(t('visibility_updated.label'), { id: loading });
      }
    } catch (e) {
      if (showToast) {
        toast.dismiss();
        toast.error('Visibility not updated');
      }
    }
  };

  const onDragEnded = async (result: any) => {
    const { source, type, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === 'configureAddons' ||
      destination.droppableId === 'configureSizes'
    ) {
      return;
    } //if we drop whatever draggable to configurator
    if (
      source.droppableId.includes('addonsOrder') &&
      destination.droppableId.includes('addonsOrder') &&
      source.droppableId !== destination.droppableId
    ) {
      return;
    }

    if (type === 'addons') {
      //dragging and droping addon chips to reorder
      dragToOrderAddonChips(result, catalogue, setCatalogue, user);
      return;
    }

    if (type === 'cat') {
      //reorder categories
      const newColumnOrder: string[] = Array.from(catalogue.categoriesOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...catalogue,
        categoriesOrder: newColumnOrder,
      };

      setCatalogue(newState);
      await changeOrderOfCategory(user, newState.categoriesOrder);
      return;
    }

    if (type === 'configurator') {
      //drag and drop sizes and addons to product
      if (source.droppableId === 'configureAddons') {
        //if dragging addons from configurator to product
        dragAddonFromConfiguratorToProduct(
          result,
          catalogue,
          user,
          showNetto,
          setCatalogue
        );
        return;
      }

      if (source.droppableId === 'configureSizes') {
        //if dragging sizes from configurator to product
        dragSizeFromConfiguratorToProduct(
          result,
          setCatalogue,
          catalogue,
          user,
          showNetto
        );
        return;
      }
      // TO BE IMPLEMENTED FOR ALLERGENS
      // if (source.droppableId === "configureAllergens") { //if dragging allergens from configurator to product
      //   dragAllergensFromConfiguratorToProduct(result, setCatalogue, catalogue, user)
      //   return
      // }
    }

    if (type === 'product') {
      //reorder products within catalogue
      const newState = onProductDrag(result, catalogue);
      if (!newState.newState) {
        return;
      } else {
        const loading = toast.loading(t('loading.label'));
        setCatalogue(newState.newState);
        changeOrderOfProducts(user, newState.catsToUpdate);
        toast.success(t('products_reordered.label'), { id: loading });
      }
    }
  };

  return [
    catalogue,
    visibleCategories,
    setVisibleCategories,
    onDragEnded,
    updateCategory,
    updateAProduct,
    deleteProduct,
    createCategory,
    deleteCategory,
    loading,
    deleteSizes,
    deleteAddons,
    addAddons,
    setSizes,
    updateCategoryVisibility,
    createProduct,
    updateProductVisibility,
    addLabels,
  ];
};
