import { useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector({ defaultLanguage }: any) {
  const { t } = useTranslation(['onboarding']);

  const isoLangs = [
    {
      option: false,
      iso: '🌐',
      name: t('language.label'),
      nativeName: t('language.label'),
    },
    {
      option: true,
      iso: 'de',
      name: 'German',
      nativeName: 'Deutsch',
    },
    {
      option: true,
      iso: 'en',
      name: 'English',
      nativeName: 'English',
    },
    {
      option: true,
      iso: 'es',
      name: 'Spanish',
      nativeName: 'Español',
    },
  ];

  const selectedLang = defaultLanguage
    ? isoLangs.find(
        (isoLang) => isoLang.iso === defaultLanguage.split('-')[0]
      ) || isoLangs[2]
    : isoLangs[2];

  const [selected, setSelected] = useState(selectedLang);

  useEffect(() => {
    i18n.changeLanguage(selected.iso);
  }, [selected]);

  return (
    <div className='w-24 md:w-36 z-30'>
      <Listbox value={selected} onChange={setSelected}>
        <div className='relative mt-1'>
          <Listbox.Button className='hover:shadow-sm transition ease-in-out relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
            <div className='flex flex-row'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className='mr-2 mt-1 md:mt-0.5 w-4 h-4 opacity-70'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802'
                />
              </svg>
              <span className='hidden md:block truncate'>
                {selected.nativeName}
              </span>
              <span className='block md:hidden uppercase'>{selected.iso}</span>
            </div>

            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                aria-hidden='true'
                className='h-5 w-5 text-gray-400'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
                />
              </svg>
            </span>
          </Listbox.Button>
          <Transition
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {isoLangs
                .filter((lang) => lang.option)
                .map((lang, langIdx) => (
                  <Listbox.Option
                    key={langIdx}
                    className={({ active }) =>
                      `relative cursor-pointer hover:bg-gray-100 select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={lang}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`hidden md:block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {lang.nativeName}
                        </span>
                        <span
                          className={`block md:hidden uppercase ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {lang.iso}
                        </span>
                        {selected ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              aria-hidden='true'
                              className='h-5 w-5'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 12.75l6 6 9-13.5'
                              />
                            </svg>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
