/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import LogoutButton from './LogoutButton';
import { NavLink } from 'react-router-dom';
import { SideBarProps } from './SideBar';

//import png image from assets
import DroovLogo from '../assets/droov_black_full_1.png';
import { useTranslation } from 'react-i18next';
import MyStoreButtonComponent from './MyStoreButton';
import FeedbackButton from './FeedbackButton';
import FeedbackModal from './FeedbackModal';

export default function ExtendedTopNav({
  NavLinks,
  setHideSideBar,
}: SideBarProps) {
  const { t } = useTranslation(['sidebar']);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

  return (
    <Popover className='bg-white sticky top-0 z-30 '>
      {({ open, close }) => (
        <Fragment>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 z-30'>
            <div className='flex justify-between items-center py-3 h-12 md:h-8 md:space-x-10'>
              <div className='flex justify-end lg:w-0 lg:flex-1'>
                <div className='md:hidden'>
                  <img
                    src={DroovLogo}
                    alt='Droov Logo'
                    className='h-6 w-auto opacity-20'
                  />
                </div>
              </div>
              <div className='md:hidden'>
                <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-inset focus:droov'>
                  <span className='sr-only'>{t('open_menu.label')}</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                </Popover.Button>
              </div>
              <div className='hidden md:absolute md:right-36 md:block'>
                <FeedbackButton openModal={() => setOpenFeedbackModal(true)} />
                <FeedbackModal
                  open={openFeedbackModal}
                  onClose={() => setOpenFeedbackModal(false)}
                />
              </div>
              <div className='hidden md:absolute md:right-4 md:block'>
                <LogoutButton />
              </div>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter='duration-200 ease-out'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='duration-100 ease-in'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Popover.Panel
              focus
              static
              className='absolute top-0 inset-x-0 transition ease-in-out duration-100 transform origin-top-right md:hidden'
            >
              <div className='shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-100 z-30'>
                <div className='pt-2 pb-3 px-5'>
                  <div className='flex items-center justify-between'>
                    <div className='mt-3'>
                      <MyStoreButtonComponent hideSideBar={true} />
                    </div>
                    <div className='-mr-2'>
                      <Popover.Button className='bg-white rounded-md pr-2 mt-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-inset focus:droov'>
                        <span className='sr-only'>Close menu</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-8 w-8'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col items-start '>
                  {NavLinks.map(
                    (navlink, index) =>
                      !navlink.hideFromNavbar && (
                        <Popover.Button
                          key={index}
                          className='flex flex-row w-full text-lg py-1'
                        >
                          <NavLink
                            onClick={() => close()}
                            activeClassName='text-droov w-full h-full '
                            to={navlink.path}
                            className='w-full text-gray-600'
                          >
                            <div className='w-full h-full py-2'>
                              {t(navlink.name)}
                            </div>
                          </NavLink>
                        </Popover.Button>
                      )
                  )}
                  <FeedbackButton
                    openModal={() => setOpenFeedbackModal(true)}
                    hideIcon={true}
                    additionalClassName={'text-gray-600 text-lg'}
                  />
                  <LogoutButton />
                  <br />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Fragment>
      )}
    </Popover>
  );
}
