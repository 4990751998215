import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CustomInputField from 'ui-components/CustomInputField';
import { useTranslation } from 'react-i18next';

export default function PreparationTimeCard({ open, setOpen, submit }: any) {
  const times = [1, 2, 3, 5, 7, 10, 15, 20, 30];
  const [value, setValue] = useState(-2);
  const [customValue, setCustomValue] = useState('');
  const { t } = useTranslation(['orders']);

  const nonCustom = (time: any) => {
    setCustomValue('');
    setValue(time);
  };
  const custom = () => {
    setCustomValue('');
    setValue(-1);
  };

  const cancel = () => {
    setValue(-2);
    setCustomValue('');
    setOpen(false);
  };

  const accept = () => {
    if (value === -1) {
      submit(parseInt(customValue));
    } else {
      submit(value);
    }
    cancel();
  };

  const isDisabled = !customValue && (!value || value === -2);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        open={open}
        onClose={cancel}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='p-6 inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg'>
              <h1 className='text-lg flex items-center'>
                <b>{t('time_to_prep.label')}</b>
              </h1>
              <p className='text-gray-400 mt-2'>{t('select_time.label')}</p>
              <div className='pt-8 pb-2 grid grid-cols-2 md:grid-cols-3 gap-4'>
                {times.map((time) => (
                  <div
                    onClick={() => nonCustom(time)}
                    key={`time-${time}`}
                    className={`flex items-center justify-between transition duration-200 rounded-lg shadow col-span-1 px-3 py-1.5 cursor-pointer hover:shadow-md ${
                      time === value
                        ? 'border border-droov shadow-none'
                        : 'border border-white'
                    }`}
                  >
                    <div className='flex items-center'>
                      <span>
                        {time} {t('min.label')}
                      </span>
                    </div>

                    {time === value ? (
                      <div className='w-6 h-6'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='text-droov'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          stroke='currentColor'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className='w-6 h-6' />
                    )}
                  </div>
                ))}
                <div
                  onClick={() => custom()}
                  key={`time-custom`}
                  className={`flex md:hidden items-center justify-between transition duration-200 rounded-lg shadow col-span-1 px-3 py-1.5 cursor-pointer hover:shadow-md ${
                    -1 === value
                      ? 'border border-droov shadow-none'
                      : 'border border-white'
                  }`}
                >
                  <div className='flex items-center'>
                    <span>Custom</span>
                  </div>

                  {value === -1 ? (
                    <div className='w-6 h-6'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='text-droov'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        stroke='currentColor'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className='w-6 h-6' />
                  )}
                </div>
              </div>

              <div className='hidden md:grid grid-cols-2 pt-2 md:grid-cols-3 gap-4'>
                <div>&nbsp;</div>
                <div
                  onClick={() => custom()}
                  key={`time-custom`}
                  className={`flex items-center justify-between transition duration-200 rounded-lg shadow col-span-1 px-3 py-1.5 cursor-pointer hover:shadow-md ${
                    -1 === value
                      ? 'border border-droov shadow-none'
                      : 'border border-white'
                  }`}
                >
                  <div className='flex items-center'>
                    <span>Custom</span>
                  </div>

                  {value === -1 ? (
                    <div className='w-6 h-6'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='text-droov'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        stroke='currentColor'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className='w-6 h-6' />
                  )}
                </div>
                <div>&nbsp;</div>
              </div>

              <div className='mt-4 grid grid-cols-3 gap-4'>
                <div>&nbsp;</div>
                {value === -1 && (
                  <div className='flex justify-center items-center'>
                    <div className='w-full relative rounded-md '>
                      <CustomInputField
                        min='1'
                        max='100'
                        required={true}
                        onChange={(e) => {
                          setCustomValue(e.target.value)
                        }}
                        type='number'
                        name='customTime'
                        id='customTime'
                        value={customValue}
                        classnameadditional='pl-4'
                        placeholder='0'
                      />
                      <div className='absolute inset-y-0 top-1 right-3 flex items-center'>
                        <div>{t('min.label')}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div>&nbsp;</div>
              </div>

              <div className='mt-6 flex justify-end'>
                <button
                  type='button'
                  className='cancel_button mr-2'
                  onClick={cancel}
                >
                  {t('cancel.button')}
                </button>
                <button
                  type='button'
                  className='relative_blue_button h-9 w-24'
                  disabled={isDisabled}
                  onClick={accept}
                >
                  {t('accept.label')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
