export default function ColorPicker({
  onColorSelected,
  selectedColor,
  showName,
}: any) {
  return (
    <div className="w-full py-8 grid grid-cols-2 md:grid-cols-4 gap-4">
      {colors.map((color) => (
        <div
          onClick={() => {
            onColorSelected(color.hex);
          }}
          key={color.name}
          className={`flex items-center justify-between transition duration-200 rounded-lg shadow col-span-1 pl-1.5 pr-2 py-1.5 cursor-pointer hover:shadow-md ${
            color.hex === selectedColor
              ? "border border-droov shadow-none"
              : "border border-white"
          }`}
        >
          <div className="flex items-center">
            <div
              style={{ backgroundColor: color.hex }}
              className="w-8 h-8 rounded-md"
            />
            {showName && <span className="ml-2">{color.name}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

const colors = [
  {
    name: "Lime",
    hex: "#84cc16",
  },
  {
    name: "Sky",
    hex: "#0ea5e9",
  },

  {
    name: "Yellow",
    hex: "#eab308",
  },
  {
    name: "Red",
    hex: "#ef4444",
  },
  {
    name: "Purple",
    hex: "#a855f7",
  },
  {
    name: "Orange",
    hex: "#f97316",
  },
  {
    name: "Teal",
    hex: "#14b8a6",
  },
  {
    name: "Stone",
    hex: "#78716c",
  },
];
