import moment, { Moment } from 'moment/moment';

export default function NInterval(
  dayInterval: {
    start: moment.Moment,
    end: moment.Moment
  },
  entityType: 'hour'
): string[] {
  const hoursOfDay = [];
  let currentHour = dayInterval.start.clone();
  while (currentHour.isSameOrBefore(dayInterval.end)) {
    hoursOfDay.push(currentHour.format('HH:mm'));
    currentHour.add(1, entityType);
  }
  return hoursOfDay;
}


export const dayInterval = (shiftDay: (m: Moment) => Moment = (m) => m) => {
  return  {
    start: shiftDay(moment()).startOf('day'),
    end: shiftDay(moment()).endOf('day'),
  };
}