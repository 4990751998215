import { uploadProfileImg } from 'firebase-logic/couriers';
import useAuthState from 'global-states/useAuthState';
import { useEffect, useState } from 'react';
import CoverCropDialog from '../../CropDialog';
import ImageUpload from 'ui-components/ImageUpload';
import NoImage from '../../../assets/noImage.png';
import { v4 as uuidv4 } from 'uuid';
import { CancelButton } from 'ui-components/CancelButton';
import { searchAddonImages } from 'firebase-logic/useCatalogue';
import CustomInputField from 'ui-components/CustomInputField';

export default function ShowOtherAddonImages({
  openImageGalery,
  index,
  addonsForm,
  setAddonsForm,
  toggleOpenImageGalery,
  setFilteredImages,
  addonsImages,
  imagesForDelete,
  setImagesForDelete,
  uploadedImages,
  setUploadedImages,
}: any) {
  const auth: any = useAuthState.getState();
  const [addonImgFile, setAddonImgFile] = useState<any>(null);
  const [showCropDialog, setShowCropDialog] = useState<boolean>(false);
  const [isImgDeleted, setIsImgDeleted] = useState(false);
  const [searchedImages, setSearchedImages] = useState<any>([]);
  const [searchText, setSearchText] = useState('');

  const handleImageSearch = async (searchText: any) => {
    const imageResults = await searchAddonImages(searchText);
    setSearchedImages(imageResults);
  };
  useEffect(() => {
    if (searchText && searchText.length > 1) {
      handleImageSearch(searchText.toLocaleLowerCase().trim());
    } else if (searchText === '') {
      setSearchedImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const markForDeletion = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAddonImgFile(null);
    setIsImgDeleted(true);
    let imgId = addonsForm.items[index].imgId
      ? addonsForm.items[index].imgId
      : '';
    addonsForm.items[index].imgURL = '';
    setAddonsForm(addonsForm);
    setImagesForDelete([...imagesForDelete, imgId]);
  };

  const handleCoverChange = (imgFile: any) => {
    setAddonImgFile(imgFile);
    setShowCropDialog(true);
  };

  const handleCropSave = async (imgFile: any) => {
    setShowCropDialog(false);
    setAddonImgFile(imgFile);
    setIsImgDeleted(false);
    if (imgFile) {
      const imgId = uuidv4();
      const addonImgUrl = await uploadProfileImg(
        auth.user,
        imgFile,
        'addons',
        imgId
      );
      setUploadedImages([...uploadedImages, imgId]);
      addonsForm.items[index].imgURL = addonImgUrl;
      addonsForm.items[index].imgId = imgId;
      setAddonsForm(addonsForm);
      toggleOpenImageGalery(-1);
      setFilteredImages([]);
    }
  };

  return (
    <div className='relative'>
      {openImageGalery === index && (
        <div className='flex flex-col mt-2 mx-4 bg-gray-200 rounded-lg relative'>
          <div className='w-64 sm:w-60 h-11 ml-4 my-3'>
            <div className='relative rounded-md shadow-sm'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <span className='text-gray-500 sm:text-sm'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth='2'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                    />
                  </svg>
                </span>
              </div>
              <CustomInputField
                classnameadditional=' placeholder-italic  pl-9  mr-2 mt-1'
                name='addon-search'
                type='text'
                maxLength={45}
                placeholder='e.g Avocado'
                step='0.01'
                value={searchText}
                id='addon-search'
                onChange={(e: any) => setSearchText(e.target.value)}
                required={false}
              />
            </div>
          </div>
          <div className='grid grid-cols-5 sm:grid-cols-7 mx-2 '>
            <button
              type='button'
              className='absolute -top-1 -right-1 bg-red-400 rounded-full z-30 text-white focus:outline-none'
              onClick={() => toggleOpenImageGalery(-1)}
            >
              <CancelButton />
            </button>
            <div>
              <button
                type='button'
                className='h-12 w-12 border hover:border-droov rounded-lg m-2 bg-white flex items-center justify-center'
                key={index}
                name='imageURL'
                value='default'
                onClick={() => {
                  delete addonsForm.items[index].imgURL;
                  setAddonsForm(addonsForm);
                  toggleOpenImageGalery(-1);
                  setFilteredImages([]);
                }}
              >
                <img
                  className='m-1 rounded-lg object-contain'
                  src={NoImage}
                  alt='default'
                />
              </button>
            </div>

            <div>
              <div className='h-12 w-12 border hover:border-droov rounded-lg m-2 mb-4 bg-white flex items-center justify-center'>
                <ImageUpload
                  imgURL={
                    addonsForm.items[index].imgId
                      ? addonsForm.items[index]?.imgURL
                      : null
                  }
                  onDeleted={(e: any) => markForDeletion(e)}
                  imgFile={addonImgFile}
                  onImgChanged={(imgFile: any) => handleCoverChange(imgFile)}
                  item='addonImg'
                  width={10}
                  height={10}
                  isImgDeleted={isImgDeleted}
                  onAlreadySelected={() => {
                    if (addonsForm.items[index].imgId && !isImgDeleted) {
                      toggleOpenImageGalery(-1);
                      setFilteredImages([]);
                    }
                  }}
                />
              </div>
            </div>
            {searchedImages.length > 0 &&
              searchedImages.map((image: any) => (
                <div key={image.imgURL}>
                  <button
                    type='button'
                    className='h-12 w-12 border hover:border-droov rounded-lg m-2 mb-4 bg-white flex items-center justify-center'
                    key={image.imgURL}
                    name='imageURL'
                    value={image.imgURL}
                    onClick={() => {
                      let imgId = addonsForm.items[index].imgId
                        ? addonsForm.items[index].imgId
                        : '';
                      setImagesForDelete([...imagesForDelete, imgId]);
                      addonsForm.items[index].imgURL = image.imgURL;
                      addonsForm.items[index].imgId = null;
                      setAddonsForm(addonsForm);
                      toggleOpenImageGalery(-1);
                      setFilteredImages([]);
                    }}
                  >
                    <img
                      className='m-1 rounded-lg object-contain'
                      src={image.imgURL}
                      alt={image.imgURL}
                    />
                  </button>
                </div>
              ))}
          </div>
        </div>
      )}
      {showCropDialog && (
        <CoverCropDialog
          imgFile={addonImgFile}
          cropInit={{ x: 0, y: 0 }}
          zoomInit={1}
          aspectInit={1}
          openCropImageDialog={showCropDialog}
          onCancel={() => setShowCropDialog(!showCropDialog)}
          onSaveCrop={handleCropSave}
        />
      )}
    </div>
  );
}
