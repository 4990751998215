import DragableCatalogueItem from '../ui-components/catalogue-components/product-components/DragableCatalogueItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toggle from '../utils/toggleArray';
import { CategoryInputCard } from '../ui-components/catalogue-components/category-components/CategoryInputCard';
import ProductInputCard, {
  CreateProductDashed,
} from '../ui-components/catalogue-components/product-components/ProductInputCard';
import CategoryHeader from '../ui-components/catalogue-components/category-components/CategoryHeader';
import ImageCropDialog from '../ui-components/catalogue-components/product-components/ImageCropDialog';
import { useCatalogue } from '../firebase-logic/useCatalogue';
import { Category } from '../types';
import useAuthState from '../global-states/useAuthState';
import { Transition } from '@headlessui/react';
import { useState, Fragment, useEffect, Dispatch } from 'react';
import CustomHeaderComponent from '../ui-components/CustomHeaderComponent';
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';
import Configurator from '../ui-components/catalogue-components/configurator-components/Configurator';
import { ReactComponent as ConfiguratorButton } from '../assets/configuratorButton.svg';
import { fetchAddonsImages } from '../firebase-logic/useCatalogue';
import AddCategory from '../ui-components/catalogue-components/category-components/AddCategory';
import { useTranslation } from 'react-i18next';

type CategoryContentProps = {
  categoryId: string;
  products: any;
  visibleCategories: unknown[];
  openCreateAProduct: string;
  setOpenCreateAProduct: Dispatch<any>;
  createProduct: (...args: unknown[]) => void;
  editProduct: string;
  showNetto: boolean;
  setEditProduct: Dispatch<any>;
  deleteProduct: (...args: unknown[]) => void;
  updateAProduct: (...args: unknown[]) => void;
  catalogue: any;
  setCropImage: Dispatch<any>;
  addonsImages: string[];
  setSizes: (...args: unknown[]) => void;
  deleteSizes: (...args: unknown[]) => void;
  deleteAddons: (...args: unknown[]) => void;
  addAddons: (...args: unknown[]) => void;
  updateProductVisibility: (...args: unknown[]) => void;
  addLabels: (...args: unknown[]) => void;
};
const CategoryContent = ({
  categoryId,
  products,
  visibleCategories,
  openCreateAProduct,
  setOpenCreateAProduct,
  createProduct,
  editProduct,
  showNetto,
  setEditProduct,
  deleteProduct,
  updateAProduct,
  catalogue,
  setCropImage,
  setSizes,
  deleteSizes,
  deleteAddons,
  addAddons,
  updateProductVisibility,
  addLabels,
  addonsImages,
}: CategoryContentProps) => {
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey((prevState) => prevState + 1);
  }, [catalogue]);
  return (
    <div>
      <Transition
        appear={true}
        show={visibleCategories.includes(categoryId)}
        as={Fragment}
        enter='transform transition ease-in-out duration-1000'
        enterFrom='-translate-y-4 opacity-0'
        enterTo='-translate-y-0'
      >
        <div>
          <div className='ml-8 md:ml-12'>
            {openCreateAProduct === categoryId ? ( //it shows the add new product icon or the form field to add a product
              <ProductInputCard
                setOpenCreateAProduct={setOpenCreateAProduct}
                catId={categoryId}
                createProduct={createProduct}
                showNetto={showNetto ?? false}
              />
            ) : (
              <CreateProductDashed
                onsetEditCategory={() => setOpenCreateAProduct(categoryId)}
              />
            )}
          </div>
          <Droppable droppableId={categoryId} type='product'>
            {(provided: any) => (
              <div
                className='ml-8 md:ml-12'
                style={{ minHeight: '50px' }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {products?.map((productId: string, index: number) => {
                  return editProduct === productId ? (
                    <ProductInputCard //Edit product
                      key={`${productId}-${key}`}
                      catId={categoryId}
                      setEditProduct={setEditProduct}
                      deleteProduct={deleteProduct}
                      updateAProduct={updateAProduct}
                      product={catalogue.products[productId]}
                      showNetto={showNetto ?? false}
                    />
                  ) : (
                    <DragableCatalogueItem
                      product={catalogue.products[productId]}
                      index={index}
                      setEditProduct={setEditProduct}
                      setCropImage={setCropImage}
                      updateAProduct={updateAProduct}
                      configurator={catalogue.configurator}
                      addonsImages={addonsImages}
                      setSizes={setSizes}
                      deleteSizes={deleteSizes}
                      deleteAddons={deleteAddons}
                      addAddons={addAddons}
                      updateProductVisibility={updateProductVisibility}
                      addLabels={addLabels}
                      key={`${productId}-${key}`}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </Transition>
    </div>
  );
};

const CatalogueScreen = () => {
  const auth = useAuthState.getState();
  const [openConfigurator, setOpenConfigurator] = useState(
    window.innerWidth > 800
  );

  const { t } = useTranslation(['catalogue']);

  const [openCreateAProduct, setOpenCreateAProduct] = useState('');

  const [editCategory, setEditCategory] = useState(''); //it holds the ID of the category that is being updated
  const [editProduct, setEditProduct] = useState(''); //it holds the ID of the product that is being updated

  const [addonsImages, setAddonsImages] = useState([]);

  const toggleSideBar = () => {
    setOpenConfigurator(!openConfigurator);
  };

  useEffect(() => {
    const wrapper = async () => {
      const result = await fetchAddonsImages();
      setAddonsImages(result);
    };
    wrapper();
  }, []);

  /* cropImage is not null only when the upload image process is started.
   * image - holds the original file without being cropped
   */
  const [cropImage, setCropImage] = useState({ image: null, productId: '' });
  const [
    catalogue,
    visibleCategories,
    setVisibleCategories,
    onDragEnded,
    updateCategory,
    updateAProduct,
    deleteProduct,
    createCategory,
    deleteCategory,
    loading,
    deleteSizes,
    deleteAddons,
    addAddons,
    setSizes,
    updateCategoryVisibility,
    createProduct,
    updateProductVisibility,
    addLabels,
  ] = useCatalogue(auth.user, auth.showNetto);

  if (loading) return <CustomLoadingScreen />;

  //it could be separate components but too many props needed to be given to the component
  const showCategoryHeader = (category: Category, provided: any) => {
    return (
      <>
        {editCategory === category.id ? (
          <CategoryInputCard //edit category form
            category={category}
            updateCategory={updateCategory}
            deleteCategory={deleteCategory}
            setEditCategory={setEditCategory}
            onCancelClicked={() => setEditCategory('')}
          />
        ) : (
          <CategoryHeader
            updateCategoryVisibility={updateCategoryVisibility}
            provided={provided}
            category={category}
            expanded={visibleCategories.includes(category.id)}
            setEditCategory={setEditCategory}
            onHeaderClicked={() => {
              setVisibleCategories(toggle(visibleCategories, category.id));
            }}
          />
        )}
      </>
    );
  };

  //it could be separate components but too many props needed to be given to the component

  return (
    <div className='flex flex-column'>
      <DragDropContext onDragEnd={onDragEnded}>
        <>
          <div className='w-full lg:w-2/3'>
            <CustomHeaderComponent heading={t('catalogue.label')} />

            <AddCategory createCategory={createCategory} />

            {/* The biggest droppable, containing all category headers and their contents (if expanded).
             *Helps to re-order categories
             */}
            <Droppable droppableId='categories' type='cat'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {catalogue?.categoriesOrder?.map(
                    (catId: string, index: number) => {
                      const category = catalogue.categories[catId];
                      return (
                        <Draggable
                          draggableId={category.id}
                          index={index}
                          key={category.id}
                        >
                          {(
                            provided //category draggable
                          ) => (
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              {showCategoryHeader(category, provided)}
                              {visibleCategories.includes(category.id) && (
                                // categoryContent(category.id, category.products)}
                                <CategoryContent
                                  categoryId={category.id}
                                  products={category.products}
                                  visibleCategories={visibleCategories}
                                  openCreateAProduct={openCreateAProduct}
                                  setOpenCreateAProduct={setOpenCreateAProduct}
                                  createProduct={createProduct}
                                  editProduct={editProduct}
                                  showNetto={auth.showNetto}
                                  setEditProduct={setEditProduct}
                                  deleteProduct={deleteProduct}
                                  updateAProduct={updateAProduct}
                                  catalogue={catalogue}
                                  setCropImage={setCropImage}
                                  addonsImages={addonsImages}
                                  setSizes={setSizes}
                                  deleteSizes={deleteSizes}
                                  deleteAddons={deleteAddons}
                                  addAddons={addAddons}
                                  updateProductVisibility={
                                    updateProductVisibility
                                  }
                                  addLabels={addLabels}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className='hidden sm:inline-flex sm:w-1/3'>
            {!openConfigurator && (
              <div
                className='sm:absolute sm:ml-8 mt-2 bg-white sm:h-12 sm:w-12 duration-300 rounded-full flex items-center justify-center hover:bg-gray-200'
                onClick={toggleSideBar}
              >
                <ConfiguratorButton />
              </div>
            )}
            <>
              <Configurator
                openConfigurator={openConfigurator}
                toggleSideBar={toggleSideBar}
                onDragEnded={onDragEnded}
                addAddons={addAddons}
                deleteSizes={deleteSizes}
                setSizes={setSizes}
                deleteAddons={deleteAddons}
                catalogue={catalogue}
                addonsImages={addonsImages}
              />
            </>
          </div>
        </>
      </DragDropContext>

      {/* It opens image cropper only if the user has started the uploading process */}
      {cropImage.image !== null && (
        <ImageCropDialog
          imageUrl={URL.createObjectURL(cropImage.image)}
          cropInit={{ x: 0, y: 0 }}
          zoomInit={1}
          aspectInit={1 / 1.5}
          onCancel={() => setCropImage({ image: null, productId: '' })}
          setCropImage={setCropImage}
          productId={cropImage.productId}
          updateAProduct={updateAProduct}
          mobileView={window.innerWidth < 500 ? true : false}
        />
      )}
    </div>
  );
};

export default CatalogueScreen;
