import React, { useEffect, useState } from 'react';
import { ReactComponent as ImageUploadIcon } from '../assets/imageUploadIcon.svg';
import { CancelButton } from './CancelButton';

export default function UploadCover({
  imgURL,
  imgFile,
  onImgChanged,
  onDeleted,
  item,
}: any) {
  const [imgUrl, setImgUrl]: any = useState(imgURL);

  const transformIntoImg = () => {
    if (!imgFile) {
      return;
    }

    let reader = new FileReader();
    reader.onload = (e) => setImgUrl(e.target?.result);

    reader.readAsDataURL(imgFile);
  };

  useEffect(() => {
    setImgUrl(imgURL);
  }, [imgURL, imgFile]);

  useEffect(() => {
    transformIntoImg();
    // eslint-disable-next-line
  }, [imgFile]);

  const labelItem = (
    <label htmlFor={item}>
      <input
        type='file'
        id={item}
        name={`${item} image`}
        accept='image/png, image/jpeg'
        onChange={(e: any) => {
          onImgChanged(e.target.files?.[0]);
          e.target.value = null; //needed to be able to upload the same file again
        }}
        className='hidden'
      />
      {imgUrl ? (
        <div className='w-24 h-40 sm:w-32 flex justify-center'>
          <div className='w-full h-full flex justify-center items-center text-white font-Ubuntu font-medium text-3xl opacity-0 hover:opacity-90 bg-gray-500 duration-500 rounded-lg transform cursor-pointer absolute'>
            Change {item}
          </div>
          <button
            type='button'
            className='absolute top-1 right-1 bg-red-400 rounded-full z-10 text-white focus:outline-none'
            onClick={onDeleted}
          >
            <CancelButton />
          </button>
        </div>
      ) : (
        <div className='w-24 sm:w-32 flex flex-col items-center justify-center transform duration-300 customPopup cursor-pointer'>
          <ImageUploadIcon />
          <p className='mt-2 text-xs'>+ Upload {item}</p>
        </div>
      )}
    </label>
  );

  return (
    <div className='flex justify-center'>
      <div
        className={`flex items-center justify-center mt-3 ${
          item === 'cover' ? 'w-full' : 'w-40'
        } relative h-40 rounded-lg`}
        style={{
          backgroundImage: imgUrl ? `url(${imgUrl})` : '',
          backgroundSize: 'cover',
        }}
      >
        {labelItem}
      </div>
    </div>
  );
}
