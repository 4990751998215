import { useTranslation } from 'react-i18next';
import { Subscription, SubscriptionStatus } from '../../types';
import useAuthState from '../../global-states/useAuthState';
import { Dispatch, SetStateAction, useState } from 'react';

type SubscriptionInfoProps = {
  subscription: Subscription;
  onHandleGetBillingPortal: (
    setDisabled: Dispatch<SetStateAction<boolean>>
  ) => Promise<void>;
};

export const SubscriptionInfo = (props: SubscriptionInfoProps) => {
  const auth: any = useAuthState.getState();
  const [disabled, setDisabled] = useState<boolean>(false);

  const { t } = useTranslation(['onboarding']);

  return (
    <div className='bg-white p-4 flex flex-col items-end rounded-lg mt-7 max-w-2xl'>
      <div className='flex flex-col gap-2'>
        <span className='text-base leading-5 flex flex-wrap sm:flex-row items-center gap-3'>
          <h1>{t('your_subscription_status.label')}</h1>
          <div className='flex flex-row gap-1 items-center'>
            {props.subscription?.status === SubscriptionStatus.active ? (
              <div className='text-green-500 font-bold'>
                {t(props.subscription?.status + '.label')}
              </div>
            ) : props.subscription?.status === SubscriptionStatus.trialing ? (
              <div
                className='rounded-button text-sm flex justify-center items-center w-auto
              text-white bg-droov h-7 px-4'
              >
                {t(props.subscription?.status + '.label')}
              </div>
            ) : (
              <div
                className='rounded-button text-sm flex justify-center items-center w-auto
                  text-white bg-red_text h-7 px-4'
              >
                {t(props.subscription?.status + '.label')}
              </div>
            )}
          </div>
        </span>
        <span className='droov_text_description_light mt-1 leading-5'>
          {props.subscription?.status === 'trialing'
            ? t('status_comment_1.label') +
              props.subscription?.current_period_end?.toLocaleDateString(
                auth?.companyLanguage || 'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              ) +
              t('status_comment_2.label')
            : props.subscription?.status === 'canceled'
            ? t('status_comment_3.label') +
              props.subscription?.canceled_at?.toLocaleDateString(
                auth?.companyLanguage || 'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )
            : t('status_comment_4.label') +
              props.subscription?.current_period_end?.toLocaleDateString(
                auth?.companyLanguage || 'en-US',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )}
        </span>
      </div>
      <div className='justify-end'>
        <button
          className='blue_button w-full justify-end'
          disabled={disabled}
          onClick={async () => {
            setDisabled(true);
            await props.onHandleGetBillingPortal(setDisabled);
          }}
        >
          {t('manage_subscription.label')}
        </button>
      </div>
    </div>
  );
};
