import CustomInputField from './CustomInputField';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export default function BusinessInfoComponent({
  settingsContactObject,
  setSettingsContactObject,
}: any) {
  const { t } = useTranslation(['appearance']);

  const translationObject: any = {
    storeName: t('storename.label'),
    storeDescription: t('storedescription.label'),
    contactEmail: t('contactemail.label'),
    telephone: t('telephonenumber.label'),
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedData = { ...settingsContactObject, [name]: value };
    setSettingsContactObject(updatedData, translationObject?.[name], value);
  };

  return (
    <>
      <div className='lg:flex w-full mb-6'>
        <div className='flex-1 md:pr-4'>
          {/*Personal details*/}
          <div className='w-full bg-white p-4 rounded-md'>
            <h1>{t('businessinformation.label')}</h1>
            <p className='droov_text_description_light'>
              {t('businessinformation.text')}
            </p>
            <br />
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 lg:col-span-6'>
                <label className='droov_label_text'>
                  {t('storename.label')}
                </label>
                <CustomInputField
                  name={'storeName'}
                  placeholder={t('storename.label')}
                  value={settingsContactObject.storeName}
                  onChange={handleInputChange}
                  ref={null}
                  maxLength={30}
                  type='text'
                  id='storeName'
                  required={true}
                />
              </div>

              <div className='col-span-6 lg:col-span-6'>
                <label className='droov_label_text'>
                  {t('storedescription.label')}
                </label>
                <CustomInputField
                  name={'storeDescription'}
                  placeholder={t('storedescription.label')}
                  value={settingsContactObject.storeDescription}
                  onChange={handleInputChange}
                  ref={null}
                  maxLength={90}
                  type='text'
                  id='storeDescription'
                  required={false}
                />
              </div>

              <div className='col-span-6 lg:col-span-3'>
                <label className='droov_label_text'>
                  {t('contactemail.label')}
                </label>
                <CustomInputField
                  name={'contactEmail'}
                  placeholder={t('contactemail.label')}
                  value={settingsContactObject.contactEmail}
                  onChange={handleInputChange}
                  ref={null}
                  type='email'
                  id='contactEmail'
                  required={true}
                />
              </div>

              <div className='col-span-6 lg:col-span-3 pb-4'>
                <label className='droov_label_text'>
                  {t('telephonenumber.label')}
                </label>
                <CustomInputField
                  name={'telephone'}
                  value={settingsContactObject.telephone}
                  placeholder='+49 12345 ...'
                  onChange={handleInputChange}
                  ref={null}
                  type='tel'
                  id='phoneNumber'
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
