export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort((a, b) => (a.refId > b.refId ? 1 : -1));
  const sortedArr2 = [...arr2].sort((a, b) => (a.refId > b.refId ? 1 : -1));

  return sortedArr1.every(
    (value, index) => value.refId === sortedArr2[index].refId
  );
};
