import { useTranslation } from 'react-i18next';
import useAuthState from '../../../global-states/useAuthState';
import moment from 'moment/moment';
import React, { MouseEventHandler } from 'react';
import { FirebaseCateringRequest } from './types';

interface CateringRequestRowProps {
  cateringRequest: FirebaseCateringRequest;
  onClicked: (orderId: string) => void;
}

export const CateringRequestRow: React.FC<CateringRequestRowProps> = (
  props
) => {
  const { t } = useTranslation(['catering']);
  const auth: any = useAuthState.getState();

  const id = props.cateringRequest.requestID.toUpperCase().toString();

  moment.locale(auth.companyLanguage);

  const handleTableRowClick: MouseEventHandler<HTMLTableRowElement> = () => {
    if (props.cateringRequest.requestID) {
      props.onClicked(props.cateringRequest.requestID);
    }
  };

  return (
    <tr
      className='flex w-full cursor-pointer bg-white hover:bg-gray-100'
      onClick={handleTableRowClick}
    >
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-items-start'>
        <p className='text-sm text-left font-medium text-blue-400 flex align-middle items-center'>
          {id}
        </p>
      </td>
      <td className='px-2 py-3 w-4/12 align-middle overflow-auto'>
        <p className='text-sm text-left text-gray-900'>
          {props.cateringRequest.customer.name}
        </p>
        <p className='text-sm text-left text-gray-500'>
          {props.cateringRequest.customer.email}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-items-start'>
        <p className='text-sm text-left text-gray-500 items-center flex align-middle'>
          {props.cateringRequest.eventTimeStamp &&
            moment(props.cateringRequest.eventTimeStamp).format('ddd D MMMM')}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-center'>
        <p className='text-sm text-left text-gray-500 flex align-middle items-center'>
          {props.cateringRequest.peopleNumber}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-center'>
        <p className='text-sm text-left text-gray-500 flex align-middle items-center'>
          <div className='flex justify-center w-full'>
            {props.cateringRequest.requestStatus === 'received' && (
              <div className='rounded-lg w-[8/12] h-7 bg-light_blue opacity-80 flex justify-center items-center text-gray-700'>
                <span className='p-2 text-blue-900'>{t('pending.label')}</span>
              </div>
            )}
            {props.cateringRequest.requestStatus === 'confirmed' && (
              <div className='rounded-lg w-[8/12] h-7 bg-light_green flex opacity-80 justify-center items-center text-gray-700'>
                <span className='p-2 text-green-900'>
                  {t('confirmed_status.label')}
                </span>
              </div>
            )}
            {props.cateringRequest.requestStatus === 'rejected' && (
              <div className='rounded-lg w-[8/12] h-7 bg-red-100 flex opacity-80 justify-center items-center text-gray-700'>
                <span className='p-2 text-red-900'>
                  {t('rejected_status.label')}
                </span>
              </div>
            )}
          </div>
        </p>
      </td>
    </tr>
  );
};
