import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import EditIcon from '../../EditIcon';
import { useState, useRef, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import ExpandArrow from '../../ExpandArrow';
import useAuthState from '../../../global-states/useAuthState';
import { CatalogueAPI } from '../../../api/catalogue';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { darkToastStyle } from 'utils/toastStyle';

export default function AddonGroupCard({
  cardElement,
  index,
  setAddonToEdit,
  setOpenAddAddonsModal,
  addonId,
}: any) {
  const { t } = useTranslation(['catalogue']);
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const auth: any = useAuthState.getState();

  const [addonElement, setAddonElement] = useState<any>(cardElement);

  let addOnToastID: string;

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isExpanded, addonElement.items]);

  useEffect(() => {
    setAddonElement(cardElement);
  }, [cardElement]);

  const onAddonGroupVisibilityChangeHandler = () => {
    addOnToastID = toast.loading(t('visibility_loading.label'), {
      style: darkToastStyle,
    });
    const visibility = addonElement.visible === false;
    setAddonElement((prev: any) => ({ ...prev, visible: visibility }));
    cardElement.visible = visibility;
    debouncedUpdateAddonVisibility(visibility);
  };
  const onAddonItemVisibilityChangeHandler = (itemIndex: number) => {
    addOnToastID = toast.loading(t('visibility_loading.label'), {
      style: darkToastStyle,
    });
    const visbility = addonElement.items[itemIndex].visible === false;
    const newAddonElement = { ...addonElement };
    newAddonElement.items[itemIndex].visible = visbility;
    setAddonElement(newAddonElement);
    cardElement.items[itemIndex].visible = visbility;
    updateAddonItemVisibility(itemIndex, visbility);
  };

  const debouncedUpdateAddonVisibility = debounce((isVisible: boolean) => {
    CatalogueAPI.updateAddonGroupVisibility(addonId, isVisible)
      .then(() => {
        toast.success(t('visibility_updated.label'), {
          id: addOnToastID,
        });
      })
      .catch((error) => {
        toast.error(t('visibility_update_failed.label'), {
          id: addOnToastID,
        });
        console.error('Error updating addon visibility:', error);
      });
  }, 200);

  const updateAddonItemVisibility = debounce(
    (itemIndex: number, isVisible: boolean) => {
      CatalogueAPI.updateAddonItemVisibility(addonId, itemIndex, isVisible)
        .then(() => {
          toast.success(t('visibility_updated.label'), {
            id: addOnToastID,
          });
        })
        .catch((error) => {
          toast.error(t('visibility_update_failed.label'), {
            id: addOnToastID,
          });
          console.error('Error updating addon item visibility:', error);
        });
    },
    200
  );

  return (
    <Draggable
      key={addonElement.title + index}
      draggableId={addonId}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='border rounded-xl mt-2 bg-white z-40 transition-all duration-200 ease-in-out'
          style={{
            ...provided.draggableProps.style,
            maxHeight: isExpanded ? `${contentHeight + 100}px` : '50px',
            overflow: 'hidden',
          }}
        >
          <div
            className='flex items-center w-full p-2'
            {...provided.dragHandleProps}
          >
            <div className='flex justify-start items-center mr-2'>
              <DotsIcon className='w-6 h-6' />
            </div>
            <b
              className={`${
                addonElement.visible !== false
                  ? 'text-gray-800'
                  : 'text-gray-400'
              } w-4/6 flex justify-start items-center text-md mr-1 line-clamp-1`}
            >
              {addonElement.title}
            </b>
            <div className='flex items-center mr-1.5'>
              <Switch
                checked={addonElement.visible !== false}
                onChange={onAddonGroupVisibilityChangeHandler}
                className={`${
                  addonElement.visible !== false
                    ? 'bg-green_chip'
                    : 'bg-gray-200'
                } inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
              >
                <span
                  className={`${
                    addonElement.visible !== false
                      ? 'translate-x-6'
                      : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
                />
              </Switch>
              <div
                className='w-10 ml-2 flex justify-end items-center'
                onClick={(e) => {
                  e.stopPropagation();
                  setAddonToEdit(index);
                  setOpenAddAddonsModal(true);
                }}
              >
                <EditIcon />
              </div>
              <div
                className='w-6 h-6 ml-2 flex items-center cursor-pointer '
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
              >
                <ExpandArrow styles='h-4 w-4 mx-auto' expanded={isExpanded} />
              </div>
            </div>
          </div>

          <div
            ref={contentRef}
            className={`transition-opacity duration-300 ease-in-out mb-2 ${
              isExpanded ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {addonElement.items.map((singleTopping: any, index: number) => (
              <div
                key={index}
                className={`${
                  singleTopping.visible !== false
                    ? 'text-gray-800'
                    : 'text-gray-400'
                } flex items-center justify-between py-1 px-4`}
              >
                <span className='line-clamp-1 cursor-default'>
                  {singleTopping.addon}
                </span>

                <div className='flex items-center cursor-default'>
                  <span className='mr-4'>
                    {new Intl.NumberFormat(auth.companyLanguage, {
                      style: 'currency',
                      currency: auth.companyCurrency,
                      currencyDisplay: 'symbol',
                      minimumFractionDigits: 2,
                    }).format(
                      auth.showNetto
                        ? singleTopping.nettoPrice
                        : singleTopping.price
                    )}
                  </span>
                  <div className='flex items-center'>
                    <Switch
                      checked={singleTopping.visible !== false}
                      onChange={() => onAddonItemVisibilityChangeHandler(index)}
                      className={`${
                        singleTopping.visible !== false
                          ? 'bg-green_chip'
                          : 'bg-gray-200'
                      } inline-flex h-5 w-10 items-center rounded-full transition-colors focus:outline-none`}
                    >
                      <span
                        className={`${
                          singleTopping.visible !== false
                            ? 'translate-x-6'
                            : 'translate-x-1'
                        } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Draggable>
  );
}
