import { useEffect, useState } from 'react';
import CustomInputField from '../../CustomInputField';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { modalConfirm } from '../../ConfirmationModal';
import InputFieldWithType from '../../InputFieldWithType';
import { useTranslation } from 'react-i18next';
import useAuthState from 'global-states/useAuthState';
import { defaultLogoLink } from 'utils/defaultDataForNewCompany';

export default function AddSizesModal({
  product,
  closeAddSizesModal,
  openAddSizesModal,
  configuratorState,
  setSizesToEdit,
  sizesToEdit,
  setSizes,
  deleteSizes,
}: any) {
  const auth: any = useAuthState.getState();

  const [formFields, setFormFields]: any = useState([
    { size: 'Small', price: 0 },
  ]);

  useEffect(() => {
    const updatedForm =
      product === undefined
        ? //if opened from configurator
          sizesToEdit === -1
          ? [{ size: 'Small', price: 0 }] //if creating
          : (Object as any).values(configuratorState?.sizes[sizesToEdit])[0]
              ?.items //if updating
        : //if opened from product
        product?.sizes === undefined
        ? [{ size: 'Small', price: 0, nettoPrice: 0 }] //if creating
        : product?.sizes?.items; //if updating

    setFormFields(updatedForm);
  }, [product, sizesToEdit, configuratorState?.sizes]);

  // Array to help update size values
  const [sizeVal, setSizeVal]: any = useState(() => {
    let sizesArr: any[] = [];
    formFields.forEach((field: { price: any; nettoPrice: any }) =>
      sizesArr.push(
        auth.showNetto && product !== undefined ? field.nettoPrice : field.price
      )
    );
    return sizesArr;
  });

  const [defaultSize, setDefaultSize] = useState(
    product?.sizes?.defaultSize ||
      (sizesToEdit !== -1 &&
        configuratorState?.sizes[sizesToEdit] !== undefined &&
        (Object as any).values(configuratorState?.sizes[sizesToEdit])[0]
          .defaultSize) ||
      0
  );
  useEffect(() => {
    if (
      sizesToEdit !== -1 &&
      configuratorState?.sizes[sizesToEdit] !== undefined
    ) {
      setDefaultSize(
        (Object as any).values(configuratorState?.sizes[sizesToEdit])[0]
          .defaultSize
      );
    }
  }, [sizesToEdit]);
  const handleSaveForConfigurator = () => {
    console.log(55);
    if (sizesToEdit === -1) {
      setSizes(formFields, defaultSize, true);
    } else {
      setSizes(
        formFields,
        defaultSize,
        true,
        undefined,
        (Object as any).keys(configuratorState?.sizes[sizesToEdit])[0],
        sizesToEdit
      );
    }
    setSizesToEdit(-1);
  };

  const modal = (e: any) => {
    e.preventDefault();
    product === undefined
      ? sizesToEdit === -1
        ? handleSave()
        : modalConfirm(
            {
              message: t('updateLinkedSizes.label'),
              title: t('update.label'),
            },
            handleSave
          )
      : product?.sizes?.refId === undefined
      ? handleSave()
      : modalConfirm(
          {
            message: t('updateUnlinkSizes.label'),
            title: t('update.label'),
          },
          handleSave
        );
  };
  const handleSave = () => {
    // e.preventDefault();
    if (product?.id === undefined) {
      handleSaveForConfigurator();
    } else {
      //handleSave for product
      setSizes(
        formFields,
        defaultSize,
        false,
        product.id,
        undefined,
        undefined,
        product.vat
      );
    }
    closeAddSizesModal(false);
  };
  const handleAddSize = () => {
    setFormFields([
      ...formFields,
      { size: '', price: '', ...(product !== undefined && { nettoPrice: '' }) },
    ]);
  };

  const handleInputChange = (name: any, value: any, index: number) => {
    let temp_state = [...formFields];
    let temp_element = { ...temp_state[index] };

    temp_element[name] = value;
    temp_state[index] = temp_element;
    setFormFields(temp_state);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...formFields];
    if (index === defaultSize) setDefaultSize(0);
    if (index < defaultSize) setDefaultSize(index);

    list.splice(index, 1);
    setFormFields(list);
  };

  const handleDeleteSizes = async () => {
    if (product === undefined) {
      //at configurator
      deleteSizes(
        Object.keys(configuratorState?.sizes[sizesToEdit])[0],
        undefined,
        sizesToEdit
      );
      closeAddSizesModal(false);
    } else {
      //at product
      deleteSizes(Object.keys(product?.sizes)[0], product.id);
      cancel();
    }
  };
  const cancelButtonRef: any = useRef();

  const cancel = () => {
    closeAddSizesModal(false);
    if (sizesToEdit !== undefined) setSizesToEdit(-1);
  };
  const { t } = useTranslation(['catalogue']);
  return (
    <Transition.Root show={openAddSizesModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={openAddSizesModal}
        onClose={cancel}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form
              onSubmit={(e) => modal(e)}
              className='p-6 inline-block align-bottom bg-white  rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg'
            >
              <h1 className=' text-lg flex items-center'>
                {product?.sizes === undefined &&
                (configuratorState?.sizes === undefined ||
                  sizesToEdit === -1) ? (
                  <b>{t('addsizes.label')}</b>
                ) : (
                  <b>{t('editsizes.label')}</b>
                )}

                <div className='group flex'>
                  {product?.sizes?.refId !== undefined && (
                    <>
                      <LinkIcon className='h-4 mt-0.5 ml-1' />
                      <span className='hidden sm:inline-block  text-sm bg-yellow-200 px-2 rounded-md opacity-0 group-hover:opacity-100 duration-300'>
                        Item linked to configurator
                      </span>
                    </>
                  )}
                </div>
              </h1>
              <p className=' text-gray-400 mt-2'>{t('editsizes.text')}</p>
              {formFields !== undefined &&
                formFields?.map((x: any, i: any) => {
                  return (
                    <div key={i} className='flex mt-5 justify-between'>
                      <div className='w-32 sm:w-48'>
                        <CustomInputField
                          classnameadditional='h-10 placeholder-italic'
                          name='size'
                          type='text'
                          maxLength={20}
                          placeholder='e.g Small'
                          value={x.size || ''}
                          step='0.01'
                          id='size'
                          onChange={(e: any) =>
                            handleInputChange('size', e.target.value, i)
                          }
                          required={true}
                        />
                      </div>
                      <div className='w-24 mx-3 '>
                        <div className='relative rounded-md shadow-sm'>
                          <InputFieldWithType
                            value={i === 0 ? 0 : +x.price || 0}
                            id='price'
                            disabled={i === 0}
                            name={
                              auth.showNetto && product !== undefined
                                ? 'nettoPrice'
                                : 'price'
                            }
                            required={true}
                            onInputFieldValueChanged={(e: any) => {
                              let sizes = [...sizeVal];
                              sizes[i] = e;
                              setSizeVal(sizes);
                              handleInputChange(
                                auth.showNetto && product !== undefined
                                  ? 'nettoPrice'
                                  : 'price',
                                parseFloat(e),
                                i
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className='w-8 flex items-center justify-center'>
                        <input
                          type='radio'
                          name='defaultSize'
                          checked={defaultSize === i}
                          value={x.size}
                          id={x.size}
                          onChange={(e) => {
                            setDefaultSize(i);
                          }}
                          required={true}
                        />
                      </div>

                      <div className='hidden w-16 ml-1 mt-0.5 lg:inline-block self-center'>
                        {defaultSize === i && <span>{t('default.label')}</span>}
                      </div>

                      <div
                        className='flex justify-center items-center w-9'
                        onClick={() => handleRemoveClick(i)}
                      >
                        {i !== 0 && (
                          <TrashIcon className='w-6 cursor-pointer text-gray-400 transition ease-in-out hover:text-gray-800' />
                        )}
                      </div>
                    </div>
                  );
                })}

              <button
                type='button'
                className='relative_add_button w-auto h-7 mt-3'
                onClick={handleAddSize}
              >
                + {t('addsize.button')}
              </button>

              <div />
              {/* <ModalButtons toEdit={sizesToEdit} product={product} deletee={handleDeleteSizes} cancel={cancel} save={handleSave}/> */}

              <div className='mt-6 flex items-center justify-between'>
                <div>
                  {sizesToEdit !== -1 && sizesToEdit !== undefined && (
                    <button
                      type='button'
                      className='relative_red_button w-24 h-9'
                      onClick={() => {
                        modalConfirm(
                          {
                            message: t(
                              product === undefined
                                ? 'deleteAllSizes.label'
                                : 'deleteSizes.label'
                            ),
                            title: t('delete.label'),
                          },
                          handleDeleteSizes
                        );
                      }}
                    >
                      {t('delete.button')}
                    </button>
                  )}
                </div>

                <div className='flex justify-end'>
                  <button
                    type='button'
                    className='cancel_button mr-2'
                    onClick={cancel}
                  >
                    {t('cancel.button')}
                  </button>
                  <button
                    className='relative_blue_button h-9 w-24'
                    type='submit'
                  >
                    {t('save.button')}
                  </button>
                </div>
              </div>
              {/* </div> */}
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
