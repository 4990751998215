import React from "react";

export default function SkeletonLoaderLargeTable(){
  return(
    <div className="flex flex-col w-full h-screen cursor-default">
      {/*Table*/}
      <div className="overflow-hidden rounded-lg">
        <table className="min-w-full animate-pulse">
          <tbody>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr><tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-droov_background h-10">
            <td colSpan={10}>
              <div className="bg-gray-200 h-4 border-b border-gray-200 rounded-lg"/>
            </td>
          </tr>
          <tr className="bg-gray-200 h-10 pt-4">
            <td colSpan={10}/>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}