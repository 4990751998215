import { useTranslation } from 'react-i18next';
import { ReactComponent as ChatBubbleLeftRight } from '../assets/chat-bubble-left-right.svg';

export default function FeedbackButton({
  openModal,
  hideIcon,
  additionalClassName,
}: any) {
  const { t } = useTranslation(['feedback']);

  return (
    <div className='flex w-full py-2 md:py-0 md:mt-0'>
      <button
        onClick={() => {
          openModal();
        }}
        className={`${additionalClassName} w-full flex justify-center items-center text-gray-500 hover:text-droov transition ease-in-out focus:outline-none`}
      >
        <div className='flex space-x-2 items-center'>
          {!hideIcon && <ChatBubbleLeftRight className='h-5' />}
          <span>{t('feedback.button')}</span>
        </div>
      </button>
    </div>
  );
}
