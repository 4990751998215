import { ReactComponent as ImageUploadIcon } from '../assets/imageUploadIcon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeImage from './catalogue-components/product-components/ChangeImage';

export default function UploadProductImage({
  product,
  pickAnImage,
  visibility,
}: any) {
  const [hiddenHover, setHiddenHover] = useState(false);
  const [changeImageHover, setChangeImageHover] = useState(false);

  const { t } = useTranslation(['catalogue']);

  return (
    <label htmlFor={product.id} className='flex justify-center items-center'>
      <input
        type='file'
        id={product.id}
        name='productImage'
        accept='image/png, image/jpeg'
        onChange={(e: any) => {
          pickAnImage(e.target.files?.[0], product.id);
          e.target.value = null;
        }}
        className='hidden'
      />
      {product.imgURL ? (
        <div
          className='w-24 sm:w-32  relative flex items-center justify-center '
          onMouseEnter={() => {
            setHiddenHover(true);
            setChangeImageHover(true);
          }}
          onMouseLeave={() => {
            setHiddenHover(false);
            setChangeImageHover(false);
          }}
        >
          {!visibility && (
            <>
              <div className='absolute bg-gray-500 opacity-50 w-full h-full rounded-lg border' />
              {!hiddenHover && (
                <span className='absolute italic text-white sm:inline-block'>
                  {t('hidden.label')}
                </span>
              )}
            </>
          )}
          <img
            src={product.imgURL}
            className='object-contain rounded-lg border'
            alt=''
          />

          {changeImageHover && (
            <>
              <div className='absolute bg-gray-500 opacity-50 w-full h-full rounded-lg border cursor-pointer' />
              <span className='absolute italic text-white sm:inline-block cursor-pointer'>
                <ChangeImage />
              </span>
            </>
          )}

          {/* <img
            src={ChangeImage}
            alt='shadow'
            className='opacity-0 hover:opacity-100 duration-500 transform cursor-pointer absolute'
          /> */}
        </div>
      ) : (
        <div className='w-24 sm:w-32 flex flex-col items-center justify-center transform duration-300 customPopup cursor-pointer'>
          <ImageUploadIcon />
          <p className='mt-2 text-xs'>+ {t('uploadimage.button')}</p>
        </div>
      )}
    </label>
  );
}
