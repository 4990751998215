import { DiscountProps, DiscountType } from '../types';
import { formatTheDate } from '../utils/dateFunctions';
import { useTranslation } from 'react-i18next';
import useAuthState from '../global-states/useAuthState';

function CustomDiscountCard({ discountObject, onClicked }: DiscountProps) {
  const auth: any = useAuthState.getState();
  const randomColorFrom = `from-${discountObject.color || 'green'}-500`;
  const randomColorTo = `to-${discountObject.color || 'gray'}-200`;

  const { t } = useTranslation(['discount']);

  return (
    <div
      onClick={() => onClicked()}
      className={`cursor-pointer bg-gradient-to-r  p-4 ${randomColorFrom} ${randomColorTo}  h-44 w-72  rounded-2xl text-white flex flex-col justify-between customPopup `}
    >
      <div>
        <h1 className='text-3xl font-semibold '>
          {discountObject.type === DiscountType.Percent
            ? `- ${discountObject.value} %`
            : `- ${new Intl.NumberFormat(auth.companyLanguage, {
                style: 'currency',
                currency: auth.companyCurrency,
              }).format(discountObject.value)}`}
        </h1>
        <p className='font-semibold text-md'>{discountObject.code}</p>
      </div>
      <div className='hidden from-red-500 from-blue-500 from-gray-500 from-green-500  to-red-200 to-blue-200 to-gray-200 to-green-200 from-pink-500 to-pink-200' />

      <div className='relative'>
        <div
          className='border-b  border-dashed inset-x-0 absolute h-10'
          style={{ top: '0%', transform: 'translateY(-50%)' }}
        />
      </div>

    
      <div className='relative w-full h-32'>        
        <div className='absolute w-7 h-7 rounded-full bg-droov_background -left-8 top-1/2 transform -translate-y-1/2' />
        <div className='absolute w-7 h-7 rounded-full bg-droov_background -right-8 top-1/2 transform -translate-y-1/2' />
      </div>

      <div>
        <p>
          {t('timesused.label')}: {discountObject.usage}
        </p>
        <p className='text-sm'>
          {t('validuntil.label')}:{' '}
          {discountObject.hasNoExpirationDate
            ? '∞'
            : formatTheDate(discountObject.date)}
        </p>
      </div>
    </div>
  );
}

export default CustomDiscountCard;
