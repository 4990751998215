import TrashIcon from '@heroicons/react/solid/TrashIcon';

type Props = {
  deletable: boolean;
  id: string;
  text: string;
  onDelete: (id: string) => void;
};
const SettingTag = (props: Props) => {
  return (
    <div className='inline-flex justify-center cursor-default items-center min-w-40 w-auto bg-gray_light py-2 px-4 rounded-3xl gap-x-2'>
      <span className={'text-sm'}>{props.text}</span>
      {props.deletable && (
        <TrashIcon
          className='w-5 cursor-pointer text-gray-400 transition ease-in-out hover:text-gray-800'
          onClick={() => props.onDelete(props.id)}
        />
      )}
    </div>
  );
};

export default SettingTag;
