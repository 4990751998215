import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CustomHeaderComponent from '../ui-components/CustomHeaderComponent';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import { useTranslation } from 'react-i18next';

import CustomerInfoCard from '../ui-components/customers/CustomerInfoCard';
import AddressCard from '../ui-components/AddressCard';
import LocationIcon from '@heroicons/react/solid/LocationMarkerIcon';
import OrdersHistoryTab from '../ui-components/OrdersHistoryTab';
import { useCurrencySymbol } from '../utils/getCurrencySymbol';
import useCustomersDetails from '../states/customerDetails';
import BoxLoader from '../ui-components/BoxLoader';

const CustomersDetailsScreen = () => {
  const history = useHistory();
  const { t } = useTranslation(['customer']);
  const { customer, loading } = useCustomersDetails();

  const generateCurrencyOutput = useCurrencySymbol();
  const [headerComponent, setHeaderComponent] = useState({
    heading: customer?.name ?? '',
    goBack: t('customer.label'),
  });

  useEffect(() => {
    if (!customer && !loading) {
      handleBackNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, loading]);

  useEffect(() => {
    if (customer) {
      setHeaderComponent(header => ({...header, heading: customer.name }))
    }
  }, [customer]);

  const customerStats = [
    customer?.stats?.numberOfOrders,
    customer?.stats?.avgBasketSize,
    customer?.stats?.totalSpend,
  ];

  const customerStatsTr = [
    t('numberOrOrders.label'),
    t('avgSize.label'),
    t('totalSpent.label'),
  ] as const;

  const customerStatsFormat = [
    (stat: number | undefined) => `${stat ?? t('noData.label')}`,
    (stat: number | undefined) =>
      stat ? generateCurrencyOutput(stat)!.toString() : t('noData.label'),
    (stat: number | undefined) =>
      stat ? generateCurrencyOutput(stat)!.toString() : t('noData.label'),
  ] as const;

  const handleBackNavigation = () => {
    history.push('/customers', { phoneNumber: customer?.phoneNumber });
  };

  if (!customer) {
    return <BoxLoader />;
  }

  return (
    <>
      <div className='top-8 bg-droov_background z-10'>
        <CustomHeaderComponent
          heading={headerComponent.heading}
          breadCrumbComponent={
            <CustomBreadCrumb
              onClickAction={handleBackNavigation}
              displayString={headerComponent.goBack}
            />
          }
        />
      </div>
      <div className='flex flex-col justify-center m-auto w-full lg:w-2/3'>
        <CustomerInfoCard
          isAuth={customer.isAuth}
          joinedAt={customer.joinedAt}
          phoneNumber={customer.phoneNumber}
          statistics={{
            values: customerStats,
            labels: customerStatsTr,
            translations: customerStatsFormat,
          }}
        />
        {customer.addresses && customer.addresses.length > 0 && (
          <div className='mt-4 bg-white rounded-xl p-4'>
            <div className='flex justify-between items-center'>
              <h6 className='font-medium text-base'>
                {t('deliveryAddress.label')}
              </h6>
              <span className='p-2 bg-gray-100 rounded-md'>
                <LocationIcon className='w-5' />
              </span>
            </div>
            <div className='flex flex-col'>
              {customer.addresses.map((address, key) => (
                <div key={key}>
                  <AddressCard address={address} label={address.instruction} />
                  {key !== customer.addresses.length - 1 && (
                    <div className='h-px w-full bg-gray-100 mt-4' />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <OrdersHistoryTab
          phoneNumber={customer.phoneNumber}
          size={'sm'}
          orderLimit={6}
          header={true}
          showEmpty={false}
        />
      </div>
    </>
  );
};

export default CustomersDetailsScreen;
