import { DocumentData, DocumentSnapshot } from '@firebase/firestore-types';
import { ChartState, ViewType } from '../typings/dashboardViewType';
import moment from 'moment';
import { returnRange } from './dashboardRetunRange';
import { Moment } from 'moment/moment';

type Statistics = {
  date: string;
  revenue: number;
  totalOrder: number;
  newCustomers: number;
};

export const getMonthlyRevenue = (
  modifiedCollection: DocumentSnapshot[],
  chartState: ChartState,
  isBeforeDates: boolean
): Record<number, Statistics> => {
  const totals = modifiedCollection.reduce<
    Record<string, Statistics>
  >(
    (
      acc: Record<string, Statistics>,
      collection: DocumentSnapshot
    ) => ({
      ...acc,
      [collection.id]: {
        date: collection.id,
        revenue: +(collection.data()?.total ?? 0),
        totalOrder: +(collection.data()?.orderCount ?? 0),
        newCustomers: +(collection.data()?.newCustomers ?? 0),
      },
    }),
    {}
  );

  const isNaNToZero = (n: number) => isNaN(n) ? 0 : n;
  const chartKey = isBeforeDates ? 'lastEntities' : 'entities';
  return chartState![chartKey]
    .map<[string, number, number, number]>((entity) => [entity, 0, 0, 0])
    .reduce<Record<string, Statistics>>(
      (acc, entity, idx) => {
        if (totals[entity[0]]) {
          acc[idx] = {
            date: entity[0],
            revenue: isNaNToZero(totals[entity[0]].revenue),
            totalOrder: isNaNToZero(totals[entity[0]].totalOrder),
            newCustomers: isNaNToZero(totals[entity[0]].newCustomers),
          };
        } else {
          acc[idx] = {
            date: entity[0],
            revenue: entity[1],
            totalOrder: entity[2],
            newCustomers: entity[3],
          };
        }
        return acc;
      },
      {}
    );
};

const getCollectionKey = (viewType?: ViewType) => {
  const collectionKey: 'hourly' | 'daily' = [
    ViewType.YESTERDAY,
    ViewType.TODAY,
  ].includes(viewType ?? ViewType.TODAY)
    ? 'hourly'
    : 'daily';

  return collectionKey
}

export const calcPercentage = (valOne: number, valTwo: number) => {
  const percentile = +(((valOne - valTwo) / valTwo).toPrecision(2)) * 100;
  return percentile === Infinity || percentile === -Infinity || isNaN(percentile) ? 0 : percentile;
}

export const getTimezoneOffset = (timeZone: string) => {
  const dateTimezone = new Date().toLocaleString('en-US', { timeZone } );
  const dateStr = new Date().toLocaleString('en-US', { timeZone: 'GMT+0' } );
  return (new Date(dateTimezone).valueOf() - new Date(dateStr).valueOf()) / 3600000;
}

const dayFromString = (viewType: ViewType | undefined, offset: number, day?: string | number, format?: string) => {
  const view = viewType !== ViewType.YESTERDAY
    ? moment(day, format)
    : moment(day, format).subtract(1, 'd');
  if (offset > 0) {
    view.add(offset, 'hours')
  } else {
    view.subtract(offset, 'hours')
  }
  return view;
};

const dayFromBeforeString = (viewType: ViewType | undefined, offset: number, day?: string | number, format?: string) => {
  let view: Moment;
  if (viewType === ViewType.TODAY) {
    view = moment(day, format).subtract(1, 'd');
  } else if (viewType === ViewType.YESTERDAY) {
    view = moment(day, format).subtract(2, 'd');
  } else {
    view = moment(day, format);
  }
  if (offset > 0) {
    view.add(offset, 'hours')
  } else {
    view.subtract(offset, 'hours')
  }
  return view;
};

export const getRangedStatistics = (
  modifiedCollection: DocumentSnapshot[],
  chartState: ChartState,
  offset: number,
  isBeforeDates: boolean,
): Record<number, Statistics> => {
  const collectionKey = getCollectionKey(chartState.viewType);
  const dateFormat: 'DD-MM-YYYY' | 'HH:mm' =
    collectionKey === 'hourly' ? 'HH:mm' : 'DD-MM-YYYY';

  if (returnRange(chartState.viewType) !== 1) {
    return getMonthlyRevenue(modifiedCollection, chartState!, isBeforeDates);
  }

  if (!modifiedCollection || modifiedCollection.length === 0) {
    return {};
  }
  const dayMoment = isBeforeDates ? dayFromBeforeString : dayFromString;
  const chartKey = isBeforeDates ? 'lastEntities' : 'entities';

  const collection = modifiedCollection
    .map(collection => collection.data())
    .reduce<{daily: DocumentData, hourly: DocumentData}>((res, doc) => (
      { daily: {...res.daily, ...doc?.daily}, hourly: {...res.hourly, ...doc?.hourly} }
    ), { daily: {}, hourly: {} });

  return chartState![chartKey]
    .reduce<Record<number, Statistics>>((accum, day, idx) => {
      const view = dayMoment(chartState.viewType, offset, day, dateFormat);
      const key = view.valueOf();
      accum[idx] = {
        date: day,
        revenue: collection![collectionKey][key]?.revenue ?? 0,
        totalOrder: collection![collectionKey][key]?.orderCount ?? 0,
        newCustomers: collection![collectionKey][key]?.newCustomers ?? 0,
      };
      return accum;
    }, {});
};

type StatsNumbers = Omit<Statistics, 'date'>;

export const getTotalStatistics = (
  stats: Record<number, Statistics>,
) => {
  const statistics: StatsNumbers = {
    revenue: 0,
    totalOrder: 0,
    newCustomers: 0,
  };
  const filledHourlyData = stats;
  statistics.revenue = +Object.values(filledHourlyData)
    .reduce((sum, stats) => sum + stats.revenue, 0)
    .toFixed(2);
  statistics.totalOrder = Object.values(filledHourlyData).reduce(
    (sum, stats) => sum + stats.totalOrder,
    0
  );
  statistics.newCustomers = Object.values(filledHourlyData).reduce(
    (sum, stats) => sum + stats.newCustomers,
    0
  );

  return statistics;
};
