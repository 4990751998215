import { useEffect, useState } from 'react';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import { ReactComponent as MapPinIcon } from '../../assets/map-pin.svg';
import { ReactComponent as PickupIcon } from '../../assets/pickup.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as PrinterIcon } from '../../assets/printer.svg';
import { ReactComponent as TruckIcon } from '../../assets/truck.svg';
import { ReactComponent as GroupIcon } from '../../assets/group.svg';
import { ReactComponent as MemberIcon } from '../../assets/member.svg';
import OrderItem from './OrderItem';
import CircularProgress from './CircularProgress';
import moment from 'moment';
import PreparationTimeCard from './PreparationTimeCard';

import {
  OrderStates,
  OrderTypes,
  getOrderState,
  getOrderType,
  canStartPreparingOrder,
  isPreparing,
} from 'typings/OrderTypes';
import useAuthState from 'global-states/useAuthState';
import { functionsEuropeWest3 } from 'firebase-logic/firebase';
import { useTranslation } from 'react-i18next';

export default function OrderCard(props: any) {
  const [openPreparationTimeCard, setOpenPreparationTimeCard] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [commandAction, setCommandAction] = useState<any>(null);
  const { t } = useTranslation(['orders']);

  const auth: any = useAuthState.getState();
  const order = props.order;
  order.tip = `${order.tip}`;

  const currentTime = props.currentTime.getTime();

  let prepTimeInSec;
  let percent;
  let remainderTimeInMin;
  const estimatedDelivery =
    order?.deliveryType?.estimatedDeliveryTime === undefined
      ? 0
      : order?.deliveryType?.estimatedDeliveryTime;

  if (order?.deliveryType?.preparationStartTime && order?.preparationTime) {
    prepTimeInSec = order.preparationTime * 60 * 1000;
    const estimatedDeliveryTimeInSec = estimatedDelivery * 60 * 1000;
    percent =
      100 -
      ((currentTime - order.deliveryType.preparationStartTime) /
        prepTimeInSec) *
        100;

    remainderTimeInMin = Math.ceil(
      (order.deliveryType.preparationStartTime +
        prepTimeInSec -
        estimatedDeliveryTimeInSec -
        currentTime) /
        60000
    );
  }

  const print = props.print;
  useEffect(() => {
    const executeCommand = async () => {
      /*console.log('COMMAND, ', commandAction);*/
      if (commandAction !== null) {
        setLoading(true);

        const updateOrderStatus =
          functionsEuropeWest3.httpsCallable('updateOrderStatus');
        const handleIncomingOrder = functionsEuropeWest3.httpsCallable(
          'handleIncomingOrder'
        );

        switch (commandAction.type) {
          case OrderStates.NOW_RECEIVED:
            await handleIncomingOrder({
              orderId: commandAction.order.documentID,
              status: 'confirmed',
              preparationTime: commandAction.preparationTime,
            });
            break;
          case OrderStates.NOW_CONFIRMED:
            await updateOrderStatus({
              orderId: commandAction.order.documentID,
              taskId: commandAction.order.taskId,
              status: 'ready',
              caller: 'COMPANY',
              companyId: auth.user,
            });
            break;
          case OrderStates.READY:
            if (commandAction.order.orderType === 'delivery') {
              await updateOrderStatus({
                orderId: commandAction.order.documentID,
                taskId: commandAction.order.taskId,
                status: 'collectedByCourier',
                caller: 'COMPANY',
                companyId: auth.user,
              });
            } else {
              await updateOrderStatus({
                orderId: commandAction.order.documentID,
                taskId: commandAction.order.taskId,
                status: 'collected',
                caller: 'COMPANY',
                companyId: auth.user,
              });
            }
            break;
          case OrderStates.SCHEDULED_RECEIVED:
            await handleIncomingOrder({
              orderId: commandAction.order.documentID,
              status: 'confirmed',
              preparationTime: commandAction.preparationTime,
            });
            break;
          case OrderStates.SCHEDULED_CONFIRMED:
            await updateOrderStatus({
              orderId: commandAction.order.documentID,
              taskId: commandAction.order.taskId,
              status: 'ready',
              caller: 'COMPANY',
              companyId: auth.user,
            });
            break;
          default:
            break;
        }
        setCommandAction(null);
        setLoading(false);
      }
    };
    executeCommand();
  }, [auth.user, commandAction]);

  useEffect(() => {
    if (selectedTime !== '') {
      setCommandAction({
        type: getOrderState(order),
        order: order,
        preparationTime: selectedTime,
      });

      setSelectedTime('');
    }
  }, [order, selectedTime]);

  if (loading) {
    return (
      <div className='bg-white p-4 mb-4 rounded-xl text-sm'>
        <div className='loaderOrderTab' />
      </div>
    );
  }
  return (
    <div className='bg-white p-4 mb-4 rounded-xl text-sm'>
      {order?.groupOrder === true && order?.groupDetails?.members && (
        <div className='flex rounded-xl bg-green_chip mb-5 p-1 text-white text-center'>
          <div className='w-10 h-10 ml-3 flex justify-between items-center'>
            <GroupIcon />
          </div>
          <div className='flex ml-24 justify-center items-center text-center'>
            {t('group_order.label')} ({order.groupDetails.members.length})
          </div>
        </div>
      )}
      <div className='flex justify-between'>
        <div className='flex flex-col w-3/4'>
          <div className='flex items-center text-left'>
            <div className='flex flex-col w-1/5'>
              <div className='bg-gray-100 p-2 w-24 rounded-md text-center font-bold'>
                {order.orderID}
              </div>
            </div>
          </div>
          <div className='flex items-center text-left'>
            <div className='flex flex-col'>
              <div className='px-2 pt-2 rounded-xl text-center font-bold'>
                {order.customer.name}
              </div>
            </div>
          </div>
          <div className='flex items-center mt-1'>
            <div className='w-6 h-6 p-1 ml-2 flex items-center text-blue-700'>
              <PhoneIcon />
            </div>
            <div className='ml-3 text-xs text-gray-400'>
              {order.customer.phone}
            </div>
          </div>
          {order?.comments?.length > 0 && (
            <div className='mt-2 bg-yellow-100 p-3 rounded-xl text-left text-sm w-4/5'>
              {order.comments}
            </div>
          )}
          {order?.deliveryAddress?.streetAndHouseNumber && (
            <div className='flex mt-2 bg-blue-100 p-3 rounded-xl text-left text-sm w-4/5'>
              <div className='w-5 h-5 flex justify-between items-center text-blue-700'>
                <MapPinIcon className='text-blue-900' />
              </div>
              <div className='flex ml-1 text-blue-900 justify-center items-center'>
                {order?.deliveryAddress?.streetAndHouseNumber}
              </div>
            </div>
          )}
          {order.groupOrder !== true && (
            <div className='flex flex-col ml-3 mt-2'>
              {Object.entries(order.orderItems).map(
                (orderItem: any, index: number, orderItems) =>
                  index !== orderItems.length - 1 ? (
                    <div>
                      <OrderItem
                        key={`orderItems-${index}`}
                        {...orderItem[1]}
                      />
                      <hr className='my-1' key={`hr-${index}`} />
                    </div>
                  ) : (
                    <div>
                      <OrderItem
                        key={`orderItems-${index}`}
                        {...orderItem[1]}
                      />
                    </div>
                  )
              )}
            </div>
          )}

          {order.groupOrder === true && order.groupDetails.members && (
            <div className='flex flex-col ml-2 mt-2'>
              {order.groupDetails.members.map((member: any) => {
                return (
                  <div className='flex flex-col mt-5'>
                    <div className='flex border-b-2'>
                      <div className='w-5 h-5 flex justify-between items-center text-green_chip'>
                        <MemberIcon />
                      </div>
                      <div className='flex ml-1 justify-center items-center text-center font-bold'>
                        {member?.fullName}
                      </div>
                    </div>

                    {member?.comment?.length > 0 && (
                      <div className='mt-2 bg-green_chip p-3 rounded-xl text-left text-sm w-4/5'>
                        {member?.comment}
                      </div>
                    )}

                    <div className='ml-1 mt-3'>
                      {Object.entries(order.orderItems)
                        .filter(
                          (orderItem: any) =>
                            orderItem[1].groupMemberId === member.id
                        )
                        .map((orderItem: any, index: number, orderItems) =>
                          index !== orderItems.length - 1 ? (
                            <div>
                              <OrderItem
                                key={`orderItems-${index}`}
                                {...orderItem[1]}
                              />
                              <hr className='my-1' key={`hr-${index}`} />
                            </div>
                          ) : (
                            <div>
                              <OrderItem
                                key={`orderItems-${index}`}
                                {...orderItem[1]}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className='flex items-left ml-3 mt-5'>
            <div className='font-bold'>{order.amount.replace('.', ',')} €</div>
            <div className='ml-3'>(Tip: {order.tip.replace('.', ',')} €)</div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-between w-1/4'>
          <div className='w-full'>
            {order.orderStatus === 'received' &&
              order?.deliveryType?.name === 'now' && (
                <div className='bg-gray-100 p-2 rounded-md text-center text-droov font-bold w-full'>
                  <p>{t('asap.label')}</p>
                </div>
              )}
            {((order.orderStatus === 'confirmed' &&
              !order?.deliveryType?.preparationStartTime) ||
              (order?.orderStatus === 'received' &&
                order?.deliveryType?.name === 'scheduled')) && (
              <div className='bg-yellow-200 p-2 rounded-md text-center font-bold w-full'>
                <p>
                  {moment(order.deliveryType.timestamp).format('DD.MM.YY') ===
                  moment(new Date()).format('DD.MM.YY') ? (
                    <p>{t('today.label')}</p>
                  ) : (
                    moment(order.deliveryType.timestamp).format('DD.MM.YY')
                  )}
                </p>
                <p className='text-lg'>
                  {auth.clockFormat12
                    ? moment(order.deliveryType.timestamp).format('h:mm A')
                    : moment(order.deliveryType.timestamp).format('HH:mm')}
                </p>
              </div>
            )}
            {order.orderStatus === 'confirmed' &&
              order?.deliveryType?.preparationStartTime && (
                <CircularProgress
                  bigText={remainderTimeInMin}
                  smallText='Min'
                  radius={30}
                  percent={percent}
                  message={t('time_out.label')}
                />
              )}
            {order.orderStatus === 'ready' && (
              <div className='flex justify-center'>
                <div className='bg-light_green rounded-full text-green-900 w-12 h-12 fond-bold flex justify-center items-center text-green_chip'>
                  <CheckIcon />
                </div>
              </div>
            )}
            {order.orderStatus === 'ready' && (
              <div className='mt-1 text-green_chip text-center'>
                {t('order_ready.label')}
              </div>
            )}
            <div className='mt-3 p-1'>
              <div className='flex justify-center'>
                {order.orderType === 'pickup' && (
                  <div className='bg-light_green rounded-full  w-12 h-12 flex justify-center items-center  text-gray-700'>
                    <PickupIcon />
                  </div>
                )}
                {order.orderType === 'delivery' && (
                  <div className='bg-light_blue rounded-full text-darker_blue w-12 h-12 flex justify-center items-center text-gray-700'>
                    <TruckIcon />
                  </div>
                )}
              </div>
              {order.orderType === 'pickup' && (
                <div className='mt-1 text-darker_green text-center'>
                  {t('pickup.label')}
                </div>
              )}
              {order.orderType === 'delivery' && (
                <>
                  <div className='mt-1 text-darker_blue text-center'>
                    {t('delivery.label')}
                  </div>
                  <div className='mt-1 text-darker_blue text-center'>
                    {order.deliveryType.estimatedDeliveryTime !== undefined &&
                      '~' +
                        t('est_delivery.label', {
                          minutes: order.deliveryType.estimatedDeliveryTime,
                        })}
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className='hidden md:flex justify-center my-6'
            onClick={() => print(order)}
          >
            <div
              className='w-12 h-12 bg-droov rounded-lg hover:shadow-lg transition ease-in-out flex justify-center items-center text-white cursor-pointer'
              onClick={() => print(order)}
            >
              <PrinterIcon />
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col mt-3'>
        <button
          className={(() => {
            if (order.orderStatus === 'ready') {
              return 'order_green_button';
            } else if (order?.deliveryType?.name === 'now') {
              return 'order_blue_button';
            } else if (order?.deliveryType?.name === 'scheduled') {
              return 'order_yellow_button';
            }
          })()}
          onClick={() => {
            if (
              [
                OrderStates.NOW_RECEIVED,
                OrderStates.SCHEDULED_RECEIVED,
              ].includes(getOrderState(order))
            ) {
              setOpenPreparationTimeCard(true);
            } else {
              setCommandAction({
                type: getOrderState(order),
                order: order,
              });
            }
          }}
          disabled={
            getOrderState(order) === OrderStates.SCHEDULED_CONFIRMED &&
            !canStartPreparingOrder(order)
          }
        >
          {getOrderState(order) === OrderStates.NOW_RECEIVED &&
            t('accept.label')}

          {getOrderState(order) === OrderStates.SCHEDULED_RECEIVED &&
            t('accept_scheduled.label')}

          {getOrderState(order) === OrderStates.SCHEDULED_CONFIRMED &&
            !isPreparing(order) &&
            canStartPreparingOrder(order) &&
            t('start_prep.label')}

          {getOrderState(order) === OrderStates.SCHEDULED_CONFIRMED &&
            !isPreparing(order) &&
            !canStartPreparingOrder(order) &&
            t('appears_in.label', {
              minutes: order.preparationTime + estimatedDelivery,
            })}
          {[
            OrderStates.NOW_CONFIRMED,
            OrderStates.SCHEDULED_CONFIRMED,
          ].includes(getOrderState(order)) &&
            isPreparing(order) &&
            t('ready.label')}

          {getOrderState(order) === OrderStates.READY &&
            getOrderType(order) === OrderTypes.PICKUP &&
            t('mark_picked_up.label')}

          {getOrderState(order) === OrderStates.READY &&
            getOrderType(order) === OrderTypes.DELIVERY &&
            t('collected_by_courier.label')}
        </button>
      </div>

      <PreparationTimeCard
        open={openPreparationTimeCard}
        setOpen={setOpenPreparationTimeCard}
        submit={setSelectedTime}
      />
    </div>
  );
}
