import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';
// import 'moment/locale/de';

import { ReactComponent as PayPalIcon } from '../assets/paypal.svg';
import { ReactComponent as ApplePayIcon } from '../assets/applePay.svg';
import { ReactComponent as CashIcon } from '../assets/icon-cash.svg';
import { ReactComponent as CardIcon } from '../assets/icon-card.svg';
import { ReactComponent as PickupIcon } from '../assets/pickup.svg';
import { ReactComponent as TruckIcon } from '../assets/truck.svg';

import { OrderProps, PaymentType } from '../types';
import useAuthState from '../global-states/useAuthState';
import React, { ForwardedRef, forwardRef, MouseEventHandler } from 'react';

const OrdersTableRow = forwardRef(({ orderObject, onClicked, onDeleted }: OrderProps, ref: ForwardedRef<HTMLTableRowElement>) => {
  const { t } = useTranslation(['orders']);
  const auth: any = useAuthState.getState();

  const id = orderObject?.orderId?.toUpperCase().toString();
  let paymentType = orderObject?.paymentType;
  moment.locale(auth.companyLanguage);

  const handleTableRowClick: MouseEventHandler<HTMLTableRowElement> = (
    event
  ) => {
    if (orderObject?.orderId) {
      onClicked(orderObject?.orderId);
    }
  };

  return (
    <tr
      className='flex w-full cursor-pointer bg-white hover:bg-gray-100'
      onClick={handleTableRowClick}
      id={`row-${id}`}
      ref={ref}
    >
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-items-start'>
        <p className='text-sm text-left font-medium text-blue-400 flex align-middle items-center'>
          {id}
        </p>
      </td>
      <td className='px-2 py-3 w-4/12 align-middle overflow-auto'>
        <p className='text-sm text-left text-gray-900'>
          {orderObject?.customer?.name}
        </p>
        <p className='text-sm text-left text-gray-500'>
          {orderObject?.customer?.phone}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-items-start'>
        <p className='text-sm text-left text-gray-500 items-center flex align-middle'>
          {orderObject?.orderTimestamp?.received &&
            moment(orderObject.orderTimestamp.received).format('ddd D MMMM')}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-items-start'>
        <p className='text-sm text-left text-gray-500 flex items-center'>
          {orderObject?.amount && (
            <span className='mr-2'>
              {new Intl.NumberFormat(auth.companyLanguage, {
                style: 'currency',
                currency: auth.companyCurrency,
                currencyDisplay: 'symbol',
                minimumFractionDigits: 2,
              }).format(orderObject.amount)}
            </span>
          )}
          {paymentType === PaymentType.Apple ? (
            <ApplePayIcon className='border border-black rounded-sm opacity-80' />
          ) : paymentType === PaymentType.PayPal ? (
            <PayPalIcon className='opacity-80' />
          ) : paymentType === PaymentType.Card ? (
            <CardIcon className='card-icon opacity-80' />
          ) : (
            <CashIcon className='cash-icon opacity-80' />
          )}
        </p>
      </td>
      <td className='px-2 py-3 w-2/12 align-middle overflow-auto flex justify-center'>
        <p className='text-sm text-left text-gray-500 w-full flex align-middle items-center'>
          <div className='flex justify-center w-full'>
            {orderObject.orderType === 'pickup' && (
              <div className='rounded-lg w-[8/12] h-7 bg-light_green flex opacity-80 justify-center items-center text-gray-700'>
                <span className='pickup-icon text-green-900'>
                  <PickupIcon />
                </span>
                <span className='mr-3 text-green-900'>{t('pickup.label')}</span>
              </div>
            )}
            {orderObject.orderType === 'delivery' && (
              <div className='rounded-lg w-[8/12] h-7 bg-light_blue opacity-80 flex justify-center items-center text-gray-700'>
                <span className='delivery-icon mr-3 ml-3 text-blue-900'>
                  <TruckIcon />
                </span>
                <span className='mr-3 text-blue-900'>
                  {t('delivery.label')}
                </span>
              </div>
            )}
          </div>
        </p>
      </td>
    </tr>
  );
});

export default OrdersTableRow;
