import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import fetchRevenue from 'firebase-logic/dashboard';
import ChartDropDown from 'ui-components/dashboard-components/ChartDropDown';
import { useEffect, useMemo, useRef, useState } from 'react';
import StatisticalInformation from 'ui-components/dashboard-components/StatisticalInformation';
import { useTranslation } from 'react-i18next';
import useAuthState from 'global-states/useAuthState';
import {ChartState} from '../typings/dashboardViewType';
import { graphOptions, THICKNESS_PADDING } from '../utils/dashboardOptions';
import {
  getTotalStatistics,
  getRangedStatistics,
  getTimezoneOffset,
  calcPercentage,
} from '../utils/dashboardGetStatistics';
import ChartBar from '../ui-components/dashboard-components/ChartBar';
import {
  convertToCurrency,
  getCurrencySymbol,
} from '../utils/convertToCurrency';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import useDashboardData from "../states/dashboardState";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DashboardScreen() {
  const { t } = useTranslation(['dashboard']);
  const auth = useAuthState();

  const {
    summary,
    setSummary,
    chartValues,
    setChartValues,
    chartState,
    setChartState,
    loadedView,
    setLoadedView,
    collection,
    setCollection
  } = useDashboardData();

  const statistics = useMemo(
    () => [
      {
        title: 'totalOrders.label',
        value: summary[0].value,
        tendency: summary[0].tendency,
      },
      {
        title: 'revenue.label',
        value: convertToCurrency(
          summary[1].value,
          auth.companyLanguage,
          auth.companyCurrency
        ),
        tendency: summary[1].tendency,
      },
      {
        title: 'newCustomers.label',
        value: summary[2].value,
        tendency: summary[2].tendency,
      },
    ],
    [summary, auth.companyLanguage, auth.companyCurrency]
  );

  const barRef = useRef<ChartJSOrUndefined<'bar', number[], string>>(null);
  const [thickness, setThickness] = useState(0);
  const initGraphOptions = useMemo(() => {
    return graphOptions({
      setThickness,
      itemsCount: chartState?.entities.length ?? 0,
      currency: getCurrencySymbol(auth.companyLanguage, auth.companyCurrency),
    });
  }, [setThickness, chartState?.entities]);

  const data = {
    labels: chartState?.chartEntities,
    datasets: [
      {
        label: 'Revenue',
        data: chartValues,
        backgroundColor: 'rgba(21, 164, 244, 0.8)',
        barThickness: thickness,
      },
    ],
  };

  useEffect(() => {
    if (!auth.user || collection) {
      return;
    }
    fetchRevenue(auth.user).then((revenue) => {
      setCollection(revenue);
    });
  }, [auth.user]);

  useEffect(() => {
    const unload = !chartState?.viewType || !auth.timezone ||
      (chartState.viewType === loadedView && !collection);
    if (unload) {
      return;
    }

    const timezoneOffset = getTimezoneOffset(auth.timezone);
    const rangedStatistics = getRangedStatistics(
      collection!,
      chartState,
      timezoneOffset,
      false
    );
    const rangedPrevStatistics = getRangedStatistics(
      collection!,
      chartState,
      timezoneOffset,
      true
    );
    const revenues = Object.values(rangedStatistics).map(
      (revenueData) => revenueData.revenue
    );
    setChartValues(revenues);
    const currentStats = getTotalStatistics(rangedStatistics);
    const prevStats = getTotalStatistics(rangedPrevStatistics);
    setSummary([
      {
        value: currentStats.totalOrder,
        tendency: calcPercentage(currentStats.totalOrder, prevStats.totalOrder),
      },
      {
        value: currentStats.revenue,
        tendency: calcPercentage(currentStats.revenue, prevStats.revenue),
      },
      {
        value: currentStats.newCustomers,
        tendency: calcPercentage(
          currentStats.newCustomers,
          prevStats.newCustomers
        ),
      },
    ]);
    setLoadedView(chartState.viewType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.timezone, chartState, collection]);

  const onInitChartState = ({
    entities,
    barThickness,
    viewType,
    lastEntities,
    chartEntities
  }: ChartState) => {
    // do not change chartValues if already exists
    if (chartValues.length > 0) {
      return;
    }
    setChartState({
      entities,
      viewType,
      lastEntities,
      barThickness: thickness,
      chartEntities
    });

    if (!barRef.current) {
      setThickness(barThickness);
    } else {
      setThickness(
        (barRef.current.width - THICKNESS_PADDING) / entities.length
      );
    }
  }

  const onSetChartState = ({
    entities,
    barThickness,
    viewType,
    lastEntities,
    chartEntities
  }: ChartState) => {
    setChartState({
      entities,
      viewType,
      lastEntities,
      barThickness: thickness,
      chartEntities
    });
    if (!barRef.current) {
      setThickness(barThickness);
    } else {
      setThickness(
        (barRef.current.width - THICKNESS_PADDING) / entities.length
      );
    }
  };

  const statisticsLoaded = !!statistics.map((stats) => stats.value).join('');
  const loaded = summary.every((m) => m.value !== '');

  const memoizedInformationList = useMemo(
    () =>
      statistics.map((val, index) => (
        <StatisticalInformation key={index} statistics={val} loaded={loaded} />
      )),
    [loaded, statistics]
  );

  return (
    <div>
      <div className='flex flex-col gap-5 md:flex-row justify-between relative z-1 mt-6'>
        <div>
          <div className={`droov_xxlarge_text`}>
            {t('hi.label')} {auth.customerName || ''},
          </div>
          <div className='text-md mt-2 text-gray-600'>
            {t('businessInfo.label')}
          </div>
        </div>
        <div className='md:ml-2 my-auto'>
          <ChartDropDown
            setChartState={onSetChartState}
            initChartState={onInitChartState}
            currentViewType={chartState?.viewType}
          />
        </div>
      </div>
      <div className='mt-10 flex flex-col gap-5'>
        <div className='flex flex-wrap gap-5 justify-between'>
          {memoizedInformationList}
        </div>
        <ChartBar
          data={data}
          ref={barRef}
          graphOptions={initGraphOptions}
          showChart={loaded && statisticsLoaded}
          label={summary[1].value}
          company={{
            currency: auth.companyCurrency,
            language: auth.companyLanguage,
          }}
        />
      </div>
    </div>
  );
}
