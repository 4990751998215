import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { ForwardedRef, forwardRef } from 'react';
import BoxLoader from '../BoxLoader';
import { convertToCurrency } from '../../utils/convertToCurrency';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: string;
  barThickness?: number;
};

export type ChartData = {
  labels: string[] | undefined;
  datasets: Dataset[];
};

type ChartBarProps = {
  graphOptions: ChartOptions<'bar'>;
  data: ChartData;
  showChart: boolean;
  label: string | number;
  company: { language: string; currency: string };
};

const ChartBar = forwardRef(
  (
    { graphOptions, data, showChart, label, company }: ChartBarProps,
    ref: ForwardedRef<ChartJSOrUndefined<'bar', number[], string>>
  ) => {
    const { t } = useTranslation(['dashboard']);

    if (!showChart) {
      return <BoxLoader />;
    }

    return (
      <div className='bg-white rounded-2xl h-fit border border-gray-200 cursor-default'>
        <div className='p-4'>
          <div className='font-text-gray-900 font-medium text-lg ml-1.5'>
            {t('timeline.label')}
          </div>
          <div className='text-4xl ml-1.5 text-gray-900 lg:text-4xl font-semibold md:font-medium my-2'>
            {convertToCurrency(label, company.language, company.currency)}
          </div>
        </div>
        <Bar options={graphOptions} data={data} ref={ref} />
      </div>
    );
  }
);

export default ChartBar;
