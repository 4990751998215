import { useTranslation } from 'react-i18next';

export default function AddAddonsButton(props: any) {
  const { t } = useTranslation(['catalogue']);

  return (
    <button
      onClick={() => {
        props.setOpenAddAddonsModal(true);
      }}
      className={props.classNameAdditional}
    >
      <span>+ {t('editaddons.button')}</span>
    </button>
  );
}
