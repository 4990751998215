import React from 'react';
import { TFunction } from 'i18next';

type Props = {
  title: string | TFunction,
  description: string | TFunction
};
const SectionHeader = ({ title, description }: Props) => {
  return (
    <>
      <h1>{title}</h1>
      <p className="droov_text_description_light">
        { description }
      </p>
    </>
  );
};


export default SectionHeader;