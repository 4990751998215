import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  addDeliveryAreas,
  changeDeliveryStatus,
  getDeliveryStatus,
} from '../../firebase-logic/deliveryArea';
import useAuthState from '../../global-states/useAuthState';
import { DeliveryDataObject } from '../../types';
import AddDeliveryAreaModal from './AddDeliveryAreaModal';
import DeliveryAreaCard from './DeliveryAreaCard';
import DeliveryInfo from './DeliveryInfo';

type Props = {
  deliveryAreas: DeliveryDataObject[];
  triggerEdit: Function;
  setDeliveryAreas: Function;
};
function DeliveryAreaList(props: Props) {
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['delivery']);
  const { deliveryAreas, triggerEdit, setDeliveryAreas } = props;
  const [isDeliveryEnabled, setIsDeliveryEnabled] = useState(false);
  const [openAddDeliveryAreaModal, setOpenAddDeliveryAreaModal] =
    useState(false);
  const [selectedDeliveryArea, setSelectedDeliveryArea] =
    useState<DeliveryDataObject>({} as DeliveryDataObject);

  useEffect(() => {
    setDeliveryStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeliveryStatus = async () => {
    const deliveryStatus = await getDeliveryStatus(auth.user);
    setIsDeliveryEnabled(deliveryStatus);
  };
  const handleEdit = () => {
    triggerEdit();
  };

  const toggle = () => {
    changeDeliveryStatus(auth.user, !isDeliveryEnabled);
    setIsDeliveryEnabled(!isDeliveryEnabled);
  };

  const handleUpdateDeliveryArea = async (
    updatedDeliveryArea: DeliveryDataObject
  ) => {
    let tempAllDeliveryAreas = [...deliveryAreas];
    tempAllDeliveryAreas[
      tempAllDeliveryAreas.findIndex(
        (area) => area.id === updatedDeliveryArea.id
      )
    ] = updatedDeliveryArea;

    toast.promise(updateDeliveryAreas(tempAllDeliveryAreas, false), {
      loading: t('updating.label'),
      success: t('updateSuccess.label'),
      error: t('somethingWentWrong.label'),
    });
  };

  const handleCancel = () => {};

  const handleEditClick = (deliveryArea: DeliveryDataObject) => {
    setSelectedDeliveryArea(deliveryArea);
    setOpenAddDeliveryAreaModal(true);
  };

  const handleDelete = (areaId: string) => {
    let filteredDeliveryAreas = deliveryAreas.filter(
      (area) => area.id !== areaId
    );
    toast.promise(updateDeliveryAreas(filteredDeliveryAreas, true), {
      loading: t('deleting.label'),
      success: t('deliveryAreaAdded.label'),
      error: t('somethingWentWrong.label'),
    });
  };

  const updateDeliveryAreas = async (
    updatedDeliveryAreas: DeliveryDataObject[],
    isDeleted: boolean
  ) => {
    try {
      await addDeliveryAreas(auth.user, [...updatedDeliveryAreas]);
      setDeliveryAreas([...updatedDeliveryAreas]);
    } catch (error) {
      console.log('error in saving', error);
    }
  };

  return (
    <div className='flex flex-col p-5'>
      <DeliveryInfo
        isDeliveryEnabled={isDeliveryEnabled}
        setIsDeliveryEnabled={toggle}
        isDeliveryAreaEmpty={deliveryAreas.length === 0}
      />

      <hr className='my-4' />
      <h1 className='text-lg font-medium text-gray-400 mb-2'>
        {t('delivery_area.label')}
      </h1>
      {deliveryAreas.length > 0 && (
        <div>
          {deliveryAreas.map((area) => (
            <DeliveryAreaCard
              onEditClicked={handleEditClick}
              deliveryAreaDetails={area}
              key={area.id}
            />
          ))}
        </div>
      )}
      <div className='flex flex-row justify-center mt-2'>
        <button
          type='button'
          className='relative_add_button w-56 h-10'
          onClick={handleEdit}
        >
          + {t('addnewarea.button')}
        </button>
      </div>
        <AddDeliveryAreaModal
          openAddDeliveryAreaModal={openAddDeliveryAreaModal}
          closeAddDeliveryAreaModal={setOpenAddDeliveryAreaModal}
          handleAddDeliveryArea={handleUpdateDeliveryArea}
          handleCancel={handleCancel}
          isUpdate={true}
          selectedDeliveryZone={selectedDeliveryArea}
          handleDelete={handleDelete}
        />
    </div>
  );
}

export default DeliveryAreaList;
