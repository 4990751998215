import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useAuthState from '../../global-states/useAuthState';
import { OrderObject } from '../../types';
import { fetchOrdersFromDates } from '../../firebase-logic/orders';
import ReactExport, { ExcelCellData } from 'react-data-export';

import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ExportExcelModal({
  openExportPdfModal,
  setOpenExportPdfModal,
}: {
  openExportPdfModal: boolean;
  setOpenExportPdfModal: any;
}) {
  const cancelButtonRef: any = useRef();

  const [dateRange, setDateRange] = useState(() => {
    const date = new Date();
    return [date, date];
  });
  const [startDate, endDate] = dateRange;

  const auth: any = useAuthState.getState();
  const [ordersFromDates, setOrdersFromDates] = useState<OrderObject[]>();
  const [onOrdersLoading, setOnOrdersLoading] = useState(false);

  const [csvReport, setCsvReport] = useState();

  const closeTheModal = () => {
    setOpenExportPdfModal(false);
  };

  const { t } = useTranslation(['orders']);

  const generateExcelDataSet = (
    ordersLoaded: OrderObject[],
    startDate: Date,
    endDate: Date
  ): any => {
    const tableRows: Array<ExcelCellData> = [];
    let orderWiseVatCollection: any = [];

    ordersLoaded.forEach((order, ind) => {
      const vatCollection: any = {};
      const nettAmount = order.nettAmount;
      order.vatBreakdown?.map((breakdown) => {
        vatCollection['vat' + breakdown.vatValue] = breakdown.vatAmount;
      });
      // @ts-ignore
      orderWiseVatCollection[order?.orderId] = vatCollection;

      const singleRow: ExcelCellData | null = generateSingleRow(
        order,
        nettAmount,
        auth.companyLanguage
      );
      if (singleRow) tableRows.push(singleRow);
    });

    const vatKeys = extractVatKeysFromOrders(orderWiseVatCollection);
    const vatColumns = vatKeys.map((key) => {
      const formattedKey = t('vat.key') + ' (' + key.slice(3) + '%)';
      return {
        title: formattedKey,
        width: { wpx: 80 },
        style: {
          font: { sz: 9, bold: true, name: 'Verdana' },
          fill: { fgColor: { theme: '0', tint: '-0.10' } },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      };
    });
    const updatedOrderWiseVatCollection = addAndSortVatKeysInOrders(
      orderWiseVatCollection,
      vatKeys
    );
    const tableRowsWithVatCollection = addVatCollectionInTableRows(
      updatedOrderWiseVatCollection,
      tableRows,
      auth.companyLanguage
    );
    const summaryRow = makeSummaryRow(
      tableRowsWithVatCollection,
      vatKeys,
      auth.companyLanguage
    );

    // add summary row
    tableRowsWithVatCollection.push(summaryRow);

    const multiDataSet = [
      {
        columns: [
          {
            title: t('from.title') + ':',
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'center' },
            },
          },
          {
            title: t('to.title') + ':',
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'center' },
            },
          },
          {
            title: t('total.title') + ':',
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'center' },
            },
          },
        ],
        data: [
          [
            {
              value: convertDate(startDate),
              style: {
                font: { sz: 9, bold: true },
                alignment: { vertical: 'center', horizontal: 'center' },
              },
            },
            {
              value: convertDate(endDate),
              style: {
                font: { sz: 9, bold: true },
                alignment: { vertical: 'center', horizontal: 'center' },
              },
            },
            {
              value: tableRowsWithVatCollection.length - 1,
              style: {
                font: { sz: 9, bold: true },
                alignment: { vertical: 'center', horizontal: 'center' },
              },
            },
          ],
        ],
      },
      {
        columns: [
          {
            title: t('orderId.title'),
            width: { wpx: 80 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'left' },
            },
          }, //pixels width
          {
            title: t('name.title'),
            width: { wpx: 100 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'left' },
            },
          }, // char width
          {
            title: t('phone.title'),
            width: { wpx: 100 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'left' },
            },
          },
          {
            title: t('orderDate.title'),
            width: { wpx: 80 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'left' },
            },
          },
          {
            title: t('paymenttype.label'),
            width: { wpx: 80 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'left' },
            },
          },
          {
            title: `${t('tip.title')} (${auth.companyCurrency})`,
            width: { wpx: 90 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'right' },
            },
          },
          {
            title: `${t('delivery.label')} (${auth.companyCurrency})`,
            width: { wpx: 90 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'right' },
            },
          },
          {
            title: `${t('discount.title')} (${auth.companyCurrency})`,
            width: { wpx: 90 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'right' },
            },
          },
          ...vatColumns,
          {
            title: `${t('netAmount.title')} (${auth.companyCurrency})`,
            width: { wpx: 100 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'right' },
            },
          },
          {
            title: `${t('orderAmount.title')} (${auth.companyCurrency})`,
            width: { wpx: 110 },
            style: {
              font: { sz: 9, bold: true, name: 'Verdana' },
              fill: { fgColor: { theme: '0', tint: '-0.10' } },
              alignment: { vertical: 'center', horizontal: 'right' },
            },
          },
        ],
        data: tableRowsWithVatCollection,
      },
    ];
    return multiDataSet;
  };

  const onGetHandle = async (startDate: Date, endDate: Date) => {
    setOnOrdersLoading(true);
    let ordersLoaded = null;
    try {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      ordersLoaded = await fetchOrdersFromDates(auth.user, startDate, endDate);
    } catch (error) {
      setOnOrdersLoading(false);
      console.log(error);
    }

    if (ordersLoaded) {
      setOrdersFromDates(ordersLoaded);
      const dataExcelSheet = generateExcelDataSet(
        ordersLoaded,
        startDate,
        endDate
      );
      if (dataExcelSheet) setCsvReport(dataExcelSheet);
    } else {
      setCsvReport(undefined);
    }

    setOnOrdersLoading(false);
  };

  /* https://github.com/securedeveloper/react-data-export/blob/HEAD/examples/styled_excel_sheet.md */

  return (
    <Transition.Root show={openExportPdfModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed  z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={openExportPdfModal}
        onClose={closeTheModal}
      >
        <div className='flex items-center justify-center min-h-screen  text-center sm:block sm:p-0 '>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form className='inline-block align-bottom bg-white rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle '>
              <div className='bg-white border rounded-2xl'>
                <div className='p-6'>
                  <h1 className=' text-lg'>
                    <b>{t('exportorders.label')}</b>
                  </h1>
                  <p className=' text-gray-400 mt-2'>
                    {t('exportorders.text')}
                  </p>
                  <div className='flex justify-between mt-5 '>
                    <div className='relative'>
                      <label className='text-gray-400 my-3'>
                        {t('daterange.label')}
                      </label>
                      <DatePicker
                        className='my-3 w-52'
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        calendarStartDay={1}
                        maxDate={new Date()}
                        timeInputLabel='Time:'
                        dateFormat='MM/dd/yyyy'
                        onChange={(update) => {
                          // @ts-ignore
                          setDateRange(update);
                          if (update[0] !== null && update[1] !== null) {
                            onGetHandle(update[0], update[1]);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    {onOrdersLoading ? (
                      <svg
                        role='status'
                        className='m-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-droov'
                        viewBox='0 0 100 101'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                          fill='currentColor'
                        />
                        <path
                          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                          fill='currentFill'
                        />
                      </svg>
                    ) : (
                      <p>
                        {ordersFromDates != null &&
                          t('numberoforders.label') +
                            ' ' +
                            ordersFromDates.length}
                      </p>
                    )}
                  </div>
                  <div />
                </div>

                <div className=' bg-gray-100 p-0 h-16 flex items-center justify-end rounded rounded-b-2xl'>
                  <div className='mr-2'>
                    <button
                      type='button'
                      className='cancel_button w-24 h-9 ml-6'
                      onClick={closeTheModal}
                    >
                      {t('cancel.button')}
                    </button>
                  </div>
                  <div className='mt-4 mr-4'>
                    {csvReport && ordersFromDates?.length !== 0 && (
                      <ExcelFile
                        element={
                          <button
                            className='add_button'
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {t('export.button')}{' '}
                          </button>
                        }
                      >
                        <ExcelSheet dataSet={csvReport} name='Organization' />
                      </ExcelFile>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function generateSingleRow(
  order: any,
  nettAmount: any,
  companyLanguage: string
) {
  if (
    order.orderId &&
    order.customer &&
    order.orderTimestamp &&
    order.amount &&
    order.tip
  ) {
    const orderTime =
      order.orderTimestamp &&
      new Date(order.orderTimestamp['received'])
        .toLocaleDateString(companyLanguage)
        .toString();
    return [
      {
        value: order.orderId,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'left' },
        },
      },
      {
        value: order.customer.name.toString(),
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'left' },
        },
      },
      {
        value: order.customer.phone.toString(),
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'left' },
        },
      },
      {
        value: orderTime,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'left' },
        },
      },
      {
        value: order.paymentType,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'left' },
        },
      },
      {
        value: convertIntToIntlNumberFormat(order.tip, companyLanguage),
        intValue: order.tip,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      },
      {
        value: convertIntToIntlNumberFormat(order.deliveryFee, companyLanguage),
        intValue: order.deliveryFee,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      },
      {
        value: convertIntToIntlNumberFormat(
          order.discount?.amount || 0,
          companyLanguage
        ),
        intValue: order.discount?.amount || 0,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      },
      {
        value: convertIntToIntlNumberFormat(nettAmount, companyLanguage),
        intValue: nettAmount,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      },
      {
        value: convertIntToIntlNumberFormat(order.amount, companyLanguage),
        intValue: order.amount,
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      },
    ];
  }
  return null;
}

function extractVatKeysFromOrders(orderWiseVatCollection: any) {
  let vatTitles: Array<string> = [];

  for (const order in orderWiseVatCollection) {
    Object.keys(orderWiseVatCollection[order]).forEach((key) => {
      if (!vatTitles.includes(key)) vatTitles.push(key);
    });
  }
  return vatTitles.sort((a, b) => {
    const aNum = parseInt(a.substring(3));
    const bNum = parseInt(b.substring(3));
    return aNum - bNum;
  });
}

function addAndSortVatKeysInOrders(
  orderWiseVatCollection: any,
  vatKeys: string[]
) {
  for (const order in orderWiseVatCollection) {
    vatKeys.forEach((key: string | number) => {
      if (!orderWiseVatCollection[order].hasOwnProperty(key)) {
        orderWiseVatCollection[order][key] = 0;
      }
    });
    let obj: any = {};
    for (const key of vatKeys) {
      obj[key] = orderWiseVatCollection[order][key];
    }
    orderWiseVatCollection[order] = obj;
  }
  return orderWiseVatCollection;
}

function addVatCollectionInTableRows(
  updatedOrderWiseVatCollection: any,
  tableRows: any[],
  companyLanguage: string
) {
  return tableRows.map((row: any, index: number) => {
    let arr = [];
    for (const i in updatedOrderWiseVatCollection[row[0].value]) {
      arr.push({
        value: convertIntToIntlNumberFormat(
          updatedOrderWiseVatCollection[row[0].value][i],
          companyLanguage
        ),
        intValue: updatedOrderWiseVatCollection[row[0].value][i],
        style: {
          font: { sz: 9 },
          alignment: { vertical: 'center', horizontal: 'right' },
        },
      });
    }
    row.splice(8, 0, ...arr);
    return row;
  });
}

function makeSummaryRow(
  tableData: any[],
  vatKeys: any,
  companyLanguage: string
) {
  let totalTip = 0;
  let totalDeliveryFee = 0;
  let totalDiscount = 0;
  let totalNettAmount = 0;
  let totalOrderAmount = 0;
  let totalVatCollection = vatKeys.map((key: string) => {
    return { [key]: 0 };
  });

  const indexOfVatStart = 8;
  const indexOfTip = indexOfVatStart - 3;
  const indexOfDeliveryFee = indexOfTip + 1;
  const indexOfDiscount = indexOfDeliveryFee + 1;
  const indexOfNettAmount = indexOfVatStart + vatKeys.length;
  const indexOfTotalAmount = indexOfNettAmount + 1;

  tableData.forEach((row) => {
    totalTip += parseFloat(row[indexOfTip].intValue);
    totalNettAmount += parseFloat(row[indexOfNettAmount].intValue);
    totalDeliveryFee += parseFloat(row[indexOfDeliveryFee].intValue);
    totalOrderAmount += parseFloat(row[indexOfTotalAmount].intValue);
    totalDiscount += parseFloat(row[indexOfDiscount].intValue);
    vatKeys.forEach((item: string | number, index: number) => {
      totalVatCollection[index][item] += parseFloat(
        row[indexOfVatStart + index].intValue
      );
    });
  });

  let vatColumns = [];

  for (const [key, value] of Object.entries<any>(totalVatCollection)) {
    vatColumns.push({
      value: convertIntToIntlNumberFormat(
        value[Object.keys(value)[0]],
        companyLanguage
      ),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    });
  }
  return [
    {
      value: 'TOTAL:',
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
      },
    },
    {
      value: '',
      style: {
        font: { sz: 9 },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: '',
      style: {
        font: { sz: 9 },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: '',
      style: {
        font: { sz: 9 },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: '',
      style: {
        font: { sz: 9 },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: convertIntToIntlNumberFormat(totalTip, companyLanguage),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: convertIntToIntlNumberFormat(totalDeliveryFee, companyLanguage),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: convertIntToIntlNumberFormat(totalDiscount, companyLanguage),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    ...vatColumns,
    {
      value: convertIntToIntlNumberFormat(totalNettAmount, companyLanguage),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right' },
      },
    },
    {
      value: convertIntToIntlNumberFormat(totalOrderAmount, companyLanguage),
      style: {
        font: { sz: 9, bold: true },
        fill: { fgColor: { theme: '0', tint: '-0.10' } },
        alignment: { vertical: 'center', horizontal: 'right', wrapText: true },
      },
    },
  ];
}

function convertIntToIntlNumberFormat(int: number, companyLanguage: string) {
  return new Intl.NumberFormat(companyLanguage, {
    minimumFractionDigits: 2,
  }).format(int);
}

function convertDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}.${
    month < 10 ? '0' : ''
  }${month}.${year}`;
  return formattedDate;
}
