import { useTranslation } from 'react-i18next';
import { modalConfirm } from './ConfirmationModal';
export default function AddSizesButton(props: any) {
  const { t } = useTranslation(['catalogue']);
  const handleDeleteSizes = async () => {
    props.deleteSizes(Object.keys(props.product?.sizes)[0], props.product.id);
  };
  return (
    <>
      {props.product?.sizes === undefined ||
      props.product?.sizes?.length === 0 ? (
        <button
          onClick={() => props.setOpenAddSizesModal(true)}
          className={'relative_add_button h-8 w-auto mr-2 mt-1'}
        >
          <span>+ {t('addsizes.button')}</span>
        </button>
      ) : (
        <button
          className={
            'flex relative_blue_button w-auto h-8 pl-3 pr-4 mr-2 mt-1 relative button_close'
          }
        >
          {props.product?.sizes.items.map((s: any, index: number) => (
            <span className='whitespace-pre' key={s.size}>
              {(index ? ' - ' : ' ') + s.size}
            </span>
          ))}
          <div
            onClick={() => {
              modalConfirm(
                {
                  message: t(
                    props.product === undefined
                      ? 'deleteAllSizes.label'
                      : 'deleteSizes.label'
                  ),
                  title: t('delete.label'),
                },
                handleDeleteSizes
              );
            }}
            className='ml-2 absolute -top-0.5 -right-0.5 bg-red-400 hidden h-4 w-4 rounded-full z-10 text-white focus:outline-none'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='duration-150 ease-in-out h-4 w-4'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </div>
        </button>
      )}
    </>
  );
}
