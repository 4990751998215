import * as firebase from 'firebase-logic/firebase';
import { REACT_APP_TESTING_MODE } from '../config';

export const StripeAPI = {
  createConnectedAccount: (companyId: string) => {
    const url = REACT_APP_TESTING_MODE(companyId)
      ? 'https://us-central1-droov-group-1.cloudfunctions.net/testStripeAccount/create-connected-account'
      : 'https://europe-west3-droov-group-1.cloudfunctions.net/stripeAccountEU/create-connected-account';
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
  },
  createAccountLink: (companyId: string) => {
    const body = new URLSearchParams();
    // eslint-disable-next-line no-restricted-globals
    body.append('refreshUrl', `${location.origin}/settings/payment`);
    // eslint-disable-next-line no-restricted-globals
    body.append('returnUrl', `${location.origin}/settings/payment`);
    const url = REACT_APP_TESTING_MODE(companyId)
      ? 'https://us-central1-droov-group-1.cloudfunctions.net/testStripeAccount/create-account-link'
      : 'https://europe-west3-droov-group-1.cloudfunctions.net/stripeAccountEU/create-account-link';
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
    });
  },
  getAccountInformation: (companyId: string, stripeAccountId: string) => {
    const body = new URLSearchParams();
    // eslint-disable-next-line no-restricted-globals
    const url = REACT_APP_TESTING_MODE(companyId)
      ? 'https://us-central1-droov-group-1.cloudfunctions.net/testStripeAccount/get-stripe-account'
      : 'https://europe-west3-droov-group-1.cloudfunctions.net/stripeAccountEU/get-stripe-account';
    body.append('stripeAccountId', stripeAccountId);
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
    });
  },
  verifyDomain: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(
        'https://europe-west3-droov-group-1.cloudfunctions.net/stripeAccountEU/verify-domain',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });
  },
  /*getDomainList: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(
        'https://us-central1-droov-group-1.cloudfunctions.net/stripeAccount/my-apple-pay-domains',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });
  },
  addAppleDomain: () => {
    return firebase.auth.currentUser?.getIdToken(true).then((token) => {
      return fetch(
        'https://us-central1-droov-group-1.cloudfunctions.net/stripeAccount/add-apple-pay-domain',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });
  },*/
};
