/**
 * Function to calculate vatAmount and netto/brutto price from netto/brutto price. Returns numbers vatAmount, nettoPrice and price
 * @param {number} price - depending on isPriceNetto the function expects price to be the netto price (if isPriceNetto:true) else brutto price to calculate vatAmount and complementary netto/brutto price from
 * @param {boolean} isPriceNetto
 * @param {number} vat
 */

export default function calculateVatFromPrice(
  price: number,
  isPriceNetto: boolean,
  vat: number
) {
  if (isPriceNetto) {
    // netto price entered => calculate vatAmount and *bruttoPrice* from that
    const vatAmount = parseFloat(
      ((Number(vat) / 100) * Number(price)).toFixed(2)
    );
    const bruttoPrice = parseFloat((Number(price) + vatAmount).toFixed(2));
    return {
      vatAmount: vatAmount,
      nettoPrice: Number(price),
      price: bruttoPrice,
    };
  } else {
    // brutto price entered => calculate vatAmount and *nettoPrice* from that
    const nettoPrice = parseFloat(
      (Number(price) / ((100 + Number(vat)) / 100)).toFixed(2)
    );
    const vatAmount = parseFloat((Number(price) - nettoPrice).toFixed(2));
    return {
      price: Number(price),
      vatAmount: vatAmount,
      nettoPrice: nettoPrice,
    };
  }
}
