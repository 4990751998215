import {Menu, Transition} from '@headlessui/react';
import {useEffect, Fragment, useState} from 'react';
import ExpandArrow from 'ui-components/ExpandArrow';
import moment from 'moment';
import lastNEntities from 'utils/dashboardLastNEntities';
import NInterval, {dayInterval} from 'utils/dashboardNInterval';
import {ChartState, ViewType} from '../../typings/dashboardViewType';
import {useTranslation} from 'react-i18next';
import useWindowScroll from '../../hooks/useWindowScroll';

type DropdownMenuProps = {
  viewTypeLabels: Record<ViewType, string>;
  dropDownOptions: Record<ViewType, () => void>;
  open: boolean;
  close: () => void;
  currentViewType?: ViewType;
};
const DropdownMenu = ({
  viewTypeLabels,
  dropDownOptions,
  open,
  close,
  currentViewType,
}: DropdownMenuProps) => {
  useWindowScroll(() => {
    close();
  });
  return (
    <>
      <Menu.Button
        className='flex bg-white items-center justify-between rounded-lg w-full md:max-w-xs h-10 px-4 shadow-sm cursor-pointer hover:shadow-none group'
        title={viewTypeLabels[currentViewType || ViewType.TODAY]}
      >
        <span className='font-medium truncate'>
          {viewTypeLabels[currentViewType || ViewType.TODAY]}
        </span>
        <ExpandArrow
          styles='w-3 ml-3 transform group-hover:text-black'
          expanded={open}
        />
      </Menu.Button>
      <Transition
        show={open}
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute top-10 py-1.5 right-0 z-50 mt-2 w-48 bg-white rounded-lg shadow-lg'>
          {(Object.keys(ViewType) as ViewType[]).map((key, index) => (
            <Menu.Item key={index}>
              {({active}) => (
                <div
                  onClick={() => {
                    close();
                    dropDownOptions[ViewType[key]]();
                  }}
                  className={`py-2 pl-2 cursor-pointer ${
                    active ? 'bg-gray-50' : ''
                  }`}
                >
                  <p className='ml-2'>{viewTypeLabels[ViewType[key]]}</p>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </>
  );
};

export default function ChartDropDown({
  setChartState,
  initChartState,
  currentViewType,
}: {
  setChartState: (state: ChartState) => void;
  initChartState: (state: ChartState) => void;
  currentViewType?: ViewType;
}) {
  const {t} = useTranslation(['dashboard']);

  const viewTypeLabels = {
    [ViewType.TODAY]: t('today.label'),
    [ViewType.YESTERDAY]: t('yesterday.label'),
    [ViewType.LASTSEVENDAYS]: t('lastSevenDays.label'),
    [ViewType.LASTTHIRTYDAYS]: t('lastThirtyDays.label'),
    [ViewType.LASTTHREEMONTH]: t('lastThreeMonth.label'),
    [ViewType.LASTSIXMONTH]: t('lastSixMonth.label'),
  };

  const [chartOptions] = useState({
    today: dayInterval(),
    yesterday: dayInterval(),
    lastSevenDays: moment().subtract(7, 'day'),
    lastThirtyDays: moment().subtract(30, 'day'),
    lastThreeMonths: moment().subtract(3, 'month'),
    lastSixMonths: moment().subtract(6, 'month'),
    lastTwelveMonths: moment().subtract(12, 'month'),
  });

  const lastChartOptions = {
    beforeToday: dayInterval((m) => m.subtract(1)),
    beforeYesterday: dayInterval((m) => m.subtract(2)),
    beforeLastSevenDays: moment().subtract(14, 'day'),
    beforeLastLastThirtyDays: moment().subtract(60, 'day'),
    beforeLastThreeMonths: moment().subtract(6, 'month'),
    beforeLastSixMonths: moment().subtract(12, 'month'),
    beforeLastTwelveMonths: moment().subtract(24, 'month'),
  };

  const todayState = {
    // do not change entities and last entities for the user style, only for logic!
    // use chartEntities for that instead
    entities: NInterval(chartOptions.today, 'hour'),
    lastEntities: NInterval(lastChartOptions.beforeToday, 'hour'),
    barThickness: 20,
    viewType: ViewType.TODAY,
    chartEntities: NInterval(chartOptions.today, 'hour'),
  };

  const dropDownOptions: Record<ViewType, () => void> = {
    [ViewType.TODAY]: () => setChartState(todayState),
    [ViewType.YESTERDAY]: () => {
      setChartState(
        {
          entities: NInterval(chartOptions.yesterday, 'hour'),
          lastEntities: NInterval(lastChartOptions.beforeYesterday, 'hour'),
          barThickness: 20,
          viewType: ViewType.YESTERDAY,
          chartEntities: NInterval(chartOptions.yesterday, 'hour'),
        }
      );
    },
    [ViewType.LASTSEVENDAYS]: () => {
      setChartState(
        {
          entities: lastNEntities(
            chartOptions.today,
            chartOptions.lastSevenDays,
            'day'
          ),
          lastEntities: lastNEntities(
            chartOptions.lastSevenDays,
            lastChartOptions.beforeLastSevenDays,
            'day'
          ),
          barThickness: 100,
          viewType: ViewType.LASTSEVENDAYS,
          chartEntities: lastNEntities(
            chartOptions.today,
            chartOptions.lastSevenDays,
            'day',
             true
          ),
        }
      );
    },
    [ViewType.LASTTHIRTYDAYS]: () => {
      setChartState(
        {
          entities: lastNEntities(
            chartOptions.today,
            chartOptions.lastThirtyDays,
            'day'
          ),
          lastEntities: lastNEntities(
            chartOptions.lastThirtyDays,
            lastChartOptions.beforeLastLastThirtyDays,
            'day'
          ),
          barThickness: 20,
          viewType: ViewType.LASTTHIRTYDAYS,
          chartEntities: lastNEntities(
            chartOptions.today,
            chartOptions.lastThirtyDays,
            'day',
            true
          ),
        }
      );
    },
    [ViewType.LASTTHREEMONTH]: () => {
      setChartState(
        {
          entities: lastNEntities(
            chartOptions.today,
            chartOptions.lastThreeMonths,
            'month'
          ),
          lastEntities: lastNEntities(
            chartOptions.lastThreeMonths,
            lastChartOptions.beforeLastThreeMonths,
            'month'
          ),
          barThickness: 200,
          viewType: ViewType.LASTTHREEMONTH,
          chartEntities: lastNEntities(
            chartOptions.today,
            chartOptions.lastThreeMonths,
            'month'
          ),
        }
      );
    },
    [ViewType.LASTSIXMONTH]: () => {
      setChartState(
        {
          entities: lastNEntities(
            chartOptions.today,
            chartOptions.lastSixMonths,
            'month'
          ),
          lastEntities: lastNEntities(
            chartOptions.lastSixMonths,
            lastChartOptions.beforeLastSixMonths,
            'month'
          ),
          barThickness: 100,
          viewType: ViewType.LASTSIXMONTH,
          chartEntities: lastNEntities(
            chartOptions.today,
            chartOptions.lastSixMonths,
            'month'
          ),
        }
      );
    },
  };

  useEffect(() => {
    initChartState(todayState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Menu as='div' className='relative'>
      {({open, close}) => (
        <DropdownMenu
          viewTypeLabels={viewTypeLabels}
          dropDownOptions={dropDownOptions}
          open={open}
          close={close}
          currentViewType={currentViewType}
        />
      )}
    </Menu>
  );
}
