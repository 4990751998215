export const convertToCurrency = (
  amount: number | string,
  language: string | undefined,
  currency: string
) => {
  if (!language || !currency) {
    return +amount;
  }
  try {
    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
    }).format(+amount);
  } catch (e) {
    return +amount;
  }
};
export const getCurrencySymbol = (
  language: string | undefined,
  currency: string
) => {
  if (!language || !currency) {
    return '';
  } else
    try {
      return (0)
        .toLocaleString(language, {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
    } catch (e) {
      return '';
    }
};
