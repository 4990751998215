import { useEffect, useState } from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FoodLabels } from 'types';
import { enumKeys } from 'utils/enumFunctions';
import { useTranslation } from 'react-i18next';

const ModalPopup = ({
  children,
  cancel,
  openAddEntityModal,
  setOpenEntityModal,
  setOpenEntityModalTwo,
  openAddEntityModalTwo,
  modal,
  modalType,
  CreateAddonButton,
}: any) => {
  const { t } = useTranslation(['catalogue']);
  const cancelButtonRef = useRef<HTMLDivElement>(null);
  const text = (modalType: any, type: any) => {
    if (modalType === 'labels') {
      return `addlabels.${type}`;
    } else if (modalType === 'addons') {
      return `addAddons.${type}`;
    } else {
      return `addSizes.${type}`;
    }
  };
  return (
    <Transition.Root show={openAddEntityModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className=' fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={!!openAddEntityModal}
        onClose={cancel}
      >
        <div className=' flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className=' fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form
              onSubmit={(e) => modal(e)}
              style={{
                minWidth: '35rem',
                minHeight: '24rem',
                maxWidth: '40rem',
              }}
              className='inline-block align-bottom bg-white rounded-xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle p-6'
            >
              <div style={{ minHeight: '24rem' }} className='flex flex-col'>
                <div className='flex-1'>
                  <h1 className=' text-lg flex items-center'>
                    <b>{t(text(modalType, 'label'))}</b>
                  </h1>
                  <p className=' text-gray-400 mt-2 mb-4'>
                    {t(text(modalType, 'text'))}
                  </p>
                  {children}
                </div>
                <div>
                  {CreateAddonButton && (
                    <div
                      onClick={() => {
                        setOpenEntityModalTwo(true);
                        setOpenEntityModal(false);
                      }}
                    >
                      <CreateAddonButton />
                    </div>
                  )}
                  <div className='flex justify-end'>
                    <button
                      type='button'
                      className='cancel_button h-9 w-24 mr-2'
                      onClick={cancel}
                    >
                      {t('cancel.button')}
                    </button>
                    <button
                      className='relative_blue_button h-9 w-24'
                      type='submit'
                    >
                      {t('save.button')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalPopup;
