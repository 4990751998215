import db, { storage } from './../firebase';
import firebase from 'firebase';
import toast from 'react-hot-toast';
import { Product } from '../../types';

const firebaseCreateAProduct = async (
  user: any,
  product: Product,
  catID: string
) => {
  try {
    const loading = toast.loading('Loading...');
    const document = await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .add(product);
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('categories')
      .doc(catID)
      .set(
        { products: firebase.firestore.FieldValue.arrayUnion(document.id) },
        { merge: true }
      );
    toast.success('Product added', { id: loading });
    return document.id;
  } catch (e) {
    toast.dismiss();
    toast.error('Product not added');
  }
};
//updateAnItem can be called either with "product" or "imageURL".
//If imageURL is defined, it will update the image, else, it will try to update the product info (without image)
const updateAnItem = async (
  user: any,
  productId: string,
  product?: Product
) => {
  if (!productId) {
    return;
  }
  try {
    const loading = toast.loading('Loading...');
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({
        name: product?.name,
        description: product?.description,
        price: product?.price,
        nettoPrice: product?.nettoPrice,
        vat: product?.vat,
        ...(product?.vatAmount && { vatAmount: product?.vatAmount }),
        ...(product?.addons && { addons: product?.addons }),
        ...(product?.sizes && { sizes: product?.sizes }),
      });
    toast.success('Product updated', { id: loading });
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error('Product not updated');
    return;
  }
};

const updateProductImage = async (
  user: any,
  productId: string,
  imgURL?: string
) => {
  if (!productId) {
    return;
  }
  try {
    const loading = toast.loading('Loading...');
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(productId)
      .update({ imgURL: imgURL });
    toast.success('Image uploaded', { id: loading });
  } catch (e) {
    toast.dismiss();
    toast.error('Image not uploaded');
  }
};
const firebaseDeleteAProduct = async (
  user: any,
  product: any,
  categoryId: any
) => {
  try {
    // Deletes the product from the products catalogue
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('products')
      .doc(product.id)
      .delete();

    // deletes the product from the category
    await db
      .collection('companies')
      .doc(user)
      .collection('catalogue')
      .doc('catalogue')
      .collection('categories')
      .doc(categoryId)
      .update({
        products: firebase.firestore.FieldValue.arrayRemove(product.id),
      });

    const storageRef = storage.ref();
    const fileRef = storageRef.child(user + '/products/' + product.id);

    if (
      !product?.demo &&
      product?.imgURL !== undefined &&
      product.imgURL !== ''
    ) {
      //if product has an image
      //await fileRef.getDownloadURL();
      await fileRef.delete();
    }

    toast.success('Success');
  } catch (e) {}
};

const changeOrderOfProducts = async (user: any, catsToUpdate: Array<any>) => {
  for (let i = 0; i < catsToUpdate.length; i++) {
    try {
      await db
        .collection('companies')
        .doc(user)
        .collection('catalogue')
        .doc('catalogue')
        .collection('categories')
        .doc(catsToUpdate[i]?.id)
        .set({ products: catsToUpdate[i]?.products }, { merge: true });
    } catch (e) {
      console.log(e);
    }
  }
};

export {
  firebaseCreateAProduct,
  updateAnItem,
  updateProductImage,
  firebaseDeleteAProduct,
  changeOrderOfProducts,
};
