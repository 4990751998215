const onProductDrag = (result: any, catalogue: any) => {
  const {destination, source, draggableId} = result;


  const category = catalogue.categories[source.droppableId];
  const destinationCat = catalogue.categories[destination.droppableId]

  if (category === destinationCat) {
    const newProductsIds = Array.from(category.products);
    newProductsIds.splice(source.index, 1);
    newProductsIds.splice(destination.index, 0, draggableId);

    const newCategory = {
      ...category,
      products: newProductsIds,
    };


    return {
      newState: {
        ...catalogue,
        categories: {
          ...catalogue.categories,
          [newCategory.id]: newCategory,
        },
      },
      catsToUpdate: [{id: newCategory.id, products: newProductsIds}]
    };


  }

  // Moving from one list to another
  const startTaskIds = Array.from(category.products);
  startTaskIds.splice(source.index, 1);
  const newStart = {
    ...category,
    products: startTaskIds,
  };

  const finishTaskIds = Array.from(destinationCat.products);
  finishTaskIds.splice(destination.index, 0, draggableId);
  const newFinish = {
    ...destinationCat,
    products: finishTaskIds,
  };

  return {
    newState: {
      ...catalogue,
      categories: {
        ...catalogue.categories,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    },
    catsToUpdate: [
      {
        id: newStart.id,
        products: startTaskIds
      },
      {
        id: newFinish.id,
        products: finishTaskIds,
      }]
  };
}

export default onProductDrag
