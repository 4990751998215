export default function CircularProgress(props: any) {
  const radius = props.radius;
  const percent = props.percent;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percent / 100) * circumference;
  const bigText = props.bigText;
  const smallText = props.smallText;

  if (percent > 0) {
    return (
      <div className='flex items-center justify-center'>
        <svg
          className='w-20 h-20'
          style={{
            transform: 'rotate(-90deg) scaleY(-1)',
          }}
        >
          <defs>
            <linearGradient id='grad1' x1='0%' y1='0%' x2='100%' y2='0%'>
              <stop
                offset='0%'
                style={{ stopColor: '#57D737', stopOpacity: 1 }}
              />
              <stop
                offset='100%'
                style={{ stopColor: '#49C8FF', stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <circle
            className='text-droov'
            strokeWidth='7'
            strokeDasharray={circumference}
            strokeDashoffset={progress}
            strokeLinecap='round'
            stroke='url(#grad1)'
            fill='transparent'
            r={radius}
            cx='40'
            cy='40'
          />
        </svg>
        <div className='absolute flex flex-col items-center'>
          <span className='text-droov text-xl font-bold'>{bigText}</span>
          <span className='text-gray-400 text-xs'>{smallText}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className='h-24 flex flex-col items-center justify-center text-orange_chip'>
        <svg
          width='65'
          height='60'
          viewBox='0 0 65 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M64.4377 53.8658C54.8913 36.5716 45.3409 19.2774 35.7926 1.98311C35.4741 1.38461 34.997 0.883613 34.4126 0.534149C33.8283 0.184685 33.1589 0 32.4765 0C31.7942 0 31.1247 0.184685 30.5403 0.534149C29.956 0.883613 29.4789 1.38461 29.1605 1.98311C19.6131 19.2774 10.0577 36.5716 0.515372 53.8658C-0.962135 56.5371 0.921102 60 3.82833 60H61.1288C62.7394 60 63.8214 59.1398 64.3857 57.9732C65.0956 56.8619 65.2857 55.3865 64.4417 53.8639'
            fill='currentColor'
            fillOpacity='0.26'
          />
          <path
            d='M30.6045 36.8237L30.0517 29.2497C29.9485 27.7745 29.8988 26.7145 29.8988 26.0714C29.8988 25.1954 30.1474 24.514 30.6461 24.0223C31.1466 23.5334 31.8043 23.2878 32.62 23.2878C33.6041 23.2878 34.2651 23.6025 34.5958 24.2279C34.9297 24.8573 35.0988 25.7608 35.0988 26.9412C35.0988 27.6351 35.0578 28.3422 34.9756 29.0589L34.2358 36.854C34.1556 37.7826 33.9847 38.4942 33.7217 38.9894C33.4568 39.4868 33.0204 39.7324 32.4105 39.7324C31.7937 39.7324 31.3598 39.4931 31.1223 39.0145C30.878 38.5342 30.707 37.8038 30.6045 36.8237ZM32.5143 47.2277C31.8149 47.2277 31.205 47.0209 30.6821 46.6034C30.1593 46.1877 29.8988 45.6051 29.8988 44.857C29.8988 44.2042 30.1474 43.6462 30.6461 43.1888C31.1466 42.7285 31.7564 42.5 32.4808 42.5C33.205 42.5 33.8193 42.7285 34.3316 43.1888C34.8382 43.6462 35.0988 44.2042 35.0988 44.857C35.0988 45.5937 34.8382 46.1751 34.3242 46.5954C33.8298 47.0092 33.183 47.2352 32.5143 47.2277Z'
            fill='currentColor'
          />
        </svg>
        <div className='text-orange_chip text-center mt-1 text-sm'>
          {props.message}
        </div>
      </div>
    );
  }
}
