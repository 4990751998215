import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../utils/cropImage';
import { uploadProfileImg } from '../../../firebase-logic/couriers';
import useAuthState from '../../../global-states/useAuthState';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import CustomLoadingScreen from '../../CustomLoadingScreen';
import { useTranslation } from 'react-i18next';

const ImageCropDialog = ({
  zoomInit,
  imageUrl,
  cropInit,
  onCancel,
  setCropImage,
  productId,
  updateAProduct,
  mobileView,
}: any) => {
  const auth = useAuthState.getState();

  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [loading, setLoading] = useState(false);

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: any) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cancelButtonRef: any = useRef();

  const onCrop = async () => {
    setLoading(true);

    const croppedImageUrl: any = await getCroppedImg(
      imageUrl,
      croppedAreaPixels
    );

    // Uploads the cropped image to the storage
    const imgURL: string = (await uploadProfileImg(
      auth.user,
      croppedImageUrl,
      'products',
      productId
    ).catch((e) =>
      console.log('the image could not be uploaded with ', e)
    )) as string;

    // Adds the imgURL to the product in the firestore
    updateAProduct(productId, undefined, imgURL);
    // when the image is uploaded the cropping process is reset to null
    setCropImage({ image: null, productId: '' });

    setLoading(false);
  };

  const { t } = useTranslation(['common']);

  return (
    <Transition.Root show={setCropImage.image !== null} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={!!setCropImage.image === null}
        onClose={onCancel}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            {loading ? (
              <CustomLoadingScreen />
            ) : (
              <div className='inline-block align-bottom w-96 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg'>
                <div className=' '>
                  {/* <div className="h-4/5"> */}
                  <div className='relative h-96'>
                    {mobileView ? (
                      <Cropper
                        image={imageUrl}
                        zoom={zoom}
                        crop={crop}
                        aspect={1.5}
                        onCropChange={onCropChange}
                        onZoomChange={onZoomChange}
                        onCropComplete={onCropComplete}
                      />
                    ) : (
                      <Cropper
                        minZoom={0.1}
                        image={imageUrl}
                        zoom={zoom}
                        crop={crop}
                        aspect={1.5}
                        restrictPosition={false}
                        onCropChange={onCropChange}
                        onZoomChange={onZoomChange}
                        onCropComplete={onCropComplete}
                      />
                    )}
                  </div>
                  <div className='w-full m-auto flex flex-col items-center justify-center'>
                    <div className='flex items-center my-7 w-full px-5'>
                      <input
                        type='range'
                        min={mobileView ? 1 : 0.1}
                        max={3}
                        step={0.01}
                        value={zoom}
                        onInput={(e: any) => {
                          onZoomChange(e.target.value);
                        }}
                        className='range-style w-full'
                      />
                    </div>

                    <div className='flex mb-5 w-full justify-end '>
                      <button
                        className='cancel_button h-9 mr-4'
                        onClick={onCancel}
                      >
                        {t('cancel.button')}
                      </button>
                      <br />
                      <button
                        className='relative_blue_button w-24 h-9 mr-5'
                        onClick={onCrop}
                      >
                        {t('save.button')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImageCropDialog;
