import { initFirebaseAuth } from 'global-states/useAuthState';
import { auth } from './firebase';

const signIn = async (email: string, password: string, sendEmail?: boolean) => {
  const userDetails = await auth.signInWithEmailAndPassword(email, password);
  if (sendEmail && !userDetails.user?.emailVerified) {
    await userDetails.user?.sendEmailVerification();
  }
  await initFirebaseAuth();

  return userDetails.user?.emailVerified;
};

const signUp = (email: string, password: string) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
    })
    .catch((error) => {
      console.log(error.message);
    });
};
const signOut = (opts: ({ reset: () => void }) | undefined) => {
  opts?.reset();
  auth.signOut();
};

const resetPassword = async (email: string) => {
  await auth.sendPasswordResetEmail(email);
};

export { signIn, signOut, signUp, resetPassword };
