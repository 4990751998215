import db from './firebase';
import toast from 'react-hot-toast';
import { auth } from './firebase';

export const searchPrefixes = async (searchText: string) => {
  let prefixes: any = [];
  const documents = await db
    .collection('prefixes')
    .where('prefix', '==', searchText)
    .get();

  documents.forEach((data) => {
    const singlePrefix = data.data();
    prefixes.push(singlePrefix);
  });

  return prefixes;
};

export const createPrefix = async (prefix: string, cid: string) => {
  try {
    const document = await db.collection('prefixes').add({
      cid,
      prefix,
    });
    return document;
  } catch (err) {
    console.log(err);
  }
};

export const firebaseCreateCompany = async (companyData: any, uid: string) => {
  try {
    const document = await db
      .collection('companies')
      .doc(uid)
      .set({
        ...companyData,
      });
    toast.success('Company created');
    return document;
  } catch (e) {
    console.log(e);
    toast.dismiss();
    toast.error('Company not created');
    return '';
  }
};

export const signUpForOnboarding = async (email: string, password: string) => {
  try {
    const userDetails = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    await userDetails.user?.sendEmailVerification();
    return { userUid: userDetails.user?.uid || '', code: '' };
  } catch (err) {
    console.log(err);
    return { userUid: '', msg: (err as { code: string }).code };
  }
};
