import React from 'react';
import useAuthState from '../global-states/useAuthState';
import { useHistory } from 'react-router-dom';
import CustomCourierCard from '../ui-components/CustomCourierCard';
import { useCouriers } from '../firebase-logic/couriers';
import { useInvitedCouriers } from '../firebase-logic/couriers';
import { CourierObject } from '../types';
import CustomHeaderComponent, {
  CustomHeaderComponentProps,
} from '../ui-components/CustomHeaderComponent';
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';
import { useTranslation } from 'react-i18next';
import User3D from '../assets/user.png';

export default function CouriersScreen() {
  const auth: any = useAuthState.getState();

  const history = useHistory();

  const { t } = useTranslation(['couriers']);

  const handleClick = (item: CourierObject | undefined) => {
    history.push('/couriers/create_courier', { data: item });
  };

  const [couriers, loading]: any = useCouriers({ companyId: auth.user });
  const [invitedCouriers, invitedCouriersLoading]: any = useInvitedCouriers({
    companyId: auth.user,
  });

  const headerComponent: CustomHeaderComponentProps = {
    heading: t('header.label'),
    buttonOnClick: () => handleClick(undefined),
    buttonString: `+ ${t('addcourier.button')}`,
  };
  return (
    <div>
      {/*Title and add courier button*/}
      <CustomHeaderComponent
        heading={headerComponent.heading}
        showButton={true}
        buttonOnClick={headerComponent.buttonOnClick}
        buttonString={headerComponent.buttonString}
      />
      {loading || invitedCouriersLoading ? (
        <CustomLoadingScreen />
      ) : (
        <>
          {/*Courier card container*/}
          <div className='flex justify-center'>
            {invitedCouriers.length + couriers.length > 0 ? (
              <div className='grid xl:grid-cols-3 md:grid-cols-2 gap-10'>
                {/*Invited Courier card*/}
                {invitedCouriers.map(
                  (courier: CourierObject, index: number) => (
                    <CustomCourierCard
                      onClicked={() => handleClick(courier)}
                      key={index}
                      courierObject={courier}
                    />
                  )
                )}
                {/*Courier card*/}
                {couriers.map((courier: CourierObject, index: number) => (
                  <CustomCourierCard
                    onClicked={() => handleClick(courier)}
                    key={index}
                    courierObject={courier}
                  />
                ))}
              </div>
            ) : (
              <div className='flex justify-center items-center h-screen'>
                <div className='text-center -mt-48'>
                  <p className='text-2xl mx-8 text-gray-400'>
                    {t('no_couriers.label')}
                  </p>

                  <img
                    className='mx-auto opacity-40'
                    src={User3D}
                    alt='Store'
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
