import React, { useEffect, useState } from 'react';
import EmojiPicker from './EmojiPicker';
import NoImage from '../assets/noImage.png';
import { CancelButton } from './CancelButton';

type Props = {
  onSelectedEmoji: Function;
  currentEmoji: string;
};
const EmojiSelector: React.FC<Props> = ({ onSelectedEmoji, currentEmoji }) => {
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(currentEmoji);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCloseBtn(toggleEmojiPicker);
    }, 200);
  }, [toggleEmojiPicker]);

  const handleEmojoSelect = (emojiObject: any) => {
    setSelectedEmoji(emojiObject.native);
    setToggleEmojiPicker(!toggleEmojiPicker);
    onSelectedEmoji(emojiObject.native);
  };

  const removeSelectedIcon = (e: any) => {
    e.stopPropagation();
    setSelectedEmoji('');
    onSelectedEmoji('');
  };

  return (
    <div className='flex flex-row relative'>
      {selectedEmoji ? (
        <div
          className='cursor-pointer mt-1 ml-2 mr-6 text-2xl rounded-full h-12 bg-white w-12 flex chip_box_shadow items-center justify-center relative'
          onClick={() => setToggleEmojiPicker(!toggleEmojiPicker)}
        >
          <button
            type='button'
            className='absolute -top-1 -right-1 bg-red-400 rounded-full z-10 text-white focus:outline-none'
            onClick={(e) => removeSelectedIcon(e)}
          >
            <CancelButton />
          </button>

          <div className='h-30 bg-white  relative '>
            <div>{selectedEmoji}</div>
            <label htmlFor='logo'>
              <div className='px-2 -top-2 -left-3 rounded-full h-12 w-12 flex justify-center items-center text-white font-Ubuntu font-bold opacity-0 hover:opacity-90 bg-gray-500 duration-500 transform cursor-pointer absolute'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z'
                  />
                </svg>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div
          className='h-30 bg-white  relative mr-2'
          onClick={() => setToggleEmojiPicker(!toggleEmojiPicker)}
        >
          <img src={NoImage} className='h-10 cursor-pointer' alt='no-img' />
          <label htmlFor='logo'>
            <div className='px-2 top-0 left-0 rounded-full h-10 w-10 flex justify-center items-center text-white font-Ubuntu font-bold opacity-0 hover:opacity-90 bg-gray-500 duration-500 transform cursor-pointer absolute'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth='2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z'
                />
              </svg>
            </div>
          </label>
        </div>
      )}

      {toggleEmojiPicker && (
        <div className='absolute left-10 z-30'>
          <div className='relative'>
            <EmojiPicker onEmojiSelect={(e: any) => handleEmojoSelect(e)} />
          </div>

          {showCloseBtn && (
            <button
              type='button'
              className='absolute -top-1 -right-1 bg-red-400 rounded-full z-10 text-white focus:outline-none'
              onClick={() => setToggleEmojiPicker(!toggleEmojiPicker)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='duration-150 ease-in-out h-4 w-4 hover:h-5 hover:w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default EmojiSelector;
