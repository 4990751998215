import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

type Props = {
  addonsForm: any;
  setAddonsForm: any;
  withImages: boolean;
  setWithImages: any;
};
export const WithImagesSwitch: React.FC<Props> = ({
  addonsForm,
  setAddonsForm,
  withImages,
  setWithImages,
}) => {
  useEffect(() => {
    setWithImages(addonsForm.withImages);
  }, [addonsForm.withImages]);
  const { t } = useTranslation(['catalogue']);
  return (
    <div
      className='flex items-center mt-8'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!withImages && (
        <span className=' italic text-gray-400 mr-4 hidden sm:inline-block'>
          {t('withoutimages.label')}
        </span>
      )}
      {withImages && (
        <span className=' italic text-gray-400 mr-4 hidden sm:inline-block'>
          {t('withimages.label')}
        </span>
      )}
      <Switch
        checked={withImages}
        onChange={() => {
          setWithImages(!withImages);
          setAddonsForm({ ...addonsForm, withImages: !addonsForm.withImages });
        }}
        className={`${
          withImages ? 'bg-droov' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
      >
        <span
          className={`${
            withImages ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
        />
      </Switch>
    </div>
  );
};
