import firebase from 'firebase';
import { CustomerObject } from '@common/types';
import { useGenericFirebasePaginator } from './generic-firebase-paginator';
import db from './firebase';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';
import { useEffect, useRef } from 'react';

export const useAllCustomers = ({ id, limit = 15, start, forgetOld, search, loadInit = true }: {
  id: string;
  limit?: number;
  start?: { startAfter: QueryDocumentSnapshot | undefined, setStartAfter: (q?: QueryDocumentSnapshot) => void };
  forgetOld?: boolean;
  search?: {
    value: string;
    key: 'splits.name' | 'splits.phone',
    active: boolean
  };
  loadInit?: boolean
}) => {
  // use ref for the search since paginator function after it is sent, copies context,
  // search value doesn't represent the changes
  const searchRef = useRef(search);
  const noKeyChange = useRef(true);
  const noValueChange = useRef(true);

  const paginator = (collection: firebase.firestore.CollectionReference) => {
    if (searchRef.current && searchRef.current.value) {
      const value = searchRef.current.value
        .toLowerCase()
        .trim()
      return collection
        .where(
          searchRef.current.key,
          'array-contains',
          value
        )
        .orderBy('stats.lastOrder', 'desc');
    }
    return collection
      .orderBy('stats.lastOrder', 'desc')
  };

  const [
    data,
    loading,
    loadMore,
    noMoreLoad,
    state,
    reset
  ] = useGenericFirebasePaginator<CustomerObject>({
    id,
    collection: 'customers',
    fromDataToObject: getCustomerObjectFromDataObject,
    paginator,
    loadInit,
    start,
    forgetOld,
    limit,
    activeSearch: !!search?.active,
  });

  useEffect(() => {
    if (!noValueChange.current) {
      reset();
    }
    searchRef.current = search;
    noValueChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.value]);

  useEffect(() => {
    if (search?.key && !noKeyChange.current) {
      reset();
    }

    searchRef.current = search;
    noKeyChange.current = false;
  }, [search?.key]);

  return [
    data,
    loading,
    loadMore,
    noMoreLoad,
    state,
  ] as const;
}

export const getCustomer = async ({ userId, customerId }: {
  userId: string;
  customerId: string;
}): Promise<CustomerObject | undefined> => {
  const snapshot= await db
    .collection('companies')
    .doc(userId)
    .collection('customers')
    .doc(customerId)
    .get();
  return getCustomerObjectFromDataObject(snapshot);
};

const TimestampRef = firebase.firestore.Timestamp;

const getCustomerObjectFromDataObject = (
  snapshot: firebase.firestore.DocumentSnapshot
): CustomerObject | undefined => {
  const data = snapshot.data();
  if (!data) {
    return;
  }
  const getJoinedAt = () => {
    if (data.joinedAt instanceof TimestampRef) {
      const joinedAt = data.joinedAt as firebase.firestore.Timestamp;
      return joinedAt.toDate().getTime();
    }
    return new Date(data.joinedAt).getTime()
  };

  return {
    phoneNumber: data.phoneNumber,
    name: data.name,
    uid: data.uid,
    addresses: data.addresses,
    joinedAt: getJoinedAt(),
    isAuth: data.isAuth,
    stats: data.stats,
  };
};
