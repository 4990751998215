import { useTranslation } from 'react-i18next';
import Cart3D from '../assets/cart_3d.png';
import useAuthState from '../global-states/useAuthState';
import useScreenDetection, { ScreenWidthName } from '../hooks/useScreenWidth';
import OrdersHistoryTable from './order-components/history/OrderHistoryTable';
import OrderHistoryBox from './order-components/history/OrderHistoryBox';
import { ReactNode, useEffect } from 'react';
import usePaginatedOrdersState from '../states/ordersState';

type OrdersHistoryProps = {
  phoneNumber?: string;
  size?: ScreenWidthName;
  orderClickable?: boolean;
  orderLimit?: number;
  header?: ReactNode;
  showEmpty?: boolean;
};

export default function OrdersHistoryTab({
  phoneNumber,
  size,
  orderLimit,
  header,
  orderClickable = true,
  showEmpty = true,
}: OrdersHistoryProps) {
  const screenDetection = useScreenDetection();
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['orders']);
  const [orders, ordersLoading, loadMore, noOrdersLeft, loadState] =
    usePaginatedOrdersState({ id: auth.user, phoneNumber, limit: orderLimit });
  const screenSize = size ?? screenDetection;

  return (
    <>
      {header && orders.length > 0 && loadState !== 'initial' && (
        <h4 className='mt-6 mb-3 droov_xxlarge_text'>
          {t('order_history.label')}
        </h4>
      )}
      {loadState === 'initial' && orders.length === 0 ? (
        <div className='flex justify-center align-middle'>
          <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 mt-32 h-12 w-12'></div>
        </div>
      ) : (
        <>
          {(orders === undefined || orders.length < 1) && showEmpty ? (
            <div className='flex justify-center items-center'>
              <div className='text-center mt-32'>
                <p className='text-2xl mx-8 text-gray-400'>
                  {t('no_orders.label')}
                </p>
                <img className='mx-auto opacity-40' src={Cart3D} alt='Store' />
              </div>
            </div>
          ) : (
            <>
              {screenSize === 'lg' && (
                <OrdersHistoryTable
                  scrollId={!phoneNumber ? 'scroll-element' : ''}
                  orders={orders}
                  loadMore={loadMore}
                  loading={ordersLoading}
                  noMoreLoad={noOrdersLeft}
                />
              )}
              {screenSize !== 'lg' && (
                <OrderHistoryBox
                  scrollId={!phoneNumber ? 'scroll-element' : ''}
                  orders={orders}
                  loadMore={loadMore}
                  loading={ordersLoading}
                  noMoreLoad={noOrdersLeft}
                  clickable={orderClickable}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
