import useDashboardData from './dashboardState';
import { useCustomersState } from './customersState';
import { useOrdersState } from './ordersState';


const useResetState = () => {
  const dashboardReset = useDashboardData().reset;
  const customerReset = useCustomersState().reset;
  const orderReset = useOrdersState().reset;
  return () => {
    dashboardReset();
    customerReset();
    orderReset();
  }
};

export default useResetState;