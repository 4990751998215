import { InformationBox } from '../InformationBox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useRefOnScreen from '../../hooks/useRefOnScreen';
import { CustomersViewProps } from './types';

export const CustomerBoxView = ({
  customersLoading,
  customers,
  loadMore,
  onOpenDetails
}: CustomersViewProps) => {
  const { t } = useTranslation(['customer']);

  const screenRef = useRefOnScreen<HTMLTableRowElement>(() => {
    !customersLoading && loadMore()
  });

  return (
      <div className='flex flex-wrap w-full'>
        {customers.map((customer, idx) => {
          const count = customer.stats?.lastOrder ? moment(new Date()).diff(customer.stats.lastOrder, 'day') :-1;
          const tProps = { postProcess: 'interval', count };
          const date = t('lastOrdered_interval', tProps);
          const isLast = idx === customers.length - 1;
          const ref = isLast ? screenRef : undefined;
          return (
            <InformationBox
              key={idx}
              idx={idx}
              headerText={customer.name}
              onClick={() => onOpenDetails(customer)}
              subheader={{
                main: customer.phoneNumber,
              }}
              ref={ref}
              format={false}
              date={date}
            />
          )
          }
        )}
      </div>
  );
}