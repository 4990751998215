import React, { KeyboardEventHandler } from 'react';

interface TextFieldProps {
  value?: string | number | string[] | undefined;
  onChange: (val: InputChangeEvent) => void;
  onBlur?: (val: any) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  lang?: string;
  inputMode?:
    | 'email'
    | 'text'
    | 'tel'
    | 'search'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  pattern?: string;
  step?: string;
  maxLength?: number;
  type:
    | 'email'
    | 'password'
    | 'text'
    | 'tel'
    | 'number'
    | 'time'
    | 'checkbox'
    | 'date';
  id: string;
  required: boolean;
  min?: number | string | undefined;
  max?: number | string | undefined;
  classnameadditional?: string;
  disabled?: boolean;
  checked?: boolean | undefined;
}

type InputElement = HTMLInputElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

const CustomInputField = React.forwardRef<InputElement, TextFieldProps>(
  ({ onChange, onBlur, onKeyDown, onKeyUp, ...rest }, ref) => {
    return (
      <input
        value={rest.value ?? ''}
        ref={ref as any}
        className={
          rest.classnameadditional
            ? 'mt-1 placeholder-gray-400 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ' +
              rest.classnameadditional
            : ' mt-1 placeholder-gray-400 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
        }
        onChange={(event: InputChangeEvent) => onChange(event)}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onBlur={(event: InputChangeEvent) => {
          if (onBlur) {
            onBlur(event);
          }
        }}
        {...rest}
      />
    );
  }
);

export default CustomInputField;
