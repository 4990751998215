import {SummaryStatistics} from "../ui-components/dashboard-components/StatisticalInformation";
import {ChartState, ViewType} from "../typings/dashboardViewType";
import create from "zustand";
import { DocumentSnapshot } from '@firebase/firestore-types';

const initialValue = [
  { value: '', tendency: 0 },
  { value: '', tendency: 0 },
  { value: '', tendency: 0 },
];

type DashboardDataOnly = {
  readonly summary: Omit<SummaryStatistics, 'title'>[],
  readonly chartValues: number[];
  readonly chartState?: ChartState;
  readonly loadedView?: ViewType;
  readonly collection: DocumentSnapshot[] | undefined,
}

type DashboardData = {
  setSummary: (summary: Omit<SummaryStatistics, 'title'>[]) => void,
  setChartValues: (chartValues: number[]) => void,
  setChartState: (chartState: ChartState) => void,
  setLoadedView: (view: ViewType) => void,
  setCollection: (collection: DocumentSnapshot[]) => void,
  reset: () => void,
} & DashboardDataOnly;

const useDashboardData = create<DashboardData>((set) => ({
  summary: initialValue,
  chartValues: [],
  chartState: undefined,
  loadedView: undefined,
  collection: undefined,
  setCollection(collection) {
    set({ collection })
  },
  setSummary(summary) {
    set({ summary });
  },
  setChartValues(chartValues) {
    set({ chartValues });
  },
  setChartState(chartState) {
    set({ chartState });
  },
  setLoadedView(loadedView) {
    set({ loadedView });
  },
  reset() {
    set({
      summary: initialValue,
      chartValues: [],
      chartState: undefined,
      loadedView: undefined,
      collection: undefined,
    } as DashboardDataOnly)
  }
}));


export default useDashboardData;