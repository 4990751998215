import { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import AddSizesModal from './AddSizesModal';
import AddAddonsModal from './AddAddonsModal';
import DraggableAddons from './DraggableAddons';
import { ReactComponent as DotsIcon } from '../../../assets/dots.svg';
import EditIcon from '../../EditIcon';
import UploadProductImage from '../../UploadProductImage';
import AddSizesButton from '../../AddSizesButton';
import AddAddonsButton from '../../AddAddonsButton';
import AddLabelsButton from 'ui-components/AddLabelsButton';
import AddLabelsModal from './AddLabelsModal';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../../global-states/useAuthState';
import AddAddonsPopupModal from './AddAddonsPopupModal';
import AddSizesPopupModal from './AddSizesPopupModal';

export default function DragableCatalogueItem({
  product,
  index,
  setEditProduct,
  setCropImage,
  updateAProduct,
  // setOpenCropImageDialog,
  configurator,
  addonsImages,
  setSizes,
  deleteSizes,
  deleteAddons,
  addAddons,
  addLabels,
  updateProductVisibility,
}: any) {
  const auth: any = useAuthState.getState();
  const { id, name, price, nettoPrice, description, visible } = product;

  const [openAddSizesModal, setOpenAddSizesModal] = useState(false);
  const [openAddSizesModalTwo, setOpenAddSizesModalTwo] = useState(false);
  const [openAddAddonsModal, setOpenAddAddonsModal] = useState(false);
  const [openAddAddonsModalTwo, setOpenAddAddonsModalTwo] = useState(false);
  const [openAddLabelsModal, setOpenAddLabelsModal] = useState(false);
  const [addonToEdit, setAddonToEdit] = useState(-1);
  const [visibility, setVisibility] = useState(
    visible === undefined ? true : visible
  );
  const pickAnImage = async (image: any, productId: string) => {
    setCropImage({ image, productId });
  };

  const dropArea = (snapshot: any) => (
    <div>
      {snapshot.isDraggingOver && (
        <div className='absolute -top-2 w-full h-full flex items-center justify-start text-3xl ml-10 text-white text-opacity-100'>
          <p className='z-10'>+ Drop here</p>
        </div>
      )}
    </div>
  );
  function onChangeHandler() {
    setVisibility(!visibility);
    updateProductVisibility(!visibility, product.id);
  }
  const { t } = useTranslation(['catalogue']);
  return (
    <Draggable draggableId={id} index={index}>
      {(providedd) => (
        <div
          {...providedd.draggableProps}
          ref={providedd.innerRef}
          id='draggable'
          className={
            'relative border border-transparent bg-white hover:border-droov rounded-box max-w-full mb-2 group overflow-hidden cursor-default'
          }
        >
          <Droppable
            droppableId={product.id}
            type='configurator'
            direction='horizontal'
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={
                  snapshot.isDraggingOver
                    ? 'bg-droov opacity-60 rounded-box duration-500 '
                    : ''
                }
              >
                {dropArea(snapshot)}

                <div
                  className={
                    'flex ' + (snapshot.isDraggingOver && ' opacity-40')
                  }
                >
                  <div className='flex p-1 sm:p-3 flex-1 h-20 sm:h-28'>
                    <div className='flex flex-col items-center'>
                      <h1 className='text-gray-400 text-lg '>{index + 1}</h1>
                      <div {...providedd.dragHandleProps}>
                        <DotsIcon className='xl:opacity-0 xl:duration xl:duration-300 xl:group-hover:opacity-100 mt-4' />
                      </div>
                    </div>

                    <div className='flex-1 pr-2 ml-1 sm:ml-3 md:mr-4 h-20'>
                      <h1 className='text-gray_bold_text text-lg mb-1'>
                        <b className='line-clamp-1'>{name}</b>
                      </h1>
                      <p className='text-gray-700 text-sm line-clamp-2'>
                        {description}
                      </p>
                    </div>

                    <div
                      className='flex flex-row justify-end'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Switch
                        checked={visibility}
                        onChange={onChangeHandler}
                        className={`${
                          visibility ? 'bg-droov' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
                      >
                        <span
                          className={`${
                            visibility ? 'translate-x-6' : 'translate-x-1'
                          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
                        />
                      </Switch>
                    </div>

                    <div className='flex flex-col justify-between mr-1 sm:mr-4 sm:my-0'>
                      <div
                        className='flex relative items-center justify-end cursor-pointer'
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditProduct(product.id);
                        }}
                      >
                        <EditIcon />
                      </div>
                      <h1 className='text-darker_blue font-bold'>
                        {`${t('from.label')} `}
                        {new Intl.NumberFormat(auth.companyLanguage, {
                          style: 'currency',
                          currency: auth.companyCurrency,
                          currencyDisplay: 'symbol',
                          minimumFractionDigits: 2,
                        }).format(auth.showNetto ? nettoPrice : price)}
                      </h1>
                    </div>
                    <UploadProductImage
                      product={product}
                      pickAnImage={pickAnImage}
                      visibility={visibility}
                    />
                  </div>
                </div>

                <div className='px-1 pb-1 sm:px-3 sm:pb-3 '>
                  <div
                    className={
                      'flex flex-wrap ' +
                      (snapshot.isDraggingOver && ' opacity-40')
                    }
                  >
                    <AddSizesButton
                      deleteSizes={deleteSizes}
                      product={product}
                      setOpenAddSizesModal={setOpenAddSizesModal}
                    />

                    <AddAddonsButton
                      setOpenAddAddonsModal={
                        configurator?.addons.length === 0
                          ? setOpenAddAddonsModalTwo
                          : setOpenAddAddonsModal
                      }
                      classNameAdditional='rounded-button duration-200  ease-in delay-75 text-sm flex justify-center items-center  w-auto
                  text-green_chip border-2 hover:text-white border-green_chip hover:bg-green_chip h-8  px-4  mr-2 mt-1'
                    />
                    <AddLabelsButton
                      setOpenAddLabelsModal={setOpenAddLabelsModal}
                      product={product}
                      classNameAdditional='rounded-button duration-200  ease-in delay-75 text-sm flex justify-center items-center  w-auto
                  text-orange_chip border-2 hover:text-white border-orange_chip hover:bg-orange_chip h-8  px-4  mr-2 mt-1'
                    />
                  </div>

                  {
                    // @ts-ignore
                    product?.addons !== {} && (
                      <DraggableAddons
                        deleteAddons={deleteAddons}
                        configurator={configurator}
                        setAddonToEdit={setAddonToEdit}
                        addonToEdit={addonToEdit}
                        snapshot={snapshot}
                        product={product}
                      />
                    )
                  }
                  {/* Provided placeholder if uncommented removes the warning "DroppableProvided > placeholder could not be found." but it changes div width */}
                  {/* {provided.placeholder} */}
                </div>
              </div>
            )}
          </Droppable>
          <AddSizesModal
            setSizes={setSizes}
            deleteSizes={deleteSizes}
            product={product}
            producctt={product}
            closeAddSizesModal={setOpenAddSizesModalTwo}
            openAddSizesModal={openAddSizesModalTwo}
            configurator={configurator}
          />
          <AddSizesPopupModal
            setAddSizesModal={setOpenAddSizesModal}
            openAddSizesModal={openAddSizesModal}
            openAddSizesModalTwo={openAddSizesModalTwo}
            setOpenAddSizesModalTwo={setOpenAddSizesModalTwo}
            product={product}
            setSizes={setSizes}
            configurator={configurator}
          />
          <AddAddonsModal
            deleteAddons={deleteAddons}
            configurator={configurator}
            addonToEdit={addonToEdit}
            addonsImages={addonsImages}
            addAddons={addAddons}
            product={product}
            setAddonToEdit={setAddonToEdit}
            setOpenAddAddonsModal={setOpenAddAddonsModalTwo}
            openAddAddonsModal={openAddAddonsModalTwo}
            updateAProduct={updateAProduct}
          />
          <AddAddonsPopupModal
            openAddAddonsModal={openAddAddonsModal}
            configurator={configurator}
            addAddons={addAddons}
            deleteAddons={deleteAddons}
            product={product}
            openAddAddonsModalTwo={openAddAddonsModalTwo}
            setOpenAddAddonsModalTwo={setOpenAddAddonsModalTwo}
            setOpenAddAddonsModal={setOpenAddAddonsModal}
          />
          <AddLabelsModal
            addLabels={addLabels}
            product={product}
            setOpenAddLabelsModal={setOpenAddLabelsModal}
            openAddLabelsModal={openAddLabelsModal}
          />
        </div>
      )}
    </Draggable>
  );
}
