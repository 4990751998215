import React, { useEffect, useState } from 'react';
import { SingleStep } from 'typings/OnboardingModuleType';
import { OnboardingHeader } from '../ui-components/onboarding/OnboardingHeader';
import { StepHeader } from '../ui-components/onboarding/StepHeader';
import { StepsLoader } from '../ui-components/onboarding/StepsLoader';
import { StoreInfoStep } from '../ui-components/onboarding/StoreInfoStep';
import { StoreLocationStep } from '../ui-components/onboarding/StoreLocationStep';
import { StoreAuthStep } from '../ui-components/onboarding/StoreAuthStep';
import {
  useOnboardingState,
} from '../global-states/useOnboardingState';
import { StoreCreationStep } from 'ui-components/onboarding/StoreCreationStep';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Store3d from '../assets/store_3d.png';
import Lock3d from '../assets/lock_3d.png';
import Pin3d from '../assets/pin_3d.png';
import { OnboardingFooter } from '../ui-components/onboarding/OnboardingFooter';
import i18n from 'i18next';

const onboardingStateSelector = (state: any) => [
  state.onboarding,
  state.setOnboardingState,
];

export const OnboardingScreen = () => {
  const [onboarding, setOnboardingState] = useOnboardingState(
    onboardingStateSelector
  );
  const { t } = useTranslation(['onboarding']);
  const language = window.navigator.language || 'en-US';
  const i18nLang = language.split('-')[0];
  const [defaultLanguage, setDefaultLanguage] = useState(i18nLang);

  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, []);

  const onboardingSteps: SingleStep[] = [
    {
      stepNumber: 0,
      title: t('createyouronlinestore.label'),
      component: <StoreInfoStep />,
      image: (
        <img
          className='absolute top-46 left-28 opacity-10 md:block z-0 opacity-20 md:opacity-40 md:h-98 w-auto md:top-96 md:left-0 lg:top-80 lg:left-10'
          src={Store3d}
          alt='Store'
        />
      ),
    },
    {
      stepNumber: 1,
      title: t('whereareyoulocated.label'),
      component: <StoreLocationStep />,
      image: (
        <img
          className='absolute top-46 left-28 opacity-10 md:block z-0 opacity-20 md:opacity-40 md:h-98 w-auto md:top-96 md:left-0 lg:top-80 lg:left-10'
          src={Pin3d}
          alt='Pin'
        />
      ),
    },
    {
      stepNumber: 2,
      title: t('laststepauthenticate.label'),
      component: <StoreAuthStep />,
      image: (
        <img
          className='absolute top-46 left-28 opacity-10 md:block z-0 opacity-20 md:opacity-40 md:h-98 w-auto md:top-96 md:left-0 lg:top-80 lg:left-10'
          src={Lock3d}
          alt='Lock'
        />
      ),
    },
    {
      stepNumber: 3,
      title: t('yourstoreisbeingcreated.label'),
      component: <StoreCreationStep />,
      image: (
        <img
          className='absolute top-46 left-28 opacity-10 md:block z-0 opacity-20 md:opacity-40 md:h-98 w-auto md:top-96 md:left-0 lg:top-80 lg:left-10'
          src={Store3d}
          alt='Store'
        />
      ),
    },
  ];

  const [currentStep, setCurrentStep] = useState(
    onboardingSteps[onboarding.currentStep ? onboarding.currentStep : 0]
  );

  useEffect(() => {
    const updatedCurrentStep = onboardingSteps[onboarding.currentStep || 0];
    setCurrentStep(updatedCurrentStep);
  }, [i18n.language]);

  const handleBackClick = () => {
    let prevStepIdx = currentStep.stepNumber - 1;
    if (prevStepIdx < 0) prevStepIdx = currentStep.stepNumber;
    const prevStep = onboardingSteps[prevStepIdx];
    if (prevStep) {
      const temp = JSON.parse(JSON.stringify(onboarding));
      setOnboardingState({
        ...temp,
        storeInfoStep: { ...temp.storeInfoStep },
        storeLocationStep: { ...temp.storeLocationStep },
        storeAuthStep: { ...temp.storeAuthStep },
        currentStep: prevStep.stepNumber,
      });
      setCurrentStep(prevStep);
    }
  };

  const handleNextClick = (data: any) => {
    let nextStepIdx = currentStep.stepNumber + 1;
    if (nextStepIdx >= onboardingSteps.length)
      nextStepIdx = onboardingSteps.length - 1;
    const nextStep = onboardingSteps[nextStepIdx];
    if (nextStep) {
      setOnboardingState({
        ...data,
        currentStep: nextStep.stepNumber,
      });
      setCurrentStep(nextStep);
    }
  };

  return (
    <div className='overflow-x-hidden w-screen h-screen flex flex-col items-center bg-white relative justify-between'>
      {React.cloneElement(currentStep.image)}
      <OnboardingHeader defaultLanguage={defaultLanguage} />
      <div className='md:w-4/6 lg:3/5 h-full'>
        <StepHeader
          stepDetails={currentStep}
          handleBackClick={handleBackClick}
        />
        <StepsLoader
          totalSteps={onboardingSteps.length}
          currentStepNo={currentStep.stepNumber + 1}
        />
        {React.cloneElement(currentStep.component, {
          currentStep: currentStep,
          handleNextClick: handleNextClick,
        })}
      </div>
      <OnboardingFooter />

      <Toaster position='bottom-center' reverseOrder={false} />
    </div>
  );
};
