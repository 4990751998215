import db from './firebase';
import { FeedbackObject } from '../types';
import { toast } from 'react-hot-toast';

export const createCompanyFeedback = async (
  authContext: any,
  feedbackObject: FeedbackObject,
  toastSuccessMsg: string,
  toastErrorMsg: string
) => {
  try {
    if (feedbackObject.tag === undefined) {
      delete feedbackObject.tag;
    }
    await db.collection('companyFeedbacks').add({
      ...feedbackObject,
      companyId: authContext.user,
      companyName: authContext.companyName,
      creationDate: Date.now(),
    });
    toast.success(toastSuccessMsg);
    return;
  } catch (e) {
    console.log(e);
    toast.dismiss();
    toast.error(toastErrorMsg);
    return e;
  }
};
