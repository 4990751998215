import { Switch } from '@headlessui/react';
import React from 'react';
import CustomInputField from 'ui-components/CustomInputField';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  customWorkingHoursScreenProps,
  Days,
  OperatingHours,
} from '../firebase-logic/settings';
import useAuthState from '../global-states/useAuthState';
import CustomLoadingScreen from 'ui-components/CustomLoadingScreen';
import HolidaysSettings from 'ui-components/settings/HolidaysSettings';
import SectionHeader from 'ui-components/layout/SectionHeader';
import TableActionButtons from '../ui-components/layout/TableActionButtons';
import TrashIcon from '@heroicons/react/solid/TrashIcon';

export default function WorkingHoursCustomScreen({
  submitFunction,
  headingForTheTable,
  subHeadingForTheTable,
  operatingTimes,
  loading,
  setOperatingTimes,
  setIsValid,
}: customWorkingHoursScreenProps) {
  const { t } = useTranslation(['settings']);
  const auth: any = useAuthState.getState();
  const daysOfTheWeek: Map<Days, typeof t> = new Map(
    Object.values(Days).reduce<[Days, typeof t][]>(
      (acc, day) => [...acc, [day, t(`${day}.label`)]],
      []
    )
  );

  const prettifyTheSummaryData = (operatingHours: any) => {
    let prettified = '';
    // In the future, completely refactor this entire file!!!
    // Format time to AM PM style if clockFormat12 is true
    if (auth.clockFormat12) {
      operatingHours.forEach((element: OperatingHours) => {
        prettified +=
          '(' +
          moment(element.from, ['hh:mm']).format('h:mm A') +
          ' - ' +
          moment(element.to, ['hh:mm']).format('h:mm A') +
          ')\n';
      });
    } else {
      operatingHours.forEach((element: OperatingHours) => {
        prettified += '(' + element.from + ' - ' + element.to + ')\n';
      });
    }

    return prettified;
  };

  let timeSummaryData: any = [];
  daysOfTheWeek.forEach((value, key) => {
    const newObject: any = {};
    newObject['parameter'] = value;
    newObject['data'] = operatingTimes[key].operating
      ? prettifyTheSummaryData(operatingTimes[key].operatingHours)
      : t('closed.label');
    timeSummaryData.push(newObject);
  });

  const toggle = (day: Days) => {
    const valueToSet = !operatingTimes[day].operating;
    let operatingTimesTemp = { ...operatingTimes };
    operatingTimesTemp[day].operating = valueToSet;

    setOperatingTimes(operatingTimesTemp);
  };

  const addFormFieldTime = (
    e: React.MouseEvent<HTMLButtonElement>,
    day: Days
  ) => {
    e.preventDefault();

    let formFieldNew = operatingTimes[day].operatingHours;
    const fromDateBeginning: string = formFieldNew[formFieldNew.length - 1].to;
    formFieldNew.push({ from: fromDateBeginning, to: '23:59' });
    let operatingTimesTemp = { ...operatingTimes };
    operatingTimesTemp[day].operatingHours = formFieldNew;
    setOperatingTimes(operatingTimesTemp);
  };

  const removeFormFieldTime = (day: Days, index: number) => {
    let operatingTimesTemp = { ...operatingTimes };
    let formFieldNew = operatingTimes[day].operatingHours;
    formFieldNew.splice(index, 1);
    operatingTimesTemp[day].operatingHours = formFieldNew;
    setOperatingTimes(operatingTimesTemp);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    day: Days
  ) => {
    const changedTimeName: string = event.target?.name;

    let operatingTimesTemp = { ...operatingTimes };
    let formFieldNew = operatingTimes[day]?.operatingHours;
    if (changedTimeName && changedTimeName === 'from') {
      if (event.target.value >= formFieldNew[index].to) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
      formFieldNew[index].from = event.target.value;
    } else if (changedTimeName && changedTimeName === 'to') {
      if (event.target.value <= formFieldNew[index].from) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
      formFieldNew[index].to = event.target.value;
    }
    operatingTimesTemp[day].operatingHours = formFieldNew;
    setOperatingTimes(operatingTimesTemp);
  };

  // @ts-ignore
  return (
    <form
      onSubmit={submitFunction}
      className='w-full px-4 py-6 max-w-3xl mx-auto'
    >
      {loading ? (
        <CustomLoadingScreen />
      ) : (
        <div className='w-full mb-6 relative inset-0 outline-none'>
          <div className='flex-1 bg-white outline-none rounded-md'>
            <div className='p-5'>
              <SectionHeader
                title={headingForTheTable}
                description={subHeadingForTheTable}
              />
            </div>

            <div className='container mx-auto p-6 m-1'>
              <div className='w-full mb-8 overflow-hidden rounded-lg '>
                <div className='w-full overflow-x-auto'>
                  <table className='w-full outline-none'>
                    <tbody>
                      {Array.from(daysOfTheWeek.keys()).map((day) => (
                        <tr className='border-b border-gray-100' key={day}>
                          <td className='px-7 py-3 '>
                            <div className='flex items-center text-sm'>
                              <div className='relative w-8 h-8 mr-3 mt-2 md:block'>
                                <p>{daysOfTheWeek.get(day)}</p>
                              </div>
                            </div>
                          </td>
                          <td className='px-4 py-3 text-ms font-semibold '>
                            <Switch
                              id={'day'}
                              checked={operatingTimes[day].operating}
                              onChange={(e) => toggle(day)}
                              className={`${
                                operatingTimes[day].operating
                                  ? 'bg-droov'
                                  : 'bg-gray-200'
                              } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none `}
                            >
                              <span
                                className={`${
                                  operatingTimes[day].operating
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
                              />
                            </Switch>
                          </td>
                          <td className='px-4 py-3 text-sm outline-none'>
                            <label className=''>
                              {operatingTimes[day].operating
                                ? t('open.label')
                                : t('closed.label')}
                            </label>
                          </td>
                          {/* inline-block transition-all ease-in-out` */}
                          <td className='px-4 py-3 text-sm '>
                            <div
                              className={`${
                                operatingTimes[day].operating
                                  ? null
                                  : 'opacity-50  '
                              } " inline-block"`}
                            >
                              {operatingTimes[day].operatingHours?.map(
                                (element, index) => (
                                  <div key={index} className='flex'>
                                    {/*Test if us or not, */}
                                    <CustomInputField
                                      type='time'
                                      id='from'
                                      name='from'
                                      onChange={(e) => {
                                        handleChange(e, index, day);
                                      }}
                                      required={false}
                                      disabled={!operatingTimes[day].operating}
                                      value={element.from}
                                    />
                                    <div className='p-2 pt-3'>
                                      {t('to.label')}
                                    </div>
                                    <CustomInputField
                                      type='time'
                                      id='to'
                                      name='to'
                                      onChange={(e) => {
                                        handleChange(e, index, day);
                                      }}
                                      required={false}
                                      disabled={!operatingTimes[day].operating}
                                      value={element.to}
                                    />
                                    {operatingTimes[day].operatingHours
                                      ?.length > 1 ? (
                                      <button
                                        type='button'
                                        onClick={() =>
                                          removeFormFieldTime(day, index)
                                        }
                                        className='bg-white rounded-md p-2 inline-flex items-center justify-center transition ease-in-out text-gray-400 hover:text-gray-500'
                                      >
                                        <span className='sr-only'>
                                          Close menu
                                        </span>
                                        {/* <!-- Heroicon name: outline/x --> */}
                                        <TrashIcon className='w-6 cursor-pointer text-gray-400 transition ease-in-out hover:text-gray-800' />
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        disabled
                                        onClick={() =>
                                          removeFormFieldTime(day, index)
                                        }
                                        className='bg-white rounded-md ml-5 inline-flex items-center justify-center transition ease-in-out text-gray-400 hover:text-gray-500 outline-none'
                                      >
                                        <span className='sr-only outline-none'>
                                          Close menu
                                        </span>
                                        {/* <!-- Heroicon name: outline/x --> */}
                                        <TrashIcon className='w-6 cursor-pointer text-gray-400 transition ease-in-out hover:text-gray-800' />
                                      </button>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                          <td className='py-3 text-xl'>
                            <button
                              onClick={(e) => addFormFieldTime(e, day)}
                              disabled={!operatingTimes[day].operating}
                              className='border-2 border-droov text-droov cursor-pointer rounded-button mx-auto w-8 h-8 flex justify-center items-center duration-200 hover:bg-droov hover:text-white text-md'
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-6'>
            <HolidaysSettings />
          </div>
          <div className='flex w-full border-b my-8' />
          <div className={'flex flex-row justify-end gap-x-8'}>
            <TableActionButtons buttonClasses={'w-auto'} />
          </div>
        </div>
      )}
    </form>
  );
}
