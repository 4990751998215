import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createSettingForLocationDetails,
  getCompanyLocation,
  SettingsLocationObject,
} from '../firebase-logic/settings';
import useAuthState from '../global-states/useAuthState';
import CountrySelector from '../ui-components/CountrySelector';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';

import Summary from '../ui-components/layout/Summary';
import toast from 'react-hot-toast';
import { LoadScript } from '@react-google-maps/api';
import { Location } from '../types';
import { useTranslation } from 'react-i18next';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../config';

function CompanyLocationScreen() {
  const auth: any = useAuthState.getState();
  const history = useHistory();

  const { t } = useTranslation(['settings']);

  const [settingsLoading, setSettingsLoading] = useState(false);
  const [settingsLocationObject, setSettingsLocationObject] =
    useState<SettingsLocationObject>({
      streetAndHouseNumber: '',
      additionalInformation: '',
      postCode: '',
      city: '',
      country: '',
      geoCode: {} as Location,
    });

  useEffect(() => {
    getLocationSettingsAsync(auth.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationSettingsAsync = async (user: any) => {
    setSettingsLoading(true);
    try {
      const companyLocation = await getCompanyLocation(user);
      setSettingsLoading(false);
      if (companyLocation != null) {
        setSettingsLocationObject({
          streetAndHouseNumber: companyLocation.streetAndHouseNumber,
          additionalInformation: companyLocation.additionalInformation,
          postCode: companyLocation.postCode,
          city: companyLocation.city,
          country: companyLocation.country,
          geoCode: companyLocation.geoCode,
        });
      }
    } catch (error) {
      setSettingsLoading(false);
      console.log(error);
    }
  };

  const locationtSummaryData = [
    {
      parameter: t('streetname.label'),
      data: settingsLocationObject.streetAndHouseNumber,
    },
    {
      parameter: t('additionalinfo.label'),
      data: settingsLocationObject.additionalInformation,
    },
    { parameter: t('postcode.label'), data: settingsLocationObject.postCode },
    { parameter: t('city.label'), data: settingsLocationObject.city },
    { parameter: t('country.label'), data: settingsLocationObject.country },
  ];

  const onSavePressed = async (e: any) => {
    e.preventDefault();
    const toastId = toast.loading(t('loading.label'));
    try {
      if (google.maps) {
        const GeoCoder = new google.maps.Geocoder();
        GeoCoder.geocode(
          {
            address: `${settingsLocationObject.streetAndHouseNumber} ${settingsLocationObject.postCode} ${settingsLocationObject.city}`,
          },
          async (response: any) => {
            const lattitude = response[0].geometry.location.lat();
            const longitude = response[0].geometry.location.lng();
            const coordinates = { lng: longitude, lat: lattitude };

            await createSettingForLocationDetails(auth.user, {
              ...settingsLocationObject,
              geoCode: coordinates,
            });
            toast.dismiss(toastId);
            toast.success(t('updateLocation.label'));
            history.push('/settings');
          }
        );
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.log('error in saving', error);
      toast.error(t('errorSavingData.label'));
    }
  };

  return (
    <>
      <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
        <div>
          <CustomBreadCrumb
            onClickAction={() => history.goBack()}
            displayString={t('settings.label')}
          />
          <h1 className='font-medium  text-2xl pt-1'>
            {t('companylocation.label')}
          </h1>

          {settingsLoading ? (
            <CustomLoadingScreen />
          ) : (
            <form onSubmit={onSavePressed}>
              <div className='lg:flex w-full mb-6 pt-10'>
                <div className='flex-1 md:pr-4'>
                  {/*Personal details*/}
                  <div className='w-full bg-white p-4 rounded-md'>
                    <h1>{t('contactinformation.label')}</h1>
                    <p className='droov_text_description_light'>
                      {t('contactinformation.text')}
                    </p>
                    <br />
                    <div className='grid grid-cols-6 gap-6'>
                      <div className='col-span-6 lg:col-span-6'>
                        <label className='droov_label_text'>
                          {t('streetandhousenumber.label')}
                        </label>
                        <input
                          value={settingsLocationObject.streetAndHouseNumber}
                          onChange={(e) =>
                            setSettingsLocationObject({
                              ...settingsLocationObject,
                              streetAndHouseNumber: e.target.value,
                            })
                          }
                          type='text'
                          id='streetAndHouseNumber'
                          className='mt-1 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 lg:col-span-6'>
                        <label className='droov_label_text'>
                          {t('additionaladdressinformation.label')}
                        </label>
                        <input
                          value={
                            settingsLocationObject.additionalInformation
                              ? settingsLocationObject.additionalInformation
                              : ''
                          }
                          onChange={(e) =>
                            setSettingsLocationObject({
                              ...settingsLocationObject,
                              additionalInformation: e.target.value,
                            })
                          }
                          type='text'
                          id='additionalInformation'
                          className='mt-1 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          required={false}
                        />
                      </div>

                      <div className='col-span-6 lg:col-span-3'>
                        <label className='droov_label_text'>
                          {t('postcode.label')}
                        </label>
                        <input
                          value={settingsLocationObject.postCode}
                          onChange={(e) =>
                            setSettingsLocationObject({
                              ...settingsLocationObject,
                              postCode: e.target.value,
                            })
                          }
                          type='text'
                          id='postCode'
                          className='mt-1 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 lg:col-span-3'>
                        <label className='droov_label_text'>
                          {t('city.label')}
                        </label>
                        <input
                          value={settingsLocationObject.city}
                          onChange={(e: any) =>
                            setSettingsLocationObject({
                              ...settingsLocationObject,
                              city: e.target.value,
                            })
                          }
                          type='text'
                          id='city'
                          placeholder='München'
                          className='mt-1 focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          required={true}
                        />
                      </div>

                      <div className='col-span-6 lg:col-span-6 pb-4 z-0'>
                        <label className='droov_label_text'>
                          {t('country.label')}
                        </label>
                        <input
                          value={
                            settingsLocationObject.country
                              ? settingsLocationObject.country
                              : ''
                          }
                          onChange={(e) =>
                            setSettingsLocationObject({
                              ...settingsLocationObject,
                              country: e.target.value,
                            })
                          }
                          type='text'
                          id='country'
                          className='mt-1 focus:ring-droov text-gray-400 focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          required={false}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Summary summaryObject={locationtSummaryData} />
              </div>
            </form>
          )}
        </div>
      </LoadScript>
    </>
  );
}

export default CompanyLocationScreen;
