import { useEffect, useState } from 'react';

const defaultConfigScreen = [
  { name: 'sm', startsAt: 0 },
  { name: 'md',  startsAt: 600 },
  { name: 'lg', startsAt: 1024 },
] as const;

export type ScreenWidthName = (typeof defaultConfigScreen)[number]['name'];
const useScreenDetection = (sizes = defaultConfigScreen) => {
  const [screenSize, setScreenSize] = useState<ScreenWidthName>('md');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenSize(sizes.reduce<ScreenWidthName>((acc, curr) =>
        screenWidth >= curr.startsAt ? curr.name : acc,'sm'))
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return screenSize;
};

export default useScreenDetection;
