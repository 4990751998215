import { MutableRefObject, useEffect, useRef, useState } from 'react';

/**
 * Check reactively  if the element is on screen
 * @param ref
 * @param reactRef
 * @param rootMargin
 * @param threshold
 */
export default function useOnScreen<T extends Element>({
   ref,
   reactRef,
   rootMargin = '0px',
   threshold = 0.5,
}: {
  ref?: MutableRefObject<T | null>,
  rootMargin?: string,
  reactRef?: T | null,
  threshold?: number,
}): boolean {
  const createInteraction = () => new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio <= 0) return;
      setIntersecting(entry.isIntersecting);
    },
    {
      rootMargin,
      threshold,
    },
  );

  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const target = ref?.current ? ref.current : reactRef;
  const prevTarget = useRef<typeof target>(null);
  const [intersection] = useState(createInteraction());

  useEffect(() => {
    if (!target || (prevTarget.current && target.id === prevTarget.current.id)) {
      return;
    }

    setIntersecting(false);
    intersection.unobserve(prevTarget.current ?? document.body);
    intersection.disconnect();
    intersection.observe(target);
    prevTarget.current = target;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);
  return isIntersecting;
}
