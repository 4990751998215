import { useEffect, useState } from 'react';
import ModalPopup from './ModalPopup';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
const CreateSizesButton = () => {
  const { t } = useTranslation(['catalogue']);
  return (
    <div className='w-auto flex items-center mt-8 justify-center my-4'>
      <button
        type='button'
        className='relative_add_button w-34 h-10'
        onClick={() => {}}
      >
        + {t('createsizes.button')}
      </button>
    </div>
  );
};
const AddSizesPopupModal = ({
  product,
  configurator,
  setAddSizesModal,
  openAddSizesModal,
  setOpenAddSizesModalTwo,
  openAddSizesModalTwo,
  setSizes,
}: any) => {
  const [selectedSize, setSelectedSize] = useState('');

  // useEffect(() => {
  //   console.log(selectedSize);
  // }, [selectedSize]);u
  const cancel = () => {
    // setAddonsForm(addonsForm);
    setAddSizesModal(false);
  };
  const handleSave = () => {
    handleSaveForProduct();
    setAddSizesModal(false);
  };
  const handleSaveForProduct = async () => {
    let selectedSizeItems;
    let defaultSize;
    // console.log(configurator)
    configurator?.sizes.map((cardElement: any) => {
      if (selectedSize === Object.keys(cardElement)[0]) {
        // console.log(cardElement);
        // console.log(selectedSize);
        selectedSizeItems = cardElement[selectedSize].items;
        defaultSize = cardElement[selectedSize].defaultSize;
      }
    });
    setSizes(
      selectedSizeItems,
      defaultSize,
      false,
      product.id,
      undefined,
      undefined,
      product.vat,
      false,
      selectedSize
    );
  };
  const modal = (e: any) => {
    e.preventDefault();
    handleSave();
  };

  return (
    <ModalPopup
      setOpenEntityModal={setAddSizesModal}
      openAddEntityModal={openAddSizesModal}
      setOpenEntityModalTwo={setOpenAddSizesModalTwo}
      openAddEntityModalTwo={openAddSizesModalTwo}
      modalType={'sizes'}
      modal={modal}
      cancel={cancel}
      CreateAddonButton={CreateSizesButton}
    >
      <div className='flex mt-8 max-h-80 overflow-y-auto	cursor-pointer flex-wrap justify-center gap-4 auto-rows-max mb-8'>
        {configurator?.sizes.map((cardElement: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                border: `2px solid ${
                  selectedSize === Object.keys(cardElement)[0]
                    ? '#49C8FF'
                    : '#0000001A'
                }`,
                borderRadius: '20px',
              }}
              onClick={(e) => {
                e.preventDefault();
                setSelectedSize(Object.keys(cardElement)[0]);
              }}
              className={'flex justify-center items-center h-20 pl-4 pr-4'}
            >
              <div
                style={{ width: '25rem' }}
                className={
                  'my-2 h-9 flex bg-gray-100 rounded-full items-center'
                }
              >
                {cardElement[Object.keys(cardElement)[0]].items.map(
                  (sizeElements: any, index: number) => {
                    return (
                      <button
                        key={index}
                        className={`cursor-pointer 	w-44 flex-grow ml-1 mr-1 font-Ubuntu text-sm leading-4 rounded-full h-7 text-center ${
                          cardElement[Object.keys(cardElement)[0]]
                            .defaultSize === index
                            ? ' bg-white'
                            : 'bg-gray-100'
                        }`}
                      >
                        {sizeElements.size}
                      </button>
                    );
                  }
                )}
              </div>
              <div>
                {selectedSize === Object.keys(cardElement)[0] ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='text-droov top-2 right-2 ml-2 mr-2 w-6 h-6 tick'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    stroke='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                      clipRule='evenodd'
                    />
                  </svg>
                ) : (
                  <div
                    style={{
                      border: '1px solid #49C8FF',
                      borderRadius: '20px',
                    }}
                    className='w-6 h-6 top-2 right-2 ml-2 mr-2 w-6 h-6 '
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </ModalPopup>
  );
};

export default AddSizesPopupModal;
