import React, { useEffect, useRef } from 'react';
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';

function EmojiPicker(props) {
  const ref = useRef();

  useEffect(() => {
    new Picker({
      ...props,
      data,
      ref,
      set: 'native',
      categories: [
        'foods',
        'frequent',
        'flags',
        'objects',
        'activity',
        'nature',
        'people',
        'places',
        // 'symbols',
      ],
    });
  }, []);

  return <div ref={ref} />;
}

export default EmojiPicker;
