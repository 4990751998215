//having all categoryemojis and the user input, it filters the images to show to the user based on the input 
const showImages = (currentCategory: any, categoryEmojis: any): any => {
  if (currentCategory?.name === undefined) return { images: ([]), selected: currentCategory.imgURL };

  //it creates an array of strings based on input "burger King" will become an array of two strings [burger, king]
  var inputArray = currentCategory?.name?.split(" ");

  //makes input to lowercase
  inputArray = inputArray.map((element: string) => {
    return element.toLowerCase();
  });

  let outputArray: any = [];
  categoryEmojis.forEach((item: {name:Array<string>, imgURL:string}) => {
    item.name.forEach((imageName: string) => {
     
      inputArray.forEach((individualInput: string) => {
        if (imageName.toLowerCase().includes(individualInput) && !outputArray.includes(item)) {
          outputArray = [...outputArray, item]
        }
      });
    })
  });
    return { images: outputArray, selected: currentCategory.imgURL };
};

export default showImages