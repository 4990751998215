import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import LogoPaypal from 'assets/logo-paypal.png';
import { PayPalDialog } from './PayPalDialog';
import { PayPalAPI } from 'api/paypal';
import useAuthState from '../global-states/useAuthState';
import toast from 'react-hot-toast';
import db from '../firebase-logic/firebase';
import { useTranslation } from 'react-i18next';
import { PayPalDisconnectDialog } from './PayPalDisconnectDialog';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { REACT_APP_PAYPAL_API_KEY, REACT_APP_TESTING_MODE } from 'config';

export const PaypalPaymentSetting: React.FC = () => {
  const [active, setActive] = useState(false);
  const [connected, setConnected] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [disconnectDialogIsOpen, setDisconnectDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const auth: any = useAuthState.getState();

  const { t } = useTranslation(['settings']);

  const getPaymentSettingsAsync = async () => {
    setIsLoading(true);
    try {
      const docFromPath = await db.collection('companies').doc(auth.user).get();
      const doc = docFromPath.data();

      if (doc != null) {
        setActive(doc.paymentSettings.payPalPayment);

        if (doc.paymentSettings.payPal) {
          const data: any = await PayPalAPI.getAccountInformation(auth.user);
          console.log(data);
          if (data !== undefined) {
            if (!(data.payments_receivable === true)) {
              setConnected(false);
              setIsLoading(false);
              toast.error(t('paypal.error.payments_receivable'));
              return;
            }
            if (!(data.primary_email_confirmed === true)) {
              setConnected(false);
              setIsLoading(false);
              toast.error(t('paypal.error.primary_email_confirmed'));
              return;
            }
            if (
              !(
                data.oauth_integrations !== undefined &&
                data.oauth_integrations
                  .map((oauth_integration: any) => {
                    return (
                      oauth_integration.integration_type ===
                        'OAUTH_THIRD_PARTY' &&
                      oauth_integration.integration_method === 'PAYPAL' &&
                      oauth_integration.oauth_third_party
                        .map(
                          (oauth_third_party: any) =>
                            oauth_third_party.partner_client_id ===
                            REACT_APP_PAYPAL_API_KEY(auth.user)
                        )
                        .includes(true)
                    );
                  })
                  .includes(true)
              )
            ) {
              setConnected(false);
              setIsLoading(false);
              toast.error(t('paypal.error.oauth_third_party'));
              return;
            }
            // if (
            //   !data.products
            //     .map((product: any) => {
            //       return (
            //         product.name.startsWith('PPCP') &&
            //         product.vetting_status === 'SUBSCRIBED' &&
            //         product.capabilities
            //           .map((capability: any) =>
            //             capability.includes('CUSTOM_CARD_PROCESSING')
            //           )
            //           .includes(true)
            //       );
            //     })
            //     .includes(true)
            // ) {
            //   setConnected(false);
            //   setIsLoading(false);
            //   toast.error(t('paypal.error.pcp_custom_card_processing'));
            //   return;
            // }
            setConnected(true);
          }
        } else {
          setConnected(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  // Fetch payment settings once on render
  useEffect(() => {
    getPaymentSettingsAsync();
  }, []);

  const urlSearchParams = new URLSearchParams(useLocation().search);

  const merchantId = urlSearchParams.get('merchantId');
  const merchantIdInPayPal = urlSearchParams.get('merchantIdInPayPal');
  const permissionsGranted = urlSearchParams.get('permissionsGranted');
  const accountStatus = urlSearchParams.get('accountStatus');
  const consentStatus = urlSearchParams.get('consentStatus');
  const isEmailConfirmed = urlSearchParams.get('isEmailConfirmed');
  const returnMessage = urlSearchParams.get('returnMessage');
  const riskStatus = urlSearchParams.get('riskStatus');

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (
        merchantId != null &&
        merchantIdInPayPal !== null &&
        permissionsGranted !== null &&
        accountStatus !== null &&
        consentStatus !== null &&
        isEmailConfirmed !== null
      ) {
        await db.collection('companies').doc(auth.user).update({
          'paymentSettings.payPal.merchantId': merchantIdInPayPal,
          'paymentSettings.payPal.permissionsGranted': permissionsGranted,
          'paymentSettings.payPal.accountStatus': accountStatus,
          'paymentSettings.payPal.consentStatus': consentStatus,
          'paymentSettings.payPal.isEmailConfirmed': isEmailConfirmed,
          'paymentSettings.payPal.returnMessage': returnMessage,
          'paymentSettings.payPal.riskStatus': riskStatus,
        });
        urlSearchParams.delete('merchantId');
        urlSearchParams.delete('merchantIdInPayPal');
        urlSearchParams.delete('permissionsGranted');
        urlSearchParams.delete('accountStatus');
        urlSearchParams.delete('consentStatus');
        urlSearchParams.delete('isEmailConfirmed');
        urlSearchParams.delete('returnMessage');
        urlSearchParams.delete('riskStatus');

        history.replace({
          search: urlSearchParams.toString(),
        });
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const createPartnerReferral = async (companyId: string) => {
    setIsLoading(true);
    const partnerReferral: any = await PayPalAPI.createPartnerReferral(
      companyId
    );
    if (partnerReferral.ok) {
      toast.success(t('redirection_to_onboarding'));
      partnerReferral.json().then((data: any) => {
        window.location.href = data.href;
      });
    } else {
      toast.error(t('redirection_to_onboarding.error'));
    }
    setIsLoading(false);
  };
  const toggleSwitch = () => {
    if (connected || active) {
      db.collection('companies')
        .doc(auth.user)
        .update({ 'paymentSettings.payPalPayment': !active });
      toast.success(
        !active
          ? t('paypalactivation.success')
          : t('paypaldeactivation.success')
      );
      setActive(!active);
    } else {
      toast.error(t('paypalactivation.failure'));
    }
  };

  return (
    <div className='w-full bg-white p-4 rounded-md max-w-3xl mx-auto mt-8 cursor-pointer'>
      <PayPalDisconnectDialog
        disconnectDialogIsOpen={disconnectDialogIsOpen}
        onClose={() => setDisconnectDialogIsOpen(false)}
        onDisconnectAccount={async () => {
          setIsLoading(true);
          try {
            const docFromPath = await db
              .collection('companies')
              .doc(auth.user)
              .get();
            const doc = docFromPath.data();

            if (doc != null) {
              db.collection('companies').doc(auth.user).update({
                'paymentSettings.payPal': null,
              });
            }
            setConnected(false);
          } catch (error) {
            console.error(error);
          }
          db.collection('companies')
            .doc(auth.user)
            .update({ 'paymentSettings.payPalPayment': false });
          setActive(false);
          setIsLoading(false);
          setDisconnectDialogIsOpen(false);
        }}
      />
      <PayPalDialog
        dialogIsOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        isLoading={isLoading}
        onCreateAccountLink={() => createPartnerReferral(auth.user)}
        hasCompletedOnboarding={connected}
      />
      <div className='w-full flex justify-between'>
        <h2 className='font-medium text-lg'>
          {t('acceptpaypalpayments.labels')}
        </h2>
        {isLoading ? (
          <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6'></div>
        ) : (
          <Switch
            checked={active}
            onChange={toggleSwitch}
            className={`${
              active ? 'bg-droov' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11`}
          >
            <span
              className={`${
                active ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full`}
            />
          </Switch>
        )}
      </div>
      <p className='droov_text_description_light mt-1'>
        {t('acceptpaypalpayments.text')}
      </p>
      <div className='flex justify-start gap-8 items-center my-4'>
        <img src={LogoPaypal} alt='PayPal' className='h-8' />
      </div>
      <div className='flex justify-between items-center'>
        {isLoading ? (
          <label className='italic text-base text-gray-400'>
            &#8226; {t('loading.label')}
          </label>
        ) : (
          <label
            className={`italic text-base ${
              connected ? 'text-green-400' : 'text-red-400'
            }`}
          >
            &#8226; {connected ? t('connected.label') : t('notconnected.label')}
          </label>
        )}
        {connected ? (
          <button
            className='relative_add_button h-10'
            onClick={() => {
              setDisconnectDialogIsOpen(true);
            }}
          >
            {t('disconnectpaypal.button')}
          </button>
        ) : (
          <button
            className='relative_add_button h-10'
            onClick={() => setDialogIsOpen(true)}
          >
            {t('connectpaypal.button')}
          </button>
        )}
      </div>
    </div>
  );
};
