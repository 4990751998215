import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../config';
import HomeIcon from '../assets/homeIcon.svg';
import CustomLoadingScreen from './CustomLoadingScreen';
import React, { useState } from 'react';
import { CustomerAddressObject } from '@common/types';
import toast from 'react-hot-toast';

type AddressCardProps = {
  address: Omit<CustomerAddressObject, 'instruction'> & {
    lat?: number;
    lng?: number;
    instruction?: string;
  };
  label: string;
  containerStyle?: { width: string; height: string };
  zoom?: number;
};

const googleMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  mapId: 'd5eece9d53c1b22a',
  fullscreenControl: false,
  zoomControl: false,
  gestureHandling: `none`,
};

const AddressCard = ({
  address,
  label,
  containerStyle = { width: '100%', height: '100%' },
  zoom = 14,
}: AddressCardProps) => {
  const generatedAddress = `${address.streetAndHouseNumber} ${address.postCode} ${address.city}`;
  const [lat, setLat] = useState(address.lat);
  const [lng, setLng] = useState(address.lng);

  const onLoadAddress = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: generatedAddress }, (response, _status) => {
      if (response !== null) {
        setLat(response[0].geometry.location.lat());
        setLng(response[0].geometry.location.lng());
      } else {
        toast.error('toast.outdated_address.failure', {
          duration: 3000,
        });
      }
    });
  };

  const loaded = !!lat && !!lng;

  return (
    <div className='flex flex-col lg:flex-row lg:justify-between'>
      <div className='pt-6 font-normal rounded-md text-left lg:w-1/3 mb-2 sm:mb-2 md:mb-2 lg:mb-8 lg:self-center'>
        <div className='flex'>
          <div className='flex font-medium text-md'>{label}</div>
        </div>
        <div className='text-gray-600 mt-4'>
          <div>{address.streetAndHouseNumber}</div>
          <div>
            {address.postCode} {address.city}
          </div>
        </div>
      </div>

      {REACT_APP_GOOGLE_MAPS_API_KEY && (
        <div className='rounded-md md:mt-2 lg:mt-4 overflow-hidden lg:w-1/2 h-42 lg:h-38 relative'>
          <LoadScript
            googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
            onLoad={() => {
              if (!lat || !lng) {
                onLoadAddress();
              }
            }}
          >
            {loaded ? (
              <>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat,
                    lng,
                  }}
                  zoom={zoom}
                  options={googleMapOptions}
                />
                <img
                  src={HomeIcon}
                  alt='home'
                  className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14'
                />
              </>
            ) : (
              <CustomLoadingScreen />
            )}
          </LoadScript>
        </div>
      )}
    </div>
  );
};

export default AddressCard;
