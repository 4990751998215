const createImage = (url: any) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

const MIME_TYPE = 'image/jpeg';

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param imageSrc
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc: string, pixelCrop:any) {
	const image: any = await createImage(imageSrc);
	const canvasWithBackground = document.createElement('canvas');
	canvasWithBackground.width = pixelCrop.width
	canvasWithBackground.height = pixelCrop.height

	const ctx = canvasWithBackground.getContext('2d')!;
	ctx.fillStyle = 'white';
	ctx.fillRect(0, 0, pixelCrop.width, pixelCrop.height);

	console.log(pixelCrop);
	const offset = {
		x: pixelCrop.x < 0 ? Math.abs(pixelCrop.x) : 0,
		y: pixelCrop.y < 0 ? Math.abs(pixelCrop.y) : 0,
	};

	ctx.drawImage(
		image,
		pixelCrop.x + offset.x,
		pixelCrop.y + offset.y,
		pixelCrop.width,
		pixelCrop.height,
		offset.x,
		offset.y,
		pixelCrop.width,
		pixelCrop.height,
	);
	
	return new Promise((resolve) => {
		canvasWithBackground.toBlob((file: any) => {
			resolve(file);
		}, MIME_TYPE, 0.5);
	});

}

