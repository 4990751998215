import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SubscriptionStatus } from '../../types';

type SubscriptionFailedWarningBannerProps = {
  status: SubscriptionStatus;
  setIsVisible: (val: boolean) => void;
};

export const SubscriptionFailedWarningBanner = (
  props: SubscriptionFailedWarningBannerProps
) => {
  const { t } = useTranslation(['onboarding']);
  const history = useHistory();
  return (
    <div className='fixed w-full px-2.5 pt-4 pb-4 md:w-2/3 md:mx-auto inset-x-0 top-10 overflow-hidden z-40 w-3/5 bg-droov_light flex flex-col md:flex-row justify-center items-center py-1 gap-4 align-middle rounded-lg shadow-md'>
      <div className='text-droov_dark ml-4 font-ubuntu'>
        {t('subscription_warning_p1.label') +
          ' ' +
          t(props.status + '.label') +
          '. ' +
          t('subscription_warning_p2.label')}
        .
      </div>
      <div className='flex flex-row gap-3 md:ml-auto md:mr-4'>
        <button
          className='blue_button mr-6'
          onClick={() => {
            props.setIsVisible(false);
            history.push('/settings/subscription');
          }}
        >
          {t('configure.label')}
        </button>
        <button
          type='button'
          onClick={() => props.setIsVisible(false)}
          className='bg-droov_light rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 outline-none'
        >
          {/* <!-- Heroicon name: outline/x --> */}
          <svg
            className='h-6 w-6'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            aria-hidden='true'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
