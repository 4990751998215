import db, { functionsEuropeWest3, storage } from './firebase';
import { CourierObject } from '../types';
import { useEffect, useState } from 'react';

export const createCourier = async (
  user: any,
  courierObject: CourierObject
) => {
  try {
    //fix courier phone number
    courierObject.phoneNumber = courierObject.phoneNumber.split(' ').join('');
    if (courierObject.invited) {
      // EDGE CASE <- add merge: true in the future
      // if you want for 2 companies to register this same courier
      // allow this invited courier to get to know that he's wanted by this company
      await db
        .collection('invitedCouriers')
        .doc(courierObject.phoneNumber)
        .set({ [user]: {} }, { merge: true });

      // add this number to database of courierPhoneNumbers
      await db
        .collection('courierNumbers')
        .doc(courierObject.phoneNumber)
        .set({});

      // add this courier as invited courier for this company
      await db
        .collection('companies')
        .doc(user)
        .collection('invitedCouriers')
        .doc(courierObject.phoneNumber)
        .set(courierObject);
    }
    return;
  } catch (e) {
    console.log('Courier creation error: ', e);
    return e;
  }
};

export const editCourier = async (user: any, courierObject: CourierObject) => {
  try {
    console.log('EDITING COURIER: ');
    //fix courier phone number
    courierObject.phoneNumber = courierObject.phoneNumber.split(' ').join('');
    console.log(courierObject);
    if (courierObject.invited) {
      // Edit invited courier
      await db
        .collection('companies')
        .doc(user)
        .collection('invitedCouriers')
        .doc(courierObject.phoneNumber)
        .set(courierObject);
    } else {
      // Edit normal courier
      await db
        .collection('companies')
        .doc(user)
        .collection('couriers')
        .doc(courierObject.id)
        .set(courierObject);
    }
    return;
  } catch (e) {
    console.log('Courier editing error: ', e);
    return e;
  }
};

export const updateCourierFieldsById = async (
  user: any,
  courierId: string,
  courierFields: Partial<CourierObject>
) => {
  await db
    .collection('companies')
    .doc(user)
    .collection('couriers')
    .doc(courierId)
    .update(courierFields);
};

export const uploadProfileImg = async (
  user: any,
  file: any,
  folderName: string,
  fileName: string
) =>
  new Promise((resolve, reject) => {
    try {
      // minimize the image
      const storageRef = storage.ref();
      const fileRef = storageRef.child(
        user + '/' + folderName + '/' + fileName
      );
      const uploadTask = fileRef.put(file);

      uploadTask.on(
        'state_changed', // or 'state_changed'

        {
          complete: async () => {
            // Upload completed successfully, now we can get the download URL
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          },
        }
      );
    } catch (e) {
      console.log('Cannot upload file, with err', e);
      resolve('');
    }
  });

export const deleteFileByURL = (url: string) =>
  new Promise(async (resolve, reject) => {
    try {
      const pictureRef = storage.refFromURL(url);
      //2.
      await pictureRef.delete();

      resolve('');
    } catch (e) {
      console.log('Cannot delete file, with err', e);
      resolve('');
    }
  });

export const deleteFileByPath = (user: any, folderName: any, fileName: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const pictureRef = storage.ref(`${user}/${folderName}/${fileName}`);
      //2.
      await pictureRef.delete();

      resolve('');
    } catch (e) {
      console.log('Cannot delete file, with err', e);
      resolve('');
    }
  });

export const deleteCourier = async (
  user: any,
  courierObject: CourierObject
) => {
  const deleteCourierFirebaseFunction =
    functionsEuropeWest3.httpsCallable('deleteCourier');
  const result = await deleteCourierFirebaseFunction({
    companyId: user,
    courierObject: courierObject,
    caller: 'COMPANY',
  });
  console.log('result: ', result);
};

export const useCouriers = (user: any) => {
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCouriersAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCouriersAsync = async () => {
    setLoading(true);
    let allCouriers: any = [];
    const docs = await db
      .collection('companies')
      .doc(user.companyId)
      .collection('couriers')
      .get();
    docs.forEach((data) =>
      allCouriers.push({
        id: data.id,
        ...data.data(),
        invited: false,
      })
    );
    setCouriers(allCouriers);
    setLoading(false);
  };
  return [couriers, loading];
};

export const useInvitedCouriers = (user: any) => {
  const [invitedCouriers, setInvitedCouriers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCouriersAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCouriersAsync = async () => {
    setLoading(true);
    let allCouriers: any = [];
    const docs = await db
      .collection('companies')
      .doc(user.companyId)
      .collection('invitedCouriers')
      .get();
    docs.forEach((data) =>
      allCouriers.push({
        id: data.id,
        ...data.data(),
        invited: true,
      })
    );
    setInvitedCouriers(allCouriers);
    setLoading(false);
  };
  return [invitedCouriers, loading];
};
