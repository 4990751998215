import React from 'react';

type Props = {
  buttonText: string;
  goNextHandler: any;
  disabled: boolean;
};
export const NextButton: React.FC<Props> = ({
  buttonText,
  goNextHandler,
  disabled,
}) => {
  return (
    <button
      className='btn_black transition ease-in-out'
      style={{
        opacity: disabled ? '0.3' : '1',
      }}
      disabled={disabled}
      onClick={goNextHandler}
    >
      {buttonText}
    </button>
  );
};
