import { useState } from 'react';
import { modalConfirm } from '../../ConfirmationModal';
import CustomInputField from '../../CustomInputField';
import InputFieldWithType from '../../InputFieldWithType';
import { useTranslation } from 'react-i18next';
import calculateVatFromPrice from '../../../utils/calculateVatFromPrice';
const ProductInputCard = ({
  catId,
  createProduct,
  product,
  setOpenCreateAProduct,
  setEditProduct,
  updateAProduct,
  deleteProduct,
  showNetto,
}: any) => {
  const [priceVal, setPriceVal]: any = useState(
    product ? (showNetto ? product.nettoPrice : product.price) : '0'
  );
  const [vatVal, setVatVal]: any = useState(product ? product.vat : '0');
  const [vatChanged, setVatChanged]: any = useState(false);
  const [currentProduct, setCurrentProduct]: any = useState(
    product
      ? product
      : {
          name: '',
          description: '',
          price: '',
          nettoPrice: '',
          vat: '',
          imgURL: '',
          visible: true,
        }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCurrentProduct({ ...currentProduct, [name]: value });
  };

  const updateAddonVatValues = (currProduct: any) => {
    // calculate vatAmount and nettoPrice based on showNetto field if vat changed
    const updatedAddons = currProduct.addons.map((addon: any) => {
      const itemsWithVat = addon.items.map((item: any) => {
        // addons inherit vat from parent, so we use currentProduct.vat
        const itemWithVat = calculateVatFromPrice(
          showNetto ? item.nettoPrice : item.price,
          showNetto,
          currProduct.vat
        );
        return { ...item, ...itemWithVat };
      });
      return { ...addon, items: itemsWithVat };
    });
    currProduct.addons = updatedAddons;
  };

  const updateSizeVatValues = (currProduct: any) => {
    // calculate vatAmount and nettoPrice based on showNetto field if vat changed
    const updatedSizes = currProduct.sizes.items.map((size: any) => {
      // addons inherit vat from parent, so we use currentProduct.vat
      const sizesWithVat = calculateVatFromPrice(
        showNetto ? size.nettoPrice : size.price,
        showNetto,
        currProduct.vat
      );
      return { ...size, ...sizesWithVat };
    });
    currProduct.sizes.items = updatedSizes;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('I CAME TO SUBMIT HEY!');
    if (vatChanged && currentProduct.addons) {
      updateAddonVatValues(currentProduct);
    }
    if (vatChanged && currentProduct.sizes) {
      updateSizeVatValues(currentProduct);
    }
    const priceResults = calculateVatFromPrice(
      showNetto ? currentProduct.nettoPrice : currentProduct.price,
      showNetto,
      currentProduct.vat
    );
    if (product === undefined) {
      createProduct(catId, { ...currentProduct, ...priceResults });
      setOpenCreateAProduct('');
    } else {
      updateAProduct(product.id, { ...currentProduct, ...priceResults });
      setEditProduct('');
    }
  };

  const cancelHandler = () => {
    product ? setEditProduct('') : setOpenCreateAProduct('');
  };

  const deleteHandler = async () => {
    deleteProduct(product.id, catId);
    setEditProduct('');
  };

  const { t } = useTranslation(['catalogue']);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className='border w-full rounded-box max-w-4xl relative overflow-hidden mb-2 p-3 bg-white'
    >
      <div className='sm:flex h-22 mb-3 text-gray-500'>
        <div className='sm:w-1/2 sm:mr-1 w-full mr-0'>
          <CustomInputField
            value={currentProduct?.name}
            name='name'
            maxLength={35}
            onChange={(e) => handleInputChange(e)}
            type='text'
            id='name'
            placeholder={t('productname.label')}
            classnameadditional='placeholder-italic md:col-span-1 mt-1 focus:ring-droov focus:border-droov block w-full sm:text-sm border-gray-300 rounded-md'
            required={true}
          />

          <div className='flex flex-rows'>
            <div className='w-1/2 mr-0.5'>
              <InputFieldWithType
                value={priceVal}
                type='text'
                id='price'
                name={showNetto ? 'nettoPrice' : 'price'}
                required={true}
                onInputFieldValueChanged={(e: any) => {
                  setPriceVal(e);
                  setCurrentProduct({
                    ...currentProduct,

                    ...(showNetto
                      ? {
                          nettoPrice:
                            Math.round((parseFloat(e) + Number.EPSILON) * 100) /
                            100,
                        }
                      : {
                          price:
                            Math.round((parseFloat(e) + Number.EPSILON) * 100) /
                            100,
                        }),
                  });
                }}
              />
            </div>

            <div className='w-1/2 ml-0.5 relative rounded-md shadow-sm'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none mt-1'>
                <span className='text-gray-500 sm:text-sm'>
                  {t('vat_%.label')}
                </span>
              </div>
              <InputFieldWithType
                value={vatVal}
                type='text'
                id='vat'
                name={'vat'}
                unit={t('vat_%.label')}
                required={true}
                onInputFieldValueChanged={(e: any) => {
                  setVatVal(e);
                  setVatChanged(true);
                  setCurrentProduct({
                    ...currentProduct,
                    vat:
                      Math.round((parseFloat(e) + Number.EPSILON) * 100) / 100,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className='sm:w-1/2 sm:ml-2 w-full'>
          <textarea
            value={currentProduct?.description}
            onChange={(e) => handleInputChange(e)}
            name='description'
            id='description'
            placeholder={t('description.label')}
            className='h-20 placeholder-italic resize-none row-span-2 mt-1 focus:ring-droov focus:border-droov block w-full sm:text-sm border-gray-300 rounded-md'
            required={false}
          />
        </div>
      </div>

      <div className='flex justify-between mt-4 w-auto'>
        <div className='flex justify-start'>
          {product && (
            <button
              type='button'
              onClick={() => {
                modalConfirm(
                  {
                    message: t('deleteProduct.label'),
                    title: t('delete.label'),
                  },
                  deleteHandler
                );
              }}
              className='relative_red_button text-sm w-fit px-4 h-9'
            >
              {t('delete.button')}
            </button>
          )}
        </div>

        <div className='flex justify-end'>
          <button
            type='button'
            onClick={cancelHandler}
            className='cancel_button mr-2 w-24 h-9'
          >
            {t('cancel.button')}
          </button>
          <button type='submit' className='relative_blue_button w-24'>
            {t('save.button')}
          </button>
        </div>
      </div>
    </form>
  );
};

export const CreateProductDashed = ({ onsetEditCategory }: any) => {
  const { t } = useTranslation(['catalogue']);

  return (
    <div
      onClick={() => onsetEditCategory()}
      className='add_button m-auto'
      style={{ width: 'fit-content' }}
    >
      <h1>+ {t('addnewproduct.button')}</h1>
    </div>
  );
};

export default ProductInputCard;
