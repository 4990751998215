import React, { ReactComponentElement, ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';

export interface CustomHeaderComponentProps {
  heading: string;
  buttonString?: string;
  buttonOnClick?: (arg0: any) => void;
  pulsatingBtn?: Boolean;
  breadCrumbComponent?: ReactComponentElement<any>;
  buttonComponent?: ReactNode;
  showButton?: boolean;
}

export default function CustomHeaderComponent(
  headerComponent: CustomHeaderComponentProps
) {
  const { pulsatingBtn = false } = headerComponent;
  const showButton =
    !!headerComponent.showButton &&
    !!headerComponent.buttonString &&
    !!headerComponent.buttonOnClick;
  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <br />
      <div className='flex justify-between gap-2 items-center'>
        <div className='flex flex-col'>
          {headerComponent.breadCrumbComponent ?? null}
          <h1 className={`droov_xxlarge_text`}>{headerComponent.heading}</h1>
        </div>
        {showButton ? (
          <button
            onClick={headerComponent.buttonOnClick}
            className={`add_button ${pulsatingBtn ? 'pulse-button' : ''}`}
            style={{ width: 'auto' }}
          >
            {headerComponent.buttonString}
          </button>
        ) : (
          headerComponent.buttonComponent ?? <div className="h-9 mb-4" />
        )}
      </div>
      <br />
    </>
  );
}
