import create from 'zustand';
import { DaysDateRange } from '../typings/holiday';
import useAuthState from '../global-states/useAuthState';
import { useEffect } from 'react';
import { getHolidayRanges } from '../firebase-logic/settings';
import moment from 'moment/moment';

type HolidayState = {
  holidays: DaysDateRange[];
  addHoliday: (date: DaysDateRange) => void;
  removeHoliday: (id: string) => void;
  clearHoliday: () => void;
};

const useHolidaysState = create<HolidayState>((set, get) => ({
  holidays: [],
  addHoliday: (date: DaysDateRange) => {
    set({ holidays: [...get().holidays, date] })
  },
  removeHoliday: (id: string) => {
    set({ holidays: get().holidays.filter(h => h.id !== id) } );
  },
  clearHoliday: () => set({ holidays: [] })
}));

export const useFetchHolidays = () => {
  const { addHoliday, clearHoliday  } = useHolidaysState();
  const user = useAuthState.getState();


  useEffect(() => {
    getHolidayRanges(user.user as unknown as string)
      .then(holidays => {
        holidays.forEach(holiday => {
          const dateHoliday = {
            ...holiday,
            start: moment(holiday.start).toDate(),
            end: moment(holiday.end).toDate()
          };
          addHoliday(dateHoliday)
        });
      })

    return () => clearHoliday()
  }, []);
}


export default useHolidaysState;