import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuthState from '../../../global-states/useAuthState';
import { useHistory } from 'react-router-dom';
import SkeletonLoaderLargeTable from '../../SkeletonLoaderLargeTable';
import { CateringRequestsTable } from './CateringRequestsTable';
import { CateringRequestsHistoryProps, FirebaseCateringRequest } from './types';
import { CateringRequestRow } from './CateringRequestRow';

export const CateringRequestsHistory: React.FC<CateringRequestsHistoryProps> = (
  props
) => {
  const { t } = useTranslation(['catering']);
  const auth: any = useAuthState.getState();
  const history = useHistory();

  const handleTableRowClick = (requestID: string) => {
    history.push(`/catering/${requestID}`);
  };

  return (
    <div>
      <div className='ml-2 mt-6'>
        <table className='text-left w-full divide-y divide-gray-200'>
          <thead className='bg-white border-t border-b border-gray-200 rounded-t-xl flex text-gray-900 w-full '>
            <tr className='flex w-full'>
              <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
                {t('id.label')}
              </th>
              <th className='px-2 py-3 w-4/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
                {t('customer.label')}
              </th>
              <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
                {t('eventDate.label')}
              </th>
              <th className='px-2 py-3 w-2/12 text-center text-xs font-bold text-blue-900 uppercase tracking-wider'>
                {t('peopleCount.label')}{' '}
              </th>
              <th className='px-2 py-3 w-2/12 text-center text-xs font-bold text-blue-900 uppercase tracking-wider'>
                {t('status.label')}{' '}
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className='ml-2'>
        <div className='flex justify-center'>
          {props.requestsLoading ? (
            <SkeletonLoaderLargeTable />
          ) : (
            <CateringRequestsTable
              onLoadMore={() =>
                !props.requestsLoading && props.fetchMore(auth.user)
              }
              moreOrdersLoading={props.moreRequestsLoading}
              noOrdersLeft={props.noRequestsLeft}
            >
              {props.requests &&
                props.requests.map((request: FirebaseCateringRequest) => (
                  <CateringRequestRow
                    key={request.requestID}
                    cateringRequest={request}
                    onClicked={handleTableRowClick}
                  />
                ))}
            </CateringRequestsTable>
          )}
        </div>
      </div>
    </div>
  );
};
