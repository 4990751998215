import { ReactComponent as WhiteEditIcon } from '../assets/whiteEditIcon.svg';
import { useTranslation } from 'react-i18next';
export default function AddLabelsButton(props: any) {
  const { t } = useTranslation(['catalogue']);

  return (
    <>
      {props.product?.labels === undefined ||
      props.product?.labels?.length === 0 ? (
        <button
          onClick={() => props.setOpenAddLabelsModal(true)}
          className={props.classNameAdditional}
        >
          <span>+ {t('addlabels.button')}</span>
        </button>
      ) : (
        <button
          onClick={() => {
            props.setOpenAddLabelsModal(true);
          }}
          className={
            'rounded-button duration-200 ease-in delay-75 text-sm flex justify-center items-center  text-white border-2 border-orange_chip bg-orange_chip hover:bg-hover_orange hover:border-hover_orange h-8 w-auto pl-4 pr-2 mr-2 mt-1'
          }
        >
          <span className='whitespace-pre'>
            {`${t('labels.button')} (${props.product?.labels.length})`}
          </span>

          <div className='ml-2'>
            <WhiteEditIcon className='flex items-center justify-center mt-0.5 h-5 text-white hover:text-gray-400 fill-current' />
          </div>
        </button>
      )}
    </>
  );
}
