import { useEffect, useState } from 'react';
import { fetchCategorieImages } from '../../../firebase-logic/useCatalogue';
import CustomInputField from '../../CustomInputField';
import FilteredEmojis from './FilteredEmojis';
import { modalConfirm } from '../../ConfirmationModal';
import showImages from '../../../utils/showImages';
import EmojiSelector from '../../EmojiSelector';
import { useTranslation } from 'react-i18next';
//It is the form that is used to create and update category
export const CategoryInputCard = ({
  onSaveClicked,
  onCancelClicked,
  category,
  deleteCategory,
  updateCategory,
  setEditCategory,
}: any) => {
  const [categoryEmojis, setCategoryEmojis] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({ ...category });

  useEffect(() => {
    console.log('NoLoop');
    const wrapper = async () => {
      const result = await fetchCategorieImages();
      setCategoryEmojis(result);
    };
    wrapper();
  }, []);

  const onSave = (e: any) => {
    e.preventDefault();
    //if category is set, it means that it is editing the category, else it is creating a new one
    category?.id !== undefined
      ? updateCategory(currentCategory)
      : onSaveClicked({ ...currentCategory });

    onCancelClicked(false);
  };

  const onDelete = () => {
    console.log(category.id);
    deleteCategory(category.id);
    onCancelClicked(false);
    setEditCategory('');
  };

  const imagesObject = showImages(currentCategory, categoryEmojis);
  const onSelectedEmoji = (emoji: string) => {
    console.log(emoji);
    setCurrentCategory({
      ...currentCategory,
      emoji: emoji,
      imgURL: currentCategory.imageUrl ? currentCategory.imgURL : emoji,
    });
  };

  const { t } = useTranslation(['catalogue']);

  return (
    <form
      onSubmit={(e) => onSave(e)}
      className='border rounded-md w-full  max-w-4xl relative mb-2 p-3 bg-white '
    >
      <div>
        {/* <FilteredEmojis
          filteredCategoryEmojis={imagesObject?.images}
          setCurrentCategory={setCurrentCategory}
          currentCategory={currentCategory}
        /> */}
        <div className='flex flex-row'>
          <EmojiSelector
            currentEmoji={currentCategory.emoji ? currentCategory.emoji : null}
            onSelectedEmoji={onSelectedEmoji}
          />
          <CustomInputField
            value={currentCategory.name}
            onChange={(e) =>
              setCurrentCategory({ ...currentCategory, name: e.target.value })
            }
            type='text'
            name='name'
            id='name'
            placeholder='Category Name'
            classnameadditional='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm border-gray-300 rounded-md'
            required={true}
          />
        </div>
      </div>
      <div className='flex justify-between  mt-5 mb-2'>
        <div>
          {category && (
            <button
              type='button'
              onClick={() => {
                modalConfirm(
                  {
                    message: t('deleteCategory.label'),
                    title: t('delete.label'),
                  },
                  onDelete
                );
              }}
              className='relative_red_button w-auto px-6 h-9'
            >
              {t('deletecategory.button')}
            </button>
          )}
        </div>

        <div className='flex justify-end'>
          <button
            type='button'
            onClick={() => onCancelClicked(false)}
            className='cancel_button h-9  mr-1 sm:mr-5 '
          >
            {t('cancel.button')}
          </button>
          <button type='submit' className='blue_button h-9 '>
            {t('save.button')}
          </button>
        </div>
      </div>
    </form>
  );
};

//it is the button to add a new category
export const CategoryInputCardDashed = ({ onCreateClicked }: any) => {
  const { t } = useTranslation(['catalogue']);

  return (
    <div
      onClick={() => onCreateClicked(true)}
      className='relative_add_button p-3 h-9 mb-3'
      style={{ width: '14rem' }}
    >
      <h1>+ {t('addnewcategory.button')}</h1>
    </div>
  );
};
