import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export interface TippingSettingProps {
  active: boolean;
  setActive: (active: boolean) => void;
  defaultTip: number;
  setDefaultTip: (defaultTip: number) => void;
}

export default function TippingSetting(props: TippingSettingProps) {
  const { t } = useTranslation(['settings']);

  return (
    <>
      <div className='w-full bg-white p-4 rounded-lg max-w-3xl cursor-default'>
        <div className='w-full flex justify-between'>
          <h2 className='font-medium text-lg'>{t('tipping.label')}</h2>
          <Switch
            checked={props.active}
            onChange={() => props.setActive(!props.active)}
            className={`${
              props.active ? 'bg-droov' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none`}
          >
            <span
              className={`${
                props.active ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
            />
          </Switch>
        </div>
        <p className='droov_text_description_light'>
          {t('tipping_description.label')}
        </p>

        {props.active && (
          <div className='pt-4'>
            <div className='mb-4 font-normal w-full m-auto'>
              {t('default_tip.label')}{' '}
              <span className='text-lg font-semibold'>{props.defaultTip}%</span>
            </div>
            <input
              type='range'
              min='0'
              max='100'
              className='range-style w-full'
              value={props.defaultTip}
              onChange={(e) => props.setDefaultTip(parseInt(e.target.value))}
              disabled={!props.active}
            />
          </div>
        )}
      </div>
    </>
  );
}
