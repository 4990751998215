import CustomInputField from './CustomInputField';
import SearchIcon from '@heroicons/react/outline/SearchIcon';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useFocusState from '../hooks/useFocusState';
import { useTranslation } from 'react-i18next';
import ExpandArrow from './ExpandArrow';

export type HeaderSearchOption = {
  id: number;
  name: string;
  value: string;
};

type SearchProps = {
  search: string;
  setSearch: (val: string) => void;
  selected: HeaderSearchOption;
  options: HeaderSearchOption[];
  setSelected: (val: HeaderSearchOption) => void;
  setActive: (v: boolean) => void;
  loading?: boolean;
  searchDelay?: number;
};

const HeaderSearch = ({
  search,
  setSearch,
  selected,
  options,
  setSelected,
  setActive,
  loading = false,
  searchDelay = 900,
}: SearchProps) => {
  const [input, setInput] = useState(search);
  const [debounceInput] = useDebounce(input, searchDelay);
  const ref = useRef<HTMLInputElement>(null);
  const [updateOnDelay, setUpdateOnDelay] = useState(true);

  const focusState = useFocusState(ref);
  const { t } = useTranslation(['customer']);

  useEffect(() => {
    setActive(focusState);
  }, [focusState]);

  useEffect(() => {
    if (updateOnDelay) {
      setSearch(debounceInput);
    }
    setUpdateOnDelay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceInput]);

  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setIsTyping(false);
        clearTimeout(timeout);
      }, searchDelay + 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isTyping]);

  return (
    <div className='relative w-full max-w-2/3 grid grid-cols-2 grid-rows-1 gap-2'>
      <div className='row-span-1 flex justify-end items-center'>
        <div className='relative mt-1'>
          <Menu as='div'>
            {({ open }) => (
              <>
                <Menu.Button
                  className='flex bg-white items-center justify-between rounded-lg w-full md:max-w-xs h-10 px-4 shadow-sm cursor-pointer hover:shadow-none group'
                  title={selected.name}
                >
                  <span className='font-medium truncate'>{selected.name}</span>
                  <ExpandArrow
                    styles='w-3 ml-3 transform group-hover:text-black'
                    expanded={open}
                  />
                </Menu.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute top-10 py-1.5 right-0 z-50 mt-2 w-full bg-white rounded-lg shadow-lg'>
                    {options.map((option, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <div
                            className={`py-2 px-3 cursor-pointer ${
                              active ? 'bg-gray-50' : 'text-gray-900'
                            }`}
                            onClick={() => {
                              setInput('');
                              setSelected(option);
                            }}
                          >
                            {option.name}
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <CustomInputField
        ref={ref}
        id={'search-input'}
        classnameadditional={'row-span-1 placeholder-italic'}
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
          setIsTyping(true);
        }}
        type={'text'}
        placeholder={t('search.label')}
        required={true}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setSearch(input);
            setUpdateOnDelay(false);
          }
        }}
      />
      {isTyping || loading ? (
        <div className='loader absolute right-2 -top-1 ease-linear rounded-full border-4 h-6 w-6 my-4' />
      ) : (
        <SearchIcon
          className='absolute w-4 right-2 top-1/2'
          style={{ transform: 'translateY(-40%)' }}
        />
      )}
    </div>
  );
};

export default HeaderSearch;
