import React from 'react';
import { useTranslation } from 'react-i18next';

export const OnboardingFooter = () => {
  const { t } = useTranslation(['onboarding']);

  return (
    <footer className='p-2 md:py-4 mb-2'>
      <div className='sm:flex sm:items-center sm:justify-between'>
        <ul className='flex flex-wrap items-center text-sm text-gray-400'>
          <li>
            <a
              className='cursor-pointer mr-12 hover:text-gray-900 transition ease-in-out md:mr-16'
              onClick={() => {
                window.open('https://droov.io/', '_blank') ||
                  window.location.replace('https://droov.io/');
              }}
            >
              {t('website.label')}
            </a>
          </li>
          <li>
            <a
              className='cursor-pointer mr-12 hover:text-gray-900 transition ease-in-out md:mr-16'
              onClick={() => {
                window.open('https://droov.io/policy', '_blank') ||
                  window.location.replace('https://droov.io/policy');
              }}
            >
              {t('privacypolicy.label')}
            </a>
          </li>
          <li>
            <a
              className='cursor-pointer hover:text-gray-900 transition ease-in-out'
              onClick={() => {
                window.open('https://droov.io/imprint', '_blank') ||
                  window.location.replace('https://droov.io/imprint');
              }}
            >
              {t('imprint.label')}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
