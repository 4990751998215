export default function ChooseColor({ onColorSelected, selectedColor }: any) {
  return (
    <div className='w-full pt-4 grid grid-cols-2 md:grid-cols-4 gap-4'>
      {colors.map((color) => (
        <div
          onClick={() => {
            onColorSelected(color.name);
          }}
          key={color.name}
          className={`flex items-center justify-between transition duration-200 rounded-lg shadow col-span-1 pl-1.5 pr-2 py-1.5 cursor-pointer hover:shadow-md ${
            color.name === selectedColor
              ? 'border border-droov shadow-none'
              : 'border border-white'
          }`}
        >
          <div className='flex items-center'>
            <div
              style={{ backgroundColor: color.hex }}
              className='w-8 h-8 rounded mr-2'
            />
            <span>{color.name}</span>
          </div>

          {color.name === selectedColor ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 text-droov'
              viewBox='0 0 20 20'
              fill='currentColor'
              stroke='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                clipRule='evenodd'
              />
            </svg>
          ) : (
            <div> </div>
          )}
        </div>
      ))}
    </div>
  );
}

const colors = [
  {
    name: 'Lime',
    hex: '#84cc16',
  },
  {
    name: 'Sky',
    hex: '#0ea5e9',
  },

  {
    name: 'Yellow',
    hex: '#eab308',
  },
  {
    name: 'Red',
    hex: '#ef4444',
  },
  {
    name: 'Purple',
    hex: '#a855f7',
  },
  {
    name: 'Orange',
    hex: '#f97316',
  },
  {
    name: 'Teal',
    hex: '#14b8a6',
  },
  {
    name: 'Stone',
    hex: '#78716c',
  },
  {
    name: 'Cyan',
    hex: '#06b6d4',
  },
  {
    name: 'Pink',
    hex: '#ec4899',
  },
  {
    name: 'Black',
    hex: '#27272a',
  },
  {
    name: 'Emerald',
    hex: '#059669',
  },
];
