import React, { useEffect, useState } from 'react';
import { CancelButton } from './CancelButton';
import { useTranslation } from 'react-i18next';

export default function UploadPhoto({
  imgURL,
  imgFile,
  onImgChanged,
  onDeleted,
}: any) {
  const { t } = useTranslation(['couriers']);
  const [imgUrl, setImgUrl]: any = useState(imgURL);
  const transformIntoImg = () => {
    if (!imgFile) {
      return;
    }

    let reader = new FileReader();
    reader.onload = function (e) {
      setImgUrl(e.target?.result);
    };
    reader.readAsDataURL(imgFile);
  };

  useEffect(() => {
    setImgUrl(imgURL);
  }, [imgURL, imgFile]);

  useEffect(() => {
    transformIntoImg();
    // eslint-disable-next-line
  }, [imgFile]);

  const onDeleteClicked = () => {
    onDeleted();
  };

  return (
    <div className='mt-2 flex items-center'>
      {imgUrl ? (
        <span className='inline-block h-12 w-12 rounded-full bg-gray-100 mr-3 relative'>
          <button
            type='button'
            className='absolute -top-1 -right-1 bg-red-400 rounded-full z-10 text-white focus:outline-none'
            onClick={() => onDeleteClicked()}
          >
            <CancelButton />
          </button>

          <span className='inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 mr-3 relative'>
            <img className='w-full h-full object-cover' src={imgUrl} alt='' />
          </span>
        </span>
      ) : (
        <span className='inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 mr-3'>
          <svg
            className='h-full w-full text-gray-300'
            fill='currentColor'
            viewBox='0 0 24 24'
          >
            <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
          </svg>
        </span>
      )}
      <input
        type='file'
        id='profile_img'
        name='profile_img'
        accept='image/png, image/jpeg'
        onChange={(e) => {
          onImgChanged(e.target.files?.[0]);
          e.target.value = '';
        }}
        className='hidden'
      />
      <label
        htmlFor='profile_img'
        className='cursor-pointer add_button pt-2 mt-5'
        style={{ width: 'auto' }}
      >
        {t('choosefiletoupload.button')}
      </label>
    </div>
  );
}
