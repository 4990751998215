import { useState } from 'react';
import ModalPopup from './ModalPopup';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { areArraysEqual } from 'utils/areArraysEqual';

const CreateAddonButton = () => {
  const { t } = useTranslation(['catalogue']);
  return (
    <div className='w-auto flex items-center mt-8 justify-center my-4'>
      <button
        type='button'
        className='relative_add_button w-34 h-10'
        onClick={() => {}}
      >
        + {t('createaddons.button')}
      </button>
    </div>
  );
};
const AddAddonsPopupModal = ({
  product,
  configurator,
  setOpenAddAddonsModal,
  setOpenAddAddonsModalTwo,
  openAddAddonsModal,
  openAddAddonsModalTwo,
  addAddons,
  deleteAddons,
}: any) => {
  const { t } = useTranslation(['catalogue']);
  const [addonsForm, setAddonsForm] = useState(configurator.addons);
  const [addonsCurrentSelection, setAddonsCurrentSelection] = useState(
    product?.addons
  );
  const [originalAddonsSelection, setOriginalAddonsSelection] = useState(
    product?.addons
  );
  const [uncheckedAddons, setUncheckedAddons] = useState<any>([]);
  const cancel = () => {
    setAddonsForm(addonsForm);
    setOpenAddAddonsModal(false);
  };

  const modal = (e: any) => {
    e.preventDefault();
    handleSave();
  };
  const handleSave = () => {
    toast.dismiss();
    if (areArraysEqual(originalAddonsSelection, addonsCurrentSelection)) {
      toast.error(t('no_changes_made.label'));
      return;
    }
    handleSaveForProduct();
    setOpenAddAddonsModal(false);
  };

  const handleClick = (addon: any) => {
    let addonType = Object.keys(addon)[0];
    const isChecked = addonsCurrentSelection.some(
      (element: any) => element.refId === addonType
    );

    if (isChecked) {
      setAddonsCurrentSelection(
        addonsCurrentSelection.filter((label: any) => label.refId !== addonType)
      );

      // Check if it was originally selected
      if (
        originalAddonsSelection.some(
          (element: any) => element.refId === addonType
        )
      ) {
        setUncheckedAddons([...uncheckedAddons, addonType]);
      }
    } else {
      setAddonsCurrentSelection([
        ...addonsCurrentSelection,
        { ...addon[addonType], refId: addonType },
      ]);

      setUncheckedAddons(
        uncheckedAddons.filter(
          (unCheckedAddon: any) => unCheckedAddon !== addonType
        )
      );
    }
  };

  const handleSaveForProduct = async () => {
    const allAddonsToAdd = addonsCurrentSelection.filter((element: any) => {
      return !product?.addons.some(
        (addon: any) => addon.refId === element.refId
      );
    });
    toast.loading(t('loading.label'));
    const allAddonsAdded = [];

    for (const addon of uncheckedAddons) {
      // these addons are to be deleted
      await deleteAddons(addon, product.id, undefined, false);
    }

    let index = 0;
    for (const addon of allAddonsToAdd) {
      allAddonsAdded.push(
        await addAddons(
          { ...addon, general: false },
          product.id,
          product.vat,
          addon,
          undefined,
          {
            multiple: true,
            isLast: index === allAddonsToAdd.length - 1,
          }
        )
      );
      index++;
    }
    const isAllChanged = allAddonsAdded.every((el) => !!el);

    if (isAllChanged) {
      toast.dismiss();
      toast.success(t('changedAddons.success'));
    } else {
      toast.error(t('changedAddons.error'));
    }
    return;
  };

  return (
    <ModalPopup
      modal={modal}
      cancel={cancel}
      setOpenEntityModalTwo={setOpenAddAddonsModalTwo}
      setOpenEntityModal={setOpenAddAddonsModal}
      openAddEntityModal={openAddAddonsModal}
      openAddEntityModalTwo={openAddAddonsModalTwo}
      modalType={'addons'}
      CreateAddonButton={CreateAddonButton}
    >
      <div className='flex flex-wrap gap-4 auto-rows-max mb-4'>
        {addonsForm.map((addon: any, index: any) => {
          return (
            <div
              onClick={() => {
                handleClick(addon);
              }}
              key={index}
              className='flex items-center justify-between transition duration-200 rounded-3xl shadow col-span-1 p-1.5 cursor-pointer hover:shadow-md'
            >
              <div
                style={{ maxWidth: '5rem' }}
                className='overflow-hidden text-sm ml-2 flex items-center'
              >
                <span
                  className='truncate'
                  title={addon[Object.keys(addon)[0]].title}
                >
                  {addon[Object.keys(addon)[0]].title}
                </span>
              </div>
              {addonsCurrentSelection?.some(
                (element: any) => element.refId === Object.keys(addon)?.[0]
              ) ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='text-droov top-2 right-2 ml-3 mr-0.5 w-6 h-6 tick'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                  stroke='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='none'
                  stroke='currentColor'
                  className='text-droov top-2 right-2 ml-3 mr-0.5 w-6 h-6 tick'
                >
                  <path
                    fillRule='evenodd'
                    d='M 13 8 m 6 2 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </div>
          );
        })}
      </div>
    </ModalPopup>
  );
};

export default AddAddonsPopupModal;
