import { Chart, ChartOptions } from 'chart.js';

export const THICKNESS_PADDING = 200;
const getAspectRatio = () => window.innerWidth / 460;

export const graphOptions = (opts?: {
  setThickness: (thick: number) => void;
  itemsCount: number;
  currency: string;
}): ChartOptions<'bar'> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      backgroundColor: '#000',
      displayColors: false,
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || '';
          // float with two digits after the comma
          const value = context.parsed.y.toFixed(2) || '';
          return `${label}: ${value}${opts?.currency || ''}`;
        },
      },
    },
  },
  elements: {
    bar: {
      backgroundColor: 'rgba(0,0,0)',
      borderRadius: 6,
    },
  },
  aspectRatio: getAspectRatio(),
  onResize(chart: Chart, dimensions) {
    chart.options.aspectRatio = getAspectRatio();
    if (opts?.itemsCount) {
      opts?.setThickness(
        (dimensions.width - THICKNESS_PADDING) / opts.itemsCount
      );
    }
  },
  layout: {
    padding: {
      left: 30,
      right: 30,
      bottom: 20,
      top: 10,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: (value) => value + (opts?.currency || ''),
      },
      grid: {
        display: false,
      },
    },
  },
});
