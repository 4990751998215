import db from "./firebase"
import {DiscountObject} from "../types";
import {useEffect, useState} from "react";

export const createDiscount = async (user:any, discountObject: DiscountObject) => {
    try {
        await db.collection("companies").doc(user).collection("discounts").doc(discountObject.id || undefined).set(discountObject)
        return
    } catch (e) {
        console.log(e)
        return e
    }
}

export const deleteDiscount = async (user:any, discountObject: DiscountObject) => {
    try {
        await db.collection("companies").doc(user).collection("discounts").doc(discountObject.id).delete()
        return
    } catch (e) {
        console.log(e)
        return e
    }
}

export const useDiscounts = (user:any) => {

    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchDiscountsAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchDiscountsAsync = async () => {
        setLoading(true)
        let allDiscounts: any = []
        const docs = await db.collection("companies").doc(user.companyId).collection("discounts").get()
        docs.forEach(data => {

            const expDate = () => {
                if (!data.data().date) {
                    return ""
                }

                return data.data().date.toDate()
            }
            allDiscounts.push({
                id: data.id, ...data.data(),
                date: expDate()
            })
        })
        setDiscounts(allDiscounts)
        setLoading(false)
    }

    return [discounts, loading]


}


