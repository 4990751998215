import create from 'zustand';
import { OrderObject } from '../types';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';
import { useAllOrders } from '../firebase-logic/orders';
import { useEffect, useState } from 'react';

type OrdersState = {
  orders: OrderObject[];
  setOrders: (orders: OrderObject[]) => void;
  startAfter: QueryDocumentSnapshot | undefined;
  setStartAfter: (q?: QueryDocumentSnapshot) => void;
  reset: () => void;
}
export const useOrdersState = create<OrdersState>((set) => ({
  orders: [],
  startAfter: undefined,
  setStartAfter: (startAfter) => set({ startAfter}),
  setOrders: (orders) => set({ orders }),
  reset: () => {
    set({
      orders: [],
      startAfter: undefined,
    })
  }
}));


type PaginatedOrdersProps = {
  id: string,
  limit?: number,
  phoneNumber?: string
};

const usePaginatedOrdersState = ({ id, limit, phoneNumber }: PaginatedOrdersProps ) => {
  const state = useOrdersState();

  const [orders, ordersLoading, loadMore, noOrdersLeft, loadState] =
    useAllOrders(
      { companyId: id },
      phoneNumber,
      { startAfter: state.startAfter, setStartAfter: state.setStartAfter },
      !(state.orders.length > 0),
      limit
    );
  const [afterFirstRender, setIsFirstRender] = useState(false);

  useEffect(() => {
    if (phoneNumber) {
      state.setStartAfter(undefined);
    }
    setIsFirstRender(true);
    return () => {
      if (phoneNumber) {
        state.setStartAfter(undefined);
      }
    }
  }, []);

  useEffect(() => {
    if (afterFirstRender) {
      const timestamps = state.orders.map(order => order.orderTimestamp?.received ?? 0)
      const ordersSet = new Set([
        ...state.startAfter ? state.orders : [],
        ...orders.filter(order => !timestamps.includes(order.orderTimestamp?.received ?? 0))
      ]);
      state.setOrders(Array.from(ordersSet));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, afterFirstRender]);

  return [state.orders, ordersLoading, loadMore, noOrdersLeft, loadState] as const;
};


export default usePaginatedOrdersState;