import React, { useState } from 'react';
import { ReactComponent as CarIcon } from '../assets/car.svg';
import { ReactComponent as BikeIcon } from '../assets/bike.svg';
import { ReactComponent as WalkingIcon } from '../assets/walking.svg';
import { CourierProps, CourierVehicleType } from '../types';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';
import EditPencil from './EditPencil';
import { updateCourierFieldsById } from 'firebase-logic/couriers';
import useAuthState from 'global-states/useAuthState';

// export enum CourierState {
//     Active = "green",
//     Inactive = "red"
// }

function CustomCourierCard({ courierObject, onClicked }: CourierProps) {
  const { t } = useTranslation(['couriers']);
  const auth: any = useAuthState.getState();
  const [courierStatus, setCourierStatus] = useState(courierObject.status);

  const handleToggle = async () => {
    const updatedStatus =
      courierStatus === 'Available' ? 'On pause' : 'Available';
    setCourierStatus(updatedStatus);
    await updateCourierFieldsById(auth.user, courierObject.id!, {
      status: updatedStatus,
    });
  };

  return (
    <div className='bg-white  p-4 m-2 h-52 w-72 rounded-xl text-black flex flex-col justify-between'>
      <div className='flex justify-between'>
        <Switch
          id={'day'}
          checked={courierStatus === 'Available' && !courierObject.invited}
          onChange={handleToggle}
          disabled={courierObject.invited}
          className={`${
            courierStatus === 'Available' ? 'bg-droov' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors outline-none 
          ${
            courierObject.invited
              ? 'opacity-40 bg-gray-200 disabled cursor-not-allowed'
              : ''
          }`}
        >
          <span
            className={`${
              courierStatus === 'Available' ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform outline-none`}
          />
        </Switch>
        <div onClick={onClicked} title={t('editCourier.label')}>
          <EditPencil />
        </div>
      </div>
      {/*Rider image*/}
      <div className='inline-flex justify-center'>
        {courierObject.profileURL ? (
          <img
            className='inline object-cover w-16 h-16 rounded-full'
            src={courierObject.profileURL}
            alt='Courier'
          />
        ) : (
          <span className='inline-block w-16 h-16 rounded-full overflow-hidden bg-gray-100 mr-3'>
            <svg
              className='h-full w-full text-gray-300'
              fill='currentColor'
              viewBox='0 0 24 24'
            >
              <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
            </svg>
          </span>
        )}
      </div>
      {/*Rider name*/}
      <div className='flex justify-center mt-2 mb-3'>
        <h1 className='text-xl mr-2 line-clamp-1'>
          {courierObject.courierName}
        </h1>
        {courierObject.vehicleType === CourierVehicleType.Car ? (
          <CarIcon className='h-7 w-auto text-gray-400 my-auto' />
        ) : courierObject.vehicleType === CourierVehicleType.Bike ? (
          <BikeIcon className='h-7 w-auto text-gray-400 my-auto' />
        ) : (
          <WalkingIcon className='h-6 w-auto text-gray-400 my-auto' />
        )}
      </div>
      {/*Rider phone*/}

      <div className='inline-flex justify-center items-center text-gray-500'>
        {courierObject.invited ? (
          <div className='text-white rounded-lg bg-gray-300 text-sm flex flex-row-reverse items-center h-8 mr-2 px-2 gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              className='w-5 h-5'
            >
              <path d='M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z' />
              <path d='M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z' />
            </svg>
            {courierObject.invited && t('invited.label')}
          </div>
        ) : (
          <div
            className={`text-white rounded-lg bg-gray-300 text-sm flex h-8 px-2 gap-2 ${
              courierStatus === 'Available' ? 'bg-droov px-2' : 'bg-gray-300'
            } `}
          >
            {courierStatus === 'Available' ? (
              <div className='items-center flex flex-row'>
                <p className='flex-col text-sm mr-2'>{t('available.label')}</p>
                <h3 className='text-lg'>&#128526;</h3>
              </div>
            ) : (
              <div className='items-center flex flex-row'>
                <p className='flex-col text-sm mr-2'>{t('onpause.label')}</p>
                <h3 className='text-lg opacity-70'>&#x1F645;</h3>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomCourierCard;
