import InputFieldSelector from '../ui-components/InputFieldSelector';
import InputFieldWithType from '../ui-components/InputFieldWithType';
import NumberInput from '../ui-components/NumberInput';
import { useHistory } from 'react-router-dom';
import { DiscountObject, DiscountType } from '../types';
import React, { useEffect, useState } from 'react';
import { createDiscount, deleteDiscount } from '../firebase-logic/discounts';
import { useLocation } from 'react-router-dom';
import { modalConfirm } from '../ui-components/ConfirmationModal';
import DatePicker from 'react-datepicker';
import Summary from '../ui-components/layout/Summary';
import useAuthState from '../global-states/useAuthState';
import CustomBreadCrumb from '../ui-components/CustomBreadCrumb';
import CustomInputField from '../ui-components/CustomInputField';

import toast from 'react-hot-toast';
import CustomCheckBox from '../ui-components/EcoFriendlyCheck';
import { useTranslation } from 'react-i18next';

function CreateDiscountScreen() {
  const auth: any = useAuthState.getState();
  const history = useHistory();
  const location: any = useLocation();
  let initialDiscountObject: DiscountObject = location?.state?.data;

  const { t } = useTranslation(['discount']);

  const [state, setState] = useState<DiscountObject>({
    code: '',
    color: 'gray',
    value: 0,
    usage: 0,
    date: new Date(),
    type: DiscountType.Percent,
    maxUsage: 0,
    minOrderValue: 0,
    hasNoExpirationDate: false,
  });
  const [discountVal, setDiscountVal]: any = useState();
  const [minOrderVal, setMinOrderVal]: any = useState();

  useEffect(() => {
    if (initialDiscountObject) {
      setState(initialDiscountObject);
      setDiscountVal(initialDiscountObject.value);
      setMinOrderVal(initialDiscountObject.minOrderValue);
    }
  }, [initialDiscountObject]);

  const onSavePressed = async (e: any) => {
    e.preventDefault();
    const toastId = toast.loading(t('loading.label'));
    // Validate if all necessary values are set
    try {
      await createDiscount(auth.user, state);
      toast.dismiss(toastId);
      if (initialDiscountObject) {
        toast.success(t('editSuccess.label'));
      } else {
        toast.success(t('createSuccess.label'));
      }

      history.push('/discounts');
    } catch (error) {
      toast.dismiss(toastId);
      console.log('error in saving', error);
      toast.error(t('errorSaving.label'));
    }
  };

  const StringToDate = () => {
    return state.date ? new Date(state.date) : new Date();
  };

  const onDeletePressed = async () => {
    const toastId = toast.loading(t('loading.label'));
    const err = await deleteDiscount(auth.user, state);
    toast.dismiss(toastId);
    if (err) {
      toast.error(t('errorDelete.label'));
      return;
    }
    toast.success(t('deleteSuccess.label'));
    history.push('/discounts');
  };

  const discountSummaryData = [
    { parameter: 'Code', data: state.code },
    {
      parameter: t('summary_value.label'),
      data:
        state.type === DiscountType.Percent
          ? `${state.value} %`
          : new Intl.NumberFormat(auth.companyLanguage, {
              style: 'currency',
              currency: auth.companyCurrency,
              currencyDisplay: 'symbol',
              minimumFractionDigits: 2,
            }).format(state.value),
    },
    { parameter: t('summary_usages.label'), data: state.maxUsage },
    { parameter: t('summary_minordervalue.label'), data: state.minOrderValue },
    {
      parameter: t('summary_expirationdate.label'),
      data: !state.hasNoExpirationDate ? state.date?.toDateString() : '∞',
    },
  ];

  return (
    <div>
      <CustomBreadCrumb
        onClickAction={() => history.goBack()}
        displayString={t('discounts.label')}
      />
      {initialDiscountObject ? (
        <h1 className='text-2xl'>{t('editdiscount.label')}</h1>
      ) : (
        <h1 className='text-2xl'>{t('addnewdiscount.label')}</h1>
      )}
      <br />
      <form onSubmit={onSavePressed}>
        <div className='lg:flex w-full'>
          <div className='flex-1 md:pr-4'>
            <div className='w-full bg-white p-4 rounded-md'>
              <h1>{t('discountcode.label')}</h1>
              <p className='droov_text_description_light'>
                {t('discountcode.text')}
              </p>
              <br />
              <CustomInputField
                value={state.code}
                name='code'
                id='code'
                required={true}
                onChange={(e) =>
                  setState({
                    ...state,
                    code: e.target.value.toUpperCase().replace(/\s/g, ''),
                  })
                }
                type='text'
                classnameadditional={'uppercase'}
              />
            </div>

            <br />
            <div className='w-full bg-white p-4 rounded-md'>
              <h1>{t('value.label')}</h1>
              <p className='droov_text_description_light'>{t('value.text')}</p>
              <br />
              <InputFieldSelector
                disabled={true}
                value={discountVal ? discountVal : '0'}
                selectedValue={state.type}
                onSelectedValueChanged={(selectedValue: DiscountType) =>
                  setState({
                    ...state,
                    type: selectedValue,
                  })
                }
                onValueChanged={(value: any) => {
                  setDiscountVal(value);
                  setState({ ...state, value: parseFloat(value) });
                }}
              />
            </div>

            <br />
            <div className='w-full bg-white p-4 rounded-md'>
              <h1>{t('minimumordervalue.label')}</h1>
              <p className='droov_text_description_light'>
                {t('minimumordervalue.text')}
              </p>
              <br />
              <div className='w-full md:w-1/3 lg:w-1/4'>
                <InputFieldWithType
                  value={minOrderVal}
                  onInputFieldValueChanged={(value: string) => {
                    setMinOrderVal(value);
                    setState({
                      ...state,
                      minOrderValue: parseFloat(value),
                    });
                  }}
                />
              </div>
            </div>
            <br />
            <div className='w-full bg-white p-4 rounded-md'>
              <h1>{t('usagelimit.label')}</h1>
              <p className='droov_text_description_light'>
                {t('usagelimit.text')}
              </p>
              <br />
              <NumberInput
                value={state.maxUsage ?? 0}
                onNumberChanged={(newNumberValue: number) =>
                  setState({
                    ...state,
                    maxUsage: newNumberValue,
                  })
                }
              />
            </div>
            <br />

            <div className='w-full bg-white p-4 rounded-md '>
              <h1>{t('expirationdate.label')}</h1>
              <br />
              <div className='flex items-center'>
                <CustomCheckBox
                  value={state.hasNoExpirationDate}
                  onValueChanged={() =>
                    setState({
                      ...state,
                      hasNoExpirationDate: !state.hasNoExpirationDate,
                    })
                  }
                  text={t('noexpirationdate.label')}
                />
              </div>
              <br />
              <div className='relative z-40'>
                <DatePicker
                  className='my-3 focus:border-droov border-gray-200'
                  selected={StringToDate()}
                  calendarStartDay={1}
                  onChange={(date) => {
                    if (date != null) {
                      setState({
                        ...state,
                        date: new Date(date),
                      });
                    }
                  }}
                ></DatePicker>

                {state.hasNoExpirationDate && (
                  <div className='absolute  bg-white bg-opacity-70  z-10 inset-0'></div>
                )}
              </div>
            </div>
            <br />
          </div>

          <Summary summaryObject={discountSummaryData} />
        </div>
      </form>

      {initialDiscountObject && (
        <div>
          <div className='flex w-full  border-b my-4'></div>

          <div className='md:flex w-full sm:justify-left p-4'>
            {/*Delete courier*/}
            <div className='w-full md:w-1/2 lg:w-1/3'>
              <button
                onClick={() =>
                  modalConfirm(
                    {
                      title: t('deleteDiscount.label'),
                      message: t('deleteDiscountQuestion.label'),
                    },
                    onDeletePressed
                  )
                }
                className='red_button text-xl'
              >
                {t('deletethisdiscount.button')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateDiscountScreen;
