import React from 'react';

export default function CustomCheckBox({
  onValueChanged,
  value,
  text,
  icon,
}: any) {
  return (
    <label className='select-none cursor-pointer inline-flex items-center mt-3'>
      <input
        checked={value}
        type='checkbox'
        className='form-checkbox cursor-pointer h-5 w-5 focus:ring-0 rounded-md text-droov'
        onChange={() => onValueChanged(!value)}
      />
      <span className='ml-2'>{text}</span>
      <span className='ml-2'>{icon}</span>
    </label>
  );
}
