import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OrdersTable from '../../OrdersTable';
import { OrderObject } from '../../../types';
import OrdersTableRow from '../../OrdersTableRow';
import React, { Ref } from 'react';
import { OrdersHistoryViewProps } from './types';
import { useScrollToLastPosition } from '../../../hooks/useScrollToLastPosition';
const OrdersHistoryTable = ({
  orders,
  loading,
  loadMore,
  noMoreLoad,
  scrollId
}: OrdersHistoryViewProps) => {
  const { t } = useTranslation(['orders']);
  const history = useHistory();
  const { makeScroll }  = useScrollToLastPosition({ scrollId });

  const handleTableRowClick = (orderId: string) => {
    makeScroll();
    history.push(`/orders/${orderId}`, { goBackLocation: '/orders#history' });
  };

  return (
    <div>
      <div className='ml-2'>
        <table className='text-left w-full divide-y divide-gray-200'>
          <thead className='bg-white border-t border-b border-gray-200 rounded-t-xl flex text-gray-900 w-full '>
          <tr className='flex w-full'>
            <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
              {t('id.label')}
            </th>
            <th className='px-2 py-3 w-4/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
              {t('customer.label')}
            </th>
            <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
              {t('orderdate.label')}
            </th>
            <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider'>
              {t('ordervalue.label')}{' '}
            </th>
            <th className='px-2 py-3 w-2/12 text-left text-xs font-bold text-blue-900 uppercase tracking-wider flex justify-center'>
              {t('ordertype.label')}{' '}
            </th>
          </tr>
          </thead>
        </table>
      </div>
      <div className='ml-2'>
        <div className='flex justify-center'>
          {
            <OrdersTable
              onLoadMore={() => loadMore()}
              moreOrdersLoading={loading}
              noOrdersLeft={noMoreLoad}
              orders={orders}
              orderRow={(order: OrderObject, idx) => (
                <OrdersTableRow
                  key={idx}
                  orderObject={order}
                  onClicked={handleTableRowClick}
                  onDeleted={() => console.log('Delete clicked')}
                />
              )}
              lastOrderRow={(order: OrderObject, ref: Ref<HTMLTableRowElement>, idx) => (
                <OrdersTableRow
                  key={idx}
                  ref={ref}
                  orderObject={order}
                  onClicked={handleTableRowClick}
                  onDeleted={() => console.log('Delete clicked')}
                />
              )}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default OrdersHistoryTable;