import { OrderStates } from 'typings/OrderTypes';
import { ReactComponent as TruckIcon } from '../../assets/truck.svg';
import { useEffect, useState } from 'react';
import useAuthState from 'global-states/useAuthState';
import { functionsEuropeWest3 } from 'firebase-logic/firebase';

export default function DeliveryCard(props: any) {
  const { order } = props;
  const [loading, setLoading] = useState(false);
  const [commandAction, setCommandAction] = useState<any>(null);

  const auth: any = useAuthState.getState();
  useEffect(() => {
    const executeCommand = async () => {
      console.log('COMMAND, ', commandAction);
      if (commandAction !== null) {
        setLoading(true);

        const updateOrderStatus =
          functionsEuropeWest3.httpsCallable('updateOrderStatus');
        const handleIncomingOrder = functionsEuropeWest3.httpsCallable(
          'handleIncomingOrder'
        );
        console.log(updateOrderStatus);
        console.log(handleIncomingOrder);
        switch (commandAction.type) {
          case OrderStates.DELIVERED:
            await updateOrderStatus({
              orderId: commandAction.order.documentID,
              taskId: commandAction.order.taskId,
              status: 'delivered',
              caller: 'COMPANY',
              companyId: auth.user,
            });
            break;
          default:
            break;
        }
        setCommandAction(null);
        setLoading(false);
      }
    };
    executeCommand();
  }, [auth.user, commandAction]);

  if (loading) {
    return (
      <div className='bg-white p-4 mb-4 rounded-xl text-sm'>
        <div className='loaderOrderTab' />
      </div>
    );
  }
  return (
    <div className='bg-white p-4 mb-4 rounded-xl text-sm'>
      <div className='bg-gray-100 p-2 rounded-md text-center font-bold'>
        {order.orderID}
      </div>
      <div className='p-2 rounded-xl text-center font-bold'>
        {order.customer.name}
      </div>
      <div className='flex justify-center'>
        <div className='bg-light_blue rounded-full w-12 h-12 flex justify-center items-center text-gray-700'>
          <TruckIcon />
        </div>
      </div>
      <div className='mt-1 text-darker_blue text-center'>
        {props.delivering}
      </div>
      <div className='flex flex-col mt-3'>
        <button
          className='order_blue_button'
          onClick={() => {
            setCommandAction({
              type: OrderStates.DELIVERED,
              order: order,
            });
          }}
        >
          {props.delivered}
        </button>
      </div>
    </div>
  );
}
