import React, { useEffect, useState } from 'react';
import db from '../../firebase-logic/firebase';
import useAuthState from '../../global-states/useAuthState';
import { ReactComponent as BlackCarIcon } from '../../assets/blackCar.svg';
import { ReactComponent as BlackBikeIcon } from '../../assets/blackBike.svg';
import { ReactComponent as BlackWalkingIcon } from '../../assets/blackWalking.svg';
import { ReactComponent as BlackPin } from '../../assets/blackPin.svg';
import Deliverer from '../../assets/deliverer.jpg';
import HomeIcon from '../../assets/homeIcon.svg';
import { orderStatusDateFormatter } from '../../utils/DateFormatter';
import CustomLoadingScreen from '../CustomLoadingScreen';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../config';

interface DeliveryDetailsProps {
  order: any;
}

interface AssignedCourier {
  courierName: string;
  ecoFriendly: boolean;
  hasAssignedTask: boolean;
  phoneNumber: string;
  profileURL: string | null;
  status: string;
  vehicleType: string;
}

interface CourierDetails {
  assignedCourier: AssignedCourier;
  geoLocations: string;
  orderStatus: string;
  orderIds: string;
  estimatedTimeArrival: string | number;
}

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({ order }) => {
  const auth: any = useAuthState.getState();
  const [courierDetails, setCourierDetails] = useState<
    CourierDetails | undefined
  >(undefined);
  const [mapLoading, setMapLoading] = useState(true);
  const [center, setCenter] = useState<any>(null);
  const { t } = useTranslation(['orders']);

  const googleMapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    mapId: 'd5eece9d53c1b22a',
    fullscreenControl: false,
    zoomControl: false,
    gestureHandling: `none`,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    let courierSnapshot: () => void;

    if (order && order.orderType === 'delivery' && order.taskId) {
      courierSnapshot = db
        .collection('companies')
        .doc(auth.user)
        .collection('tasks')
        .doc(order.taskId)
        .onSnapshot((doc: any) => {
          if (doc.exists) {
            const courierInfo: CourierDetails = {
              assignedCourier: doc.data()?.assignedCourier,
              geoLocations: doc.data()?.geoLocations,
              orderStatus: doc.data()?.orderStatus,
              orderIds: doc.data()?.orderIds,
              estimatedTimeArrival: doc.data()?.estimatedTimeArrival,
            };
            setCourierDetails(courierInfo);
          }
        });
    }

    return () => {
      if (courierSnapshot) {
        courierSnapshot();
      }
    };
  }, [order, auth.user]);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    setMapLoading(true);
    try {
      if (order?.deliveryAddress?.geoCode) {
        setCenter(order?.deliveryAddress?.geoCode);
        setMapLoading(false);
      }
    } catch (error) {
      setMapLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className='w-full p-4'>
        <div className='flex justify-between'>
          <div className='flex font-medium text-lg mb-1 pl-2'>
            {t('delivery_details.label')}
          </div>
          <div className='flex items-center'>
            <div className='bg-gray-100 ml-1 px-2.5 py-1.5 rounded-md text-center text-md sm:text-md md:text-md lg:text-lg font-medium'>
              {courierDetails?.assignedCourier ? (
                <>
                  {courierDetails?.assignedCourier?.vehicleType === 'Bike' && (
                    <BlackBikeIcon />
                  )}
                  {courierDetails?.assignedCourier?.vehicleType === 'Car' && (
                    <BlackCarIcon />
                  )}
                  {courierDetails?.assignedCourier?.vehicleType ===
                    'Walking' && <BlackWalkingIcon />}
                </>
              ) : (
                <BlackPin />
              )}
            </div>
          </div>
        </div>
        {courierDetails?.assignedCourier && (
          <>
            <div className='flex flex-col mt-2 mb-4'>
              <div className='font-normal rounded-md text-left w-full'>
                <div className='flex'>
                  <div className='w-1/4 sm:w-1/4 md:w-1/6 pl-1.5 md:pl-2 lg:pl-3'>
                    {courierDetails?.assignedCourier?.profileURL ? (
                      <img
                        src={courierDetails?.assignedCourier?.profileURL}
                        alt='Courier Profile'
                        className='rounded-full w-14 h-auto border-4 border-blue-200 align-middle'
                      />
                    ) : (
                      <img
                        src={Deliverer}
                        alt='Default Deliverer Image'
                        className='rounded-full w-12 h-auto border-4 border-blue-200 align-middle'
                      />
                    )}
                  </div>
                  <div className='w-3/4 sm:w-3/4 md:w-5/6 flex flex-col md:flex-row justify-center'>
                    <div className='flex flex-col md:flex-row w-full'>
                      <div className='md:w-4/5 flex'>
                        <div className='flex flex-col justify-center'>
                          <div className='flex items-center'>
                            <span className='mr-1'>{t('courier.label')}</span>
                            <span className='ml-1'>
                              {courierDetails?.assignedCourier?.ecoFriendly && (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 24 24'
                                  width='24'
                                  height='24'
                                >
                                  <path fill='none' d='M0 0H24V24H0z' />
                                  <path
                                    d='M21 3v2c0 9.627-5.373 14-12 14H7.098c.212-3.012 1.15-4.835 3.598-7.001 1.204-1.065 1.102-1.68.509-1.327-4.084 2.43-6.112 5.714-6.202 10.958L5 22H3c0-1.363.116-2.6.346-3.732C3.116 16.974 3 15.218 3 13 3 7.477 7.477 3 13 3c2 0 4 1 8 0z'
                                    fill='rgba(60,207,135,1)'
                                  />
                                </svg>
                              )}
                            </span>
                          </div>
                          <div className='text-gray-400'>
                            <span>
                              {t('delivered_by.label')}{' '}
                              {courierDetails?.assignedCourier?.courierName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='md:w-1/5 mr-1.5 lg:mr-2 flex md:justify-end text-gray-400 items-center'>
                        {order?.orderTimestamp?.delivered ? (
                          <div>
                            {orderStatusDateFormatter(
                              order?.orderTimestamp?.delivered,
                              auth.companyLanguage,
                              auth.clockFormat12,
                              true
                            )}
                          </div>
                        ) : (
                          <div>{t('in_progress.label')}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        )}
        <div className='flex flex-col lg:flex-row'>
          <div className='pt-4 ml-2 font-normal rounded-md text-left lg:w-1/3 mb-2 sm:mb-2 md:mb-2 lg:mb-8'>
            <div>
              <div className='flex'>
                <div className='flex font-medium text-md'>
                  {t('delivery_address.label')}
                </div>
              </div>
            </div>
            <div className='text-gray-600 mt-4'>
              <div>C/O {order?.deliveryAddress?.customerName}</div>
              <div>{order?.deliveryAddress.streetAndHouseNumber}</div>
              <div>
                {order?.deliveryAddress?.postCode}{' '}
                {order?.deliveryAddress?.city}
              </div>
              {order?.deliveryAddress?.deliveryInstructions && (
                <div>
                  {t('notes.label')}:{' '}
                  {order?.deliveryAddress?.deliveryInstructions}
                </div>
              )}
            </div>
          </div>

          {/* Map */}
          <div className='rounded-md md:mt-2 lg:mt-4 overflow-hidden lg:w-2/3 h-52 sm:h-52 md:h-52 lg:h-48'>
            <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
              {!mapLoading && center ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={13}
                  options={googleMapOptions}
                >
                  <Marker
                    position={{
                      ...center,
                    }}
                    icon={HomeIcon}
                  />
                </GoogleMap>
              ) : (
                <CustomLoadingScreen />
              )}
            </LoadScript>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryDetails;
