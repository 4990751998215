import { useEffect, useRef } from 'react';
import create from 'zustand';

type ScrollState = {
  scroll: number;
  reset: () => void;
  activated: boolean,
  setScroll: (scroll: number, activated: boolean) => void;
}

const useLastScroll = create<ScrollState>((set) => ({
  scroll: 0,
  activated: false,
  reset: () => set({ scroll: 0, activated: false }),
  setScroll: (scroll, activated: boolean = false) => set({ scroll, activated }),
}))

export const useScrollToLastPosition = ({ scrollId }: { scrollId: string }) => {
  const scroll = useLastScroll();
  const scrollRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    scrollRef.current = document.getElementById(scrollId);
    if (scroll.scroll > 0 && scrollRef.current) {
      scroll.setScroll(scroll.scroll, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (scrollRef.current && scroll.activated) {
      scrollRef.current?.scrollTo({ top: scroll.scroll });
      scroll.setScroll(0, false);
    }
  }, [scrollRef, scroll.activated]);

  const makeScroll = () => {
    scroll?.setScroll(scrollRef.current?.scrollTop ?? 0, false);
  }

  return { makeScroll } as const;
}