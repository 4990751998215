import create, { GetState } from 'zustand';
import { DateRange } from '../../typings/holiday';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';

type ModalHolidaysData = { startDay: Date; numberOfDays: number };

type ModalHoliday = {
  startDay: ModalHolidaysData['startDay'] | null;
  numberOfDays: ModalHolidaysData['numberOfDays'];
  updateData: (data: Partial<ModalHolidaysData>) => void;
  resetData: () => void;
};
export const useModalHolidaysData = create<
  ModalHoliday & { get: GetState<ModalHoliday> }
>((set, get) => ({
  startDay: null,
  numberOfDays: 0,
  updateData: (data) =>
    set({
      startDay: data.startDay ?? get().startDay,
      numberOfDays: data.numberOfDays ?? get().numberOfDays,
    }),
  resetData: () => set({ startDay: null, numberOfDays: 0 }),
  get: get,
}));

const AddHolidayDialogContent = ({
  addedHolidays,
}: {
  addedHolidays: DateRange[];
}) => {
  const startDay = useModalHolidaysData((state) => state.startDay);
  const [endDay, setEndDate] = useState<Date | null>(null);
  const numberOfDays = useModalHolidaysData((state) => state.numberOfDays);
  const updateModelData = useModalHolidaysData((state) => state.updateData);

  const dateIntervals = addedHolidays.map<DateRange>((interval) => ({
    start: moment(interval.start).toDate(),
    end: moment(interval.end).toDate(),
  }));
  const { t } = useTranslation(['settings']);

  useEffect(() => {
    if (!startDay) {
      return;
    }
    const diffDays = moment(endDay ?? startDay).diff(startDay, 'days');
    updateModelData({
      numberOfDays: diffDays,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDay, endDay]);

  const updateDates = (date: [Date | null, Date | null]) => {
    if (date[0] !== null) {
      updateModelData({ startDay: date[0] });
      setEndDate(null);
    }
    if (date[1] !== null) {
      setEndDate(date[1]);
    }
  };

  return (
    <section className='flex items-center justify-between gap-x-5'>
      <div>
        <p className='droov_text_description_light'>
          {t('holiday_modal_range.label')}
        </p>
        <DatePicker
          className='my-3 rounded-md focus:border-droov border-gray-200'
          calendarStartDay={1}
          startDate={startDay}
          endDate={endDay}
          excludeDateIntervals={dateIntervals}
          onChange={updateDates}
          selectsRange
        />
      </div>
      <div>
        <p className='droov_text_description_light'>
          {t('holiday_modal_duration.label')}
        </p>
        <div className='mb-4 mt-4'>
          <span>{startDay ? numberOfDays + 1 : 0}</span>
        </div>
      </div>
    </section>
  );
};

export default AddHolidayDialogContent;
